import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import { useSelector} from 'react-redux';
import {getMenus} from "./../utils/reducer";
const Privacy = (props) => {
    const menusdata = useSelector(state => state.menusdata);
    const [privacyContent, setPrivacyContent] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {

        getMenus('200009').then(function (response) {
        console.log('privacymenunewwe',response);
    //  // Find the item with type "footermenu" and menu "Privacy"
    //  const foundItem = apiResponse.find(item => item.type === "footermenu" && item.menu === "Privacy");

    //  if (foundItem) {
        if (response?.result) {
            setPrivacyContent(response?.result[0].content);
        }
    //  }
       
           
        }).catch((err) => {
          console.error.bind("Login error", err);
        })
        
        }, []);
        

  return (
    
    <div style={{
        backgroundImage: 'url(assets/images/common-img/landingBg.jpg)',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}>
    <div className="content_page">
        <div className="container">
            <div className="header">
                <div className="logo">
                    <img src="assets/images/brand/logo-light.png" />
                </div>
                <a className="xray-btn" href='#' onClick={(e) => {e.preventDefault(); navigate(-1);}}> <span className="material-symbols-outlined icon" > arrow_left_alt </span>Back</a>
            </div>
            {privacyContent ? <div className="content" dangerouslySetInnerHTML={{ __html: privacyContent }} />:
            <div className="content">
                <h1 className="title">Privacy Policy</h1>
                <p>We are committed to safeguarding your privacy on its website located at liquidlogics.com. This is the
                    online privacy policy of Easy Street Capital, LLC, LLC (Easy Street Capital, LLC) – the company that
                    runs liquidlogics.com, NOVA, Bullseye, bullseyebridge.com and LiquidLogics.com and other services.
                    This Privacy Policy does not apply to products or services provided, or information obtained, other
                    than through the website. Please read the following policy to understand how your information will
                    be treated. This policy may change from time to time, so please check back periodically. If there is
                    an important change in our collection, use, or disclosure practices, we will attempt to send you a
                    notice via e-mail if you have provided us with an e-mail address. Please keep in mind that some of
                    the services mentioned specifically in this policy may not be available on the site at this time. If
                    you have questions concerning this policy, please contact Privacy Officer at
                    privacy@LiquidLogics.com. This Privacy Policy is effective as of March 1, 2014. </p>

                <h3> Lending and Service Provider’s Private Label Privacy and Confidentiality Policy</h3>
                <p> You could be using Easy Street Capital, LLC systems, platforms, or services where they are private
                    labeled to lending organizations and service providers. In this case, those providers are
                    responsible for their own privacy policy and practices. Easy Street Capital, LLC does not assume in
                    that case any responsibility of your data confidentiality or privacy. Those organizations are
                    responsible for the privacy and confidentiality of your data. We simply keep the data for them in
                    our systems, platforms or services for their use. You are subject to their confidentiality and
                    privacy practices and policies. Please make sure to visit the service or lending organization web
                    site for their handling, use policy, privacy and confidentiality policies.</p>

                <h3>What info does Easy Street Capital, LLC, LLC collect from me?</h3>
                <p>Easy Street Capital, LLC, LLC may collect information in several ways. For example, some personal
                    information is gathered when you register in the different sites managed or owned by Easy Street
                    Capital, LLC as a user through our account interface. During registration, we may ask for your name,
                    address, phone number, and e-mail. Once your registration is complete, you are no longer anonymous
                    to Easy Street Capital, LLC and are able to take full advantage of our services. In addition to
                    registration, we may ask you for information at other times, such as when you report a problem with
                    our site or services, or contact the company via the corporate e-mail address. If you contact Easy
                    Street Capital, LLC, we may keep a record of that correspondence. If you contact us by e-mail, we
                    use your e-mail address (which is personally identifiable information) only to respond to your
                    specific request or question. We keep that information and use it only for the purpose of providing
                    administrating account permissions and access approvals. We may collect your contact information
                    where necessary for your safety. If you want full access to our site, we will collect your e-mail
                    address. We may also collect your name and e-mail address for the limited purposes of protecting the
                    security and integrity of the site, taking precautions against liability, responding to the judicial
                    process, or for law enforcement on a matter related to public safety. If you believe that any
                    inaccurate or inappropriate information has been obtained or disseminated through your use of this
                    website, you should contact a representative of Easy Street Capital, LLC at
                    privacy@liquidlogics.com.
                </p>
                <h3> How does Easy Street Capital, LLC use cookies and other technology?</h3>
                <p>We may collect information through technology to make our site more interesting and useful to you.
                    For instance, we collect IP addresses and use technology to track traffic patterns on the site. Once
                    you have logged into the site, we may track your activities by using logs of database transactions
                    and server interactions. This data is used to improve your online experience. We also use this
                    information to understand which areas of our website are most appealing or least appealing. Our site
                    also may use cookies, which are pieces of information that a website sends to your computer while
                    you are viewing the website. Cookies provide information to the site so that the site will remember
                    who you are. These technical methods may involve the transmission of information either directly to
                    us or to another party we authorize to collect information on our behalf.</p>
                <h3>How does Easy Street Capital, LLC use the information?</h3>
                <p>Easy Street Capital, LLC’ primary goal in collecting information is to improve the site and provide
                    the user with the best possible experience on the site. We may also use your personal information to
                    notify you of other products or services available from Easy Street Capital, LLC. Except as provided
                    herein, only Easy Street Capital, LLC will have access to individuals' account information. We also
                    may do research on our users' demographics and behavior based on the information provided to us upon
                    registration, gathered from transaction activity, from our server log files or from surveys. This
                    research may be compiled, analyzed on an aggregated basis, which does not include information
                    identifying individual users.</p>
                <h3>Who is collecting information?</h3>
                <p>When you are on a Easy Street Capital, LLC site and are asked for personal information, you are
                    sharing that information with Easy Street Capital, LLC and its parent company or its subsidiaries.
                    Please be aware that other websites to which we link may collect personally identifiable information
                    about you when you visit those sites. The information practices of other websites linked to our
                    websites are not covered by this Privacy Policy.</p>
                <h3>With whom does Easy Street Capital, LLC share my information?</h3>
                <p>We will not disclose any of your personally identifiable information except when we have your
                    permission. For example, by using our site, you consent to share it with a lending organization who
                    you wish to share your information with and indicated that choice or under special circumstances,
                    such as when we believe in good faith that the law requires it or under the circumstances described
                    below. The following describes some of the ways that your information may be disclosed. Content:
                    information, videos, photos, text, and other content that you upload to the site will be associated
                    with your registered account, subject to the content control settings selected by the registered
                    client. Blinded Data: We may disclose “blinded” aggregated data and user statistics to other third
                    parties, and for other lawful purposes. Blinded data is data that does not identify an individual
                    person. Lending organizations: All information shared by you online will be made available to you to
                    distribute to any organization or individuals of choice where you expressly consent to Easy Street
                    Capital, LLC’ disclosure of such information to your lending organization. Lending organizations:
                    All information shared by you online may be made available to your administrators and you expressly
                    consent to Easy Street Capital, LLC’ disclosure of such information to your administrators. Other:
                    We also may disclose your information in special cases when we have reason to believe that
                    disclosing this information is necessary to identify, contact or bring legal action against someone
                    who may be violating Easy Street Capital, LLC’ Master Service Agreement signed by the Lending
                    organization, or may otherwise be causing injury to or interference with (either intentionally or
                    unintentionally) Easy Street Capital, LLC’ rights or property, other Easy Street Capital, LLC
                    website users or customers, or anyone else that may be harmed by such activities. We may disclose or
                    access account information when we believe in good faith that the law requires it and for
                    administrative and other purposes that we deem necessary to maintain, service and improve our
                    products and services. We occasionally hire or partner with other companies to provide limited
                    services on our behalf or we feel they are reputable companies to be affiliated with Easy Street
                    Capital, LLC, including packaging, delivering, answering customer questions about products or
                    services, sending postal mail, providing investor information, and processing data. We will only
                    provide those companies the information they need to deliver the service, and they are contractually
                    prohibited from using that information for any other purpose. As we continue to develop our
                    business, we may buy or sell businesses or assets. In such transactions, confidential customer
                    information generally is one of the transferred business assets. In the event of a transaction
                    involving the sale of some or all of Easy Street Capital, LLC’ businesses, customer and site visitor
                    information may be one of the transferred assets. In such case, the transferred information may
                    become subject to a different privacy policy. By using any of our sites or services you opt-in and
                    we have your permission, we may share your information with business affiliates that have strategic
                    and business partnerships with Easy Street Capital, LLC. These alliances are carefully screened to
                    further the mission of Easy Street Capital, LLC to provide an enriching environment that facilitates
                    our services as a financial technology company providing systems and services to the industry.</p>
                <h3>How can I control my personal information?</h3>
                <p>Easy Street Capital, LLC offers its users choices for the collection, use and sharing of personal
                    information. You may login at www.easystreetcap.com and any of its service such as
                    nova.liquidlogics.com and systems you are using if you wish to view, edit or delete your personal
                    information from our database, and we will use commercially reasonable efforts to accommodate your
                    request.</p>
                <h3>How does Easy Street Capital, LLC protect financial-related information?</h3>
                <p>Keeping your personal financial information private is vitally important to us. As a matter of policy
                    and business practice, Easy Street Capital, LLC does not sell personal information provided by its
                    customers. At Easy Street Capital, LLC, we conform to the Payment Card Industry (PCI) Data Security
                    Standard for the protection of financial credit card information. This standard provides security
                    requirements for credit card merchants to safely secure, transport and store your sensitive personal
                    and financial data. We may report a security breach to you as required by law. Any user statistics
                    that we may provide to prospective partners regarding financial matters are provided in the
                    aggregate only and do not include any personally identifiable information about any individual user.
                    We will transfer your credit card number, bank account number or other personal information to the
                    appropriate credit card company or bank in order to process any online payments or your mortgage
                    application.</p>
                <h3>What security precautions are in place to protect the loss, misuse, or alteration of my information?
                </h3>
                <p>Your Easy Street Capital, LLC Account Information and Easy Street Capital, LLC Profile are
                    password-protected so you have access to this personal information. We use secure socket layer
                    (“ssl”) encryption technology for all transmissions. Remember to sign out of your Easy Street
                    Capital, LLC account and close your browser window when you have finished your work. This is to
                    ensure that others cannot access your account by using your computer when you are away from it.
                    Because of the global nature of the Internet, when you give us information, that information may be
                    sent electronically to servers outside of the country where you originally entered the information.
                    Unfortunately, no data transmission over the Internet can be guaranteed to be 100% secure. Except as
                    discussed above or otherwise designated, this site does not use security encryption measures over
                    the Internet. Therefore, information that you disclose by use of this site, by posting a message or
                    using email, potentially could be collected and used by others since e-mail and message postings are
                    not encrypted. This may result in unsolicited messages from third parties or use of such information
                    by third parties for their own purposes, legal or illegal. As a result, while we strive to protect
                    your personal information, Easy Street Capital, LLC cannot ensure or warrant the security of any
                    information you transmit to us or from our services, and you do so at your own risk. Once we receive
                    your transmission, we use commercially reasonable efforts to ensure its security on our systems.</p>
                <p>Choice/Opt-In</p>
                <p>Except for administrative messages needed to run the site and administer your account, our website
                    provides users the opportunity to opt-in to receiving communications from us. Where indicated in
                    your personal account profile, just click on the appropriate choice to Opt-In/Out. For your
                    convenience and in order to facilitate transparent communication by you set up your registration
                    process to opt-in to the designated communication. For example, you may be able to opt-in to
                    communications with Easy Street Capital, LLC strategic partners, participation in research products
                    and communications with other third parties. We operate in this manner to protect your choice as a
                    user of our services. Please allow a reasonable period of time for your changes to take effect. To
                    ensure that you will not receive recurring marketing information from us unless you choose to, we
                    will provide simple instructions on every marketing e-mail from Easy Street Capital, LLC for you to
                    let us know that you have chosen to stop receiving such e-mails from us.</p>
                <h3>What else should I know about my privacy?</h3>
                <p>Please keep in mind that whenever you voluntarily disclose information online, that information may
                    be accessible to other customers and users to facilitate the services you are seeking. Ultimately,
                    you are solely responsible for maintaining the secrecy of your passwords and/or any account
                    information. Please be careful and responsible whenever you are online. When you or we end your
                    system use relationship with us, we will treat the information we have about you as if you were
                    still our system user.</p>
                <h3>Lending Data</h3>
                <p>Easy Street Capital, LLC recognizes the sensitivity of lending information and strives to provide
                    services that maintain the confidentiality of that information at all times. Easy Street Capital,
                    LLC ultimately is a service for your service provider to provide said services to you. We provide
                    your lender or service provider a location for all of your data in one place and build a profile you
                    can in some instances compare services or offerings from various service providers or lending
                    organization where we share the information with the lending organizations or service providers you
                    wish to do business with. Easy Street Capital, LLC does not offer mortgages, engage in rendering
                    mortgages or provide financial advice or services. You can choose to proceed with and engage any
                    lending organization of your choice from the organizations who provide you those services using our
                    platform, systems and services</p>
                <h3>Who do users contact with questions or concerns about our Privacy Policy?</h3>
                <p>If you need further assistance, please send an e-mail with your questions or comments to
                    privacy@LiquidLogics.com.</p>
            </div>}

        </div>
        <Footer />
    </div>
  
</div>
  );
};

export default Privacy;