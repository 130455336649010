import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import Select from 'react-select';
import { getCities } from "../utils/reducer";
import { useLocation, useNavigate} from 'react-router-dom';
import { addLOSFees} from "../utils/reducer";
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import HomeFooter from './HomeFooter';
const Createfee = () => {
    const dispatch = useDispatch();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const loading = useSelector((state) => state.loaderVisible); 
    const [popupMessage, setmessage] = useState('Fee successfully submitted.');
    const location = useLocation();
    const createfee = location.state?.createfee;
    const mode = location?.state?.mode;
    const [createFee, setCreateFee] = useState(createfee ? createfee : {'fee' : '' , 'feein' : 'Dollar'});
    const [formChanged, setFormChanged] = useState(false);
    const [errors , setErrors] = useState({});
    const navigate = useNavigate();
    

    const percentValueOptions = [
        { value: '', label: 'Please Select' },
        { value: 'Loan Amount', label: 'Loan Amount' },
        { value: 'Appraised Amount', label: 'Appraised Amount' },
        { value: 'Purchase Price', label: 'Purchase Price' },
      ];
    const paidbyOptions = [
        { value: '', label: 'Please Select' },
        { value: 'Borrower', label: 'Borrower' },
        { value: 'Seller', label: 'Seller' },
        { value: 'Lender', label: 'Lender' },
        { value: 'Broker', label: 'Broker' },
        { value: 'LoanOfficer', label: 'Loan Officer' },
      ];
    const feesType = [
        { value: '', label: 'Please Select' },
        { value: 'Origination Charges', label: 'Origination Charges' },
        { value: 'Processing Charges', label: 'Processing Charges' },
        { value: 'Underwriting Charges', label: 'Underwriting Charges' },
        { value: 'Valuation Charges', label: 'Valuation Charges' },
        { value: 'Payment Reserves', label: 'Payment Reserves' },
        { value: '3rd party Charges', label: '3rd party Charges' },
        { value: 'Other', label: 'Other' },
        
      ];
      useEffect(() => {
        const isFormChanged = JSON.stringify(createfee) !== JSON.stringify(createFee);
        setFormChanged(isFormChanged);
    }, [createfee, createFee]);

    const handleChange = (e) => {
        
        setCreateFee((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
     };
    const handleCheckBox = (e,type)=>{
        setCreateFee((prev)=>({
            ...prev , 'fee' : '' , 'feein' : type
        }));
    }

    const handleAmountChange = (e) => {
        // const parsedValue = parseFloat(e.target.value);
        if(createFee?.feein ==='Percent'){
            if(e.target.value <=100){
                let updatedValue = isNaN(e.target.value) || e.target.value < 0 ? '' : e.target.value;
            setCreateFee((prev) => ({
                ...prev,
                [e.target.name]: updatedValue,
            }));
            }
        }else{
            let updatedValue = isNaN(e.target.value) || e.target.value < 0 ? '' : e.target.value;
            setCreateFee((prev) => ({
                ...prev,
                [e.target.name]: updatedValue,
            }));
        }
        

    };
    
  useEffect(() => {
    console.log('createFee', createFee)
  }, [createFee])
  

     const handleSubmit =()=>{
        const error = {};
        const fieldsToCheck =   {'feename' : 'feename','paidby':'paidby','feetype':'feetype','effectivedate': 'effectivedate'}
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            if (fieldName === 'percentvalueof' && createFee.feein === "Percent") {
                continue; 
            }
            if (!createFee?.[fieldName]?.trim()) {
                error[fieldName] = `Please Enter ${fieldLabel}`;
            }
        };

        if(!createFee?.fee){
            error['fee'] = `Please Enter Amount`;
        }

        setErrors(error);
        if (Object.keys(error).length === 0) {
            const prefilsNames = ["title", "escrow", "originationFee", "docFee", "Title", "TITLE", "Escrow", "ESCROW", "OrginationFee",
            "DOCFEE", "docfee"]
            const feeNameToCheck = createFee?.feename?.toLowerCase()?.trim();
            const isDuplicate = prefilsNames.map(name => name.toLowerCase()).includes(feeNameToCheck);
            if (isDuplicate) {
                setmessage('Fee name already exists in Prefils');
                setPopupOpen(true);
                return;
            }
            console.log('createFee--->',createFee);
            let apiData = {...createFee}
        if ( apiData['fee']) {
             apiData['fee'] = parseFloat(apiData?.fee);
        };
        if(createFee?.feename?.trim()?.toLowerCase() === createfee?.feename?.toLowerCase()){
            delete apiData['feename'];
        }
            dispatch(showLoader());
            addLOSFees(apiData).then(function (response) {
                dispatch(hideLoader());
                if (response?.result ==='fee name already exists') {
                    setmessage('fee name already exists');
                    setPopupOpen(true)
                }else{
                    setmessage(mode === "edit" ? "Fee updated successfully" : 'Fee successfully submitted.');
                    setPopupOpen(true)
                }
                }).catch((err) => {
                  dispatch(hideLoader());
                  console.error.bind("my account error", err);
                })
        }
     };

     useEffect(() => {
       console.log('createFee--->',createFee);
     }, [createFee])
     const closePopup = () => {
        if(popupMessage!== 'Fee name already exists in Prefils' && popupMessage!== 'fee name already exists'){
            navigate('/losfees')
        }
        setPopupOpen(false);
    };

    const styles = {
        'opacity': formChanged ? '1' : '0.6',
        'backgroundColor': '#7CC149',
        'color': '#fff'
    };

    return (
        <div id="layout-wrapper">


            <MainHeader />

            {loading ? <Loader/> : null} 
        {isPopupOpen && (
            <PopupMessage
             type={popupMessage ==  'Fee name already exists in Prefils' || popupMessage === 'fee name already exists' ? 'warning' : 'success'}
             message={popupMessage}
             onClose={closePopup}
            />
        )}

            <SideMenu />

            <div className="main-content">

                <div className="page-content user_management add_default create_fee">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">{ mode === "edit" ? "Edit fee" : "Create fee" }</h4>
                                    <a href="#" id="navigateCreateFeeBack" onClick={(e)=>{e.preventDefault();navigate('/losfees')}} className="back_btn"><span className="material-icons icon"> arrow_back
                                    </span>BACK</a>
                                </div>
                                <div className="card">
                                    <div className="card-body ">
                                        <div className="form_section">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Fee Name</label>
                                                        <input id="feeName" value={createFee?.feename || ''} name='feename' onChange={handleChange} type="text" placeholder="" className={`form-control ${errors.feename && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, feename: null }) }} />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group d-flex justify-content-between align-items-center">
                                                        <label className="toggle mb-0">
                                                            <input id="feeDollar" onChange={(e)=>handleCheckBox(e,'Dollar')} checked={createFee?.feein ==='Percent' ?false : true} className="toggle-checkbox" type="checkbox" />
                                                            <div className="toggle-switch"></div>
                                                            <span className="toggle-label">Dollar</span>
                                                        </label>
                                                        <label className="toggle mb-0">
                                                            <input id="feePercent" onChange={(e)=>handleCheckBox(e,'Percent')} className="toggle-checkbox" checked={createFee?.feein ==='Percent' ?true : false} type="checkbox" />
                                                            <div className="toggle-switch"></div>
                                                            <span className="toggle-label">Percent</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-10">
                                                    <label className="toggle mb_10">

                                                    </label>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>{createFee?.feein === 'Percent' ? 'Percent' : 'Dollar'} Value</label>
                                                        <input id="feeDollarValue" type="number" name='fee'pattern="[0-9]*" value={createFee?.fee || ''} placeholder={createFee?.feein === 'Percent' ? '0%' : '$ 0.00'} onChange={handleAmountChange} className={`form-control ${errors?.fee && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, fee: null }) }} />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Effective Date</label>
                                                        <input id="feeEffectiveDate" type="date" name='effectivedate'  value={createFee?.effectivedate ? ( new Date(createFee?.effectivedate).toISOString().split('T')[0]) : ''} onChange={handleChange} placeholder="mm-dd-yyy" className={`form-control ${errors.effectivedate && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, effectivedate: null }) }}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row">
                                                    {/* <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>Percent Value Of</label>
                                                                <select  value={createFee?.percentvalueof || ''} onChange={handleChange} name='percentvalueof' placeholder="Please Select Option" className={`form-control ${errors.percentvalueof && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, percentvalueof: null }) }}>
                                                                    {percentValueOptions.map((data,index)=>(
                                                                         <option value={data.value} >{data.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div> */}
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>Paid By</label>
                                                                <select  id="feePaidBy" value={createFee?.paidby || ''} onChange={handleChange} name='paidby' placeholder="Please Select Option" className={`form-control ${errors.paidby && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, paidby: null }) }}>
                                                                {paidbyOptions.map((data,index)=>(
                                                                         <option value={data.value} >{data.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>Fee Type</label>
                                                                <select id="feeType" value={createFee?.feetype || ''} onChange={handleChange} name='feetype' placeholder="Please Select Option" className={`form-control ${errors.feetype && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, feetype: null }) }}>
                                                                {feesType.map((data,index)=>(
                                                                         <option value={data.value} >{data.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Description</label>
                                                        <textarea value={createFee?.description || ''} onChange={handleChange} id="description" name="description" rows="4"
                                                            cols="50" className="form-control"
                                                            spellcheck="false"> </textarea>
                                                    </div>
                                                </div>

                                                <div className="btn-group mt_10">
                                                    <button id="submitFee" onClick={(e)=>{e.preventDefault();handleSubmit()}} disabled={!formChanged} style={styles} className="fs_btn">{mode === "edit" ? "Update" : "Submit" }</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal popup fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Checklist</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">

                                        <div className="form-group">
                                            <label>Line Item name</label>
                                            <div className="dsp_flx">
                                                <input type="text" placeholder="Enter" className="form-control" />
                                                <button type="button" className="fs_btn ml_20">Add</button>
                                            </div>
                                        </div>

                                        <p><b>Checklist</b> <br />
                                            Begin creating your checklist by typing the <span className="color">Line item name
                                            </span> and clicking<span className="color"> Add</span> button.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="modal message_popup popup fade" id="messagepopup" tabindex="-1"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">

                                    <div className="modal-body">
                                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                        <span className="material-symbols-outlined icon"> delete </span>
                                        <h3>Delete</h3>
                                        <p>This action cannot be undone. <br />
                                            Do you want to continue?</p>
                                        <button type="button" className="fs_btn">Yes</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default Createfee;
