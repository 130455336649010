import React from "react";

const PasswordErrors = ({ passwordErrors }) => {
  return (
    <>
      <div className="col-lg-5 col-md-5 col-xs-12">
        <div className="create-password">
          <div className="crpsd-cnt-blk">
            <div className="create-password-instruction" id="instruction">
              <p
                className={passwordErrors.characterLength ? "error vaild" : "error"}
                id="err4"
              >
                {" "}
                <span className="material-symbols-outlined">
                  {passwordErrors.characterLength ? "check" : "close"}
                </span>{" "}
                New Password must be 8 characters long
              </p>
              <p
                className={passwordErrors.number ? "error vaild" : "error"}
                id="err2"
              >
                {" "}
                <span className="material-symbols-outlined">
                  {passwordErrors.number ? "check" : "close"}
                </span>{" "}
                Must contain at least one numeric value.
              </p>
              <p
                className={
                  passwordErrors.specialCharacter ? "error vaild" : "error"
                }
                id="err3"
              >
                {" "}
                <span className="material-symbols-outlined">
                  {passwordErrors.specialCharacter ? "check" : "close"}
                </span>{" "}
                It cannot contain the following special characters: &'"
              </p>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordErrors;
