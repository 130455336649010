import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const VideoViewer = (props) => {
    console.log(props.type);
    const docs = [
        { uri: props.source ,fileType: props.type,
            // fileName: props.name
         }  // Local File
      ];

   console.log('docs',docs);
    return (
        <>
         <div className="modal popup new_popup fade pdf_doc show" id="pdfdoc" tabindex="-1"
                        aria-labelledby="pdfdoc_achLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel"> {props.name}</h5>
                                    <button id="fileViewClose" type="button" className="pop_close" onClick={(e) => props.close(false)} data-bs-dismiss="modal" aria-label="Close"><span
                                        className="material-symbols-outlined">close</span></button>
                                </div>
                                <div className="modal-body">
                                <video width="100%" height="100%" controls autoPlay>
                                    <source src={props.source} type={`video/${props.type}`}/>
                                </video>
                                </div>
                            </div>
                        </div>
                    </div>
           

        </>
    );
};


export default VideoViewer;