import React, { useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn, forgotPassword} from "./../utils/reducer";
import { saveTokenToLocalStorage, saveUserDataLocalStorage } from "./../utils/local";
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import Loader from './Loader';
import PopupMessage from './PopupMessage';
const Login = () => {
        const navigate = useNavigate();
        const [isChecked,setIsChecked] = useState(false);
        const loading = useSelector((state) => state.loaderVisible);
        const [isPopupOpen, setPopupOpen] = useState(false);
        const [popupMessage, setPopupMsg] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const dispatch = useDispatch();
    const handleLogin = (e) => {
      e.preventDefault();
      // Reset error messages
      setEmailError('');
      setPasswordError('');
  
      // Simple email and password validation
      if (!email) {
        setEmailError('Please enter a valid email address or username.');
        return;
      }
  
      if (!password) {
        setPasswordError('Please enter a password.');
        return;
      }
      
      dispatch(showLoader());
      let userdata = {"username":email, "password":password}
      signIn(userdata).then(function (response) {

        dispatch(hideLoader());
        console.log('res', response);
        if (response.statusCode == 200 && response.result == "Invalid username/password") {
          setPopupMsg(response?.result)
          setPopupOpen(true)
        }else if(response.statusCode == 200 && response.result == "INACTIVE"){
          setPopupMsg('Invalid username/password')
          setPopupOpen(true)
        }
        else if(response.statusCode == 200){
          saveTokenToLocalStorage(response.result.token)
          saveUserDataLocalStorage(response.result)
          navigate('/pipeline');
        }
      }).catch((err) => {
        console.error.bind("Login error", err);
      })
      // If both email and password are valid, you can proceed with authentication logic here
      console.log('Email:', email);
      console.log('Password:', password);
    };
    const handleForgot = (e) => {
        e.preventDefault()
        setEmailError('');
        setPasswordError('');
        if (!email) {
            setEmailError('Enter a valid email address');
            return;
          }
          dispatch(showLoader());
        let userdata = {"emailid":email}
        forgotPassword(userdata).then(function (response) {
            if (response.result && response.result != "Client not found") {
                setPopupMsg('Password reset link sent to your email. Check your inbox to complete the process.')
                dispatch(hideLoader());
                setPopupOpen(true)
            }else if(response.result == "Client not found"){
              setPopupMsg(response?.result)
              setPopupOpen(true)
              dispatch(hideLoader());
            }
          }).catch((err) => {
            console.error.bind("Login error", err);
            dispatch(hideLoader());
          })
    }
    const closePopup = () => {
        setPopupOpen(false);
    };

    const handleCheckBox = () => {
      setIsChecked(!isChecked);
    }
  return (<>
    {loading ? <Loader/> : null} 
    {isPopupOpen && (
        <PopupMessage
          type={popupMessage ==  'Client not found' || popupMessage ==  "Invalid username/password" ? 'warning' : 'success'}
          message={popupMessage? popupMessage :"Password reset link sent to your email. Check your inbox to complete the process."}
          onClose={closePopup}
        />
      )}
    <div className="account-pages my-5">
    <div className="container">
        <div className="row justify-content-center login_box">
            <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="signin-header mb-4">
                    <img src="../assets/images/brand/logo-light.png" className="signin-logo" />
                   <a id="loginBack" className="xray-btn" href="#" onClick={(e) => {e.preventDefault(); navigate('/');}}> <span className="material-symbols-outlined"> west </span>Back</a>
                </div>
                <div className="card overflow-hidden">
            
                    <div className="card-body text-center"> 
                        <p>I am affiliated with Easy Street Capital LLC as</p>
                        <h2>Team Member</h2>
                        <div className="icon-circle center-position">
                            <img src="../assets/images/icons/team-member-icon.svg" />
                        </div>
                        <div className="p-2">
                            <form className="form-horizontal" action="#">

                                <div className="mb-3">
                                    <label htmlFor="username" className="form-label">Username or Email</label>
                                    <input type="text" className="form-control" id="username" placeholder="Enter valid email or username" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    <p className="text-danger">{emailError}</p>
                                </div>
        
                                <div className="mb-3">
                                    <label className="form-label">Password</label>
                                        <input id="loginPassword" className="form-control" placeholder="Enter password" aria-label="Password" aria-describedby="password-addon" type={!isChecked ? 'password' : 'text'} value={password} onChange={(e) => setPassword(e.target.value)} />
                                        <p className="text-danger">{passwordError}</p>
                                     
                                </div>

                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="remember-check" onClick={handleCheckBox} checked = {isChecked} />
                                    <label className="form-check-label" htmlFor="remember-check">
                                        Show Password
                                    </label>
                                </div>
                                <div className="btn_wrp">
                                    <button id="loginButton" className="btn btn-primary waves-effect waves-light" type="submit"  onClick={(e) => handleLogin(e)}>Log In</button>
                                    <a href='#' id="forgotLogin" className="text-muted" onClick={(e) => handleForgot(e)}><i className="mdi mdi-lock me-1"></i> Forgot your password?</a>
                                </div>
                            </form>
                        </div>
    
                    </div>
                </div>
                
                <div className="mt-4 text-center">
                    <div>
                        <p>© 2024 easystreetcapital.com. All rights reserved.</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div></>
  );
};

export default Login;