

export const formatPhoneNumber = (value) => {
    if (!value) return value;
    

    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  export const formatCurrency = (number) => {
    return number?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
    });
  };

  export const transformData = (data) => {
    const transformedData = {};

    // Helper function to format the label
    function formatLabel(label) {
        // Split the label into words based on spaces
        const words = label.split(' ');

        // Capitalize the first letter of each word and join with a space
        return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }

    data.forEach(item => {
        // Check if "Our Field" exists and is a string
        if (item["Our Field"] && typeof item["Our Field"] === 'string') {
            const fieldParts = item["Our Field"].split('.');
            const labelKey = fieldParts[0]; // e.g., applicant[0] or cleartoclose

            // Initialize the label if it doesn't exist
            if (!transformedData[labelKey]) {
                transformedData[labelKey] = {
                    label: formatLabel(labelKey),
                    subOptions: []
                };
            }

            // Add the sub-option
            transformedData[labelKey].subOptions.push({
                label: formatLabel(item["Display Name"]),
                value: `{${item["Display Name"]}}`
            });
        } else {
            console.warn(`"Our Field" is undefined or not a string for item:`, item);
        }
    });

    // Convert the transformedData object into an array
    return Object.values(transformedData);
  };
