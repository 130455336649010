import React, { useCallback, useState, useMemo, useEffect } from "react";
import JoditEditor from "jodit-react";

export default function Editor() {
  const [content, setContent] = useState("");

  useEffect(() => {
    const htmlCode = '<div className="my-class">This is your HTML code</div>';

    setContent(htmlCode);
  }, []);
  const config = useMemo(
    () => ({
      showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: false,
    }),
    []
  );

  const onChange = useCallback((newContent) => {
    // console.log("Content changed:", newContent);
    setContent(newContent);
  }, []);

  const onBlur = useCallback(
    (newContent) => {
      setContent(newContent);
    },
    [setContent]
  );

  return (
    <div>
      <JoditEditor
        value={content}
        config={config}
        onBlur={onBlur}
        onChange={onChange}
      />
    </div>
  );
}
