import React, { useState, useEffect, useRef} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import ReactQuill from "react-quill";
import { useLocation, useNavigate } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import { addTasksApi, getTasksApi, getallUsers, updateTasksApi ,loanidsget} from "./../utils/reducer";
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import { useLoanInfo } from '../utils/loanInfoContext';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { getTokenFromLocalStorage, getUserDataFromLocalStorage, saveUserDataLocalStorage } from "./../utils/local";
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import Loader from './Loader';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import PopupMessage from './PopupMessage.js';
import DataTable from 'react-data-table-component';


let token = getTokenFromLocalStorage()
const Tasks = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loaderVisible);
    const [isChild, setisChild] = useState(props.isChild || false);
    const [errors,setErrors] = useState({});

    const [users, setUsers] = useState([]);
    const [alert, setAlert] = useState(false);
    const [alert2, setAlert2] = useState(false);
    const [showTaskPopup, setShowTaskPopup] = useState(false);
    const [taskData, setTaskData] = useState({'assignedTo':'','defaultAssignment':'','emailid':'','dueDate':'',"taskDescription":''});
    const [getTaskData, setGetTaskData] = useState({});
    const [quillValue, setQuillValue] = useState('');
    const { loanInfo } = useLoanInfo();

    const [pageNumber, setPageNumber] = useState(1);
    const [assetCount, setAssetCount] = useState(5);
    const [totalCount, setTotalCount] = useState(0);
    const [perPage, setPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [statusChecked, setStatusChecked] = useState('false');
    const [loanid,setLoanid] = useState(loanInfo?.loanid);
    const location = useLocation();
    const [selectedOption, setSelectedOption] = useState(location?.state?.outstanding ? "Incomplete" : null);
    const [loanids,setLoanids] = useState([]);
    const loanidDropdown = loanids?.map((loanid) => ({
        value: loanid,
        label: loanid,
    }));
    // useEffect(() => {
    //     if (loanid) {
    //         getAllUserFun();
    //          getTaskFun();
    //     }
    // }, [loanid]);

    // useEffect(() => {
        
    //     getAllUserFun();
    //     if(location?.state?.outstanding === true){
    //          // setSelectedOption("Incomplete")
    //         getTaskFun("Incomplete");
    //     }else{
    //         getTaskFun();

    //     }
    // }, []);
    useEffect(() => {

        getAllUserFun();
        if(isChild){
            getAllLoansId();
        }
        getTaskFun();
    }, []);
    

    useEffect(() => {
        if(selectedOption !== null){
          getTaskFun();
        }
    }, [selectedOption])
    useEffect(() => {
        // console.log('useefect loanInfo')

        setLoanid(loanInfo?.loanid)
    }, [loanInfo?.loanid]);

const getAllLoansId = ()=>{
    loanidsget().then(function (response) {
        if (response?.result) {
            const loanIdsArray = response?.result?.map(item => item.loanid);
            setLoanids(loanIdsArray)
        }
        }).catch((err) => {
          console.error.bind("my account error", err);
        })
}

    let userdata = getUserDataFromLocalStorage();

// console.log('loanInfo',loanInfo)
const handleLoanClick = (loanid)=>{
    navigate('/tasks?loanid='+loanid);
}
    const columns = [
        ...(isChild ? [{
            name: 'Loan ID',
            cell: (row) => (
              <div onClick={() => handleLoanClick(row.loanid)} style={{ cursor: 'pointer' }}>
                {row.loanid ?? ""}
              </div>
            ),
            minWidth: '8%',
            maxWidth: '8%',
          }] : []),
       
       {
            name: 'Created Date',
            selector: (row) => row.created ? moment.utc(row.created).format("MM-DD-YYYY"):"",
            minWidth: '10%',
            maxWidth: '10%',
        },
        {
            name: 'Due Date',
            selector: (row) => row.dueDate ? moment.utc(row.dueDate).format("MM-DD-YYYY"):"",
            minWidth: '10%',
            maxWidth: '10%',
        },
        {
            name: 'Assigned By',
            selector: (row) => row.assignedFrom ??"",
            minWidth: '15%',
            maxWidth: '15%',
        },
        {
            name: 'Assigned To',
            selector: (row) => row.assignedTo ?? "",
            minWidth: '15%',
            maxWidth: '15%',
        },

        {
            name: 'Task',
            selector: (row) => {
                const parser = new DOMParser();
                const htmlDoc = parser.parseFromString(row.taskDescription ?? "", 'text/html');
                const descriptionText = htmlDoc.body.textContent;
                return (
                    <div className="clmn">
                            <span>{descriptionText}</span>
                        </div>
                );
            },
            minWidth: '24%',
            maxWidth: '24%',
        },
        {
            name: 'Default Assignment',
            // selector: (row) => row.defaultAssignment ?? "",
            selector: (row) => {
                const assignment = defaultAssignments?.find(user => user?.value === row?.defaultAssignment);
                return assignment ? assignment?.label : "";
            },
            minWidth: '10%',
            maxWidth: '10%',
        },
        {
            name: 'Status',
            cell: (row) => (
                <div className="clmn">
                    <span className={`badge ${row.status === "Incomplete" ? 'incomplete' : row.status === "Completed" ? '' : 'pastdue'}`}>
                        {row.status === "Completed" ? row.status : moment.utc(new Date()).format("MM-DD-YYYY") > moment.utc(row.dueDate).format("MM-DD-YYYY") ? "Past Due" : row.status}
                    </span>
                </div>
            ),
            minWidth: '8%',
            maxWidth: '8%',
        },
       
        {
            name: 'Done',
            cell: (row) => {
                if ((userdata.userId === row.assignToUserId || row.assignToUserId === row.userid)) {
                    return (
                        <div className="clmn">
                            <label className="toggle mb-0" onChange={e => updateTask(e, row)}>
                                <input className={`toggle-checkbox ${row.status === "Completed" ? 'pe-none':''}`} type="checkbox" checked={row.status === "Completed" ? statusChecked : false} disabled={row.status === "Completed"} value={taskData.status} name="status" />
                                <div className={`toggle-switch  ${row.status === "Completed" ? 'pe-none':''}`}></div>
                            </label>
                        </div>
                    );
                } else if (row.status === "Completed" && row.updated && (userdata.userId === row.userid)) {
                    return (
                        <div className="clmn">
                            <span>{row.updated ? moment.utc(row.updated).format("MM-DD-YYYY") : ""}</span>
                        </div>
                    );
                } else {
                    return (
                        <div className="clmn">
                            <span></span>
                        </div>
                    );
                }
                
            },
            minWidth: '8%',
            maxWidth: '8%',
        }
        
  
      ];
      const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',

            },
        },
        table: {
            style: {
                border: '1px solid #CCD8F6',
            },
        },
    };
    const getAllUserFun = () => {
        // dispatch(showLoader());
        getallUsers().then(function (response) {
            if (response) {
                // console.log('getallUsers response', response);
                let data = response?.result?.data.filter(item=>item?.securityInfo?.status === "ACTIVE")
                // console.log('data------------------------------------->',data.length);
                if (data) {
                    setUsers(data);
                }
                // dispatch(hideLoader());
            }
        })
            .catch((err) => {
                // console.error("error", err);
                dispatch(hideLoader());
            });
    };
   
    // console.log('isChild----------------->',isChild)
    const getTaskFun = () => {
        dispatch(showLoader());
        let PayloadBody = {};
        let userData = getUserDataFromLocalStorage();
       
        if(selectedOption!==""){
            PayloadBody["status"] = selectedOption;
        }
        if (!isChild) {
            let k = location?.search?.split("=");
             PayloadBody["loanid"] = parseInt(k?.[1])??"";
        }
        getTasksApi(PayloadBody).then(function (response) {
            if (response) {

                // if (response?.result?.data) {
                let data = response?.result?.data
                setTotalCount(data?.totalCount)
                setGetTaskData(data);
                setTaskData({'assignedTo':'','defaultAssignment':'','emailid':'','dueDate':'',"taskDescription":''});

                // }
                dispatch(hideLoader());
            }
        })
            .catch((err) => {
                // console.error("error", err);
                dispatch(hideLoader());
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTaskData(prev => ({
            ...prev,
            [name]: value
        }));
        setErrors({...errors,[name] : ""});
    };
    const handleFocus = (e) => {
        const {name,value} = e.target;
        setErrors({...errors,[name] : ""});
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
   

    const handleDateChange = (date, name) => {
        // If date is null (cleared), remove the key from the state object
        const updatedFormData = { ...taskData };
        if (date !== null) {
            updatedFormData[name] = formatDate(date);
        } else {
            delete updatedFormData[name];
        }
        setTaskData(updatedFormData);
        setErrors({...errors,[name] : ""});
    };
    const handleCheckBox = (e) => {
        // // console.log(e.target.checked);
        if (e.target.checked == true) {
            taskData["status"] = "Complete"
        } else {
            taskData["status"] = "InComplete"
        }
        // // console.log('taskData', taskData);
        setTaskData({ ...taskData });
    }

    const dataClear = () =>{
        setTaskData({'assignedTo':'','defaultAssignment':'','emailid':'','dueDate':'',"taskDescription":''});
        setQuillValue("")
        setErrors({})
    }

    const cancelTask = () => {
       
        // setQuillValueMain("")
       
    }
    const checkValidation = () => {
        let formvalid = true;
        const newErrors = {};
    
        let validationRules = {
            // assignedTo: "Enter a assignedTo",
            dueDate: "Enter a dueDate",
            defaultAssignment: "Enter a defaultAssignment",
            // emailid: "Enter a emailid"
        };
        if(isChild){
            validationRules['loanid'] = "Please Select Loan id"
        }

        Object.entries(validationRules).forEach(([key, errorMessage]) => {
            if (!taskData?.[key] || taskData[key].length === 0) {
                newErrors[key] = errorMessage;
            }
        });
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(quillValue, 'text/html');
        const descriptionText = htmlDoc.body.textContent;
        // console.log('descriptionText', descriptionText);
        if (!quillValue || quillValue.length === 0 || quillValue === "<p><br></p>" || descriptionText.length === 0) {
            newErrors['quillValue'] = `Enter a quillValue`;
        }
    
        // console.log('newErrors', newErrors);
        setErrors(newErrors);
    
        if (Object.keys(newErrors).length === 0) {
            //addTask();
        } else {
            formvalid = false;
            // console.log('errors ----> ', errors);
        }
    
        return formvalid;
    };
    // console.log('quillValue',quillValue);
    
    const addTask = () => {

      const isvalid = checkValidation()
      if(!isvalid){
        return
      }
        let userdata = getUserDataFromLocalStorage()
        // if(isvalid){

        
        // // console.log('quillValue', quillValue,);
        taskData["taskDescription"] = quillValue
        const splitValue = taskData && taskData.assignedTo && taskData.assignedTo.split("/");

        let PayloadBody = {
            assignedTo: splitValue[0],
            defaultAssignment: taskData.defaultAssignment,
            created: new Date(),
            emailid: taskData.emailid,
            dueDate: taskData.dueDate,
            status: "Incomplete",
            taskDescription: taskData.taskDescription,
            assignToUserId: splitValue[1],
            userid: userdata.userId,
            assignedFrom: userdata.username,
            loanid :taskData.loanid,
        }
        if (!isChild) {
            let k = location?.search?.split("=");
            PayloadBody["loanid"] = parseInt(k?.[1])??"";
            // PayloadBody["loanid"] = loanInfo?.loanid;
        }

        dispatch(showLoader());
        addTasksApi(PayloadBody).then(function (response) {
            if (response) {
                // console.log('add response', response);

                setAlert(true);
                getTaskFun()
                setShowTaskPopup(false);
                dispatch(hideLoader());
                dataClear();
              
            }
        })
            .catch((err) => {
                // console.error("error", err);
                dispatch(hideLoader());
            });
        // }
    }

    const updateTask = (e, item) => {
        setStatusChecked(e.target.checked)
        if (e.target.checked === true) {
            taskData["status"] = "Completed"
        } else {
            taskData["status"] = "InComplete"
        }
        let body = {
            status: taskData.status,
            loanid : item?.loanid
        }
        if (!isChild) {
            let k = location?.search?.split("=");
            body["loanid"] = parseInt(k?.[1])??"";
            // body["loanid"] = loanInfo?.loanid;
        }
      
        body["taskid"]=item.taskid || item.taskId
        // console.log('itemwwwwwwwwwwwwwwwwwww',item)
        // console.log('body',body)
        dispatch(showLoader());
        updateTasksApi(body).then(function (response) {
            if (response) {
                // console.log('add response', response);

                setAlert2(true);
                getTaskFun()
                setShowTaskPopup(false);
                dispatch(hideLoader());
            }
        })
            .catch((err) => {
                // console.error("error", err);
                dispatch(hideLoader());
            });
    }
    const handleAlertClose = () => {
        setAlert(false);
        getTaskFun()

    }

    const modules = {
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ color: [] }, { background: [] }],
          [{ script: 'sub' }, { script: 'super' }],
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }],
          ['link', 'image', 'video'],
          ['clean'],
          ['table'],
          [{ align: [] }],
        ],
        clipboard: {
          matchVisual: false,
        },
      };

    const indexOfLastItem = currentPage * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;
    let currentItems = []
    if (getTaskData && getTaskData.length > 0) {
        currentItems = getTaskData.slice(indexOfFirstItem, indexOfLastItem);
    }
    // console.log('get task data',getTaskData);
    // console.log('curent task data',currentItems);

    // const roles = [
    //     "LoanOfficer", "Processor", "UnderWriter", "Closer", "OpenerSetup",
    //     "Funder", "PostCloser", "Shipper", "Archiver", "LoanOfficerAsst",
    //     "Processor2", "Accounting", "Servicer", "QC", "DrawManagement",
    //     "Appraiser", "Accounting2", "Treasury", "SecondaryMarketplace",
    //     "Inspector", "Legal", "Management", "Servicer2", "SalesManagement",
    //     "CorporateLoanOfficer", "RelationshipManager", "RelationshipAssociate",
    //     "AcquisitionTeamMember", "CreditAnalyst", "CreditManagement",
    //     "CreditExecutive", "ChiefExecutive", "CreditCommittee", "ServicingAssociate",
    //     "ServicingManager", "InternalValuations", "ConstructionManagement",
    //     "ComplianceOfficer"
    // ];
    const roles = [
        { value: 'accountexecutive', label: 'Account Executive Officer' },
        { value: 'changeaccountexceutiveto', label: 'Account Executive Assistant' },
        { value: 'changeaccountingto', label: 'Accounting' },
        { value: 'adminto', label: 'Admin' },
        { value: 'changecloserto', label: 'Closer' },
        { value: 'changedrawmanagementto', label: 'Draw Management' },
        { value: 'changefunderto', label: 'Funder' },
        { value: 'changeopenerto', label: 'Opener/Setup' },
        { value: 'changeprocessorto', label: 'Processor' },
        { value: 'changeprocesser2to', label: 'Processor 2' },
        { value: 'changeservicerto', label: 'Servicer' },
        { value: 'changeservicer2to', label: 'Servicer 2' },
        { value: 'changeunderwriterto', label: 'Underwriter' }
      ]
      
    const defaultAssignments = [
        { value: 'accountexecutive', label: 'Account Executive Officer' },
        { value: 'changeaccountexceutiveto', label: 'Account Executive Assistant' },
        { value: 'changeaccountingto', label: 'Accounting' },
        { value: 'adminto', label: 'Admin' },
        { value: 'changecloserto', label: 'Closer' },
        { value: 'changedrawmanagementto', label: 'Draw Management' },
        { value: 'changefunderto', label: 'Funder' },
        { value: 'changeopenerto', label: 'Opener/Setup' },
        { value: 'changeprocessorto', label: 'Processor' },
        { value: 'changeprocesser2to', label: 'Processor 2' },
        { value: 'changeservicerto', label: 'Servicer' },
        { value: 'changeservicer2to', label: 'Servicer 2' },
        { value: 'changeunderwriterto', label: 'Underwriter' }
    ];
    // const defaultAssignments = [
    //     "LoanOfficer", "Processor", "UnderWriter", "Closer", "OpenerSetup",
    //     "Funder", "PostCloser", "Shipper", "Archiver", "LoanOfficerAsst",
    //     "Processor2", "Accounting", "Servicer", "QC", "DrawManagement",
    //     "Appraiser", "Accounting2", "Treasury", "SecondaryMarketplace",
    //     "Inspector", "Legal", "Management", "Servicer2", "SalesManagement",
    //     "CorporateLoanOfficer", "RelationshipManager", "RelationshipAssociate",
    //     "AcquisitionTeamMember", "CreditAnalyst", "CreditManagement",
    //     "CreditExecutive", "ChiefExecutive", "CreditCommittee", "ServicingAssociate",
    //     "ServicingManager", "InternalValuations", "ConstructionManagement",
    //     "ComplianceOfficer"
    // ];

    // console.log('taskData', taskData)
    const handleQuillChange = (content, delta, source, editor) => {
        const htmlContent = editor.getHTML();
        setTaskData({
          ...taskData,
          taskDescription: htmlContent
        });
        setQuillValue(htmlContent);
        setErrors({...errors,['quillValue'] : ""});
      };
    const paginate = (currentPage) => setCurrentPage(currentPage);

    const [shouldAddTask, setShouldAddTask] = useState(false);
    const prevShouldAddTask = useRef(shouldAddTask);
  
    return (
        <>
            {alert && <PopupMessage
                type={'success'}
                message={"Task added successfully"}
                onClose={handleAlertClose}
            />
            }
            {alert2 && <PopupMessage
                type={'success'}
                message={"Task updated successfully"}
                onClose={() => { setAlert2(false); getTaskFun(); }}
            />
            }



            <div id="layout-wrapper" className="dashboard">
            {!isChild && loading ? <Loader/> : null} 
                {!isChild && <PipelineHeader />}
                <div className="dashboard_block">
                    {!isChild && <PipelineStatus />}
                    <>
       
       <div className="title_block">
           <h2 className="form_title"> <span className="sub">Tasks</span> </h2>
        { isChild && <><button type="button" className="btn close_btn" onClick={() => {props.setShowPage('');props.setFilteredStage('Account Executive Review');props.setfilteredLoans([]);props.handleClearSearch();}}><span className="material-icons icon"> close </span></button></> }
       </div>
       <div className="card task_block">
           <div className="card-body services_form">
               <div className="search ">
                   <button type="button" className="create_btn"
                
                   onClick={() => { setShowTaskPopup(true);dataClear(); }}>Add Task<span className="material-symbols-outlined">Add</span></button>
                   <div className="form-group">
                       <label>Filter By Status</label>
                       <select className="form-control" value={selectedOption} onChange={(e) => { setSelectedOption(e.target.value); }} name="status">
                           <option value="">Please Select</option>
                           <option value="Completed">Completed</option>
                           <option value="Incomplete">Incomplete</option>
                           {/* <option value="Past Due">Past Due</option> */}
                           {/* <option>Past Due</option> */}
                       </select>
                   </div>
               </div>
            
          
             {getTaskData && getTaskData?.length > 0 ?
                 <DataTable className='task_item'
                   columns={columns}
                   data={getTaskData}
                   pagination
                   customStyles={customStyles}
                   paginationPerPage={10}
                   paginationRowsPerPageOptions={[10, 20, 30, 40]}
                   paginationComponentOptions={{
                     rowsPerPageText: 'Per Page',
                   }}
                 />:
                 !loading && <div className="inprogress_page"><span className="material-symbols-outlined icon"> content_paste_search </span><p>No tasks were found</p></div>
               }
            

           </div>
       </div>
       {showTaskPopup &&
           <div 
           className="modal popup new_popup conditional_popup fade show"    >
               <div className="modal-dialog">
                   <div className="modal-content">
                       <div className="modal-header">
                           <h5 className="modal-title" id="exampleModalLabel">Add task</h5>
                           <button type="button"
                            className="pop_close" onClick={() => { setShowTaskPopup(false);dataClear();}}><span
                               className="material-symbols-outlined">close</span></button>
                       </div>
                       <div className="modal-body">
                           <div className="row">
                               <div className="col-md-6">
                                   <div className="form-group">
                                       <label>Assign to User</label>

                                       <select name="assignedTo" value={taskData.assignedTo} onChange={handleChange} 
                                       
                                       className={`form-control ${errors['assignedTo'] && 'invalid-input'}`} 
                                       // onFocus={handleFocus}
                                       // onFocus={() => { setErrors({ ...errors, assignedTo: null }) }}
                                       >
                                           <option value=''>Please Select</option>
                                           {users?.map((key, index) => (<option value={key?.userInfo?.firstname +' '+ key?.userInfo?.lastname + "/" + key?.userid}>{key?.userInfo?.firstname +' '+ key?.userInfo?.lastname }</option>))}
                                       </select>
                                   </div>
                               </div>
                               <div className="col-md-6">
                                   <div className="form-group">
                                       <label>Default Assignment</label>

                                       <select name="defaultAssignment" id="defaultAssignment"  className={`form-control ${errors['defaultAssignment'] && 'invalid-input'}`} 
                                                                                   // onFocus={() => { setErrors({ ...errors, defaultAssignment: null }) }}

                                       // onFocus={handleFocus}
                                       value={taskData.defaultAssignment} onChange={handleChange}>
                                           <option value="">Please Select</option>
                                           {/* {defaultAssignments.map((role, index) => (
                                               <option key={index} value={role}>{role}</option>
                                           ))} */}
                                            {defaultAssignments.map((role, index) => (
                                               <option key={index} value={role?.value}>{role?.label}</option>
                                           ))}
                                       </select>
                                   </div>
                               </div>

                               <div className="col-md-6">
                                   <div className="form-group">
                                       <label>Due Date</label>

                                       <DatePicker
                                        // name='dueDate'
                                           selected={taskData.dueDate ? new Date(taskData.dueDate) : null}
                                           onChange={(date) => handleDateChange(date, 'dueDate')}
                                           dateFormat="MM/dd/yyyy"
                                           placeholderText="Select Date"
                                           minDate={new Date()}
                                           icon="fa fa-calendar"
                                           showIcon
                                           className={`form-control ${errors['dueDate'] && 'invalid-input'}`} 
                                           // onFocus={() => { setErrors({ ...errors, dueDate: null }) }}

                                           // onFocus={handleFocus}
                                       />
                                   </div>
                               </div>
                               <div className="col-md-6">
                                   <div className="form-group">
                                       <label>Email On Task Completion</label>

                                       <select name="emailid"  className={`form-control ${errors['emailid'] && 'invalid-input'}`} 
                                       
                                       // onFocus={handleFocus} 
                                       // onFocus={() => { setErrors({ ...errors, emailid: null }) }}

                                       value={taskData.emailid} onChange={(e) => handleChange(e)}>
                                           <option value="">Please Select</option>
                                           {/* {roles.map((role, index) => (
                                               <option key={index} value={role}>{role}</option>
                                           ))} */}
                                           {roles.map((role, index) => (
                                               <option key={index} value={role?.value}>{role?.label}</option>
                                           ))}
                                       </select>
                                   </div>
                               </div>
                               {isChild && 
                               <div className="col-md-6">
                               <div className="form-group">
                                   <label>Loan Id</label>
                                   <Select
                                        inputId="task-loanid"
                                        options={loanidDropdown}
                                        name='loanid'
                                        placeholder="Select"
                                        value={taskData?.loanid ? loanidDropdown.find(option => option.value === taskData?.loanid) : ""}
                                        className={`w-100 ${errors['loanid'] && 'invalid-input'}`} 
                                        onChange={(selectedOption) => {
                                            setTaskData({...taskData, loanid : selectedOption.value});
                                            setErrors({...errors,'loanid' : ""});
                                        }}
                                        tabIndex={5}
                                    />
                               </div>
                           </div>
                               }
                               

                               <div className="col-md-12">
                                   <h3 className="title mt_10 mb_10">Task</h3>
                                   <div className="form-group">
                                       {/* <img src="assets/images/texteditor.jpg" alt="" /> */}
                                       <ReactQuill
                                           style={{ height: "200px" }}
                                           theme="snow"
                                           value={quillValue??""}
                                           className={`${errors['quillValue'] && 'invalid-input'}`} 
                                           modules={modules}
                                        //    onChange={(value)=>{setQuillValue(value);setTaskData({...taskData,taskDescription:value})}}
                                           onChange={handleQuillChange}
                                           formats={["header", "bold", "italic", "underline", "strike", "link", "image", "blockquote", "code-block", "list", "indent", "align", "color",
                                               "background", "script"]}
                                            id="taskText"
                                       />
                                   </div>
                               </div>
                               <div className="col-md-12">
                                   <div className="btn-group mb_10" ><a href="#" className="border_btn mr_10" data-bs-dismiss="modal"
                                       aria-label="Close" onClick={()=>{ setShowTaskPopup(false);dataClear();}}>Cancel</a><button className="fs_btn" data-bs-dismiss="modal"
                                           aria-label="Close" onClick={()=>addTask()}>Add to task</button></div>
                               </div>

                           </div>


                       </div>

                   </div>
               </div>
           </div>
       }
       
    
 
   </>
                </div>
                {!isChild && <PipelineFooter />}

            
            </div>
        </>
    );
};

export default Tasks;