import React from 'react';
import { useNavigate } from 'react-router-dom';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import HomeFooter from './HomeFooter';
import { useLoanInfo } from '../utils/loanInfoContext';

const NewLoan = () => {
  const navigate = useNavigate();
  const { loanInfo, setLoanInfo} = useLoanInfo();
  const refreshLoan = () => {
    setLoanInfo({
      propertyinfo: {},
      strategyinfo: {},
      borrowerinfo: {},
      dealinfo: {},
      requestor:''
    })
}
  return (
    <div id="layout-wrapper">
       <MainHeader />
       <SideMenu />
    
<div className="main-content new_loan">
<div className="page-content">
    <div className="container-fluid">
        <div className="row">
            <div className="col-12 content_body">
               <div className="card">
                    <div className="card-body">
                    <h1>New loan</h1>
                    <div className="d-flex justify-content-center">    
                      <button id="newPipelineBorrowerButton" className="btn" onClick={(e) => {refreshLoan(); navigate('/propertyinfo/?usertype=borrower');}}><img src="assets/images/borrower.svg" />Borrower<span>+</span></button>
                      <button id="newPipelineBrokerButton" className="btn me-0"  onClick={(e) => {refreshLoan(); navigate('/propertyinfo/?usertype=broker');}}><img src="assets/images/broker1.svg" />Broker<span>+</span></button>
                    </div>
                      
                </div>
            </div>
        </div>

    </div>
</div>

  
</div>
<HomeFooter />
</div>
</div>

  );
};

export default NewLoan;
