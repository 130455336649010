import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import {getAllFundManagmentAdmin, deleteFundManagementAdmin} from "../utils/reducer";
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import Loader from './Loader';
import SweetAlert from 'react-bootstrap-sweetalert';   
import PopupMessage from './PopupMessage.js';
import HomeFooter from './HomeFooter';
import DataTable from 'react-data-table-component';
import moment from 'moment';

const AdminFundManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector((state) => state.loaderVisible);
    const [fundManagementData, setFundManagementData] = useState([]);
    const [selectedOption, setSelectedOption] = useState({});
    const [showConfirmation,setShowConfirmation] = useState(false);
    const [deleteConfirmation,setDeleteConfirmation] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalCount,setTotalCount] = useState(null);
    const [currentPage,setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortDirection,setSortDirection] = useState('asc');
    const [sortedPdfData, setSortedPdfData] = useState([]); // State for sorted data
    const[sortBool,setSortBool] = useState(false);
    const [fundNames,setFundNames] = useState([]);

      useEffect(() => {
        
        getAllFundData();
      }, [currentPage,perPage]);

      const getAllFundData = ()=>{
        dispatch(showLoader());
        getAllFundManagmentAdmin(currentPage,perPage).then(function (response) {
        if (response?.result) {
            console.log("get all fund maangement api called",response?.result);
            setFundManagementData(response?.result?.data);
            const names = response?.result?.data?.map(item => item.name);
            setFundNames(names);
            setTotalCount(response?.result?.totalCount);
        }
        dispatch(hideLoader());
        }).catch((err) => {
          dispatch(hideLoader());
          console.error.bind("my account error", err);
        })
    }

    /* data table */

    const handleSearchChange = (e) => {
        console.log('serach term',e.target.value);
        setSearchTerm(e.target.value);
    }; 
    
    const filteredFundData = fundManagementData?.filter((sheet) =>
        sheet?.name?.toLowerCase()?.trim().includes(searchTerm?.toLowerCase()?.trim())
        // sheet?.programtype?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        // sheet?.state?.join(',')?.toLowerCase().includes(searchTerm?.toLowerCase())
    );

    const editSheet = (data) => {
        console.log('data',data)
        navigate('/createfundmanagement', { state: { fundId : data?.uuid,selectedfund: data, fundManagementData:fundManagementData , mode : "Edit" ,fundNames } });
    };
    
    const deleteSheet = (data) => {
        setShowConfirmation(true);
        setSelectedOption(data?.uuid);
        console.log('data',data);
    };
    
    const columnWidth = '8%'; // Adjust the width for each column as needed

    const columns = [
        {
            name: '#',
            selector: (row, index) => (currentPage - 1) * perPage + index + 1,
            width: '4%', // Adjusted width for the index column
        },
        {
            name: 'Fund Name',
            selector: (row) => row?.name,
            sortable: true,
            sortFunction: (a, b) => {
                const nameA = a?.fundname?.toLowerCase();
                const nameB = b?.fundname?.toLowerCase();
                return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            },
            width: columnWidth,
        },
        // {
        //     name: 'Fund ID',
        //     selector: (row) => row?.fundid,
        //     width: columnWidth,
        //     sortable: true,
        // },
        {
            name: 'Fund Type',
            selector: (row) => row?.type,
            width: columnWidth,
            sortable: true,
        },
        {
            name: 'Fund Level',
            selector: (row) => row?.level,
            width: columnWidth,
            sortable: true,
        },
        {
            name: 'Fund Address',
            selector: (row) => row?.address,
            width: columnWidth,
            sortable: true,
        },
        {
            name: 'Disburse Interest',
            selector: (row) => (row?.disburseinterest) ? 'Yes' : 'No',
            width: columnWidth,
            sortable: true,
        },
        {
            name: 'Disburse Principal',
            selector: (row) => (row?.disburseprincipal) ? 'Yes' : 'No',
            width: columnWidth,
            sortable: true,
        },
        {
            name: 'Disburse Fee',
            selector: (row) => (row?.disbursefee) ? 'Yes' : 'No',
            width: columnWidth,
            sortable: true,
        },
        {
            name: 'Disburse Fee Interest',
            selector: (row) => (row?.disbursefeeinterest) ? 'Yes' : 'No',
            width: columnWidth,
            sortable: true,
        },
        {
            name: 'Disburse Strip Fee',
            selector: (row) => (row?.disbursestripfee) ? 'Yes' : 'No',
            width: columnWidth,
            sortable: true,
        },
        {
            name: 'Created', // New column for "Created"
            selector: (row) => row?.created ? moment.utc(row?.created).format("MM-DD-YYYY") : '',
            width: columnWidth,
            sortable: true,
        },
        {
            name: 'Created By',
            selector: (row) => row?.createdBy?.name,
            width: columnWidth,
            sortable: true,
        },
       
        {
            name: 'Actions',
            cell: (row, index) => (
                <>
                    <button id={`editFund-${index}`} type="button" onClick={() => editSheet(row)} className="icon_btn edit">
                        <span style={{ color: '#444', fontSize: '16px' }} className="material-icons icon tooltip-container">
                            <span className="tooltip">Edit</span>edit
                        </span>
                    </button>
                    <button id={`deleteFund-${index}`} type="button" onClick={() => deleteSheet(row)} className="icon_btn delete">
                        <span style={{ color: '#444', fontSize: '16px' }} className="material-icons icon tooltip-container">
                            <span className="tooltip">Delete</span>delete
                        </span>
                    </button>
                </>
            ),
            width: "8%", // Adjusted width for the "Actions" column
        },
    ];


      const customStyles = {
        //   headRow: {
      // 	style: {
        //         fontWeight: 'bold',
        //         backgroundColor: '#E9F0E6',
      // 	},
      // },
        headCells: {
        style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',
        
        },
      },
            table: {
          style: {
            border: '1px solid #CCD8F6', 
          },
        },
      };

    

      /*const totalPages = Math.ceil(totalCount / perPage);
      //console.log('total Pages',totalPages);

      const handlePrevious = () => {
        setCurrentPage((prevPage) => prevPage - 1);
      };
    
      const handleNext = () => {
        setCurrentPage((prevPage) => prevPage + 1);
      };*/
    
      const handlePerPageChange = (newperPage,page) => {
        setPerPage(newperPage);
        setCurrentPage(page); // Reset to first page
      };

      const handlePageChange = (page) => {
        setCurrentPage(page);
      };

      // const handlePageNumber = (e) => {
      //   setCurrentPage(e.target.value);
      // }

      const handleDelete = ()=>{
        closePopup()
        console.log('termSheetsData',selectedOption);
        dispatch(showLoader());
        deleteFundManagementAdmin(selectedOption).then(function (response) {
          getAllFundData();
            setDeleteConfirmation(true);
            //dispatch(hideLoader());
          }).catch((err) => {
           // dispatch(hideLoader());
            console.error.bind("my account error", err);
          })
    }

    const handleSort = (column, sortDirection) => {
       
        const sortField = column.selector;
    
        // Modify safeValue to ensure it always returns a string
        const safeValue = value => (value == null ? '' : String(value));
    
        const newSortedData = [...fundManagementData].sort((a, b) => {
            const aValue = typeof sortField === 'function' ? sortField(a) : safeValue(a[sortField]);
            const bValue = typeof sortField === 'function' ? sortField(b) : safeValue(b[sortField]);
    
            console.log(`Sorting by column: ${column.name}`);
    
            // Proper sorting logic for "CREATED BY"
            if (column.name === 'CREATED BY') {
                const aCreatedBy = safeValue(a.createdBy?.username).toLowerCase();
                const bCreatedBy = safeValue(b.createdBy?.username).toLowerCase();
    
                console.log(`Comparing: ${aCreatedBy} vs ${bCreatedBy}`);
                return sortDirection === 'asc'
                    ? aCreatedBy.localeCompare(bCreatedBy)
                    : bCreatedBy.localeCompare(aCreatedBy);
            }
    
            // General string comparison
            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return sortDirection === 'asc' 
                    ? aValue.localeCompare(bValue) 
                    : bValue.localeCompare(aValue);
            }
    
            // Fallback for numbers or other types
            return sortDirection === 'asc' 
                ? safeValue(aValue) - safeValue(bValue) 
                : safeValue(bValue) - safeValue(aValue);
        });
    
        //setForm1098Data(sortedData);
         // Update sorted data and toggle sort direction
         setFundManagementData(newSortedData);
         setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
         //setSortBool(!sortBool);
       
    };

    
    const filteredFunds = Array.isArray(fundManagementData)
    ? fundManagementData.filter((sheet) =>
        sheet?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
        // ||
        // sheet?.programType?.toLowerCase().includes(searchTerm?.toLowerCase())
      ): 
  [];

  const handleCreate = (e) => {
    e.preventDefault(); 
    navigate('/createfundmanagement',{ state : { fundNames } });
  }

    
    const closePopup = () => {
        setShowConfirmation(false)
     }

     const closePopup2 = ()=>{
        setDeleteConfirmation(false)
     }

    return (
        <div id="layout-wrapper">

{loading ? <Loader/> : null} 
           <MainHeader />
           {deleteConfirmation && (
                <PopupMessage
                    type={'success'}
                    message={'Deleted Successfully'}
                    onClose={closePopup2}
                />
            )}
           {showConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={handleDelete}
                onCancel={closePopup}
                focusCancel={true}
                customButtons={[
                  React.createElement('button', {
                      id: 'delete-fund-yes',
                      className: 'btn btn-primary',
                      onClick: handleDelete,
                      style: { marginRight: '8px' ,fontSize: '16px'}
                  }, 'Yes'),
                  React.createElement('button', {
                      id: 'delete-fund-no',
                      className: 'btn btn-light',
                      onClick: closePopup,
                      style: { fontSize: '16px'}
                  }, 'No')
              ]}
            >
                    <p>Do you really want to delete this Fund?</p>
                {/* Additional content can go here */}
            </SweetAlert>}
            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Fund Management</h4>
                            </div>
                            <div className="card">
                                <div className="card-body ">
                                    <div className='search'>
                                        <button id="createFundManager" onClick={(e) => handleCreate(e)} className="create_btn">Create</button>
                                            <div className="right">
                                                <div className='form-group search_block'>
                                                    <input id="searchFund" type="text" className='form-control' placeholder='Search Fund' value={searchTerm} onChange={handleSearchChange}/>
                                                    <button id="searchFundBtn" className="material-symbols-outlined btn">search</button>
                                                </div>
                                                
                                            </div>
                                    </div>
                                    {/* <div className='search mb-3'>
                                        <h5 className="title mt-0 mb-0"> Fund Management</h5>
                                        <button id="createPrefills" type="button" onClick={(e) => {  navigate('/createfundmanagement')}} className="create_btn">CREATE <span className="material-symbols-outlined icon"> arrow_outward </span></button>
                                    
                                    </div> */}
                                {/* <div className="table-responsive">
                                        <table className="table db_table tsr_table los_table">

                                            <thead className="table-light">
                                                <tr>
                                                    <th width="15%">Fund Name</th>
                                                    <th width="8%">FUND ID</th>
                                                    <th width="10%">FUND TYPE</th>
                                                    <th width="8%">FUND LEVEL</th>
                                                    <th width="12%">fUND aDDRESS</th>
                                                    <th width="6%" className="text-center">dISBURSE iNTEREST</th>
                                                    <th width="6%" className="text-center">dISBURSE PRINCIPAL</th>
                                                    <th width="6%" className="text-center">dISBURSE FEE</th>
                                                    <th width="8%" className="text-center">dISBURSE FEE iNTEREST</th>
                                                    <th width="6%" className="text-center">dISBURSE STRIP FEE</th>
                                                    <th width="5%" className="text-center">aCTIONS</th>
                                                  
                                                    </tr>
                                            </thead>
                                            <tbody>
                                               <tr>
                                                    <td>Easy Street Capital</td>
                                                    <td>4112</td>
                                                    <td>Guaranteed</td>
                                                    <td>Level 4</td>
                                                    <td>10 Browning, CO</td>
                                                    <td className="text-center">YES</td>
                                                    <td className="text-center">YES</td>
                                                    <td className="text-center">YES</td>
                                                    <td className="text-center">YES</td>
                                                    <td className="text-center">YES</td>
                                                    <td className="text-center"><div className="btn-group align-items-center">
                                                   <button id="editContacts-0" type="button" className="btn btn-outline-secondary tooltip-container">
                                                   <span className="tooltip">edit</span>
                                                   <span className="material-symbols-outlined icon">edit</span>
                                                   </button>
                                                   <button id="deleteContacts-0" type="button" className="btn btn-outline-secondary tooltip-container">
                                                   <span className="tooltip">delete</span>
                                                   <span className="material-symbols-outlined icon">delete</span>
                                                   </button>
                                                   </div>
                                                   </td>
                                                 </tr>
                                                 <tr>
                                                    <td>Easy Street Capital</td>
                                                    <td>4112</td>
                                                    <td>Guaranteed</td>
                                                    <td>Level 4</td>
                                                    <td>10 Browning, CO</td>
                                                    <td className="text-center">YES</td>
                                                    <td className="text-center">YES</td>
                                                    <td className="text-center">YES</td>
                                                    <td className="text-center">YES</td>
                                                    <td className="text-center">YES</td>
                                                    <td className="text-center"><div className="btn-group">
                                                   <button id="editContacts-0" type="button" className="btn btn-outline-secondary tooltip-container">
                                                   <span className="tooltip">edit</span>
                                                   <span className="material-symbols-outlined icon">edit</span>
                                                   </button>
                                                   <button id="deleteContacts-0" type="button" className="btn btn-outline-secondary tooltip-container">
                                                   <span className="tooltip">delete</span>
                                                   <span className="material-symbols-outlined icon">delete</span>
                                                   </button>
                                                   </div>
                                                   </td>
                                                 </tr>
                                                 <tr>
                                                    <td>Easy Street Capital</td>
                                                    <td>4112</td>
                                                    <td>Guaranteed</td>
                                                    <td>Level 4</td>
                                                    <td>10 Browning, CO</td>
                                                    <td className="text-center">YES</td>
                                                    <td className="text-center">YES</td>
                                                    <td className="text-center">YES</td>
                                                    <td className="text-center">YES</td>
                                                    <td className="text-center">YES</td>
                                                    <td className="text-center"><div className="btn-group">
                                                   <button id="editContacts-0" type="button" className="btn btn-outline-secondary tooltip-container">
                                                   <span className="tooltip">edit</span>
                                                   <span className="material-symbols-outlined icon">edit</span>
                                                   </button>
                                                   <button id="deleteContacts-0" type="button" className="btn btn-outline-secondary tooltip-container">
                                                   <span className="tooltip">delete</span>
                                                   <span className="material-symbols-outlined icon">delete</span>
                                                   </button>
                                                   </div>
                                                   </td>
                                                 </tr>
                                                 <tr>
                                                    <td>Easy Street Capital</td>
                                                    <td>4112</td>
                                                    <td>Guaranteed</td>
                                                    <td>Level 4</td>
                                                    <td>10 Browning, CO</td>
                                                    <td className="text-center">YES</td>
                                                    <td className="text-center">YES</td>
                                                    <td className="text-center">YES</td>
                                                    <td className="text-center">YES</td>
                                                    <td className="text-center">YES</td>
                                                    <td className="text-center"><div className="btn-group">
                                                   <button id="editContacts-0" type="button" className="btn btn-outline-secondary tooltip-container">
                                                   <span className="tooltip">edit</span>
                                                   <span className="material-symbols-outlined icon">edit</span>
                                                   </button>
                                                   <button id="deleteContacts-0" type="button" className="btn btn-outline-secondary tooltip-container">
                                                   <span className="tooltip">delete</span>
                                                   <span className="material-symbols-outlined icon">delete</span>
                                                   </button>
                                                   </div>
                                                   </td>
                                                 </tr>
                                                  
                                                  
                                            
                                            </tbody>
                                             

                                        </table>
                                </div> */}
                                    {/* {filteredFundData?.length > 0 && */}
                                        <DataTable className='termsheetprefills_table'
                                            columns={columns}
                                            data={filteredFundData}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={(!searchTerm) ? totalCount : filteredFundData?.length}
                                            customStyles={customStyles}
                                            paginationPerPage={perPage}
                                            paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                            paginationComponentOptions={{
                                              rowsPerPageText: 'Per Page',
                                            }}
                                            persistTableHead
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handlePerPageChange}
                                            id="prefillPagination"
                                            sortServer={false}  // If you're handling sorting on the server-side, use this prop
                                            onSort={handleSort}
                                        /> 
                                    {/* } */}

                                   

                                    {/* {fundManagementData?.length === 0  &&
                                        <div className="inprogress_page">
                                            <span className="material-symbols-outlined icon"> content_paste_search </span>
                                            <p>No Data Found</p>
                                        </div>
                                    } */}
                                </div>
                               
                            </div>
                            
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default AdminFundManagement;