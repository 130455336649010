import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import {addTermSheets, deleteTermSheetsData , getTermSheets , getSingleTermSheetManager, getValuesDropdown} from "../utils/reducer";
import EditorEdit from './EditorEdit.js';
import { useLocation, useNavigate} from 'react-router-dom';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import HomeFooter from './HomeFooter';
import formValuesKeys from '../JsonData/formValuesKeys.json';
import {transformData} from '../utils/commonUtils.js';
import ValuesDropdown from './ValuesDropdown.js';

const CreateTermSheetManager = () => {
    const navigate = useNavigate();
    const location = useLocation();
    console.log('location',location?.state);
    const { selectedSheet } = location ;
    const getMode = location.state?.mode;
    console.log('selectedSheet---->',selectedSheet);
    const term_sheet_id = location.state?.termId;
    const loading = useSelector((state) => state.loaderVisible);
    const dispatch = useDispatch();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [type , setType] = useState(location?.state?.mode ? 'update' : 'add');
    const [template, setTemplate] = useState(location?.state?.data?.content);
    const [message , setMessage] = useState('Updated Successfully');
    const [termSheetsData, setTermSheetsData] = useState({termSheet: 'new',sheetName: '',programType: '',whiteLabel: false, template: ''});
    const [editorValidate,setEditorValidate] = useState(false);
    const [termSheetOld,settermSheetOld] = useState({});
    const [termBool,setTermBool] = useState(false);
    const [pastData,setPastData] = useState({});
    const [formVariables,setFormVariables] = useState([]);
    const [formValuesOption,setFormValuesOption] = useState(null);
    const [formattedVariables,setFormattedVariables] = useState(null);
    const [valueDependency,setValuesDependency] = useState(0);
   
   

    const programType = [
        { "label": "Please Select", "value": "" },
        { "label": "EASY BUILD", "value": "EASY BUILD" },
        { "label": "EASY FIX", "value": "EASY FIX" },
        // { "label": "EASY RENT", "value": "EASY RENT" },
    ];

    const [errors, setErrors] = useState({});
    // const jsdom = require("jsdom");
    // const { JSDOM } = jsdom;
    
        useEffect(() => {
        console.log('quillValuequillValue',template)
        setTermSheetsData((prev) => ({
            ...prev,
            ['template']: template,
        }));
       }, [template]);


        const compareTerms = () => {
            // const getKeys= Object.keys(termSheetsData);
            const allowedKeys = ["_id","programType","whiteLabel","sheetName"];
            
            const bool1 = allowedKeys.every(key => termSheetsData[key] === termSheetOld[key]);
            const temp1 = document.createElement('div');
            temp1.innerHTML = termSheetsData?.template;
            const temp2 = document.createElement('div');
            temp2.innerHTML = termSheetOld?.template;
            const equal = temp1.isEqualNode(temp2);

            // const dom1 = root1.toString();
            // const dom2 = root2.toString();
           
            // const bool2 = dom1.window.document.isEqualNode(dom2.window.document);
            if(bool1 && equal){
                return true;
            }else{
                return false;
            }
            
        }

        useEffect(() => {
            console.log('termSheetsData',termSheetsData);
            console.log('old sheet data',termSheetOld);
            if(type === "update"){
                const areEqual = compareTerms();
                console.log("equal",areEqual);
                setTermBool(areEqual);
            }
            
        },[termSheetsData]);

        useEffect(() => {
            console.log('past data',pastData);
        },[pastData])

        useEffect(() => {
            console.log("sheet id",term_sheet_id);
            callGetSingleTermSheet();
        },[getMode]); 
    
        const callGetSingleTermSheet = () => {
            dispatch(showLoader());
            
            getSingleTermSheetManager(term_sheet_id).then(function (response) {
                if (response?.result?.data[0]) {
                    setPastData(response?.result?.data[0]); 
                    settermSheetOld(response?.result?.data[0]); 
                    if(getMode){
                        setTermSheetsData(response?.result?.data[0]);
                    }
                    
                    setTemplate(response?.result?.data[0]?.template);
                    console.log('single data---->',response?.result);
                }
                dispatch(hideLoader());
            }).catch((err) => {
                dispatch(hideLoader());
                console.error.bind("single account error", err);
            })
        }

        // useEffect(() => {
        //     // dispatch(showLoader());
        //     getTermSheets().then(function (response) {
        //         if (response?.result) {
        //             setPastData(response?.result);
        //             // console.log('past data---->',response?.result);
        //         }
        //         // dispatch(hideLoader());
        //     }).catch((err) => {
        //         // dispatch(hideLoader());
        //         console.error.bind("my account error", err);
        //     })
        // },[])

       

    const handleCheckBox = (e)=>{
        setTermSheetsData({...termSheetsData,'whiteLabel' : e.target.checked })
    }

    const handleChange = (e) => {
        console.log('e.target', e.target.name, e.target.value, e.target.label);
        if (e.target.name === 'termSheet' && e.target.value === 'new') {
            setTemplate('');
            setTermSheetsData({
                'termSheet': '',
                'sheetName': '',
                'programType': '',
                'whiteLabel': false,
                'template': template
            });
        } 
        setTermSheetsData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
        
    };

    function cleanString(str) {
        // Remove symbols and spaces, and convert to lowercase
        return str?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    }
    
    function inputValueMatches(inputValue1,inputValue2) {
        
        const cleanedInputValue1 = cleanString(inputValue1);
        const cleanedInputValue2 = cleanString(inputValue2);
        // Check if any object in arr has both cleaned sheetName and programType properties that match the cleaned inputValue
        // if (Array.isArray(pastData)) {
            // termSheetsData is an array
            //return pastData?.data?.some(item => cleanString(item?.sheetName) === cleanedInputValue1 && cleanString(item?.programType) === cleanedInputValue2);
        // } 
         // Check if any object in pastData.data has both cleaned sheetName and programType properties that match the cleaned inputValue
        if (Array.isArray(pastData?.data)) {
            return pastData?.data?.some(item => 
                cleanString(item.sheetName) === cleanedInputValue1 && cleanString(item?.programType) === cleanedInputValue2
            );
        }
        return false;
        
    }

    useEffect(() => {
        const inputElement = document.querySelector(`.jodit-react-container`);
        if(inputElement.classList.contains('invalid-input')){
            removeErrorClass("jodit-react-container");
        }
    },[termSheetsData?.template])

    const addErrorClass = (fieldName) => {
        const inputElement = document.querySelector(`.${fieldName}`);
        if (inputElement) {
          inputElement.classList.add('invalid-input');
          console.log("Error class added to element:", inputElement);
        }
      };
    
      // Remove error classes on all inputs
    const removeErrorClass = (fieldName) => {
        
        const searchWrpElement = document.querySelector(`.${fieldName}`);
        if (searchWrpElement) {
                searchWrpElement.classList.remove("invalid-input");
        }
    }

    const handleSave = ()=>{
        // console.log("existing data",termSheetsData);
        const fieldsToCheck = {
             sheetName : 'sheetName', programType: 'programType'
        };
        let error = {};
        const isWhitespace = /^<p>(&nbsp;|\s)*<br>\s*<\/p>$/;
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            if(fieldName == 'termSheet'){
                console.log('termSheettermSheettermSheet',termSheetsData?.[fieldName]);
            }
            if (!termSheetsData?.[fieldName]?.trim()) {
                error[fieldName] = `Please Enter ${fieldLabel} `;
            }
        };
        // if(content === ""){
        //     setEditorValidate(true);
        // }

        if ((isWhitespace?.test(termSheetsData?.template) || !termSheetsData?.template || termSheetsData?.template === "" || termSheetsData?.template === "<p><br></p>" || termSheetsData?.template === undefined)) {
            //error[input] = 'required';
            addErrorClass("jodit-react-container"); 
        }

        setErrors(error);
        
        const inputElement = document.querySelector(`.jodit-react-container`);
        if (Object.keys(error).length === 0 && !inputElement.classList.contains('invalid-input')) {
            let valid = inputValueMatches(termSheetsData?.sheetName,termSheetsData?.programType);
           

            if(valid && type !== "update"){
                dispatch(showLoader());
                setPopupOpen(true);
                setMessage("Sheet Name or Program Type already Exists.")
                dispatch(hideLoader());
            }else{
                let mainData = {...termSheetsData, 'template': template}
                if(termSheetsData?.termSheet == 'new' || termSheetsData?.termSheet == ''){
                    mainData = {...termSheetsData, 'termSheet':termSheetsData?.sheetName }
                    setTermSheetsData((prev) => ({
                        ...prev,
                        ['termSheet']: termSheetsData?.sheetName,
                    }));
                }
                console.log('data to api----->',mainData)
                dispatch(showLoader());
                addTermSheets(mainData).then(function (response) {
                    console.log('responseresponse',response);
                    if(response.result === 'Termsheets data modified successfully'){
                        
                        setPopupOpen(true);
                        setMessage(type === 'update' ? 'Updated Successfully' : 'Created Successfully');
                    
                    }

                    dispatch(hideLoader());
                }).catch((err) => {
                    dispatch(hideLoader());
                    console.error.bind("Login error", err);
                })
            }

             
        }
    }

    const handleChangeValues = (value) => {
        const selectedValue = value;
    
        // Update formVariables by appending the selected value to the array
        setFormVariables((prevFormVariables) => {
            // Check if selectedValue is already in the array to avoid duplicates
            if (!prevFormVariables.includes(selectedValue)) {
                return [...prevFormVariables, selectedValue];
            }
            return prevFormVariables; // Return the same array if the value already exists
        });
    
        // Update formValuesOption with the selected value
        setFormValuesOption(selectedValue);
        setValuesDependency(valueDependency + 1);
        // Uncomment if you want to prepend the selected value to the template
        // setTemplate((prevContent) => selectedValue + prevContent);
    };
    

    const closePopup = () => {
        setPopupOpen(false);
        navigate('/underwritingtermsheetmanager');
    };

   

    return (
        <div id="layout-wrapper">
        {loading ? <Loader/> : null} 

        {isPopupOpen && (
        <PopupMessage
          type={message === "Sheet Name or Program Type already Exists." ? 'warning' : 'success'}
          message={message}
          onClose={closePopup}
        />
      )}

           <MainHeader />
            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">{type === 'update' ? 'Update Term Sheets' : 'Create Term Sheets'} </h4>
                                <a href="#" id="navigateManagerBack" onClick={(e) => {e.preventDefault(); navigate(-1);}} className="back_btn"><span className="material-icons icon"> arrow_back
                                    </span>BACK</a>
                            </div>
                            <div className="card">
                                <div className="card-body ">
                                    <div className="form_section">
                                        <div className="row">
                                            <div className="col-md-12">
                                                {/* <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>Select Term Sheet</label>
                                                            <select name='termSheet' onChange={handleChange} className={`form-control ${errors?.termSheet && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, termSheet: null }) }}>
                                                                {termSheet.map((data,index)=>{
                                                                    return(
                                                                        <option value={data.value}>{data.label}</option>
                                                                    )
                                                                })}
                                                               
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>Term Sheet Name</label>
                                                            <input id="termSheetManagerName" name='sheetName' value={termSheetsData?.sheetName} onChange={handleChange} type="text" className={`form-control ${errors?.sheetName && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, sheetName: null }) }} placeholder="Enter Sheet Name" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>Program Type</label>
                                                            <select id="termSheetManagerprogramType" name='programType' value={termSheetsData?.programType} onChange={handleChange} className={`form-control ${errors?.programType && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, programType: null }) }}>
                                                            {programType?.map((data,index)=>{
                                                                    return(
                                                                        <option value={data.value}>{data.label}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <ValuesDropdown onChange={handleChangeValues}/>
                                                </div>
                        
                                                <div className="row">
                                                    <div className={"col-md-12"}>
                                                        <div className="form-group">
                                                          
                                                            <EditorEdit content={template} setContent={setTemplate} editorValidate = {editorValidate} setEditorValidate = {setEditorValidate} type={type} formVariables={formVariables} formValuesOption={formValuesOption} valueDependency = {valueDependency} />
                                                        </div>
                                                        {termSheetsData?.sheetName === 'Underwriting Term Sheet' ? '' : 
                                                         <label className="toggle">
                                                         <input id="termSheetManagerWhiteLabel" className="toggle-checkbox" disabled={true} onChange={(e)=>handleCheckBox(e)} checked={termSheetsData?.whiteLabel} type="checkbox" />
                                                         <div className="toggle-switch"></div>
                                                         <span className="toggle-label">White Label</span>
                                                     </label>
                                                        }
                                                       
                                                    </div>
                                                </div>
                                                <div className="btn-group mt_10">
                                                 
                                                {type === 'update' && <button id="updateTermSheetManager" onClick={(e) => {if (!termBool) { e.preventDefault();  handleSave() }}} className="fs_btn mr_10" disabled = {termBool} 
                                                 style={{
                                                    opacity: termBool ? '0.6' : '1',
                                                    pointerEvents: termBool ? 'none' : 'auto',
                                                    cursor: termBool ? 'not-allowed' : 'pointer',
                                                }}> Update </button>}
                                                    {type !== 'update' && <button id="createTermSheetManager" onClick={(e) => {e.preventDefault();  handleSave()}} className="fs_btn mr_10" > Create</button>}
                                                    {/* <a href="#" onClick={(e) => {e.preventDefault(); handleDelete()}} className="border_btn">Delete</a> */}
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default CreateTermSheetManager