import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import PopupMessage from './PopupMessage';
import { getCleartoClose,addCleartoclose ,dealratepointsGet} from '../utils/reducer'; 
import { useLoanInfo } from '../utils/loanInfoContext';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import Loader from './Loader';
const Closing = () => {
    const navigate = useNavigate();
    // const { loanInfo } = useLoanInfo();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loaderVisible);
    const [errors,SetErrors] = useState({});
    const [popupMessage, setmessage] = useState('Saved successfully');
    const [isPopupOpen, setPopupOpen] = useState(false);
    const configdata = useSelector(state => state.configdata);
    let jsonFormat = {
        cleartoclose : {
            constructioncompleteddate: "",
            actualclosingdate: "",
        cleartoclosingdate: "",
        declaredmaturitydate: "",
        docssenttotitlecompanydate: "",
        prelimeffectivedate: "",
        scheduledclosingdate: "",
        scheduledfirstpaymentdate: "",
        titleordernumber: "",
        proposedmaturitydate : "",
        }
    };
    const [tableValues,setTableValues] = useState({
        brokerfee : '0.00%',
        loanOrigination : '0.00%',
        ProposedMaturityDate : 'N/A',
    })
    const [closingData, setClosingData] = useState({ ...jsonFormat});
    const [closingDataCheck,setClosingDataCheck] = useState({...jsonFormat});
    const [isChange,setIsChange] = useState(false);
    const { loanInfo, setLoanInfo} = useLoanInfo();
    const [loanid,setLoanid] = useState(loanInfo?.loanid);
  const [closingDate, setClosingDate] = useState(''); 
  const [maturityDate,setMaturityDate] = useState('');
  // Default closing date is current date

  // Function to calculate maturity date
  const calculateMaturityDate = () => {
    console.log('loanInfo',loanInfo?.loanterm);
    if(loanInfo?.loanterm && closingData.cleartoclose?.actualclosingdate){
        const closingDateClone = new Date(closingData?.cleartoclose?.actualclosingdate);

            closingDateClone?.setMonth(closingDateClone.getMonth() + (loanInfo?.loanterm + ((loanInfo?.extensionDataCount || 0) * 3)));
            closingDateClone.setDate(1);
            closingDateClone.setMonth(closingDateClone.getMonth() + 1);
            // console.log('closingDate',closingDate)
        console.log('closingDateClone',closingDateClone.toDateString());
        setMaturityDate(closingDateClone.toDateString());
    }else{
        setMaturityDate('')
    }
   
  };
    
    useEffect(() => {
     console.log('closingData --->',closingData);
    //  calculateMaturityDate();
    }, [closingData]);
    useEffect(() => {
        if(loanid){
            getClosingData();
            getRatePoints();
        }
      }, [loanid]);
      useEffect(() => {
        setLoanid(loanInfo?.loanid)
      }, [loanInfo?.loanid]);

    const getClosingData = ()=>{
        dispatch(showLoader());
        getCleartoClose(loanInfo?.loanid)
        .then(function(response) {
            if (response?.result?.[0]) {
                // console.log('closing response', response?.result?.[0]?.propertyinfo?.constructioncompleteddate);
                if(response?.result?.[0]?.propertyinfo?.constructioncompleteddate){
                    console.log('rannnnn')
                    setClosingDate(response?.result?.[0]?.cleartoclose?.actualclosingdate);
                    setClosingData({
                        cleartoclose : {
                            ...jsonFormat?.cleartoclose,
                            constructioncompleteddate : response?.result?.[0]?.propertyinfo?.constructioncompleteddate,
                            ...response?.result?.[0]?.cleartoclose,
                        }
                    });
                    setClosingDataCheck({
                        cleartoclose : {
                            ...jsonFormat?.cleartoclose,
                            constructioncompleteddate : response?.result?.[0]?.propertyinfo?.constructioncompleteddate,
                            ...response?.result?.[0]?.cleartoclose,
                        }
                    });
                }else{
                    setClosingDate(response?.result?.[0]?.cleartoclose?.actualclosingdate);
                    setClosingData({
                        cleartoclose : {
                            ...response?.result?.[0]?.cleartoclose,
                            ...jsonFormat?.cleartoclose
                        }
                    });
                    setClosingDataCheck({
                        cleartoclose : {
                            ...response?.result?.[0]?.cleartoclose,
                            ...jsonFormat?.cleartoclose
                        }
                    });
                }
                
                SetErrors({})
                dispatch(hideLoader());
            }else{
                setClosingDate('')
                setClosingData({...jsonFormat});
                setClosingDataCheck({...jsonFormat});
                SetErrors({})
                dispatch(hideLoader());
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    };
    const getRatePoints = ()=>{
        dispatch(showLoader());
        dealratepointsGet(loanInfo?.loanid)
        .then(function(response) {
            if (response?.result?.length !== 0) {
                console.log('all rate and points response', response.result);
                const approved = response.result.filter(item => item.status === 'APPROVED');
                console.log(approved)
                if(approved?.length!==0){
                    const lastApprovedPoint = approved[approved.length - 1];
                console.log("Last approved point:", lastApprovedPoint,loanInfo?.loanamount);
                setTableValues({
                    ...tableValues,
                    loanOrigination :lastApprovedPoint?.loanorganisationpoints + '%',
                    brokerfee : lastApprovedPoint?.points + '%'
                })
                }
                
                
                dispatch(hideLoader());
            }else{
                setTableValues({
                    loanOrigination : '0.00%',
                    brokerfee : '0.00%'
                })
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    };
    const addApiCall =()=>{
        dispatch(showLoader());
        let finalClosing = { ...closingData };
        let constructioncompleteddate = closingData?.cleartoclose?.constructioncompleteddate;
        if (finalClosing.cleartoclose) {
            finalClosing.cleartoclose = { ...finalClosing.cleartoclose };
            delete finalClosing.cleartoclose.constructioncompleteddate;
        }
        // finalClosing.cleartoclose.proposedmaturitydate = maturityDate ? moment(maturityDate).format("DD-MM-YYYY") : '';
        let apiData = {
            constructioncompleteddate: constructioncompleteddate,
            ...finalClosing,
            loanid: loanInfo?.loanid
        };
        // if(closingData?.cleartoclose?.constructioncompleteddate === closingDataCheck?.cleartoclose?.constructioncompleteddate){
        //     delete apiData['constructioncompleteddate'];
        // }
        // let fieldsCheck = ['scheduledclosingdate','actualclosingdate'];
        // fieldsCheck?.forEach(field => {
        //     if (closingData?.cleartoclose?.[field] === closingDataCheck?.cleartoclose?.[field] ) {
        //         delete apiData.cleartoclose[field];
        //     }
        // });
        console.log('apiDataaaaa---->',apiData)
        addCleartoclose(apiData)
        .then(function(response) {
            if (response?.result) {
                console.log('add closing --- > ', response?.result);
                setPopupOpen(true);
                getClosingData();
                dispatch(hideLoader());
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    }
    useEffect(() => {
        const isFormChanged =
            JSON.stringify(closingData) !== JSON.stringify(closingDataCheck);
            setIsChange(isFormChanged);
        console.log(isFormChanged);
    }, [closingData, closingDataCheck]);
    const inputFields = [
        { label: "Requested Closing Date", type : 'date',name: "constructioncompleteddate"},
        { label: "Scheduled Closing Date" , type : 'date', name: "scheduledclosingdate" },
        { label: "Actual Closed Date" , type : 'date', name: "actualclosingdate", },
        { label: "Scheduled First Payment Date" , type : 'date', name: "scheduledfirstpaymentdate",},
        { label: "Clear to Close Date" , type : 'date', name: "cleartoclosingdate",},
        { label: "Prelim Effective Date" , type : 'date', name: "prelimeffectivedate",  },
        { label: "Docs Sent to Title Company Date" , type : 'date', name: "docssenttotitlecompanydate",  },
        { label: "Declared Maturity Date" , type : 'date', name: "declaredmaturitydate",  },
        { label: "Document Expiration Date" , type : 'date', name: "docexpirationdate",  },
        { label: "Escrow Number" , type : 'number', name: "escrownumber", placeholder: "Enter Escrow Number" },
        { label: "Title Order Number" , type : 'number', name: "titleordernumber", placeholder: "Enter Title Order Number" },
    ];
    const handleFocus = (e) => {
        const {name} = e.target;
        SetErrors({...errors,[name] : ""});
    };
    const handleChange=(e)=>{
        let {name,value,type} = e.target;
        let updatedValue = value;
        if(type === 'number'){
            const parsedValue = parseFloat(value.replace(/[^.0-9]/g, ''));
            updatedValue = isNaN(parsedValue) ? '' : parsedValue;
            setClosingData((prev) => ({
                ...prev,
                cleartoclose : {
                    ...prev?.cleartoclose,
                    [name] : updatedValue,
                }
            }));
        }else if(name === 'declaredmaturitydate'){
            setClosingData((prev) => ({
                ...prev,
                cleartoclose : {
                    ...prev?.cleartoclose,
                    [name] : updatedValue,
                    ['proposedmaturitydate'] : updatedValue,
                    ['currentmaturitydate'] : updatedValue
                }
            }));
        }else if (name === 'actualclosingdate'){
            if(!closingData?.cleartoclose?.declaredmaturitydate){
                if(loanInfo?.loanterm && updatedValue){
                    const closingDateClone = new Date(updatedValue);
                    closingDateClone?.setMonth(closingDateClone.getMonth() + (loanInfo?.loanterm));
                    closingDateClone.setDate(1);
                    closingDateClone.setMonth(closingDateClone.getMonth()+ 1);
                    console.log('closingDateClone',closingDateClone.toDateString());
                    setMaturityDate(closingDateClone.toDateString());
                    setClosingData((prev) => ({
                        ...prev,
                        cleartoclose : {
                            ...prev?.cleartoclose,
                            [name] : updatedValue,
                            ['proposedmaturitydate'] : closingDateClone.toDateString(),
                        }
                    }));
                   
                }
            }else{
                setClosingData((prev) => ({
                    ...prev,
                    cleartoclose : {
                        ...prev?.cleartoclose,
                        [name] : updatedValue,
                    }
                }));
            }
        }
        else{
            setClosingData((prev) => ({
                ...prev,
                cleartoclose : {
                    ...prev?.cleartoclose,
                    [name] : updatedValue,
                }
            }));
        }
        if (name === 'scheduledfirstpaymentdate') {
            const loanTerm = loanInfo?.loanterm; // Assuming loanTerm is in months
    
            if (updatedValue && loanTerm) {               
                // Convert updatedValue (yyyy-mm-dd) to a Date object
                const dateParts = updatedValue.split('-'); // Split by '-' for yyyy-mm-dd
                const firstPaymentDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]); // year, month (0-based), day
    
                // Calculate the declared maturity date by adding the loan term in months
                const declaredMaturityDate = new Date(firstPaymentDate);
                declaredMaturityDate.setMonth(firstPaymentDate.getMonth() + (loanTerm-1)); // Add loanTerm directly
    
                // Format the declared maturity date back to 'yyyy-mm-dd' format
                const formattedMaturityDate = declaredMaturityDate.toISOString().split('T')[0];
    
                // Update the state with the calculated declared maturity date
                setClosingData((prev) => ({
                    ...prev,
                    cleartoclose: {
                        ...prev?.cleartoclose,
                        ['declaredmaturitydate']: formattedMaturityDate,
                        ['proposedmaturitydate']: formattedMaturityDate,
                        ['currentmaturitydate']: formattedMaturityDate,
                    },
                }));
            }
        }
    };

    const handleSave = () => {
        let fieldsToCheck = {'constructioncompleteddate' : 'constructioncompleteddate'};
        let errorsCheck = {};
        // inputFields.forEach(field => {
        //     fieldsToCheck[field.name] = field.name;
        // });
        console.log(fieldsToCheck);
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            const fieldValue = closingData?.cleartoclose?.[fieldName];
            if (typeof fieldValue === 'string' && !fieldValue.trim()) {
                errorsCheck[fieldName] = `Please Enter ${fieldLabel} `;
            } else if (typeof fieldValue === 'number') {
                const stringValue = fieldValue.toString();
                if (!stringValue.trim()) {
                    errorsCheck[fieldName] = `Please Enter a valid ${fieldLabel} `;
                }
            }
        };
        console.log("errorsCheck",errorsCheck);
        SetErrors(errorsCheck);
        if(Object.keys(errorsCheck).length === 0){
            addApiCall();
        }
    };
    const closePopup = () => {
        setPopupOpen(false);
    };
    

    return (
        <div id="layout-wrapper" className="dashboard">
            {loading ? <Loader/> : null} 
            {isPopupOpen && (
                <PopupMessage
                type={'success'}
                message={popupMessage}
                onClose={closePopup}
                />
            )}
         <PipelineHeader />
        <div className="dashboard_block">
        <PipelineStatus />
        <h2 className="form_title">Closing and Funding <span className="space">&#10095;</span> <span className="sub">Clear To Close</span> </h2>
            <div className="card user_management ctc">
                <div className="card-body my_account loan_summary">
                    <div className='main_details'>
                    <div className="detail_block"><p>Date Created</p><h4>{moment.utc(loanInfo?.createdAt).format("MM-DD-YYYY")}</h4></div>
                    <div className="detail_block"><p>Loan Number</p><h4>{loanInfo?.loanid || 'N/A'}</h4></div>
                    <div className="detail_block capitalize-text"><p>Originator/AE</p><h4>{loanInfo?.applicant?.[0]?.accountexecutive || 'N/A'}</h4></div>
                    <div className="detail_block"><p>Proposed Maturity Date</p><h4>{closingData?.cleartoclose?.proposedmaturitydate ? moment.utc(closingData?.cleartoclose?.proposedmaturitydate).format("MM-DD-YYYY") : 'N/A'}</h4></div>
                    <div className="detail_block"><p>Loan Origination Fee %</p><h4>{tableValues?.loanOrigination}</h4></div>
                    </div>
                    <div className='main_details'>
                    <div className="detail_block"><p>LTV %</p> <h4>{loanInfo?.propertyinfo?.[0]?.maxltv ? `${loanInfo?.propertyinfo?.[0]?.maxltv} %` : '0.00%'}</h4></div>
                    <div className="detail_block"><p>Interest Rate</p><h4>{loanInfo?.interestrate ? (loanInfo?.interestrate.toFixed(2)  + '%') : '0.00%'}</h4></div>
                    <div className="detail_block"><p>Per Diem</p><h4>$0.00</h4></div>
                    <div className="detail_block"><p>Broker Fee %</p><h4>{tableValues?.brokerfee}</h4></div>
                    <div className="detail_block"> <p>Loan Origination Fee</p><h4>
                            {loanInfo?.loanamount && tableValues?.loanOrigination ?
                            `${((loanInfo?.loanamount / 100) * parseFloat(tableValues?.loanOrigination)).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}` :
                            '$0.00'
                            }
                        </h4>
                    </div>
                    </div>
               
                    <div className="form_section">
                        <hr className="hr" />
                        <div className="row">
                            {inputFields.map((field, index) => (
                                <div key={index} className="col-md-3">
                                    <div className="form-group">
                                        <label>{field.label}</label>
                                        {field.type === 'date' ? (
                                            
                                            <input
                                                type="date"
                                                name={field?.name}
                                                className={`form-control ${errors[field?.name] && 'invalid-input'}`}
                                                onChange={handleChange}
                                                value={closingData?.cleartoclose?.[field?.name] ? ( new Date(closingData?.cleartoclose?.[field?.name]).toISOString().split('T')[0]) : ''}
                                                onFocus={handleFocus}
                                                min={field?.name === 'declaredmaturitydate' && closingData?.cleartoclose?.['scheduledfirstpaymentdate'] 
                                                    ? new Date(closingData.cleartoclose['scheduledfirstpaymentdate']).toISOString().split("T")[0] 
                                                    :field?.name === 'scheduledfirstpaymentdate' && closingData?.cleartoclose?.['actualclosingdate'] 
                                                    ? new Date(closingData.cleartoclose['actualclosingdate']).toISOString().split("T")[0]: undefined} 
                                                id={field?.name}
                                            />
                                        ) : (
                                            <input
                                                type="number"
                                                name={field?.name}
                                                placeholder={field.placeholder}
                                                className={`form-control ${errors[field?.name] && 'invalid-input'}`}
                                                onChange={handleChange}
                                                value={closingData?.cleartoclose?.[field?.name]}
                                                onFocus={handleFocus}
                                                id={field?.name}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="btn-group mt_10">
                            <button id="saveClearToClose" className="fs_btn" disabled={!isChange} style={{'opacity' : isChange ? 1 : 0.6}}  onClick={(e)=>{e.preventDefault();handleSave()}}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PipelineFooter />
    </div>
    );
};

export default Closing;