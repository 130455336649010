import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import {getSingleHmda, hmdaAdd} from "./../utils/reducer";
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import { useDispatch, useSelector} from 'react-redux';
import Loader from './Loader';
import PopupMessage from './PopupMessage';
import { hideLoader, showLoader } from '../redux/reducers/Actions';
import { useLoanInfo } from '../utils/loanInfoContext';
const DealHMDA = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const loading = useSelector((state) => state.loaderVisible);
    const [errors,setErrors] = useState({});
    const { loanInfo } = useLoanInfo();
    console.log("loanInfo",loanInfo);
    
    let hmdaVariable = {
        "loanandorganisationinfo":{
            "loanpurpose":"",
            "preapprovals":"",
            "actiontaken":"",
            "actiondate":"",
            "typeofpurchase":"",
            "rateofspreadpercentage":"",
            "hoepastatus":""
        },
        "reasonfordenial":{
            "reasonfordenial1": "",
            "reasonfordenial2": "",
            "reasonfordenial3": "",
            "reasonfordenial4": "",
            "otherreason": "",
        },
        "closingcostdetails":{
            "negativeamortization" : "",
            "othernonamortization" : "",
            "manusecuredproptype" : "",
            "manuhomelandpropintr" : "",
            "multifamilynounits" : "",
            "submissionofapp" : "",
            "initiallypayable" : "",
            "nmlsloanoriginatorid" : ""
        },
        "riskassessment" :{
            "aus1" : '',
            "aus2" : '',
            "aus3" : '',
            "aus4" : '',
            "aus5" : '',
            "otheraus" : '',
            "ausrecommendation1" : "",
            "ausrecommendation2" : "",
            "ausrecommendation3" : "",
            "ausrecommendation4" : "",
            "ausrecommendation5" : "",
            "otherausrecommendation" : "",
            "reversemortgage" : "",
            "openendlineofcredit" : "",
            "businesscommercial" : "",
        },
        "regulationb" : {
            "actiontaken" : "",
            "actiondate" : "",
        }
    };

    const [hmdaData , setHmdaData] = useState(hmdaVariable);
    const [hmdaCheck,setHmdaCheck] = useState(hmdaVariable);
    const [isloanOrganisationChange,setIsloanOrganisationChange] = useState(false);
    const [isReasonChange,setIsReasonChange] = useState(false);
    const [isClosingChange,setIsClosingChange] = useState(false);
    const [isRiskChange,setIsRiskChange] = useState(false);
    const [isRegulationChange,setIsRegulationChange] = useState(false);
    useEffect(() => {
        if(loanInfo?.loanid){
            getHmda();
        }
    }, [loanInfo?.loanid]);

    // dropdwons 
    const reasonsForDenialOptions = [
        "Collateral",
        "Credit application incomplete",
        "Credit history",
        "Debt-to-income ratio",
        "Employment history",
        "Insufficient cash (downpayment, closing costs)",
        "Mortgage insurance denied",
        "Not applicable",
        "Other",
        "Unverifiable information"
    ];
    
    const ausOptions = [
        "Desktop Underwriter (DU)",
        "Guaranteed Underwriting System (GUS)",
        "Loan Prospector (LP)",
        "Not applicable",
        "Other",
        "Technology Open to Approved Lenders (TOTAL) Scorecard"
    ];
    
    const ausRecOptions = [
        "Accept",
        "Approve/Eligible",
        "Approve/Ineligible",
        "Caution",
        "Eligible",
        "Error",
        "Ineligible",
        "Incomplete",
        "Invalid",
        "Not applicable",
        "Other",
        "Out of Scope",
        "Refer",
        "Refer with Caution",
        "Refer/Eligible",
        "Refer/Ineligible",
        "Unable to Determine"
    ];
    
    useEffect(() => {
      console.log("hmdaaaaaa------>",hmdaData)
    }, [hmdaData]);
   
    useEffect(() => {
        formChangeCheck(hmdaData?.reasonfordenial,hmdaCheck?.reasonfordenial,setIsReasonChange);
        formChangeCheck(hmdaData?.loanandorganisationinfo,hmdaCheck?.loanandorganisationinfo,setIsloanOrganisationChange);
        formChangeCheck(hmdaData?.closingcostdetails,hmdaCheck?.closingcostdetails,setIsClosingChange);
        formChangeCheck(hmdaData?.riskassessment,hmdaCheck?.riskassessment,setIsRiskChange);
        formChangeCheck(hmdaData?.regulationb,hmdaCheck?.regulationb,setIsRegulationChange);
    }, [hmdaData, hmdaCheck]);
    const formChangeCheck = (formdata,formCheck,setIsCheck)=>{
        const isFormChanged =
            JSON.stringify(formdata) !== JSON.stringify(formCheck);
            setIsCheck(isFormChanged);
        console.log(isFormChanged);
    }
    const handleChange = (section, e) => {
        let { name, value } = e.target;
        let updatedValue = value;
        if(name === 'rateofspreadpercentage'){
            const parsedValue = parseFloat(value.replace(/[^.0-9]/g, ''));
            updatedValue = isNaN(parsedValue) ? '' : parsedValue;
            setHmdaData((prevData) => ({
                ...prevData,
                [section]: {
                    ...prevData[section],
                    [name]: updatedValue,
                },
            }));

        }else{
            setHmdaData((prevData) => ({
                ...prevData,
                [section]: {
                    ...prevData[section],
                    [name]: updatedValue,
                },
            }));
        }
    
        
    };
    const handleCreditInfo = (section, subSection, fieldName, value) => {
        setHmdaData((prevData) => ({
            ...prevData,
            [section]: {
                ...prevData[section],
                [subSection]: {
                    ...prevData[section][subSection],
                    [fieldName]: value,
                },
            },
        }));
    };
    

    
    const getHmda = ()=>{
        dispatch(showLoader());
        getSingleHmda(loanInfo?.loanid)
        .then(function(response) {
            if (response.result?.length !== 0) {
                console.log('hmda response', response);
                setHmdaData((prev) => ({
                    ...prev,
                    ...response.result[0], 
                }));
                setHmdaCheck((prev) => ({
                    ...prev,
                    ...response.result[0], 
                }));
                setErrors({})
                dispatch(hideLoader());
            }else{
                setHmdaData({
                    ...hmdaVariable,
                });
                setHmdaCheck({
                    ...hmdaVariable
                })
                setErrors({})
                dispatch(hideLoader());
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    };

    const handleAddHmda =(name ,fields)=>{
        let apiData = {[name]: fields,loanid : loanInfo?.loanid}
        if(hmdaData?.hmdaid){
            apiData["hmdaid"] = hmdaData?.hmdaid
        }
        dispatch(showLoader());
        hmdaAdd(apiData).then(function (response) {
            if (response) {
               console.log('response?.result',response.result);
               getHmda();
               dispatch(hideLoader());
               setPopupOpen(true);
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
              dispatch(hideLoader());
            })
    };
    const closePopup = () => {
        setPopupOpen(false);
        
    };

    const handleAddloanandorganisationinfo = ()=>{
        const fieldsToCheck = {
             loanpurpose:"loanpurpose", preapprovals:"preapprovals", actiontaken:"actiontaken", actiondate:"actiondate", 
             typeofpurchase:"typeofpurchase", rateofspreadpercentage:"rateofspreadpercentage", hoepastatus:"hoepastatus"}
            let fields = hmdaData?.loanandorganisationinfo;
            let name = 'loanandorganisationinfo'
            handleAdd(name,fields,fieldsToCheck);
    };
    const handleAddreasonfordenial = ()=>{
        const fieldsToCheck = { reasonfordenial1: "reasonfordenial1",reasonfordenial2: "reasonfordenial2", reasonfordenial3: "reasonfordenial3",
            reasonfordenial4: "reasonfordenial4", otherreason: "otherreason" }
            let fields = hmdaData?.reasonfordenial;
            let name = 'reasonfordenial'
            handleAdd(name,fields,fieldsToCheck);
    };
    const handleAddclosingcostdetails = ()=>{
        const fieldsToCheck = { negativeamortization: "negativeamortization",othernonamortization: "othernonamortization", manusecuredproptype: "manusecuredproptype",
            manuhomelandpropintr: "manuhomelandpropintr", multifamilynounits: "multifamilynounits", submissionofapp: "submissionofapp",  initiallypayable: "initiallypayable",
            nmlsloanoriginatorid: "nmlsloanoriginatorid"
        };
            let fields = hmdaData?.closingcostdetails;
            let name = 'closingcostdetails'
            handleAdd(name,fields,fieldsToCheck);
    };
    const handleAddriskassessment = ()=>{
        const fieldsToCheck = {aus1: "aus1",aus2: "aus2",aus3: "aus3",aus4: "aus4", aus5: "aus5", otheraus: "otheraus", ausrecommendation1: "ausrecommendation1",
            ausrecommendation2: "ausrecommendation2", ausrecommendation3: "ausrecommendation3",ausrecommendation4: "ausrecommendation4",ausrecommendation5: "ausrecommendation5",otherausrecommendation: "otherausrecommendation",
            reversemortgage: "reversemortgage", openendlineofcredit: "openendlineofcredit", businesscommercial: "businesscommercial"
        }
            let fields = hmdaData?.riskassessment;
            let name = 'riskassessment'
            handleAdd(name,fields,fieldsToCheck);
    };
    const handleAddregulationb = () => {
        const fieldsToCheck = { "actiontaken": "actiontaken", "actiondate": "actiondate" };
        let fields = hmdaData?.regulationb;
        let name = 'regulationb';
    
        let handleAdd = (name, fields, fieldsToCheck) => {
            const errors = {};
            for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
                const fieldValue = fields[fieldName];
                if (!fieldValue) {
                    const errorFieldName = fieldName + "s"; 
                    errors[errorFieldName] = `${fieldLabel} is required.`;
                }
            }
            setErrors(errors);
            if (Object.keys(errors).length === 0) {
                handleAddHmda(name, fields);
            } else {
                console.log('errors ----> ', errors);
            }
        };
    
        handleAdd(name, fields, fieldsToCheck);
    };
    

    // const handleAddCreditInfo = () => {
    //     const fieldsToCheck = {"creditscore": "Credit Score", "creditscoremodel": "Credit Score Model","otherscoringmodel": "Other Scoring Model" };
    
    //     const borrowerErrors = {};
    //     for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
    //         if (!hmdaData.creditinformation.borrower?.[fieldName]?.trim()) {
    //             borrowerErrors[fieldName] = `Please Enter ${fieldLabel}`;
    //         }
    //     }
    
    //     setCreditBorrower(borrowerErrors);

    //     const coBorrowerErrors = {};
    //     for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
    //         if (!hmdaData.creditinformation.coborrower?.[fieldName]?.trim()) {
    //             coBorrowerErrors[fieldName] = `Please Enter ${fieldLabel}`;
    //         }
    //     }
    
    //     setCreditCoBorrower(coBorrowerErrors);
    
    //     if (Object.keys(borrowerErrors).length === 0 && Object.keys(coBorrowerErrors).length === 0) {
    //         handleAddHmda();
    //     }
    // };
    


    const handleAdd = (name,fields,fieldsToCheck)=>{
        // console.log(fields)
        // const errors = {};
        // for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
        //     const fieldValue = fields[fieldName];
        //     if (typeof fieldValue === 'string' && !fieldValue.trim()) {
        //         errors[fieldName] = `Please Enter ${fieldLabel} `;
        //     } else if (typeof fieldValue === 'number') {
        //         const stringValue = fieldValue.toString();
        //         if (!stringValue.trim()) {
        //             errors[fieldName] = `Please Enter a valid ${fieldLabel} `;
        //         }
        //     }
        // };
        // setErrors(errors);
        // if (Object.keys(errors).length === 0) {
        //     handleAddHmda(name,fields);
        // }else{
        //     console.log('errors ----> ',errors)
        // }
        handleAddHmda(name,fields);
    };

    return (
        <div id="layout-wrapper" className="dashboard">
            {loading ? <Loader/> : null} 
            {isPopupOpen && (
            <PopupMessage
             type={'success'}
             message={'Saved Successfully'}
             onClose={closePopup}
            />
        )}
             <PipelineHeader />
            <div className="dashboard_block">
                <PipelineStatus/>
                <h2 className="form_title">Deal <span className="space">&#10095;</span>  <span className="sub">HMDA</span> </h2>
                <div className="card user_management detailed_app">
                    <div className="card-body my_account">
                        <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                            <li className="nav-item">
                                <a id="loanOrginationInfoTab" className="nav-link active" data-bs-toggle="tab" href="#ENTITY" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">loan & origination info</span>
                                </a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link " data-bs-toggle="tab" href="#APPLICANT" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">credit information</span>
                                </a>
                            </li> */}
                            <li className="nav-item">
                                <a id="reasonForDenialTab" className="nav-link " data-bs-toggle="tab" href="#HMDA" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">reasons for denial</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a id="closingCostsDetailsTab" className="nav-link " data-bs-toggle="tab" href="#PROPERTY" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">closing costs details</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a id="riskAssessmentTab" className="nav-link" data-bs-toggle="tab" href="#LOAN" role="tab">
                                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                    <span className="d-none d-sm-block">risk assessment</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a id="regulationTab" className="nav-link" data-bs-toggle="tab" href="#REGULATION" role="tab">
                                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                    <span className="d-none d-sm-block">regulation b</span>
                                </a>
                            </li>


                        </ul>


                        <div className="tab-content pt-15 text-muted">
                            <div className="tab-pane active" id="ENTITY" role="tabpanel">



                                <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-0">

                                </div>
                                <div className="form_section">
                                    <div className="row">

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Loan Purpose</label>
                                                <select id="loanPurposeHmda" className={`form-control ${errors?.loanpurpose && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, loanpurpose: null }) }} name='loanpurpose' value={hmdaData?.loanandorganisationinfo?.loanpurpose} onChange={(e) => handleChange('loanandorganisationinfo', e)}>
                                                    <option selected value="">Select</option>
                                                    <option value="Cash-out refinancing">Cash-out refinancing</option>
                                                    <option value="Home improvement">Home improvement</option>
                                                    <option value="Home purchase">Home purchase</option>
                                                    <option value="Not applicable">Not applicable</option>
                                                    <option value="Other purpose">Other purpose</option>
                                                    <option value="Refinancing">Refinancing</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Pre approvals</label>
                                                <select id="preApprovalsHmda" className={`form-control ${errors?.preapprovals && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, preapprovals: null }) }} name='preapprovals' value={hmdaData?.loanandorganisationinfo?.preapprovals} onChange={(e) => handleChange('loanandorganisationinfo', e)}>
                                                  <option selected value="">Select</option>
                                                    <option value="Preapproval requested">Pre approval requested</option>
                                                    <option value="Preapproval not requested">Pre approval not requested</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Action Taken</label>
                                                <select id="actionTakenHmda" className={`form-control ${errors?.actiontaken && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, actiontaken: null }) }} name='actiontaken' value={hmdaData?.loanandorganisationinfo?.actiontaken} onChange={(e) => handleChange('loanandorganisationinfo', e)}>
                                                        <option selected value="">Select</option>
                                                        <option value="Application approved but not accepted">Application approved but not accepted</option>
                                                        <option value="Application denied">Application denied</option>
                                                        <option value="Application withdrawn by applicant">Application withdrawn by applicant</option>
                                                        <option value="File closed for incompleteness">File closed for incompleteness</option>
                                                        <option value="Loan originated">Loan originated</option>
                                                        <option value="Preapproval request approved but not accepted">Preapproval request approved but not accepted</option>
                                                        <option value="Preapproval request denied">Preapproval request denied</option>
                                                        <option value="Purchased loan">Purchased loan</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Action Date</label>
                                                <input id="actionDateHmda" type="date"  className={`form-control ${errors?.actiondate && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, actiondate: null }) }} placeholder="mm-dd-yyy" name='actiondate'  value={hmdaData?.loanandorganisationinfo?.actiondate ? ( new Date(hmdaData?.loanandorganisationinfo?.actiondate).toISOString().split('T')[0]) : ''}
                                                onChange={(e) => handleChange('loanandorganisationinfo', e)}/>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Type of Purchaser</label>
                                                <select id="purchaserHmda" className={`form-control ${errors?.typeofpurchase && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, typeofpurchase: null }) }} name='typeofpurchase' value={hmdaData?.loanandorganisationinfo?.typeofpurchase} onChange={(e) => handleChange('loanandorganisationinfo', e)}>
                                                        <option selected value="">Select</option>
                                                        <option value="Affiliate institution">Affiliate institution</option>
                                                        <option value="Commercial bank, savings bank, or savings association">Commercial bank, savings bank, or savings association</option>
                                                        <option value="Credit union, mortgage company, or finance company">Credit union, mortgage company, or finance company</option>
                                                        <option value="Fannie Mae">Fannie Mae</option>
                                                        <option value="Farmer Mac">Farmer Mac</option>
                                                        <option value="Freddie Mac">Freddie Mac</option>
                                                        <option value="Ginnie Mae">Ginnie Mae</option>
                                                        <option value="Life insurance company">Life insurance company</option>
                                                        <option value="Not applicable">Not applicable</option>
                                                        <option value="Other type of purchaser">Other type of purchaser</option>
                                                        <option value="Private securitizer">Private securitizer</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Rate of Spread %</label>
                                                <input id="rateSpreadHmda" type="number"  className={`form-control ${errors?.rateofspreadpercentage && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, rateofspreadpercentage: null }) }} name='rateofspreadpercentage' value={hmdaData?.loanandorganisationinfo?.rateofspreadpercentage} onChange={(e) => handleChange('loanandorganisationinfo', e)}
                                                    placeholder="Enter Rate of Spread" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>HOEPA Status</label>
                                                <select id="HoepaStatusHmda" className={`form-control ${errors?.hoepastatus && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, hoepastatus: null }) }} name='hoepastatus' value={hmdaData?.loanandorganisationinfo?.hoepastatus} onChange={(e) => handleChange('loanandorganisationinfo', e)}>
                                                <option selected value="">Select</option>
                                                <option value="High-cost mortgag">High-cost mortgage</option>
                                                <option  value="Not applicable">Not applicable</option>
                                                <option value="Not a high-cost mortgage">Not a high-cost mortgage</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <button id="saveLoanOrigination" href="#" disabled={!isloanOrganisationChange}  onClick={(e) => {e.preventDefault();handleAddloanandorganisationinfo() }} style={{'opacity' : isloanOrganisationChange ? 1 : 0.6}} className="fs_btn">save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            {/* <div className="tab-pane" id="APPLICANT" role="tabpanel">




                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <div className="d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0 font-size-18">Borrower</h4>
                                    </div>

                                </div>
                                <div className="form_section">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Credit Score</label>
                                                <input type="text"  className={`form-control ${creditBorrower?.creditscore && 'invalid-input'}`} onFocus={() => { setCreditBorrower({ ...creditBorrower, creditscore: null }) }} value={hmdaData?.creditinformation?.borrower?.creditscore} onChange={(e) => handleBorrowerChange('creditscore', e.target.value)} placeholder="Enter Credit Score"/> 
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Credit Scoring Model</label>
                                                <select  className={`form-control ${creditBorrower?.creditscoremodel && 'invalid-input'}`} onFocus={() => { setCreditBorrower({ ...creditBorrower, creditscoremodel: null }) }}  placeholder="Please Select" value={hmdaData?.creditinformation?.borrower?.creditscoremodel} onChange={(e) => handleBorrowerChange('creditscoremodel', e.target.value)}>
                                                   <option selected value="">Select</option>
                                                    <option value="Equifax Beacon 5.0">Equifax Beacon 5.0</option>
                                                    <option value="Experian Fair Isaac">Experian Fair Isaac</option>
                                                    <option value="FICO Risk Score Classic 04">FICO Risk Score Classic 04</option>
                                                    <option value="FICO Risk Score Classic 98">FICO Risk Score Classic 98</option>
                                                    <option value="VantageScore 2.0">VantageScore 2.0</option>
                                                    <option value="VantageScore 3.0">VantageScore 3.0</option>
                                                    <option value="More than one credit scoring model">More than one credit scoring model</option>
                                                    <option value="Other credit scoring model">Other credit scoring model</option>
                                                    <option value="Not applicable">Not applicable</option>
                                                    <option value="No co-applicant">No co-applicant</option>

                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Other Scoring Model</label>
                                                <input type="text"  className={`form-control ${creditBorrower?.otherscoringmodel && 'invalid-input'}`} onFocus={() => { setCreditBorrower({ ...creditBorrower, otherscoringmodel: null }) }} name='otherreason' value={hmdaData?.creditinformation?.borrower?.otherscoringmodel} onChange={(e) => handleBorrowerChange('otherscoringmodel', e.target.value)}
                                                    placeholder="Enter Other Scoring Model" />
                                            </div>
                                        </div>


                                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                            <div className="d-sm-flex align-items-center justify-content-between">
                                                <h4 className="mb-sm-0 font-size-18">Co-Borrower</h4>
                                            </div>

                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Credit Score</label>
                                                <input  className={`form-control ${creditCoBorrower?.creditscore && 'invalid-input'}`} onFocus={() => { setCreditCoBorrower({ ...creditBorrower, creditscore: null }) }} type="text" value={hmdaData?.creditinformation?.coborrower?.creditscore} onChange={(e) => handleCoBorrowerChange('creditscore', e.target.value)}  placeholder="Enter Credit Score"  />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Credit Scoring Model</label>
                                                <select  className={`form-control ${creditCoBorrower?.creditscoremodel && 'invalid-input'}`} onFocus={() => { setCreditCoBorrower({ ...creditBorrower, creditscoremodel: null }) }} placeholder="Please Select" value={hmdaData?.creditinformation?.coborrower?.creditscoremodel} onChange={(e) => handleCoBorrowerChange('creditscoremodel', e.target.value)} >
                                                <option selected value="">Select</option>
                                                    <option value="Equifax Beacon 5.0">Equifax Beacon 5.0</option>
                                                    <option value="Experian Fair Isaac">Experian Fair Isaac</option>
                                                    <option value="FICO Risk Score Classic 04">FICO Risk Score Classic 04</option>
                                                    <option value="FICO Risk Score Classic 98">FICO Risk Score Classic 98</option>
                                                    <option value="VantageScore 2.0">VantageScore 2.0</option>
                                                    <option value="VantageScore 3.0">VantageScore 3.0</option>
                                                    <option value="More than one credit scoring model">More than one credit scoring model</option>
                                                    <option value="Other credit scoring model">Other credit scoring model</option>
                                                    <option value="Not applicable">Not applicable</option>
                                                    <option value="No co-applicant">No co-applicant</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Other Scoring Model</label>
                                                <input type="text"  className={`form-control ${creditCoBorrower?.otherscoringmodel && 'invalid-input'}`} onFocus={() => { setCreditCoBorrower({ ...creditBorrower, otherscoringmodel: null }) }}  name='otherreason' value={hmdaData?.creditinformation?.coborrower?.otherscoringmodel} onChange={(e) => handleCoBorrowerChange('otherscoringmodel', e.target.value)} 
                                                    placeholder="Enter Other Scoring Model" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <a href="#"  onClick={(e) => {e.preventDefault();handleAddCreditInfo() }} className="fs_btn">save</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div> */}
                            <div className="tab-pane hmda" id="HMDA" role="tabpanel">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-0">

                                </div>
                                <div className="form_section">
                                    <div className="row">

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Reason for Denial #1</label>
                                                <select id="reasonDeal1" className={`form-control ${errors?.reasonfordenial1 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, reasonfordenial1: null }) }} name='reasonfordenial1' value={hmdaData?.reasonfordenial?.reasonfordenial1} onChange={(e) => handleChange('reasonfordenial', e)}>
                                                    <option selected value="">Select</option>
                                                    {reasonsForDenialOptions.map((option, index) => (
                                                        <option key={index} value={option}>{option}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Reason for Denial #2</label>
                                                <select id="reasonDeal2" className={`form-control ${errors?.reasonfordenial2 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, reasonfordenial2: null }) }} name='reasonfordenial2' value={hmdaData?.reasonfordenial?.reasonfordenial2} onChange={(e) => handleChange('reasonfordenial', e)}>
                                                <option selected value="">Select</option>
                                                    {reasonsForDenialOptions.map((option, index) => (
                                                        <option key={index} value={option}>{option}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Reason for Denial #3</label>
                                                <select  id="reasonDeal3" className={`form-control ${errors?.reasonfordenial3 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, reasonfordenial3: null }) }} name='reasonfordenial3' value={hmdaData?.reasonfordenial?.reasonfordenial3} onChange={(e) => handleChange('reasonfordenial', e)}>
                                                <option selected value="">Select</option>
                                                    {reasonsForDenialOptions.map((option, index) => (
                                                        <option key={index} value={option}>{option}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Reason for Denial #4</label>
                                                <select id="reasonDeal4" className={`form-control ${errors?.reasonfordenial4 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, reasonfordenial4: null }) }} name='reasonfordenial4' value={hmdaData?.reasonfordenial?.reasonfordenial4} onChange={(e) => handleChange('reasonfordenial', e)}>
                                                <option selected value="">Select</option>
                                                    {reasonsForDenialOptions.map((option, index) => (
                                                        <option key={index} value={option}>{option}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Other Denial Reasons</label>
                                                <input id="otherDenialReasons" type="text"  className={`form-control ${errors?.otherreason && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, otherreason: null }) }} name='otherreason' value={hmdaData?.reasonfordenial?.otherreason} onChange={(e) => handleChange('reasonfordenial', e)}
                                                    placeholder="Enter Other Denial Reasons" />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <button id="saveReasonForDenial" disabled={!isReasonChange} onClick={(e) => {handleAddreasonfordenial() }} style={{'opacity' : isReasonChange ? 1 : 0.6}} className="fs_btn">save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="tab-pane property" id="PROPERTY" role="tabpanel">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-0">

                                </div>
                                <div className="form_section">
                                    <div className="row">

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Negative Amortization</label>
                                                <select  id="negativeAmortization" className={`form-control ${errors?.negativeamortization && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, negativeamortization: null }) }} name='negativeamortization' value={hmdaData?.closingcostdetails?.negativeamortization} onChange={(e) => handleChange('closingcostdetails', e)}>
                                                <option selected value="">Select</option>
                                                <option value="Negative amortization">Negative amortization</option>
                                                <option  value="No negative amortization">No negative amortization</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Other Non-Amortization</label>
                                                <select  id="otherAmortization" className={`form-control ${errors?.othernonamortization && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, othernonamortization: null }) }} name='othernonamortization' value={hmdaData?.closingcostdetails?.othernonamortization} onChange={(e) => handleChange('closingcostdetails', e)}>
                                                <option selected value="">Select</option>
                                                <option value="Other non-fully amortizing features">Other non-fully amortizing features</option>
                                                <option value="No other non-fully amortizing features">No other non-fully amortizing features</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Manu. Secured Prop Type</label>
                                                <select  id="securedPropType" className={`form-control ${errors?.manusecuredproptype && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, manusecuredproptype: null }) }} name='manusecuredproptype' value={hmdaData?.closingcostdetails?.manusecuredproptype} onChange={(e) => handleChange('closingcostdetails', e)}>
                                                <option selected value="">Select</option>
                                                <option value="Manufactured home and land">Manufactured home and land</option>
                                                <option value="Manufactured home and not land">Manufactured home and not land</option>
                                                <option value="Not applicable">Not applicable</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Manu. Home Land Prop Intr</label>
                                                <select  id="homeLandProp" className={`form-control ${errors?.manuhomelandpropintr && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, manuhomelandpropintr: null }) }} name='manuhomelandpropintr' value={hmdaData?.closingcostdetails?.manuhomelandpropintr} onChange={(e) => handleChange('closingcostdetails', e)}>
                                                <option selected value="">Select</option>
                                                <option value="Direct ownership">Direct ownership</option>
                                                <option value="Indirect ownership">Indirect ownership</option>
                                                <option value="Paid leasehold">Paid leasehold</option>
                                                <option value="Unpaid leasehold">Unpaid leasehold</option>
                                                <option value="Not applicable">Not applicable</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Multifamily No Units</label>
                                                <input id="multiFamilyNoUnits" type="text"  className={`form-control ${errors?.multifamilynounits && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, multifamilynounits: null }) }} name='multifamilynounits' value={hmdaData?.closingcostdetails?.multifamilynounits} onChange={(e) => handleChange('closingcostdetails', e)}
                                                    placeholder="Enter Multifamily No Units" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Submission of App</label>
                                                <select id="appSubmission" className={`form-control ${errors?.submissionofapp && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, submissionofapp: null }) }} name='submissionofapp' value={hmdaData?.closingcostdetails?.submissionofapp} onChange={(e) => handleChange('closingcostdetails', e)}>
                                                <option selected value="">Select</option>
                                                <option value="Submitted directly to your institution">Submitted directly to your institution</option>
                                                <option value="Not submitted directly to your institution">Not submitted directly to your institution</option>
                                                <option value="Not applicable">Not applicable</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Initially Payable</label>
                                                <select  id="initiallyPayable" className={`form-control ${errors?.initiallypayable && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, initiallypayable: null }) }} name='initiallypayable' value={hmdaData?.closingcostdetails?.initiallypayable} onChange={(e) => handleChange('closingcostdetails', e)}>
                                                <option selected value="">Select</option>
                                                <option  value="Initially payable to your institution">Initially payable to your institution</option>
                                                <option value="Not initially payable to your institution">Not initially payable to your institution</option>
                                                <option value="Not applicable">Not applicable</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>NMLS Loan Originator ID</label>
                                                <input id="nmlsLoanOriginatorId" type="text"  className={`form-control ${errors?.nmlsloanoriginatorid && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, nmlsloanoriginatorid: null }) }} name='nmlsloanoriginatorid' value={hmdaData?.closingcostdetails?.nmlsloanoriginatorid} onChange={(e) => handleChange('closingcostdetails', e)}
                                                    placeholder="Enter NMLS Loan Originator ID" />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <button id="saveClosingCosts" disabled={!isClosingChange} onClick={(e) => {handleAddclosingcostdetails() }} style={{'opacity' : isClosingChange ? 1 : 0.6}} className="fs_btn">save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="tab-pane" id="LOAN" role="tabpanel">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-0">

                                </div>
                                <div className="form_section">
                                    <div className="row">

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>AUS #1</label>
                                                <select id="aus1" className={`form-control ${errors?.aus1 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, aus1: null }) }} name='aus1' value={hmdaData?.riskassessment?.aus1} onChange={(e) => handleChange('riskassessment', e)}>
                                                    <option value='' selected>Select</option>
                                                    {ausOptions.map((option, index) => (
                                                    <option key={index} value={option}>{option}</option>
                                                ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>AUS #2</label>
                                                <select id="aus2" className={`form-control ${errors?.aus2 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, aus2: null }) }}  name='aus2' value={hmdaData?.riskassessment?.aus2} onChange={(e) => handleChange('riskassessment', e)}>
                                                <option value='' selected>Select</option>
                                                    {ausOptions.map((option, index) => (
                                                    <option key={index} value={option}>{option}</option>
                                                ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>AUS #3</label>
                                                <select id="aus3" className={`form-control ${errors?.aus3 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, aus3: null }) }}  name='aus3' value={hmdaData?.riskassessment?.aus3} onChange={(e) => handleChange('riskassessment', e)}>
                                                <option value='' selected>Select</option>
                                                    {ausOptions.map((option, index) => (
                                                    <option key={index} value={option}>{option}</option>
                                                ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>AUS #4</label>
                                                <select id="aus4" className={`form-control ${errors?.aus4 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, aus4: null }) }}  name='aus4' value={hmdaData?.riskassessment?.aus4} onChange={(e) => handleChange('riskassessment', e)}>
                                                <option value='' selected>Select</option>
                                                    {ausOptions.map((option, index) => (
                                                    <option key={index} value={option}>{option}</option>
                                                ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>AUS #5</label>
                                                <select id="aus5" className={`form-control ${errors?.aus5 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, aus5: null }) }} name='aus5' value={hmdaData?.riskassessment?.aus5} onChange={(e) => handleChange('riskassessment', e)}>
                                                <option value='' selected>Select</option>
                                                    {ausOptions.map((option, index) => (
                                                    <option key={index} value={option}>{option}</option>
                                                ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Other AUS(s)</label>
                                                <input id="otherAus" type="text"  className={`form-control ${errors?.otheraus && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, otheraus: null }) }} name='otheraus' value={hmdaData?.riskassessment?.otheraus} onChange={(e) => handleChange('riskassessment', e)}
                                                    placeholder="Enter Other AUS" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">

                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">

                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>AUS Recommendation #1</label>
                                                <select id="ausRecommendation1" className={`form-control ${errors?.ausrecommendation1 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, ausrecommendation1: null }) }} name='ausrecommendation1' value={hmdaData?.riskassessment?.ausrecommendation1} onChange={(e) => handleChange('riskassessment', e)}>
                                                    <option selected value=''>Select</option>
                                                    {ausRecOptions.map((option, index) => (
                                                            <option key={index} value={option}>{option}</option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>AUS Recommendation #2</label>
                                                <select id="ausRecommendation2" className={`form-control ${errors?.ausrecommendation2 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, ausrecommendation2: null }) }} name='ausrecommendation2' value={hmdaData?.riskassessment?.ausrecommendation2} onChange={(e) => handleChange('riskassessment', e)}>
                                                <option selected value=''>Select</option>
                                                    {ausRecOptions.map((option, index) => (
                                                            <option key={index} value={option}>{option}</option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>AUS Recommendation #3</label>
                                                <select id="ausRecommendation3" className={`form-control ${errors?.ausrecommendation3 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, ausrecommendation3: null }) }} name='ausrecommendation3' value={hmdaData?.riskassessment?.ausrecommendation3} onChange={(e) => handleChange('riskassessment', e)}>
                                                <option selected value=''>Select</option>
                                                    {ausRecOptions.map((option, index) => (
                                                            <option key={index} value={option}>{option}</option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>AUS Recommendation #4</label>
                                                <select id="ausRecommendation4" className={`form-control ${errors?.ausrecommendation4 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, ausrecommendation4: null }) }} name='ausrecommendation4' value={hmdaData?.riskassessment?.ausrecommendation4} onChange={(e) => handleChange('riskassessment', e)}>
                                                <option selected value=''>Select</option>
                                                    {ausRecOptions.map((option, index) => (
                                                            <option key={index} value={option}>{option}</option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>AUS Recommendation #5</label>
                                                <select id="ausRecommendation5" className={`form-control ${errors?.ausrecommendation5 && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, ausrecommendation5: null }) }} name='ausrecommendation5' value={hmdaData?.riskassessment?.ausrecommendation5} onChange={(e) => handleChange('riskassessment', e)}>
                                                <option selected value=''>Select</option>
                                                    {ausRecOptions.map((option, index) => (
                                                            <option key={index} value={option}>{option}</option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Other AUS(s) Recommendation</label>
                                                <input id="otherAusRecommendation" type="text"  className={`form-control ${errors?.otherausrecommendation && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, otherausrecommendation: null }) }} name='otherausrecommendation' value={hmdaData?.riskassessment?.otherausrecommendation} onChange={(e) => handleChange('riskassessment', e)}
                                                    placeholder="Enter Other AUS Recommendation" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">

                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">

                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Reverse Mortgage</label>
                                                <select id="reverseMortgage" className={`form-control ${errors?.reversemortgage && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, reversemortgage: null }) }} name='reversemortgage' value={hmdaData?.riskassessment?.reversemortgage} onChange={(e) => handleChange('riskassessment', e)}>
                                                    <option value="" selected>Select</option>
                                                    <option value="Reverse Mortgage">Reverse Mortgage</option>
                                                 <option selected="selected" value="Not a reverse mortgage">Not a reverse mortgage</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Open-End Line of Credit</label>
                                                <select id="creditOpenEndLine" className={`form-control ${errors?.openendlineofcredit && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, openendlineofcredit: null }) }} name='openendlineofcredit' value={hmdaData?.riskassessment?.openendlineofcredit} onChange={(e) => handleChange('riskassessment', e)}>
                                                <option value="" selected>Select</option>
                                                   <option value="Open-end line of credit">Open-end line of credit</option>
                                                   <option  value="Not an open-end line of credit">Not an open-end line of credit</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Business/Commercial</label>
                                                <select id="businessOrCommercial" className={`form-control ${errors?.businesscommercial && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, businesscommercial: null }) }} name='businesscommercial' value={hmdaData?.riskassessment?.businesscommercial} onChange={(e) => handleChange('riskassessment', e)}>
                                                <option value="" selected>Select</option>
                                                <option value="Primarily for a business or commercial purpose">Primarily for a business or commercial purpose</option>
                                                <option  value="Not primarily for a business or commercial purpose">Not primarily for a business or commercial purpose</option>

                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">

                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <button id="saveRiskAssessment" disabled={!isRiskChange} onClick={(e) => {handleAddriskassessment() }} style={{'opacity' : isRiskChange ? 1 : 0.6}} className="fs_btn">save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane property" id="REGULATION" role="tabpanel">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-0">

                                </div>
                                <div className="form_section">
                                    <div className="row">

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Action Taken</label>
                                                <select id="regulationActionTaken" className={`form-control ${errors?.actiontakens && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, actiontakens: null }) }} name='actiontaken' onChange={(e) => handleChange('regulationb', e)} value={hmdaData?.regulationb?.actiontaken}>
                                                    <option value='' selected>Select</option>
                                                    <option value="Booked/closed">Booked/closed</option>
                                                    <option value="Cancelled by lender">Cancelled by lender</option>
                                                    <option value="Denied/Decline">Denied/Decline</option>
                                                    <option value="Incomplete or inactive for over 30 days">Incomplete or inactive for over 30 days</option>
                                                    <option value="In Process">In Process</option>
                                                    <option value="Withdrawn by borrower">Withdrawn by borrower</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Action Date</label>
                                                <input id="regulationActionDate" type="date" name='actiondate' onChange={(e) => handleChange('regulationb', e)} value={hmdaData?.regulationb?.actiondate ? ( new Date(hmdaData?.regulationb?.actiondate).toISOString().split('T')[0]) : ''}  className={`form-control ${errors?.actiondates && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, actiondates: null }) }} placeholder="mm-dd-yyy" />
                                            </div>
                                        </div>


                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <button id="saveRegulation" disabled={!isRegulationChange} onClick={(e) => {handleAddregulationb() }} style={{'opacity' : isRegulationChange ? 1 : 0.6}} className="fs_btn">save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-sm-12">
                            <div className="text-sm-end d-none d-sm-block">
                            © 2024 easystreetcapital.com. All rights reserved.
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default DealHMDA;