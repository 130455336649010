import React from 'react';
import { useSelector} from 'react-redux';
const Footer = () => {
  const configdata = useSelector(state => state.configdata);

  return (
    <div className="cp_footer">
    <div className="wrapper">
        <p>{configdata?.admin?.rights}</p>
        <ul>
            <li><a href="privacy">Privacy </a></li>
            <li><a href=""> | </a></li>
            <li><a href="terms">Terms of Use</a></li>
        </ul>
    </div>
</div>
  );
};

export default Footer;