import React from 'react';

const CustomPagination = ({ totalRows, rowsPerPage, currentPage, onPageChange, onRowsPerPageChange }) => {
    const totalPages = Math.ceil(totalRows / rowsPerPage);

    // Calculate the current range of items (e.g., "1-10 of 95")
    const startItem = (currentPage - 1) * rowsPerPage + 1;
    const endItem = Math.min(currentPage * rowsPerPage, totalRows);

    return (
        <div className="custom-pagination">
            <label>Per page</label>
            <select
                value={rowsPerPage}
                onChange={(e) => onRowsPerPageChange(Number(e.target.value))}
                className="rows-per-page-select"
            >
                {[10, 20, 30, 40].map((size) => (
                    <option key={size} value={size}>
                        {size}
                    </option>
                ))}
            </select>

            <span>{`${startItem}-${endItem} of ${totalRows}`}</span>

            {/* First Page Button */}
            <button
                onClick={() => onPageChange(1)}
                disabled={currentPage === 1}
                className="pagination-button"
            >
                &#171; {/* Double Left Arrow */}
            </button>

            {/* Previous Page Button */}
            <button
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="pagination-button"
            >
                &#8249; {/* Left Arrow */}
            </button>

            {/* Next Page Button */}
            <button
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="pagination-button"
            >
                &#8250; {/* Right Arrow */}
            </button>

            {/* Last Page Button */}
            <button
                onClick={() => onPageChange(totalPages)}
                disabled={currentPage === totalPages}
                className="pagination-button"
            >
                &#187; {/* Double Right Arrow */}
            </button>
        </div>
    );
};

export default CustomPagination;
