export const checkRouteVisibility = (path, permissions) => {
  let pathExistsInPermissions = false;

  const checkVisibility = (items) => {
    for (const item of items) {
      if ('/'+item.route === path) {
        pathExistsInPermissions = true;
        return item.view;
      }
      if (item.submenus && checkVisibility(item.submenus)) {
        return true;
      }
    }
    return false;
  };

  for (const permission of permissions) {
    if ('/'+permission.route === path) {
      pathExistsInPermissions = true;
      if (permission.view) {
        return true;
      }
    }
    if (permission.submenus && checkVisibility(permission.submenus)) {
      return true;
    }
  }

  // If the path does not exist in the permissions array, return true
  return !pathExistsInPermissions;
};

export const hasViewPermission = (routeName, pipelinePermissions) => {
  const route = routeName;
  const cleanRoute = route.startsWith('/') ? route.slice(1) : route;
    if (!cleanRoute || !pipelinePermissions) {
      return false; // Handle invalid inputs gracefully
    }

    const flattenPermissions = (permissions) => {
      return permissions.reduce((acc, permission) => {
        acc.push(permission);
        if (permission.submenus) {
          acc.push(...flattenPermissions(permission.submenus));
        }
        return acc;
      }, []);
    };
    const allPermissions = flattenPermissions(pipelinePermissions);

  // Check if the route is listed in the permissions
  const routeListed = allPermissions.some(
    (permission) => permission.route === cleanRoute
  );

  // If the route is not listed, return true
  if (!routeListed) {
    return true;
  }

  // If the route is listed, check the view flag
    const matchingPermission = allPermissions.find(
      (permission) => permission.route === cleanRoute && permission.view === true
    );

    return !!matchingPermission; // Return boolean value directly
}