import React, { useEffect, useState, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser, updateUserColumns } from "./../utils/reducer";
import { showLoader, hideLoader } from "../redux/reducers/Actions";
import { Draggable } from "react-drag-reorder";
const SelectColumns = (props) => {
  const dispatch = useDispatch();
  const [userColumns, setUserColumns] = useState(null);
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true); 
  const [selectedColumns, setSelectedColumns] = useState([
  ]);
  const [unselectedColumns, setUnselectedColumns] = useState([
  ]);
  const [dataCheck,setDataCheck] = useState(false);
  const initialUserColumnsRef = useRef([]);
  useEffect(() => {
    // Compare the initial userColumns with the current ones
    const hasChanged = JSON.stringify(initialUserColumnsRef.current) !== JSON.stringify(userColumns);
    if (hasChanged) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userColumns]);


console.log('show page------------------------>',props.showPage);
  useEffect(() => {
    //dispatch(getMenus());
    dispatch(showLoader());
    getUser()
      .then(function (response) {
        // console.log("privacymenunewwe", response);
        if (response?.result) {
          setUserColumns(response?.result?.[0]?.pipelinecolumns)
          initialUserColumnsRef.current = response?.result?.[0]?.pipelinecolumns;
          const filteredTrue = response?.result?.[0]?.pipelinecolumns.filter(obj => obj.default);
          const filteredFalse = response?.result?.[0]?.pipelinecolumns.filter(obj => !obj.default);

          const transformedTrue = filteredTrue
            .map(obj => ({
              label: obj.name,
              id: obj.field,
              checked: false,
              order: obj.order,
              ismandatory: obj.ismandatory,
              mappingField: obj.mappingField
              
            }))
            .sort((a, b) => a.order - b.order);

          const transformedFalse = filteredFalse
            .map(obj => ({
              label: obj.name,
              id: obj.field,
              checked: false,
              order: obj.order,
              ismandatory: obj.ismandatory,
              mappingField: obj.mappingField
            }))
            .sort((a, b) => a.order - b.order);
          dispatch(hideLoader());
          setSelectedColumns(transformedTrue);
          setDataCheck(true)
          setUnselectedColumns(transformedFalse);
        }
      })
      .catch((err) => {
        console.error.bind("my account error", err);
      });
  }, []);

  


  const handleMoveToSelected = () => {
    const checkedItems = unselectedColumns.filter(item => item.checked);
    if (selectedColumns.length + checkedItems.length > 30) {
      //alert("You can't select more than 30 columns.");
      setShowAlertPopup(true)
      return;
    }
    setUnselectedColumns(prevUnselected => prevUnselected.filter(item => !item.checked));
    setSelectedColumns(prevSelected => [...prevSelected, ...checkedItems.map(item => ({ ...item, checked: false }))]);
  };
  
  const handleMoveToUnselected = () => {
    const checkedItems = selectedColumns.filter(item => item.checked);
    setSelectedColumns(prevSelected => prevSelected.filter(item => !item.checked));
    setUnselectedColumns(prevUnselected => [...prevUnselected, ...checkedItems.map(item => ({ ...item, checked: false }))]);
  };

  const handleCheckboxToggle = (index, listType) => {
    if (listType === 'selected') {
      const checkedCount = selectedColumns.filter(item => item.checked).length;
      if (!selectedColumns[index].checked && checkedCount >= 30) {
        return; // Limit reached, do not allow more selections
      }
      setSelectedColumns(prevSelected =>
        prevSelected.map((item, idx) => idx === index ? { ...item, checked: !item.checked } : item)
      );
    } else if (listType === 'unselected') {
      setUnselectedColumns(prevUnselected =>
        prevUnselected.map((item, idx) => idx === index ? { ...item, checked: !item.checked } : item)
      );
    }
  };

  const handleSelectAll = (listType) => {
    if (listType === 'selected') {
      console.log('yessss')
      const allSelected = selectedColumns.filter(item => item.id !== 'loanid').every(item => item.checked);
      console.log('allSelected',allSelected)
      const updatedSelected = selectedColumns.map(item => {
        if (item.id === 'loanid') {
          return { ...item, checked: false };
        } else {
          return { ...item, checked: !allSelected };
        }
      });
      console.log('updatedSelected',updatedSelected)
      setSelectedColumns(updatedSelected);
    } else if (listType === 'unselected') {
      const allSelected = unselectedColumns.every(item => item.checked);
      const updatedUnselected = unselectedColumns.map(item => ({ ...item, checked: !allSelected }));
      setUnselectedColumns(updatedUnselected);
    }
  };

  const selectedCount = selectedColumns.filter(item => item.checked).length;
  const unselectedCount = unselectedColumns.filter(item => item.checked).length;
  const getChangedPos = (currentPos, newPos) => {
    setSelectedColumns(prevColumns => {
      // Create a copy of the previous columns
      let reorderedColumns = Array.from(prevColumns);

      const [removed] = reorderedColumns.splice(currentPos, 1);
      reorderedColumns.splice(newPos, 0, removed);
      const loanIdIndex = reorderedColumns.findIndex(column => column.id === 'loanid');
      if (loanIdIndex !== -1) {
        const [loanIdItem] = reorderedColumns.splice(loanIdIndex, 1);
        reorderedColumns.unshift(loanIdItem);
      }

      reorderedColumns.forEach((column, index) => {
        column.order = index + 1; // assuming order starts from 1
      });
  
      return reorderedColumns;
    });
  };
  
  const getChangedPosunSelected = (currentPos, newPos) => {
    setUnselectedColumns(prevColumns => {
      const reorderedColumns = Array.from(prevColumns);
      const [removed] = reorderedColumns.splice(currentPos, 1);
      reorderedColumns.splice(newPos, 0, removed);
      reorderedColumns.forEach((column, index) => {
        column.order = index + 1; // assuming order starts from 1
    });
      return reorderedColumns;
    });
  };
  

  useEffect(() => {
    const compiledColumns = [...selectedColumns.map(col => ({...col, default: true})), ...unselectedColumns.map(col => ({...col, default: false}))];
    compiledColumns.sort((a, b) => a.order - b.order);
    const updatedPipelineColumns = compiledColumns.map((column, index) => ({
        name: column.label,
        field: column.id,
        default: column.default, 
        order: column.order,
        ismandatory: column.ismandatory,
        mappingField: column.mappingField
    }));
    setUserColumns(updatedPipelineColumns)
    console.log("selectedColumns unselectedColumns", updatedPipelineColumns);
    
  }, [selectedColumns, unselectedColumns]);

//   const DraggableRender = useCallback(() => {

//     return (
//       <Draggable onPosChange={getChangedPos}>
//           {selectedColumns.map((item, index) => (
//             <li key={index}>
//               <label className="check_box">
//                 {item.label}
//                 <input type="checkbox" checked={item.checked} onChange={() => handleCheckboxToggle(index, 'selected')} />
//                 <span className="checkmark"></span>
//               </label>
//             </li>
//           ))}
//         </Draggable>
//     );
// }, [selectedColumns]);
   const saveUpdatedColumns = () => {
    dispatch(showLoader());
    updateUserColumns(userColumns).then(function (response) {
      // console.log("privacymenunewwe", response);
      dispatch(hideLoader());
      props.setShowPage('')
      props.reload()
      if (response?.result) {
        props?.handleClearSearch();
      }
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.error.bind("my account error", err);
      });
  }

const DraggableRendermain = ({ columns, onPosChange, type}) => {
  return (
    <Draggable onPosChange={onPosChange}>
      {columns.map((item, index) => (
        <li key={index} >
          <label className="check_box">
            {item.label}
            {(!item?.ismandatory && item.loanid!=='loanid')&&
            <>
            <input type="checkbox" checked={item.checked} onChange={() => !item?.ismandatory && handleCheckboxToggle(index, type)} />
            <span className="checkmark"></span>
            </>}
          </label>
        </li>
      ))}
    </Draggable>
  );
};
  return (
    <>{showAlertPopup && 
      <div className="modal popup conditional_popup deletepop_new alert_popup fade show" id="messagepopup" tabindex="-1"
          aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
              <div className="modal-content ">

                  <div className="modal-body">
                      <button type="button" className="close" data-bs-dismiss="modal"
                          aria-label="Close"><span className="material-symbols-outlined" onClick={()=>{setShowAlertPopup(false);props.setfilteredLoans([])}}>close</span></button>
                   <span className="material-symbols-outlined alert_icon"> warning </span>
                      <h3>Alert</h3>
                     
                      <label className='alert_check'>
      You can't select more than 30 columns.
  </label>
                      <button type="button" className="fs_btn pop_btn" onClick={()=>{setShowAlertPopup(false)}} >Ok</button>
                  </div>

              </div>
          </div>
      </div>}
    <div className="columns">
      <div className="title_block">
        <h3 className="title">Selected Columns</h3>
        <button type="button" className="btn close_btn" onClick={() => props.handleClearSearch()}><span className="material-icons icon"> close </span></button>
      </div>
      <div className="columns_block">
        <div className="con">
          <div className="column_box">
            <div className="clm_hed">
              <label className="check_box">
                <input type="checkbox"  checked={selectedColumns.filter(item => item.id !== 'loanid').every(item => item.checked)} onChange={() => handleSelectAll('selected')} /> <span className="checkmark"></span>
              </label>
              <div className="right">
                <h3>Selected Columns (30Max)</h3>
                <p>{selectedCount}/ {dataCheck && selectedColumns.length} Selected</p>
              </div>
            </div>
            <div className="clm_con">
      <ul className="loan_list">
      {/* <DraggableRender/> */}
      <DraggableRendermain columns={selectedColumns} onPosChange={getChangedPos} type={'selected'}/>
      </ul>
    </div>
          </div>
          <div className="middle">
            <button type="button" className="border_btn" onClick={handleMoveToUnselected}>
              <span className="material-symbols-outlined icon">
                chevron_right
              </span>
            </button>
            <button type="button" className="border_btn" onClick={handleMoveToSelected}>
              <span className="material-symbols-outlined icon"> chevron_left </span>
            </button>
            <button disabled={isDisabled} style = {{'opacity' : (isDisabled) ? '0.4' : '1'

              // ,backgroundColor:(isDisabled)? 'gray':'#60a033'
            }} className="fs_btn" onClick={(e) => {e.preventDefault(); saveUpdatedColumns();}}>
              save
            </button>
          </div>
          <div className="column_box">
            <div className="clm_hed">
              <label className="check_box">
                <input type="checkbox" checked={unselectedColumns.every(item => item.checked)} onChange={() => handleSelectAll('unselected')} /> <span className="checkmark"></span>{" "}
              </label>
              <div className="right">
                <h3>Available Columns</h3>
                <p>{unselectedCount}/{unselectedColumns.length} Selected</p>
              </div>
            </div>
            <div className="clm_con">
              <ul className="loan_list">
              <DraggableRendermain columns={unselectedColumns} onPosChange={getChangedPosunSelected} type={'unselected'}/>             
               
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div></>
  );
};

export default SelectColumns;
