import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import { useLoanInfo } from '../utils/loanInfoContext';
import { formatCurrency } from '../utils/commonUtils.js';
const DrawRequestsStatus = () => {
    const navigate = useNavigate();
    const { loanInfo, setLoanInfo} = useLoanInfo();
    // 


console.log('loanInfo',loanInfo);
    return (
        <div id="layout-wrapper" className="dashboard">
             <PipelineHeader />
            <div className="dashboard_block">
                <PipelineStatus />
                <h2 className="form_title">Draws <span className="space">&#10095;</span> <span className="sub"> Draw Request Status</span> </h2>
            <div className="card user_management detailed_app">
                <div className="card-body my_account loan_summary">

                    <div className="form_section">
                
                        <div className="main_details payments_details drs">
                            <div className="detail_block">
                                <p>Initial Rehab Cost</p>
                                <h4>{formatCurrency(loanInfo?.rehabfunding)}</h4>
                            </div>
                            <div className="detail_block">
                                <p>Current Rehab Cost</p>
                                <h4>$ 0.00</h4>
                            </div>
                            <div className="detail_block">
                                <p>Original Holdback Balance</p>
                                <h4>{formatCurrency(loanInfo?.renovationcost)}</h4>
                            </div>
                            <div className="detail_block">
                                <p>Current Holdback Balance</p>
                                <h4>$6661.22</h4>
                            </div>
                            <div className="detail_block">
                                <p>Total Requested to Date</p>
                                <h4>4th January, 2024</h4>
                            </div>
                            

                        </div>
                        <div className="main_details payments_details drs">
                        <div className="detail_block">
                                <p>Total Approved to Date</p>
                                <h4>1st March, 2024</h4>
                            </div>
                            <div className="detail_block">
                                <p>Total Paid Out to Date</p>
                                <h4>4th March, 2024</h4>
                            </div>
                            <div className="detail_block">
                                <p>% Total Project Completion</p>
                                <h4>80%</h4>
                            </div>
                            </div>
              
                    </div>
                </div>
            </div>
            </div>
            <PipelineFooter />
        </div>
    );
};

export default DrawRequestsStatus;