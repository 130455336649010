import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import { getServicingSetupApi,addServicingSetupApi ,LoanTermDropdown,servicingUpdate,getLoanDetails} from '../utils/reducer';
import PipelineHeader from './PipelineHeader';
import { useDispatch, useSelector} from 'react-redux';
import Loader from './Loader';
import CurrencyInput from 'react-currency-input-field';

import PopupMessage from './PopupMessage';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import { useLoanInfo } from '../utils/loanInfoContext';
import { hideLoader, showLoader } from '../redux/reducers/Actions';
import Blockingtextmsg from './Blockingtext';

const ServicingServicingSetup = () => {
    const blockingtext = 'Servicing Setup cannot be accessed until a wire fund date has been entered'
    const dispatch = useDispatch();
    const configdata = useSelector(state => state.configdata);
    let showServicingForms = configdata?.common?.showServicingForms || true;
    const navigate = useNavigate();
    // 
    let servicingSetupObject = {
        "loansetup": {
            "actualwire_funddate": "",
            "scheduledfirstpaymentdate": "",
            "currentmaturitydate": "",
            "proposedmaturitydate": "",
            "interestrate": "",
            "loanamount": "",
            "rehabfunding": "",
            "servicingenddate": "",
            // "residualinterestoptions": "",
            // "servicingloantype": "",
            // "chargeinterestonall": false,
            "loanterm": "",
        },
        "perdiemcalculation": {
            "loanperdiemcalculation": "",
            "fundingmonthperdiemcalculation": "",
            "maturitymonthperdiemcalculation": "",
            "calculatedrawmonthsat365": false,
            "roundperdiem": false,
            "roundfundingperdiem": false,
            "roundmaturityperdiem": false
        },
        "paymentsetup": {
            "deferpayments": false,
            "paritalpayment": false,
            "useautopayforreserves": false,
            // "maturitymonthperdiemcalculation": ""
        },
        "advanced": {
            "payinadvance": false,
            "endofmonthduedates": false,
            "includematuritydate": false,
            "netfunddrawbalance": false,
            "legacyloan": false,
            "paidoff": false,
            "funddistributionpercentageofupb": false,
            "advancedcalculations": false,
            "indefault": false,
            "defaultdate":"",
            "indefaultduetotaxinsurance": false,
            "funddisbursementmethod": "",
            "showcollectatclosinginpaytable": false,
            "fciloanaccountnunmber": "",
            "fciretrievetoken": "",
            "fcipushtoken": ""
        },
        // "status": "",
        // "created": "",
        // "updated": ""
    };
    const [servicingSetupData,setServicingSetupData]=useState(servicingSetupObject);
    const [errors,setErrors] = useState({});
    const [isPopupOpen, setPopupOpen] = useState(false);
    const loading = useSelector((state) => state.loaderVisible);
    const { loanInfo, setLoanInfo} = useLoanInfo();
    const [loanid,setLoanid] = useState(loanInfo?.loanid);
    console.log("loanInfo",loanInfo);
    const [loanTerm,setLoanTerm] = useState([]);
    const [message,setMessage] = useState('');
    
 
    const jsonInputs = [
        {
          type: "date",
          label: "Loan Start Date",
          name: "actualwire_funddate",
          placeholder: "mm-dd-yyyy",
          errorKey: "actualwire_funddate",
          disabled: loanInfo?.isLoanLocked || false,
        },
        {
          type: "date",
          label: "First Payment Due Date",
          name: "scheduledfirstpaymentdate",
          placeholder: "mm-dd-yyyy",
          errorKey: "scheduledfirstpaymentdate",
          disabled: loanInfo?.isLoanLocked || false,
        },
        {
            type: "date",
            label: "Current Maturity Date",
            name: "currentmaturitydate",
            placeholder: "mm-dd-yyyy",
            disabled: loanInfo?.isLoanLocked || false,
            errorKey:"currentmaturitydate"
          },
        {
          type: "date",
          label: "Original Maturity Date",
          name: "proposedmaturitydate",
          placeholder: "mm-dd-yyyy",
          errorKey: "proposedmaturitydate",
        //   disabled: loanInfo?.isLoanLocked || false,
          disabled : true,
        },
        {
          type: "number",
          label: "Interest Rate (%)",
          name: "interestrate",
          placeholder: "Enter Interest Rate",
          errorKey: "interestrate",
          disabled: loanInfo?.isLoanLocked || false,
        },
        {
          type: "currency",
          label: "Total Loan Amount (USD)",
          name: "loanamount",
          placeholder: "Enter Total Loan Amount",
          errorKey: "loanamount",
          disabled: loanInfo?.isLoanLocked || false,
        },
        {
          type: "currency",
          label: "Initial Draw Balance (USD)",
          name: "rehabfunding",
          disabled: loanInfo?.isLoanLocked || false,
          placeholder: "Enter Initial Draw Balance",
          errorKey: "rehabfunding",
        },
        {
          type: "date",
          label: "Servicing End Date",
          name: "servicingenddate",
          placeholder: "Auto Fills When a Final Payment is Made",
          errorKey: "servicingenddate",
          disabled: loanInfo?.isLoanLocked || false,
        },
        // {
        //   type: "select",
        //   label: "Residual Interest Options",
        //   name: "residualinterestoptions",
        //   options: [{label : "Collect At Closing" , value : 'Collect At Closing'},{label : "Add To First Payment" , value : 'Add To First Payment'}],
        //   errorKey: "residualinterestoptions",
        //   disabled: loanInfo?.isLoanLocked || false,
        // },
        // {
        //   type: "select",
        //   label: "Loan Type",
        //   name: "servicingloantype",
        //   options: [{label : "Select" , value : ''},{label : "Interest Only" , value : 'Interest Only'},{label : "Mixed" , value : 'Mixed'}],
        //   errorKey: "servicingloantype",
        //   disabled: loanInfo?.isLoanLocked || false,
        // },
        {
          type: "select",
          label: "Loan Term",
          name: "loanterm",
          options: loanTerm,
          errorKey: "loanterm",
          disabled: loanInfo?.isLoanLocked || false,
        },
        {
            "label": "Draw Interest Type",
            "name": "drawinteresttype",
            "options": [
                {"label": "Select", "value": ""},
                {"label": "Interest as Disbursed", "value": "Interest as Disbursed"},
                {"label": "Interest on Full Balance", "value": "Interest on Full Balance"}
            ],
            "type": "select",
            errorKey: "drawinteresttype",
            disabled: loanInfo?.isLoanLocked || false,
        },
        
        // {
        //   type: "checkbox",
        //   label: "Charge Interest on All",
        //   name: "chargeinterestonall",
        //   disabled: loanInfo?.isLoanLocked || false,
        // },
        // {
        //   type: "select",
        //   label: "Amortization Term (Months)",
        //   name: "amortizationterm",
        //   options: loanTerm,
        //   errorKey: "amortizationterm",
        // }
      ];
      
      useEffect(() => {
        if(loanid){

        }
      }, [loanid]);
      useEffect(() => {
        let setupvariable = {
                actualwire_funddate : loanInfo?.wireinstructions?.actualwire_funddate || '',
                scheduledfirstpaymentdate : loanInfo?.cleartoclose?.scheduledfirstpaymentdate || '',
                currentmaturitydate : loanInfo?.cleartoclose?.currentmaturitydate || '',
                proposedmaturitydate :  loanInfo?.cleartoclose?.proposedmaturitydate || '',
                interestrate : loanInfo?.interestrate || '',
                loanamount : loanInfo?.loanamount || '',
                rehabfunding : loanInfo?.rehabfunding || 0,
                servicingenddate : loanInfo?.servicingsetup?.loansetup?.servicingenddate ||  '',
                // residualinterestoptions :  loanInfo?.servicingsetup?.loansetup?.residualinterestoptions || 'Collect At Closing',
                // servicingloantype :loanInfo?.servicingsetup?.loansetup?.servicingloantype || 'Interest Only',
                loanterm : loanInfo?.loanterm || '',
                // chargeinterestonall : loanInfo?.servicingsetup?.loansetup?.servicingloantype || loanInfo?.drawinteresttype === 'Interest as Disbursed' ? false : true,
                drawinteresttype : loanInfo?.drawinteresttype,
            };
            let  paymentsetup = {
                "deferpayments":  loanInfo?.serivcepaymentsetup?.deferpayments || false,
                "paritalpayment": loanInfo?.serivcepaymentsetup?.paritalpayment || false,
                "useautopayforreserves": loanInfo?.serivcepaymentsetup?.useautopayforreserves || false,
            }
            
            const calculateMaturityDate = () => {
                let closingDate = loanInfo?.cleartoclose?.actualclosingdate;
                console.log('loanInfo', loanInfo?.loanterm);
                if (loanInfo?.loanterm && closingDate) {
                    const closingDateClone = new Date(closingDate);
                    closingDateClone.setMonth(closingDateClone.getMonth() + (loanInfo.loanterm + ((loanInfo?.extensionDataCount || 0) * 3)));
                    closingDateClone.setDate(1);
                    closingDateClone.setMonth(closingDateClone.getMonth() + 1);
                    setupvariable.proposedmaturitydate = closingDateClone.toISOString().split('T')[0];
                    console.log('closingDateClone', closingDateClone.toDateString());
                } else {
                    setupvariable.proposedmaturitydate = '';
                }
            };
    
            // calculateMaturityDate();
           
            setServicingSetupData((prevData) => ({
                ...prevData,
                'loansetup': {
                    ...setupvariable,
                },
                'paymentsetup' : {
                    ...paymentsetup,
                }
            }));
      }, [loanInfo]);

      const calculateMaturityDate = () => {
        let closingDate = loanInfo?.cleartoclose?.actualclosingdate;
        let updateddate = ''
        console.log('loanInfo', servicingSetupData?.loansetup?.loanterm);
        if (servicingSetupData?.loansetup?.loanterm && closingDate) {
            const closingDateClone = new Date(closingDate);
            closingDateClone.setMonth(closingDateClone.getMonth() + (Number(servicingSetupData?.loansetup?.loanterm) + ((loanInfo?.extensionDataCount || 0) * 3)));
            closingDateClone.setDate(1);
            closingDateClone.setMonth(closingDateClone.getMonth() + 1);
            updateddate = closingDateClone.toISOString().split('T')[0];
            console.log('closingDateClone', closingDateClone.toDateString());
        } else {
            updateddate = '';
        };
        setServicingSetupData((prevData) => ({
            ...prevData,
            'loansetup': {
                ...prevData?.loansetup,
                proposedmaturitydate : updateddate
            },
        }));
    };
    useEffect(() => {
        // calculateMaturityDate()
      }, [servicingSetupData?.loansetup?.loanterm]);

      useEffect(() => {
        
        setLoanid(loanInfo?.loanid);
      }, [loanInfo?.loanid]);
      useEffect(() => {
        loandropdown();
      }, []);
    const getServicingSetup = ()=>{
        dispatch(showLoader());
        getServicingSetupApi(loanid)
        .then(function(response) {
            if (response) {
                console.log('ServicingSetup response', response);
                setServicingSetupData((prev) => ({
                    ...prev,
                    ...response.result[0], 
                }));
                dispatch(hideLoader());
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    };
    const loandropdown = async () => {
        LoanTermDropdown().then(function (response) {
            if (response?.result) {
                console.log("categories from lookups --->",response?.result?.data[0]?.lookupOptions);
                let data = response?.result?.data[0]?.lookupOptions;
                let formattedData = data.map(item => ({
                    value: parseInt(item.option, 10), 
                    label: item.option
                }));
                formattedData.unshift({ value: '', label: 'Select' });
                setLoanTerm(formattedData);
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
    };
   
    const checkValidation = (name)=>{
        let dataObj = servicingSetupData?.loansetup;
        const fieldsCheck = {
            actualwire_funddate: "actualwire_funddate",
            scheduledfirstpaymentdate: "scheduledfirstpaymentdate",
            currentmaturitydate: "currentmaturitydate",
            //proposedmaturitydate: "proposedmaturitydate",
            interestrate: "interestrate",
            loanamount: "loanamount",
            rehabfunding: "rehabfunding",
            //servicingenddate: "servicingenddate",
            // residualinterestoptions: "residualinterestoptions",
            // servicingloantype: "servicingloantype",
            loanterm: "loanterm",
            drawinteresttype : "drawinteresttype"
            // amortizationterm: "amortizationterm"
          };
          const errors = {};
          for (const [fieldName, fieldLabel] of Object.entries(fieldsCheck)) {
            let value = dataObj[fieldName];
             if (typeof value === 'number') {
              }else{
                if (!dataObj[fieldName]?.trim()) {
                    errors[fieldName] = `Please Enter ${fieldLabel} `;
                }
              }
          };
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            let tabname = name;
            let data = dataObj;
            if(tabname==='save'){
                saveServicingSetup();
            }else if(tabname === 'saveandlock'){
                saveAndLock();
            }
           
            
            // handleAddServicingSetup(tabname,data);
        }
    };

    const saveServicingSetup =()=>{
        let apiData = {
            loanid : loanid,
            isLoanLocked : true,
            interestrate : servicingSetupData?.loansetup?.interestrate,
            loanamount : servicingSetupData?.loansetup?.loanamount,
            loanterm : servicingSetupData?.loansetup?.loanterm,
            actualwire_funddate : servicingSetupData?.loansetup?.actualwire_funddate,
            scheduledfirstpaymentdate : servicingSetupData?.loansetup?.scheduledfirstpaymentdate,
            proposedmaturitydate : servicingSetupData?.loansetup?.proposedmaturitydate,
            currentmaturitydate : servicingSetupData?.loansetup?.currentmaturitydate,
            drawinteresttype : servicingSetupData?.loansetup?.drawinteresttype,
            rehabfunding :servicingSetupData?.loansetup?.rehabfunding,
            servicingsetup : {
                isServiceSetupCompleted : true,
                loansetup :{
                    // servicingloantype : servicingSetupData?.loansetup?.servicingloantype,
                    // residualinterestoptions : servicingSetupData?.loansetup?.residualinterestoptions,
                    // chargeinterestonall : servicingSetupData?.loansetup?.chargeinterestonall,
                    servicingenddate : servicingSetupData?.loansetup?.servicingenddate,
                }
            }
        };
        const numericInputs = [  'loanamount', 'rehabfunding','loanterm'];
        numericInputs.forEach(key => {
           if (apiData.hasOwnProperty(key)) {
               apiData[key] = apiData[key] === '' ? '' : parseFloat(apiData[key]);
               }
           });
        setMessage('Saved Successfully');
        ApiCallForUpdate(apiData)
        
    };

    const saveAndLock =()=>{
        let apiData = {
            loanid : loanid,
            isLoanLocked : true,
            interestrate : servicingSetupData?.loansetup?.interestrate,
            loanamount : servicingSetupData?.loansetup?.loanamount,
            loanterm : servicingSetupData?.loansetup?.loanterm,
            actualwire_funddate : servicingSetupData?.loansetup?.actualwire_funddate,
            scheduledfirstpaymentdate : servicingSetupData?.loansetup?.scheduledfirstpaymentdate,
            currentmaturitydate : servicingSetupData?.loansetup?.currentmaturitydate,
            drawinteresttype : servicingSetupData?.loansetup?.drawinteresttype,
            proposedmaturitydate : servicingSetupData?.loansetup?.proposedmaturitydate,
            rehabfunding :servicingSetupData?.loansetup?.rehabfunding,
            servicingsetup : {
                isServiceSetupCompleted : true,
                loansetup :{
                    // servicingloantype : servicingSetupData?.loansetup?.servicingloantype,
                    // residualinterestoptions : servicingSetupData?.loansetup?.residualinterestoptions,
                    // chargeinterestonall : servicingSetupData?.loansetup?.chargeinterestonall,
                    servicingenddate : servicingSetupData?.loansetup?.servicingenddate,
                }
            }
        };
        const numericInputs = [  'loanamount', 'rehabfunding','loanterm'];
         numericInputs.forEach(key => {
            if (apiData.hasOwnProperty(key)) {
                apiData[key] = apiData[key] === '' ? '' : parseFloat(apiData[key]);
                }
            });
        setMessage('Saved and Locked');
        ApiCallForUpdate(apiData)

    };
    const unlockCall =()=>{
        let apiData ={
            loanid : loanid,
            isLoanLocked : false,
        }
        setMessage('Unlocked Successfully');
        ApiCallForUpdate(apiData)
    };
    const handlePaymentSetup =()=>{
        let apiData ={
            loanid : loanid,
            serivcepaymentsetup : {
                    deferpayments : servicingSetupData?.paymentsetup?.deferpayments,
                    paritalpayment : servicingSetupData?.paymentsetup?.paritalpayment,
                    useautopayforreserves : servicingSetupData?.paymentsetup?.useautopayforreserves
            }
        };
        setMessage('Payment Setup Saved Successfully');
        ApiCallForUpdate(apiData);
    };

    const ApiCallForUpdate = (apiData)=>{
        dispatch(showLoader());
        let pagename = 'servicingsetup';
        servicingUpdate(apiData,loanid,pagename).then(function (response) {
            if (response) {
               console.log('response?.result',response.result);
               setLoandetails()
               dispatch(hideLoader());
               setPopupOpen(true);
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
              dispatch(hideLoader());
            })
    }
    const setLoandetails =()=>{
        getLoanDetails(loanid)
        .then(response => {
          console.log('getLoanDetails', response);
          if (response?.result[0]) {
            setLoanInfo(response?.result[0]);
            console.log(response);
          }
        })
        .catch(err => {
          console.error('my account error', err);
        });
      }

    const handleAddServicingSetup =(name,data)=>{
        let apiData = { [name]: data, loanid: loanid };

        if (servicingSetupData?.setupid !== undefined) {
            apiData.setupid = servicingSetupData.setupid;
        }

        dispatch(showLoader());
        addServicingSetupApi(apiData).then(function (response) {
            if (response) {
               console.log('response?.result',response.result);
               getServicingSetup();
               dispatch(hideLoader());
               setPopupOpen(true);
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
              dispatch(hideLoader());
            })
    };
    const closePopup = () => {
        setPopupOpen(false);
    };
    console.log('serviciing setup',servicingSetupData)
    const handleChange = (section, e) => {
        let { name, value } = e.target;
        let updatedValue = value;
        if(name === "servicingloantype" && value !== "Mixed"){
            setServicingSetupData((prevData) => ({
                ...prevData,
                [section]: {
                    ...prevData[section],
                    [name]: updatedValue,
                },
            }));
        }else if(name ===  'interestrate'){
            const parsedValue = parseFloat(value.replace(/[^.0-9]/g, ''));
            updatedValue = isNaN(parsedValue) ? '' : parsedValue;
            if(updatedValue<=100){
                setServicingSetupData((prevData) => ({
                    ...prevData,
                    [section]: {
                        ...prevData[section],
                        [name]: updatedValue,
                    },
                }));
            }
        }else if(name ===  'chargeinterestonall'){
            let checked = e.target.checked
                setServicingSetupData((prevData) => ({
                    ...prevData,
                    [section]: {
                        ...prevData[section],
                        [name]: checked,
                    },
                }));
        }
        else{
            setServicingSetupData((prevData) => ({
                ...prevData,
                [section]: {
                    ...prevData[section],
                    [name]: updatedValue,
                },
            }));
        }
    
        
    };
    console.log('servicingSetupData',servicingSetupData);
    return (
        <div id="layout-wrapper" className="dashboard">
                 {loading ? <Loader/> : null} 
            {isPopupOpen && (
            <PopupMessage
             type={'success'}
             message={message}
             onClose={closePopup}
            />
        )}
                <PipelineHeader />
            <div className="dashboard_block">
            <PipelineStatus />
                <h2 className="form_title">Servicing <span className="space">&#10095;</span>  <span className="sub">Servicing Setup</span> </h2>
                { !loanInfo?.wireinstructions?.actualwire_funddate  && !configdata?.common?.showServicingForms ? 
                    <Blockingtextmsg textmessage={blockingtext}/> :
<div className="card user_management detailed_app">
                    <div className="card-body my_account">
                        <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#ENTITY" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">Loan Setup</span>
                                </a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link " data-bs-toggle="tab" href="#APPLICANT" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">per diem calculations</span>
                                </a>
                            </li> */}
                            {/* <li className="nav-item">
                                <a className="nav-link " data-bs-toggle="tab" href="#HMDA" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">Payment Setup</span>
                                </a>
                            </li> */}
                            {/* <li className="nav-item">
                                <a className="nav-link " data-bs-toggle="tab" href="#PROPERTY" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">Advanced</span>
                                </a>
                            </li> */}
                        </ul>


                        <div className="tab-content pt-15 text-muted">
                            <div className="tab-pane active" id="ENTITY" role="tabpanel">



                                <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-0">

                                </div>
                                <div className="form_section">
                                    <div className="row">
                                        {jsonInputs.map((field, index) => (
                                           <div key={index} className={`col-md-3 ${field.type === 'checkbox' ? 'interest_only' : ''}`}>
                                                <div className="form-group sed">
                                                    <label>{field.type !== 'checkbox' && field.label}</label>{field.name == 'servicingenddate' && <span className='field_info'> * Auto fills when a final payment is made.</span>}
                                                        
                                                    {field.type === 'text' && (
                                                        <input
                                                            id={field.name}
                                                            type="text"
                                                            className={`form-control ${errors?.[field.errorKey] && 'invalid-input'}`}
                                                            onFocus={() => setErrors({ ...errors, [field.errorKey]: null })}
                                                            name={field.name}
                                                            placeholder={field.placeholder}
                                                            value={servicingSetupData.loansetup[field.name] || ''}
                                                            onChange={(e) => handleChange('loansetup', e)}
                                                            disabled={field.disabled || false}
                                                        />
                                                    )}
                                                    {field.type === 'date' && (
                                                        <input
                                                        id={field.name}
                                                            type="date"
                                                            className={`form-control ${errors?.[field.errorKey] && 'invalid-input'}`}
                                                            onFocus={() => setErrors({ ...errors, [field.errorKey]: null })}
                                                            name={field.name}
                                                            placeholder={field.name == 'servicingenddate'  ? '* Auto fills when a final payment is made.' :field.placeholder}
                                                            readOnly={field.name == 'servicingenddate'}
                                                            style={{
                                                                ...(field.disabled ? { cursor: 'default' } : {})
                                                              }}
                                                            // value={servicingSetupData.loansetup[field.name] ? ( new Date(servicingSetupData.loansetup[field.name]).toISOString().split('T')[0]) : ''} 
                                                            value={
                                                                servicingSetupData.loansetup[field.name]
                                                                  ? new Date(servicingSetupData.loansetup[field.name]).toISOString().slice(0, 10) 
                                                                  : ''
                                                           }
                                                            onChange={(e) => handleChange('loansetup', e)}
                                                            disabled={field.disabled || false}
                                                            
                                                        />
                                                    )}
                                                    {field.type === 'number' && (
                                                        <input
                                                            id={field.name}
                                                            type="number"
                                                            className={`form-control ${errors?.[field.errorKey] && 'invalid-input'}`}
                                                            onFocus={() => setErrors({ ...errors, [field.errorKey]: null })}
                                                            name={field.name}
                                                            placeholder={field.placeholder}
                                                            value={servicingSetupData.loansetup[field.name] || ''}
                                                            onChange={(e) => handleChange('loansetup', e)}
                                                            disabled={field.disabled || false}
                                                        />
                                                    )}
                                                    {field.type === 'currency' && (
                                                         <CurrencyInput
                                                         id={field.name}
                                                         name={field.name}
                                                         className={`form-control ${errors?.[field.errorKey] && 'invalid-input'}`}
                                                         placeholder={field.placeholder}
                                                         value={servicingSetupData.loansetup[field.name] || 0}
                                                         decimalsLimit={2}
                                                         prefix="$"
                                                         onFocus={() => setErrors({ ...errors, [field.errorKey]: null })}
                                                         disabled={field.disabled || false}
                                                         onValueChange={(value, name, values) => {
                                                           setServicingSetupData((prevData) => ({
                                                             ...prevData,
                                                             loansetup: {
                                                               ...prevData.loansetup,
                                                               [field.name]: values.value,
                                                             },
                                                           }));
                                                           
                                                         }}
                                                       />
                                                    )}
                                                    {field.type === 'select' && (
                                                        <select
                                                            id={field.name}
                                                            className={`form-control ${errors?.[field.errorKey] && 'invalid-input'}`}
                                                            disabled={field.disabled || false}
                                                            onFocus={() => setErrors({ ...errors, [field.errorKey]: null })}
                                                            name={field.name}
                                                            // value ={
                                                            //    ( field.name === 'residualinterestoptions' && 
                                                            //     servicingSetupData.loansetup[field.name] !== 'Add To First Payment') ? 'Collect At Closing' : servicingSetupData.loansetup[field.name]
                                                            // }
                                                            value={servicingSetupData.loansetup[field.name] || ''}
                                                            onChange={(e) => handleChange('loansetup', e)}
                                                        >
                                                            {field.options.map((option, index) => (
                                                                <option key={index} value={option?.value}>{option?.label}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                    {field.type === 'checkbox' && (
                                                        <label className="toggle mb-0" style={{
                                                                    ...(field.disabled ? { cursor: 'default' } : {})
                                                                  }}>
                                                            <span className="toggle-label">{field.label}</span>
                                                            <input
                                                                className="toggle-checkbox"
                                                                disabled={field.disabled || false}
                                                                style={{
                                                                    ...(field.disabled ? { cursor: 'default' } : {})
                                                                  }}
                                                                type="checkbox"
                                                                name={field.name}
                                                                value={servicingSetupData.loansetup[field.name] || ''}
                                                                onChange={(e) => handleChange('loansetup', e)}
                                                            />
                                                            <div className="toggle-switch"></div>
                                                        </label>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                {(!loanInfo?.servicingsetup?.isServiceSetupCompleted && !loanInfo?.isLoanLocked) &&
                                                <button  id='savebtn' className="fs_btn" onClick={(e) => { checkValidation('save') }}>save</button>
                                                }
                                                {(loanInfo?.servicingsetup?.isServiceSetupCompleted && !loanInfo?.isLoanLocked) &&
                                                <button  id='saveandlockbtn' className="fs_btn" onClick={(e) => { checkValidation('saveandlock') ; }}>save and lock</button>
                                                }
                                                {(loanInfo?.servicingsetup?.isServiceSetupCompleted && loanInfo?.isLoanLocked) &&
                                                <button  id='unlockbtn' className="fs_btn" onClick={(e) => { unlockCall(); }}>Unlock</button>
                                                }
                                                
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane" id="APPLICANT" role="tabpanel">

                                <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-0">

                                </div>
                                <div className="form_section">
                                    {/* <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Loan Per Diem Calculation</label>
                                                <select  name="loanperdiemcalculation"  className={`form-control ${errors?.loanperdiemcalculation && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, loanperdiemcalculation: null }) }} onChange={(e) => handleChange('perdiemcalculation', e)}  placeholder="Enter" value={servicingSetupData.perdiemcalculation.loanperdiemcalculation}>
                                                    <option selected="">Select</option>
                                                    <option>NA</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Funding Month Per Diem Calculation</label>
                                                <select className="form-control">
                                                    <option selected="">Select</option>
                                                    <option>NA</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Funding Month Per Diem Calculation</label>
                                                <select className="form-control">
                                                    <option selected="">Select</option>
                                                    <option>NA</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3 interest_only">
                                            <div className="form-group">
                                                <label></label>
                                                <label className="toggle mb-0">
                                                    <span className="toggle-label">Calculate Draw Months at 365</span>
                                                    <input className="toggle-checkbox" type="checkbox" />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="perr_diem">
                                                <p>Options Below are for Rounding per diem before multiplying the number of days</p>
                                            </div>
                                        </div>
                                  
                                        <div className="col-md-3 interest_only diem_cal">
                                            <div className="form-group">
                                                <label></label>
                                                <label className="toggle mb-0">
                                                    <span className="toggle-label">Round per Diem</span>
                                                    <input className="toggle-checkbox" type="checkbox" />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-3 interest_only diem_cal">
                                            <div className="form-group">
                                                <label></label>
                                                <label className="toggle mb-0">
                                                    <span className="toggle-label">Round Funding per Diem</span>
                                                    <input className="toggle-checkbox" type="checkbox" />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-3 interest_only diem_cal">
                                            <div className="form-group">
                                                <label></label>
                                                <label className="toggle mb-0">
                                                    <span className="toggle-label">Round Maturity per Diem</span>
                                                    <input className="toggle-checkbox" type="checkbox" />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>


                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <a href="#" className="fs_btn">save</a>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Loan Per Diem Calculation</label>
                                                <select name="loanperdiemcalculation" className={`form-control ${errors?.loanperdiemcalculation && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, loanperdiemcalculation: null }) }} onChange={(e) => handleChange('perdiemcalculation', e)} placeholder="Enter" value={servicingSetupData?.perdiemcalculation.loanperdiemcalculation}>
                                                    <option value="">Select</option>
                                                    <option value="360">360</option>
                                                    <option value="360 rate applied at 365 (Actual)">360 rate applied at 365 (Actual)</option>
                                                    <option value="365 (Actual)">365 (Actual)</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Funding Month Per Diem Calculation</label>
                                                <select name="fundingmonthperdiemcalculation" className={`form-control ${errors?.fundingmonthperdiemcalculation && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, fundingmonthperdiemcalculation: null }) }} onChange={(e) => handleChange('perdiemcalculation', e)} placeholder="Enter" value={servicingSetupData.perdiemcalculation.fundingmonthperdiemcalculation}>
                                                <option value="">Select</option>
                                                    <option value="360">360</option>
                                                    <option value="360 rate applied at 365 (Actual)">360 rate applied at 365 (Actual)</option>
                                                    <option value="365 (Actual)">365 (Actual)</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Maturity Month Per Diem Calculation</label>
                                                <select name="maturitymonthperdiemcalculation" className={`form-control ${errors?.maturitymonthperdiemcalculation && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, maturitymonthperdiemcalculation: null }) }} onChange={(e) => handleChange('perdiemcalculation', e)} placeholder="Enter" value={servicingSetupData.perdiemcalculation.maturitymonthperdiemcalculation}>
                                                <option value="">Select</option>
                                                    <option value="360">360</option>
                                                    <option value="360 rate applied at 365 (Actual)">360 rate applied at 365 (Actual)</option>
                                                    <option value="365 (Actual)">365 (Actual)</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3 interest_only">
                                            <div className="form-group">
                                                <label></label>
                                                <label className="toggle mb-0">
                                                    <span className="toggle-label">Calculate Draw Months at 365</span>
                                                    <input className="toggle-checkbox" type="checkbox" name="calculatedrawmonthsat365" checked={servicingSetupData.perdiemcalculation.calculatedrawmonthsat365} onChange={(e) => setServicingSetupData({ ...servicingSetupData, perdiemcalculation: { ...servicingSetupData.perdiemcalculation, calculatedrawmonthsat365: e.target.checked } })} />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="perr_diem">
                                                <p>Options Below are for Rounding per diem before multiplying the number of days</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3 interest_only diem_cal">
                                            <div className="form-group">
                                                <label></label>
                                                <label className="toggle mb-0">
                                                    <span className="toggle-label">Round per Diem</span>
                                                    <input className="toggle-checkbox" type="checkbox" name="roundperdiem" checked={servicingSetupData.perdiemcalculation.roundperdiem} onChange={(e) => setServicingSetupData({ ...servicingSetupData, perdiemcalculation: { ...servicingSetupData.perdiemcalculation, roundperdiem: e.target.checked } })} />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-3 interest_only diem_cal">
                                            <div className="form-group">
                                                <label></label>
                                                <label className="toggle mb-0">
                                                    <span className="toggle-label">Round Funding per Diem</span>
                                                    <input className="toggle-checkbox" type="checkbox" name="roundfundingperdiem" checked={servicingSetupData.perdiemcalculation.roundfundingperdiem} onChange={(e) => setServicingSetupData({ ...servicingSetupData, perdiemcalculation: { ...servicingSetupData.perdiemcalculation, roundfundingperdiem: e.target.checked } })} />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-3 interest_only diem_cal">
                                            <div className="form-group">
                                                <label></label>
                                                <label className="toggle mb-0">
                                                    <span className="toggle-label">Round Maturity per Diem</span>
                                                    <input className="toggle-checkbox" type="checkbox" name="roundmaturityperdiem" checked={servicingSetupData.perdiemcalculation.roundmaturityperdiem} onChange={(e) => setServicingSetupData({ ...servicingSetupData, perdiemcalculation: { ...servicingSetupData.perdiemcalculation, roundmaturityperdiem: e.target.checked } })} />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <a href="#" className="fs_btn"  onClick={(e) => {e.preventDefault();checkValidation('perdiemcalculation',servicingSetupData.perdiemcalculation) }}>save</a>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>

                            </div>
                            <div className="tab-pane hmda" id="HMDA" role="tabpanel">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-0">

                                </div>
                                <div className="form_section">
                                    {/* <div className="payment_setup">

                                        <div className="defer_payments interest_only diem_cal">
                                            <div className="form-group">

                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">Defer Payments</span>
                                                    <input className="toggle-checkbox" type="checkbox" />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="empty_column"></div>
                                        <div className="partial_payments interest_only diem_cal">
                                            <div className="form-group">

                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">Partial Payments</span>
                                                    <input className="toggle-checkbox" type="checkbox" />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="empty_column"></div>
                                        <div className="auto_pay interest_only diem_cal">
                                            <div className="form-group">

                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">Use AutoPay From Reserves</span>
                                                    <input className="toggle-checkbox" type="checkbox" />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="empty_column"></div>
                                        <div className="maturity_month interest_only diem_cal">
                                            <div className="form-group">

                                                <label className="toggle mb-0 mt-0 d-flex align-items-center">
                                                    <span className="toggle-label">Maturity Month Per Diem Calculation</span>
                                                    <select className="form-control">
                                                        <option selected="">Select</option>
                                                        <option>NA</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div>



                                    </div> */}
                                    <div className="payment_setup">
                                        <div className="defer_payments interest_only diem_cal">
                                            <div className="form-group">
                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">Defer Payments</span>
                                                    <input className="toggle-checkbox" name='deferpayments' type="checkbox" checked={servicingSetupData.paymentsetup.deferpayments} onChange={(e) => setServicingSetupData({ ...servicingSetupData, paymentsetup: { ...servicingSetupData.paymentsetup, deferpayments: e.target.checked } })} />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="empty_column"></div>
                                        <div className="partial_payments interest_only diem_cal">
                                            <div className="form-group">
                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">Partial Payments</span>
                                                    <input className="toggle-checkbox" name='paritalpayment' type="checkbox" checked={servicingSetupData.paymentsetup.paritalpayment} onChange={(e) => setServicingSetupData({ ...servicingSetupData, paymentsetup: { ...servicingSetupData.paymentsetup, paritalpayment: e.target.checked } })} />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="empty_column"></div>
                                        <div className="auto_pay interest_only diem_cal">
                                            <div className="form-group">
                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">Use AutoPay From Reserves</span>
                                                    <input className="toggle-checkbox" type="checkbox" name='useautopayforreserves' checked={servicingSetupData.paymentsetup.useautopayforreserves} onChange={(e) => setServicingSetupData({ ...servicingSetupData, paymentsetup: { ...servicingSetupData.paymentsetup, useautopayforreserves: e.target.checked } })} />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        
                                        <div className="empty_column"></div>
                                       
                                        <div className="maturity_month interest_only diem_cal">
                                            <div className="form-group">
                                                <label className="toggle mb-0 mt-0 d-flex align-items-center">
                                                    <span className="toggle-label">Trust Account </span>
                                                <input type="text" className="form-control" disabled placeholder="Enter" value=' Easy Street Capital LLC'/>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <a href="#" className="fs_btn"  onClick={(e) => {e.preventDefault();handlePaymentSetup()}}>save</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane advaned" id="PROPERTY" role="tabpanel">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-0">

                                </div>
                                <div className="form_section">
                                    <div className="row">
                                        <div className="col-md-12 d-flex align-items-center mb_20">
                                            <h4 className="mb-sm-0 font-size-16 mr_10">Amortization Type : Fixed Rate</h4>
                                            <div className="perr_diem mt-0">
                                                <p>ARM Settings Only Available on Adjustable Rate Mortgages.</p>
                                            </div>
                                        </div>
                               
                                        <div className="col-md-1 interest_only diem_cal column-1">
                                            <div className="form-group">

                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">Pay in Advance</span>
                                                    <input className="toggle-checkbox" type="checkbox" />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-1 column-1"></div>
                                        <div className="col-md-3 interest_only diem_cal column-3">
                                            <div className="form-group">

                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">End of Month Due Dates</span>
                                                    <input className="toggle-checkbox" type="checkbox" />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-1 column-1"></div>
                                        <div className="col-md-3 interest_only diem_cal column-3">
                                            <div className="form-group">

                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">Include Maturity Date</span>
                                                    <input className="toggle-checkbox" type="checkbox" />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-3 interest_only diem_cal column-3 ps-5">
                                            <div className="form-group">

                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">Net Fund Draw Balance</span>
                                                    <input className="toggle-checkbox" type="checkbox" />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-1 interest_only diem_cal column-1">
                                            <div className="form-group">

                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">Legacy Loan</span>
                                                    <input className="toggle-checkbox" type="checkbox" />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-1 column-1"></div>
                                        <div className="col-md-3 interest_only diem_cal column-3">
                                            <div className="form-group">

                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">Paid Off</span>
                                                    <input className="toggle-checkbox" type="checkbox" />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-1 column-1"></div>
                                        <div className="col-md-3 interest_only diem_cal column-3">
                                            <div className="form-group">

                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">Fund Distribution % of UPB</span>
                                                    <input className="toggle-checkbox" type="checkbox" />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-3 interest_only diem_cal column-3 ps-5">
                                            <div className="form-group">

                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">Advanced Calculations</span>
                                                    <input className="toggle-checkbox" type="checkbox" />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-1 interest_only diem_cal column-1">
                                            <div className="form-group">

                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">In Default</span>
                                                    <input className="toggle-checkbox" type="checkbox" />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-1 column-1"></div>
                                        <div className="col-md-3 interest_only diem_cal column-3">
                                            <div className="form-group">

                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">In Default Due To Taxes/Insurance</span>
                                                    <input className="toggle-checkbox" type="checkbox" />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-1 column-1"></div>
                                        <div className="col-md-3 interest_only diem_cal column-3">
                                            <div className="form-group">

                                                <label className="toggle mb-0 mt-0 d-flex align-items-center">
                                                    <span className="toggle-label">Fund Disbursement Method</span>
                                                    <select className="form-control">
                                                        <option selected="">Select</option>
                                                        <option>NA</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-3 interest_only diem_cal column-3 ps-5">
                                            <div className="form-group">

                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">Show Collect at Closing in Pay Table</span>
                                                    <input className="toggle-checkbox" type="checkbox" />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-3 mt-4">
                                            <div className="form-group">
                                                <label>FCI Loan Account Number</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-3 mt-4">
                                            <div className="form-group">
                                                <label>FCI Retrieve Token</label>
                                                <select className="form-control">
                                                    <option selected="">Select</option>
                                                    <option>NA</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3 mt-4">
                                            <div className="form-group">
                                                <label>FCI Push Token</label>
                                                <select className="form-control">
                                                    <option selected="">Select</option>
                                                    <option>NA</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3 mt-4">
                                            <div className="form-group">

                                            </div>
                                        </div>



                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <a href="#" className="fs_btn">save</a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-md-12 d-flex align-items-center mb_20">
                                            <h4 className="mb-sm-0 font-size-16 mr_10">Amortization Type : Fixed Rate</h4>
                                            <div className="perr_diem mt-0">
                                                <p>ARM Settings Only Available on Adjustable Rate Mortgages.</p>
                                            </div>
                                        </div>

                                        <div className="col-md-1 interest_only diem_cal column-1">
                                            <div className="form-group">
                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">Pay in Advance</span>
                                                    <input className="toggle-checkbox" type="checkbox" name='payinadvance' checked={servicingSetupData.advanced.payinadvance} onChange={(e) => setServicingSetupData({ ...servicingSetupData, advanced: { ...servicingSetupData.advanced, payinadvance: e.target.checked } })} />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-1 column-1"></div>

                                        <div className="col-md-3 interest_only diem_cal column-3">
                                            <div className="form-group">
                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">End of Month Due Dates</span>
                                                    <input className="toggle-checkbox" type="checkbox" name='endofmonthduedates' checked={servicingSetupData.advanced.endofmonthduedates} onChange={(e) => setServicingSetupData({ ...servicingSetupData, advanced: { ...servicingSetupData.advanced, endofmonthduedates: e.target.checked } })} />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-1 column-1"></div>

                                        <div className="col-md-3 interest_only diem_cal column-3">
                                            <div className="form-group">
                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">Include Maturity Date</span>
                                                    <input className="toggle-checkbox" type="checkbox" name='includematuritydate' checked={servicingSetupData.advanced.includematuritydate} onChange={(e) => setServicingSetupData({ ...servicingSetupData, advanced: { ...servicingSetupData.advanced, includematuritydate: e.target.checked } })} />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-3 interest_only diem_cal column-3 ps-5">
                                            <div className="form-group">
                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">Net Fund Draw Balance</span>
                                                    <input className="toggle-checkbox" type="checkbox" name='netfunddrawbalance' checked={servicingSetupData.advanced.netfunddrawbalance} onChange={(e) => setServicingSetupData({ ...servicingSetupData, advanced: { ...servicingSetupData.advanced, netfunddrawbalance: e.target.checked } })} />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-1 interest_only diem_cal column-1">
                                            <div className="form-group">
                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">Legacy Loan</span>
                                                    <input className="toggle-checkbox" type="checkbox" name='legacyloan' checked={servicingSetupData.advanced.legacyloan} onChange={(e) => setServicingSetupData({ ...servicingSetupData, advanced: { ...servicingSetupData.advanced, legacyloan: e.target.checked } })} />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-1 column-1"></div>

                                        <div className="col-md-3 interest_only diem_cal column-3">
                                            <div className="form-group">
                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">Paid Off</span>
                                                    <input className="toggle-checkbox" type="checkbox" name='paidoff' checked={servicingSetupData.advanced.paidoff} onChange={(e) => setServicingSetupData({ ...servicingSetupData, advanced: { ...servicingSetupData.advanced, paidoff: e.target.checked } })} />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-1 column-1"></div>

                                        <div className="col-md-3 interest_only diem_cal column-3">
                                            <div className="form-group">
                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">Fund Distribution % of UPB</span>
                                                    <input className="toggle-checkbox" type="checkbox" name='funddistributionpercentageofupb' checked={servicingSetupData.advanced.funddistributionpercentageofupb} onChange={(e) => setServicingSetupData({ ...servicingSetupData, advanced: { ...servicingSetupData.advanced, funddistributionpercentageofupb: e.target.checked } })} />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-3 interest_only diem_cal column-3 ps-5">
                                            <div className="form-group">
                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">Advanced Calculations</span>
                                                    <input className="toggle-checkbox" type="checkbox" name='advancedcalculations' checked={servicingSetupData.advanced.advancedcalculations} onChange={(e) => setServicingSetupData({ ...servicingSetupData, advanced: { ...servicingSetupData.advanced, advancedcalculations: e.target.checked } })} />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-1 interest_only diem_cal column-1">
                                            <div className="form-group">
                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">In Default</span>
                                                    <input className="toggle-checkbox" type="checkbox" name='indefault' checked={servicingSetupData.advanced.indefault} 
                                                    
                                                    
                                                    onChange={(e) => handleChange('advanced', e)}/>
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        {servicingSetupData.advanced.indefault === true && 
                                        <div className="col-md-3 interest_only diem_cal column-3">
                                            <div className="form-group">
                                                <label>Default Date:</label>
                                                <input type="date" name='defaultdate' className={`form-control ${errors?.defaultdate && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, defaultdate: null }) }}  placeholder="Enter"  onChange={(e) => handleChange('advanced', e)} value={servicingSetupData.advanced.defaultdate  ? ( new Date(servicingSetupData.advanced.defaultdate).toISOString().split('T')[0]) : ''}  />
                                            </div>
                                        </div>}

                                        <div className="col-md-1 column-1"></div>

                                        <div className="col-md-3 interest_only diem_cal column-3">
                                            <div className="form-group">
                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">In Default Due To Taxes/Insurance</span>
                                                    <input className="toggle-checkbox" type="checkbox" name='indefaultduetotaxinsurance' checked={servicingSetupData.advanced.indefaultduetotaxinsurance} onChange={(e) => setServicingSetupData({ ...servicingSetupData, advanced: { ...servicingSetupData.advanced, indefaultduetotaxinsurance: e.target.checked } })} />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-1 column-1"></div>

                                        <div className="col-md-3 interest_only diem_cal column-3">
                                            <div className="form-group">
                                                <label className="toggle mb-0 mt-0 d-flex align-items-center">
                                                    <span className="toggle-label">Fund Disbursement Method</span>
                                                    <select  name='funddisbursementmethod' value={servicingSetupData.advanced.funddisbursementmethod} onChange={(e) => handleChange('advanced', e)} className={`form-control ${errors?.fciloanaccountnunmber && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, fciloanaccountnunmber: null }) }}  placeholder="Enter">
                                                        <option value="">Select</option>
                                                        <option value="Accrual Basis">Accrual Basis</option>
                                                        <option value="Cash Basis">Cash Basis</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-3 interest_only diem_cal column-3 ps-5">
                                            <div className="form-group">
                                                <label className="toggle mb-0 mt-0">
                                                    <span className="toggle-label">Show Collect at Closing in Pay Table</span>
                                                    <input className="toggle-checkbox" type="checkbox" name='showcollectatclosinginpaytable' checked={servicingSetupData.advanced.showcollectatclosinginpaytable} onChange={(e) => setServicingSetupData({ ...servicingSetupData, advanced: { ...servicingSetupData.advanced, showcollectatclosinginpaytable: e.target.checked } })} />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-3 mt-4">
                                            <div className="form-group">
                                                <label>FCI Loan Account Number</label>
                                                <input type="text" name='fciloanaccountnunmber' className={`form-control ${errors?.fciloanaccountnunmber && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, fciloanaccountnunmber: null }) }}  placeholder="Enter"  onChange={(e) => handleChange('advanced', e)} value={servicingSetupData.advanced.fciloanaccountnunmber}  />
                                            </div>
                                        </div>

                                        <div className="col-md-3 mt-4">
                                            <div className="form-group">
                                                <label>FCI Retrieve Token</label>
                                                <select  className={`form-control ${errors?.fciretrievetoken && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, fciretrievetoken: null }) }} name='fciretrievetoken'  onChange={(e) => handleChange('advanced', e)} value={servicingSetupData.advanced.fciretrievetoken} >
                                                    <option value="">Select</option>
                                                    <option value="NA">NA</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-3 mt-4">
                                            <div className="form-group">
                                                <label>FCI Push Token</label>
                                                <select className={`form-control ${errors?.fcipushtoken && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, fcipushtoken: null }) }}  name='fcipushtoken' onChange={(e) => handleChange('advanced', e)} value={servicingSetupData.advanced.fcipushtoken} >
                                                    <option value="">Select</option>
                                                    <option value="NA">NA</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-3 mt-4">
                                            <div className="form-group">
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <a href="#" className="fs_btn"  onClick={(e) => {e.preventDefault();checkValidation('advanced',servicingSetupData.advanced) }}>save</a>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>

                            </div>



                        </div>

                    </div>
                </div>
                }
                
            </div>
            <PipelineFooter />
        </div>
    );
};

export default ServicingServicingSetup;