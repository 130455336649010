import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import PopupMessage from './PopupMessage';
import { useLoanInfo } from '../utils/loanInfoContext';
import {Appname, APIUrl} from '../utils/reducer';
import { hideLoader, showLoader } from '../redux/reducers/Actions';
import Loader from './Loader';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';
import FileViewer from './DocViewer';
import axios from "axios";
const SupportingDocs = () => {
    const dispatch = useDispatch();
    const { loanInfo, setLoanInfo} = useLoanInfo();
    const [loanid,setLoanid] = useState(loanInfo?.loanid);
    const [showForm,setShowForm] = useState('Table');
    const data = [  {
        id: 1,
        documentname: 'Easy Street Capital',
        // attachtoline: 300000,
        notes: 'Payment',
        attachtodrawrequest: 'Attached files',
        uploaddate: '2024-07-26T07:16:08.916Z',
      },]
    const [documentsTable,setDocumentsTable] = useState([])
    const loading = useSelector((state) => state.loaderVisible);
    const [formData,setFormData] = useState({});
    const [errors,setErrors] = useState({});
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupMessage, setmessage] = useState('');
    const [deleteConfirmation,setDeleteConfirmation] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [tableKey,setTableKey] = useState(0);
    const configdata = useSelector(state => state.configdata);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [showDoc, setshowDoc] = useState(false);
    const [type, setType] = useState(false);
    const [playContent, setPlayContent] = useState({});
    const [fileDocName, setFileDocName] = useState("");
    const initializer =()=>{
        setPage(1); setShowForm('Table');setFormData({});setPerPage(10);setTotalRows(0);setDeleteConfirmation(false);setmessage('');
        setTableKey(prevKey => prevKey + 1);setshowDoc(false);setType(false);setPlayContent({});setFileDocName("");setSelectedFiles([])
    };
    let uploadFilesData = {
        file : '',
        filecode : ''
      };
    useEffect(() => {
        if(loanid){
            initializer();
            setDocumentsTable([...data])
        }
      }, [loanid]);
    useEffect(() => {
        setLoanid(loanInfo?.loanid)
    }, [loanInfo?.loanid]);

    const handleChange = (e)=>{
        let {name , value} = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleUploadClick =()=>{
        setShowForm('UploadForm');
        setFormData({});
        setErrors({});
        setSelectedFiles([]);
    };

    const handleBack =()=>{
        setShowForm('Table');
        setFormData({});
        setErrors({});
        setSelectedFiles([]);
        setTableKey(prevKey => prevKey + 1);
        setPage(1);
        setPerPage(10);
    };

  

    const handleDeleteClick =(data)=>{
        setFormData(data);
        setDeleteConfirmation(true);
    }

    const handleDelete =()=>{
        setDeleteConfirmation(false);
        //delete and then pop up
        setmessage('Deleted Successfully');
        setPopupOpen(true);
        setPage(1);
        setPerPage(10);
        setTableKey(prevKey => prevKey + 1);
    };
    const closePopup= ()=>{
        setShowForm('Table');
        setPopupOpen(false);
    };
    const closePopup2 =()=>{
        setDeleteConfirmation(false);
    }

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',

            },
        },
        table: {
            style: {
                border: '1px solid #CCD8F6',
            },
        },
    };
    const columns = [
        {
          name: 'DOCUMENT NAME',
          selector: (row) => row?.documentname || '',
        //   sortable: true,
          width: '20%',
        },
        // {
        //   name: 'ATTACH TO LINE ITEM',
        //   selector: (row) => row?.attachtoline || '',
        //   sortable: true,
        //   width: '16%',
        // },
        {
            name: 'ATTACH TO DRAW REQUEST',
            selector: (row) => row?.attachtodrawrequest || '',
          //   sortable: true,
            width: '20%',
          },
          {
            name: 'UPLOADED DATE AND TIME',
            selector: (row) => row?.uploaddate ?  moment.utc(row?.uploaddate).format("MM-DD-YYYY") : '',
          //   sortable: true,
            width: '20%',
          },
          {
            name: 'DOCUMENTS',
            cell: (row, index) => (
                <div className="btn-group">
                     <span className="material-symbols-outlined icon2 btn" 
                             onClick={(e) => handleViewOpen(e, data)}> visibility </span>
                </div>
               
              ),
          //   sortable: true,
            width: '20%',
          },
        {
          name: 'Actions',
          cell: (row, index) => (
            <div className="btn-group">
                
              <a href="#" onClick={(e)=>{e.preventDefault(); handleDeleteClick(row);}} className="icon_btn edit"> <span
                                                className="material-symbols-outlined icon"> delete </span> </a>
            </div>
          ),
          width: '20%',
        },
      ];

      // file upload and save section
      const handleFileChange = (event) => {
        const files = event.target.files;
        setSelectedFiles([...selectedFiles, ...files]);
        console.log('files',files);
      };

      const hanldeSave =()=>{
        let data = {...formData,...uploadFilesData ,loanid : loanInfo?.loanid}
        // dispatch(showLoader());
        console.log('data------>',data)
        // addNeeds(data,loanInfo?.loanid)
        // .then(function(response) {
        //     if (response) {
        //         console.log('response', response);
        //         dispatch(hideLoader());
        //         setmessage('Need Added Successfully');
        //         setPopupOpen(true);
        //     }
        // })
        // .catch((err) => {
        //     console.error("error", err); 
            dispatch(hideLoader());
        // });
    };
     
    const hanldeUploadDocument = async(event) => {
        if(!formData?.documentname?.trim()){
            setErrors({documentname: true});
            return;
        }else{
            console.log(formData);
            // upload and then mesage
            // setmessage('Uploaded Successfully');
            // setPopupOpen(true);
            try {
                dispatch(showLoader());
              await Promise.all(selectedFiles.map((file, index) => handleUpload(file, index)));
              
              hanldeSave();
              console.log('Form data submitted:', formData);
              // You can add your form submission logic here
          } catch (error) {
              console.error('Error uploading files:', error);
          }
        }
       
      };
    const handleUpload = async (file, index) => {
        try {
    
            var bucket;
            const timestamp = Date.now();
            let format = file?.name?.split(".");
            var fileformat = format[format.length - 1];
            bucket = configdata?.common?.resourceBucket || 'easystreetcapital-dev-resources';
            var ObjectID = require("bson-objectid");
            var filecode = new ObjectID().toString(); 
            let uploadFilePath = Appname + "/needs/" + filecode + "/" + timestamp + "." + fileformat;
            var data = { source_bucket: bucket, sourcepath: uploadFilePath };
            const response = await axios.post(APIUrl + 'uploadURL?appname=' + Appname, data, { type: 'application/json' });
            if (response?.data && response?.data?.result) {
              uploadFilesData['file'] = uploadFilePath;
              uploadFilesData['filecode'] = filecode;
                var url = response?.data?.result;
                await axios.put(url, file, {
                    "headers": {
                        "Content-Type": "multipart/form-data",
                        "Accept": "/",
                        "Cache-Control": "no-cache",
                        "Accept-Encoding": "gzip, deflate",
                        "Connection": "keep-alive",
                        "cache-control": "no-cache"
                    }
                });
                const updatedFiles = [...selectedFiles];
                updatedFiles[index]['path'] = uploadFilePath;
                console.log("updatedFiles",updatedFiles,filecode)
                // setFormData({
                //     ...formData,
                //     selectedFiles: updatedFiles
                // });
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
     };
      const handleViewOpen = (e, content) => {
        const fileType = content?.file?.split('.').pop();
        let source;
        // let type = content?.filetype?.split("/")
        // if(type[0]==="image"){
        //     source = configdata?.common?.awsImgix  + content.filepath;
        // }else{
        //     source = configdata?.common?.resourcesCloudfront  + content.filepath;
        // };
        source = configdata?.common?.resourcesCloudfront  + content.file;
        setFileDocName(content?.filename)
        setPlayContent(source);
        setshowDoc(true);
        setType(fileType);
        // console.log("playercontent==========>", source)
    }

    return (
        <div id="layout-wrapper" className="dashboard">
        {showDoc && <FileViewer source={playContent} type={type} close={setshowDoc} name={fileDocName} />}
             {loading ? <Loader/> : null} 
            {isPopupOpen && (
                <PopupMessage
                    type={popupMessage.includes('Successfully') ? 'success' : 'warning'}
                    message={popupMessage}
                    onClose={closePopup}
                />
            )}
            {deleteConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={handleDelete}
                onCancel={closePopup2}
                focusCancel={true}
            >
                <p>Do you want to delete this Fee</p>
            </SweetAlert>}
             <PipelineHeader />
            <div className="dashboard_block">
                <PipelineStatus />
                <h2 className="form_title">Draws <span className="space">&#10095;</span> <span className="sub">Supporting Docs</span>
            </h2>
            <div className="card user_management detailed_app sd">
                <div className="card-body my_account">
                    {showForm === 'Table' && 
                    
                    <>
                     <div className="search">
                        <h3 className="title">Supporting Docs</h3>

                        <button onClick={handleUploadClick} type="button" className="border_btn icon">Upload Documents<span className="material-icons icon">
                                file_upload </span></button>
                    </div>
                    {documentsTable?.length > 0 ? <div className="table-responsive">
                    <DataTable className='contacts'
                            key={tableKey}
                                columns={columns}
                                data={documentsTable}
                                pagination
                                customStyles={customStyles}
                                paginationTotalRows={totalRows}
                                paginationServer
                                paginationPerPage={perPage}
                                paginationDefaultPage={page}
                                // onChangeRowsPerPage={handlePerRowsChange}
                                // onChangePage={handlePageChange}
                                // onSort={handleSort}
                                // sortServer={true}
                                paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Per Page',
                                }}
                            />
                     
                    </div> : <div className="inprogress_page">
                                            <span className="material-symbols-outlined icon"> content_paste_search </span><p>No Data Found</p></div>}
                    
                    
                    </>}
                   
                   {showForm === 'UploadForm' &&
                   <div className="upload_docs">
                        <a href="#" onClick={(e) => {e.preventDefault();handleBack();}}  className="back_btn"><span className="material-icons icon"> arrow_back
                            </span>BACK</a>
                        <div className="alert_message">
                            <h3 className="title text-center">Upload docs</h3>
                            <div className="form_section mt_20">
                                <div className="form-group">
                                    <label>Upload Support Documents</label>
                                    <select  className={`form-control ${errors?.documentname && 'invalid-input'}`}
                                    onFocus={()=>{setErrors({})}}
                                     value={formData?.documentname || ''} name='documentname' onChange={handleChange}>
                                        <option value='' selected="">Select</option>
                                        <option value='N/A'>NA</option>
                                    </select>
                                </div>
                                {/* <div className="form-group">
                                    <label>Attach to Line (Optional)</label>
                                    <textarea id="w3review" onChange={handleChange} value={formData?.attachtoline || ''}  name="attachtoline" rows="2" cols="40" className="form-control"
                                        placeholder="Add Description" spellcheck="false"> </textarea>
                                </div> */}
                                <div className="form-group">
                                    <label>Attach to Draw Request (Optional)</label>
                                    <textarea id="w3review" onChange={handleChange} value={formData?.attachtodrawrequest || ''}  name="attachtodrawrequest" rows="2" cols="40" className="form-control"
                                        placeholder="Add Description" spellcheck="false"> </textarea>
                                </div>

                                <div className="uploads">
                                    <div className="item">
                                        <div className="left">
                                            <img src="assets/images/icons/mode-portrait.svg" className="img" />
                                            <div>
                                                {/* <h4>Photo ID</h4> */}
                                                <h6>
                                                Upload clear Picture
                                                </h6>
                                                {/* <p>Upload clear Picture</p> */}

                                            </div>
                                        </div>
                                        <label for="file-input" className="upload"><span className="material-icons icon">file_upload</span></label>
                                        <input type="file" onChange={handleFileChange} accept=".image/*,.doc, .docx, .xls, .xlsx, .pdf, .jpg, .jpeg, .png, .zip" id="file-input" name="photoid" tabindex="8" style={{display:'none'}} />

                                        {/* <input onChange={handleFileChange} className="custom-file-input" accept="image/*,.doc, .docx, .xls, .xlsx, .pdf, .jpg, .jpeg, .png, .zip" type="file"  style={{display:'none'}} /> */}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <button onClick={hanldeUploadDocument} className="fs_btn">save</button>
                                </div>

                            </div>
                        </div>
                    </div>
                   
                   }

                    


                    <div className="modal popup new_popup fade pdf_doc" id="pdfdoc" tabindex="-1"
                        aria-labelledby="pdfdoc_achLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">BOOK STATEMENT - DOCUMENT</h5>
                                    <button type="button" className="pop_close" data-bs-dismiss="modal"
                                        aria-label="Close"><span className="material-symbols-outlined">close</span></button>
                                </div>
                                <div className="modal-body">
                                    <img src="assets/images/book-statement.jpg" alt="" height="30" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <PipelineFooter />
        </div>
    );
};

export default SupportingDocs;