import { legacy_createStore, applyMiddleware } from 'redux';
import reducer from './reducers/reducer';
import { thunk } from 'redux-thunk'
import { loadState, saveState } from './localStorage';

const presistedState = loadState();

const store = legacy_createStore(
  reducer,
  presistedState,
  applyMiddleware(thunk)
);

store.subscribe(()=> {
  saveState(store.getState());
})

// store.subscribe(()=> {
//   saveState({
//      featured: store.getState().featured 
//     });
// })
export default store;