import React, { useState, useEffect } from 'react';
import { getUser, updateUser , createNoteTemplates ,getUserNoteTemplates , getTemplateInfo ,updateNoteTemplate , getValuesDropdown} from '../../utils/reducer';
import { getUserDataFromLocalStorage } from "../../utils/local";
import { useSelector, useDispatch } from 'react-redux';
import PasswordSettings from './PasswordSettings';
import AboutMe from './AboutMe';
import MainHeader from './../MainHeader';
import SideMenu from './../SideMenu';
import { Appname, APIUrl } from '../../utils/reducer';
import axios from "axios";
import { showLoader, hideLoader } from '../../redux/reducers/Actions.js';
import PopupMessage from '../PopupMessage';
import Loader from '../Loader';
import {formatPhoneNumber} from "../../utils/commonUtils.js";
import ReactQuill from "react-quill";
// import EditorEdit from './EditorEdit.js';
import EditorEdit from '../EditorEdit.js';
// import EasyFixhtml from '../TemplateFiles/EasyFixhtml';
import {getStates} from "../../utils/reducer";
import { useNavigate } from "react-router-dom";
import HomeFooter from '../HomeFooter';
import formValuesKeys from '../../JsonData/formValuesKeys.json';
import {transformData} from '../../utils/commonUtils.js';
import ValuesDropdown from '../ValuesDropdown.js';

const MyAccount = () => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loaderVisible);
    const configdata = useSelector(state => state.configdata);
    const [data, setData] = useState({ username: '', emailid: '', state: '', lastname: '', nmlsLicense: '', stateLicense: '', defaultNote: '', number: '' });
    const [userData, setUserData] = useState(null);
    const [userInfoErrors, setUserInfoErrors] = useState({});
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupMessage, setmessage] = useState('Changes updated successfully.');
    // const [quillValue, setQuillValue] = useState("");
    const [dropDownValue,setDropDownValue] = useState('');
    const [templateValues, setTemplateValues] = useState({
        noteName : '',
        noteSubject : ''
    });
    const [templateError, setTemplateError] = useState({});
    const [content, setContent] = useState("");
    // const [noteName, setNoteName] = useState('');
    // const [noteSubject, setNoteSubject] = useState('');
    const [myAccountOld,setMyAccountOld] = useState({ username: '', emailid: '', state: '', lastname: '', nmlsLicense: '', stateLicense: '', defaultNote: '', number: '' });
    // const [myAccountOld,setMyAccountOld] = useState({ emailid: '', location: '', lastname: '', nmlsLicense: '', number: '' });
    const [updateFirst,setUpdateFirst] = useState(true);
    const [states, setStates] = useState([]);
    const [editorValidate,setEditorValidate] = useState(false);
    let errors = {};
    const getUserData = getUserDataFromLocalStorage();
    const user_id = getUserData?.userId;
    const [dropdownTemplates,setDropdownTemplates] = useState([]);
    const [noteId,setNoteId] = useState();
    const [saveButton,setSaveButton] = useState(false);
    const noteNames = [];
    const [quillValue, setQuillValue] = useState('');
    const [signaturetext, setSignatureText] = useState('');
    const [validateContent,setValidateContent] = useState(false);
    const [updateTwo,setUpdateTwo] = useState(true);
    const navigate = useNavigate();
    const [formValuesOption,setFormValuesOption] = useState(null);
    const [valueDependency,setValuesDependency] = useState(0);
    

    const pipelineLoanOptions = [
        { value: "", label: "Empty" },
        { value: "10", label: "10" },
        { value: "20", label: "20" },
        { value: "30", label: "30" },
        { value: "40", label: "40" },
        { value: "50", label: "50" },
        { value: "60", label: "60" },
        { value: "70", label: "70" },
        { value: "80", label: "80" },
        { value: "90", label: "90" },
        { value: "100", label: "100" },
    ];

    const payload = {
        "userid" : user_id,
        "notename" : templateValues.noteName,
        "notesubject" :  templateValues.noteSubject,
        "notedescription" : content
    }
   
    useEffect(() => {
        getUsertData()
    }, []);

    useEffect(() => {
        getStates().then(function (response) {
          console.log('getStates',response);
          if (response?.result) {
             setStates(response?.result)
          }
          }).catch((err) => {
            console.error.bind("my account error", err);
          })
      }, []);

    const getUsertData = () => {
        getUser().then(function (response) {
            if (response?.result) {
                setData({ ...data, ...response?.result?.[0]?.userInfo , 'confirmPass' : '','newPass' : '','oldPass': ''}  || {...response?.result?.[0] });
                setMyAccountOld({ ...data, ...response?.result?.[0]?.userInfo , 'confirmPass' : '','newPass' : '','oldPass': ''}  || {...response?.result?.[0] });
                setUserData(response?.result?.[0]?.userInfo || response?.result?.[0]);
                setSignatureText(response?.result?.[0]?.userInfo.emailsignature);
                setQuillValue(response?.result?.[0]?.userInfo.aboutme);
                const isAllEmpty = Object.values(myAccountOld).every(value => value === '');
                console.log('isAllEmpty',isAllEmpty)
                // Object.entries(myAccountOld).map(([key,value]) => {
                //     if(value){
                //         setMyAccountOld({ ...myAccountOld, ...response?.result?.[0]?.userInfo || response?.result?.[0] });
                //     }
                // })
                if(isAllEmpty){
                    // setMyAccountOld({ ...myAccountOld, ...response?.result?.[0]?.userInfo || response?.result?.[0] });
                    setMyAccountOld({ ...myAccountOld, ...response?.result?.[0]?.userInfo , 'confirmPass' : '','newPass' : '','oldPass': ''}  || {...response?.result?.[0] });
                }
                    
                
            }
        }).catch((err) => {
            console.error.bind("my account error", err);
        })
    }

    //compare existing and new 
    useEffect(() => {
        compareObjects();
        console.log(data,myAccountOld)
    },[data]);

    // useEffect(() => {
    //     dropdownTemplates.map((item))
    // },[dropdownTemplates])

    const callGetUserNoteApi = () => {
        getUserNoteTemplates(user_id).then(function (response) {
            console.log('get note template response', response);
            if(response?.statusCode === 200){
               setDropdownTemplates(response?.result);
            }
            
        }).catch((err) => {
            console.error.bind("get note templates  error", err);
           
        })
    }

    useEffect(() => {

       callGetUserNoteApi();

    },[]);

    // useEffect(() => {
    //     callGetUserNoteApi();
 
    //  },[saveButton])

    const compareObjects = () => {
       
            console.log("myaccountold",myAccountOld);
            console.log("myaccountnew",data);
            // console.log(JSON.stringify(myAccountOld))
            const buttonBool = (JSON.stringify(myAccountOld) === JSON.stringify(data)) ;
            setUpdateFirst(buttonBool);
        
       
    }

    

    const handleChange = (e) => {
        setData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleUserReset = () => {
        setUserInfoErrors({ firstname: false, lastname: false, state: false, nlms: false });
        setData({...myAccountOld });
        setQuillValue(myAccountOld?.aboutme);
        setSignatureText(myAccountOld?.emailsignature);
    }

    const fieldsToCheck = {
        firstname: 'FirstName', state: 'State', lastname: 'LastName',emailid: 'emailid',pipelineLoanHistoryDays : 'pipelineLoanHistoryDays'
    };

    const handleUpdate = () => {
        // const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/;
        console.log('ran');
        const errors = {};

        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            if (!data[fieldName].trim()) {
                errors[fieldName] = `Please Enter ${fieldLabel} `;
            }
        };

        if (data.emailid) {
            if (!emailRegex.test(data.emailid)) {
                errors.emailid = 'Email Structure Error';
            }
        }

        if(data.firstname){
            if(data.firstname === "0" ) {
           
                errors.firstname = 'firstname Structure Error';
            }
        
        }
        
        if(data.username){
            if(data.username === "0" ) {
           
                errors.username = 'username Structure Error';
            }
        
        }

        if(data.lastname){
            if(data.lastname === "0" ) {
           
                errors.lastname = 'lastname Structure Error';
            }
        
        }

        if(data.location){
            if(data.location === "0") {
           
                errors.location = 'location Structure Error';
            }
        
        }

        if(data.nmlsLicense ){
            if(data.nmlsLicense === "0") {
           
                errors.location = 'nmlsLicense Structure Error';
            }
        
        }

       
        setUserInfoErrors(errors);
        if (Object.keys(errors).length === 0) {
            console.log('ready to api call -----> ', data);
            dispatch(showLoader());
            updateUser(data).then(function (response) {
                if (response?.result == "incorrect old password") {
                    setmessage(response?.result)
                } else {
                    setmessage("Changes updated successfully.")
                }
                dispatch(hideLoader());
                getUsertData()
                setPopupOpen(true);
                console.log('responseresponse', response);
            }).catch((err) => {
                console.error.bind("Login error", err);
                dispatch(hideLoader());
            })

        }
    }


    const closePopup = () => {
        setPopupOpen(false);
        setData({...data , newPass : '' , oldPass : '', confirmPass : ''});
    };

    const handleFileChange = (e) => {
        // Handle file selection here

        let userData = getUserDataFromLocalStorage();
        let UserId = userData?.userId
        console.log('UseuserDatarId,userData', UserId)

        const file = e.target.files[0];
        var bucket;
        const timestamp = Date.now();
        let format = file?.name?.split(".");
        var fileformat = format[format.length - 1]
        bucket = configdata?.common?.resourceBucket || 'easystreetcapital-dev-resources';
        console.log('filefilefilebucket', bucket,);
        let category = e.target.name;
        console.log('datadacategory', category);

        let uploadFilePath = Appname + "/" + UserId + "/" + timestamp + "profileimage." + fileformat;
        console.log('datadauploadFilePath', uploadFilePath);
        var uploaddata = { source_bucket: bucket, sourcepath: uploadFilePath }
        console.log('datadatadata', data);
        dispatch(showLoader());
        axios.post(APIUrl + 'uploadURL?appname=' + Appname, uploaddata, { type: 'application/json' })
            .then((response) => {
                console.log('ressss', response);
                if (response?.data && response?.data?.result) {
                    console.log('ressss2', response.data);
                    var url = response?.data?.result;
                    console.log('ressss3', response.data.result);

                    console.log("url", url);
                    axios.put(url, file, {
                        "headers": {
                            "Content-Type": "multipart/form-data",
                            "Accept": "/",
                            "Cache-Control": "no-cache",
                            "Accept-Encoding": "gzip, deflate",
                            "Connection": "keep-alive",
                            "cache-control": "no-cache"
                        }
                    })
                        .then((response) => {
                            if (response && response?.status === 200) {
                                console.log('responseherererer', uploadFilePath);
                                data.profileImage = uploadFilePath;
                                updateUser(data).then(function (response) {
                                    console.log('responseresponse', response);
                                    setmessage('Uploaded Successfully');
                                    setPopupOpen(true)
                                    getUsertData()
                                    dispatch(hideLoader());
                                }).catch((err) => {
                                    console.error.bind("Login error", err);
                                    dispatch(hideLoader());
                                })

                            }
                        });
                }


            });
    };

    // const options = [
    //     { value: "Note Test(Shared to you)", label: "Note Test(Shared to you)" },
    // ];


    const handleTempValueChaneg =(e)=>{
        console.log(e.target.value);
        setTemplateValues((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    }

    const handleReset = () =>{
        const templateInfo ={ html: '', noteName: '', noteSubject: '' };
        setDropDownValue('');
        // setQuillValue(templateInfo.html);
        setTemplateValues({noteName :templateInfo.noteName , noteSubject : templateInfo.noteSubject});
        setTemplateError({});
        setContent(templateInfo.html);
    };

    const templateData = {
        "Note Test(Shared to you)": {
          html: `
            <p>This is to test the notes</p>
          `,
          noteName: "Note Test",
          noteSubject: "Note Test",
        },
       
      };
      

    const handleTemplateChange = (e) => {
        const templateId = e.target.value;//template _id 
        
        // const selectedTemplate = e.target.value;//template _id 
        const selectedTemplateName = e.target.name;
        setDropDownValue(e.target.value);
        if(e.target.value !== ""){
            //call get remplate info api
            setNoteId(templateId);
            getTemplateInfo(user_id,templateId).then(function (response) {
                console.log('get specific note template info response', response);
                if(response?.statusCode === 200){
                    const tempRes = response?.result[0];

                    if(templateValues['notesubject'] && errors['notesubject']){
                        errors['notesubject'] = '';    
                    }
                    
                    if(templateValues['notename'] && errors['notename']){
                        errors['notename'] = '';    
                    }
    
                   setTemplateError(errors);
                   
                    // const templateInfo = templateData[selectedTemplate] || { html: '', noteName: '', noteSubject: '' };
                    setTemplateValues({noteName :tempRes.notename , noteSubject : tempRes.notesubject})
                    setQuillValue(tempRes.notedescription);
                    setContent(tempRes.notedescription);

                }
                
            }).catch((err) => {
                console.error.bind("get specific note template info error", err);
               
            })
        }else{
            const templateInfo = templateData[templateId] || { html: '', noteName: '', noteSubject: '' };
            setTemplateValues({noteName :templateInfo.noteName , noteSubject : templateInfo.noteSubject})
            setQuillValue(templateInfo.html);
            setContent(templateInfo.html);
        }
       
    };

   

    const templateValidation = () => {
        let fields = {noteName : 'noteName', noteSubject : 'noteSubject'};

        for (const [fieldName, fieldLabel] of Object.entries(fields)) {
            if (!templateValues[fieldName].trim() && fieldName !== "content") {
                errors[fieldName] = `Please Enter ${fieldLabel} `;
            }
        };

        //const isWhitespace = /^<p>(&nbsp;|\s)*<br>\s*<\/p>$/;
        
        // Adjust the regex to handle multiple <p><br></p> elements
        const isWhitespace = /^(<p>(\s|&nbsp;|<br>)*<\/p>)*$/;
        const contentIsEmpty = !content || content === "<p><br></p>" || isWhitespace.test(content);
      
        // if (contentIsEmpty) {
        //     setValidateContent(true);
        // } else {
        //     setValidateContent(false);
        // }
        // Set validation state for content
        //setValidateContent(contentIsEmpty);

        
        // If there's content validation error, add to errors
        if (contentIsEmpty) {
            errors['content'] = 'Content cannot be empty.';
        }
        setTemplateError(errors);

       
        console.log('errors---->',errors);
    }

    const handleTemplateUpdate =()=>{
        
        templateValidation();
        //console.log("content",content);
        
        if (Object.keys(errors).length === 0)  {
            payload._id = noteId;
            dispatch(showLoader());
            updateNoteTemplate(payload).then(function (response) {
                console.log('update note template response', response);
                if(response?.statusCode === 200 && response?.result === "UserTemplates modified successfully"){
                    
                   
                    callGetUserNoteApi();
                    setTemplateValues({
                        noteName : '',
                        noteSubject : ''
                    });
                    setContent('')
                    setDropDownValue('');
                    setmessage("Changes updated successfully.");
                    setPopupOpen(true);
                    dispatch(hideLoader());
                }
                
            }).catch((err) => {
                console.error.bind("update note templates  error", err);
                dispatch(hideLoader());
               
            })
    
        
            // console.log('selected value---->',dropDownValue);
            // console.log('template data ----->',templateValues);
            // console.log('Quill value---->',quillValue);
            // dispatch(hideLoader());
            // setmessage("Updated Successfully");
            // setPopupOpen(true);
        }
        
    };

    const handleTemplateSave = () => {
        // console.log(content);
        templateValidation();
        
        
        if (Object.keys(errors).length === 0 && !validateContent)  {
            dispatch(showLoader());
        //  console.log('selected value---->',dropDownValue);
        //  console.log('template data ----->',templateValues);
        //  console.log('Quill value---->',quillValue);
            
        createNoteTemplates(payload).then(function (response) {
            console.log('create note template response', response);
            if(response?.statusCode === 200 && response?.result === "UserTemplates modified successfully"){
                callGetUserNoteApi();
                // setSaveButton(!saveButton);
                setmessage("Saved Successfully");
                setPopupOpen(true);
                setTemplateValues({
                    noteName : '',
                    noteSubject : ''
                });
                setContent('')
            }else if(response?.statusCode === 200 && response?.result?.message === "note name already exisits"){
                setmessage("Note name already exists");
                setPopupOpen(true);
            }
            dispatch(hideLoader());
        }).catch((err) => {
            console.error.bind("create note templates  error", err);
            dispatch(hideLoader());
        })

         dispatch(hideLoader());
         
        }
    }

    const styles = {
        'opacity' : (updateFirst === true) ? '0.6' : '1' ,
        'background-color': '#7CC149',
        'color' : '#fff'
    }
    const stylesComp = {
        'opacity' : (updateTwo === true) ? '0.6' : '1' ,
        'background-color': '#7CC149',
        'color' : '#fff'
      }
      const modules = {
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ color: [] }, { background: [] }],
          [{ script: 'sub' }, { script: 'super' }],
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }],
          ['link', 'image', 'video'],
          [{ align: [] }],
        ],
        clipboard: {
          matchVisual: false,
        },
      };

      useEffect(() => {
       setData((prev) => ({
          ...prev,
          'emailsignature': signaturetext,
        }));
        
       }, [signaturetext]);
    
       useEffect(() => {
        setData((prev) => ({
          ...prev,
          'aboutme': quillValue,
        }));
       }, [quillValue]);
       useEffect(() => {
        compareObjectsAbout();
        
      },[data])
    
      const compareObjectsAbout = () => {
 
        const buttonBool2 = (JSON.stringify(myAccountOld) === JSON.stringify(data)) ;
        setUpdateTwo(buttonBool2);
    }

  

  const handleChangeValues = (value) => {
    const selectedValue = value;

    // setFormVariables((prevFormVariables) => {
    //     if (!prevFormVariables.includes(selectedValue)) {
    //         return [...prevFormVariables, selectedValue];
    //     }
    //     return prevFormVariables; 
    // });

    setFormValuesOption(selectedValue);
    setValuesDependency(valueDependency + 1);
};

   
    

    return (
        <div id="layout-wrapper">
            {isPopupOpen && (
                <PopupMessage
                    type={popupMessage == 'Changes updated successfully.' || popupMessage === 'Saved Successfully' ||  popupMessage === 'Uploaded Successfully' ?'success' : 'warning'}
                    message={popupMessage}
                    onClose={closePopup}
                />
            )}
            {loading ? <Loader /> : null}
            <MainHeader userData={data} />

            <SideMenu />

            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">My Account</h4>
                                    <a href="#" id="myAccountBack" onClick={(e) => {e.preventDefault();navigate('/pipeline') }} className="back_btn">
                                        <span className="material-icons icon"> arrow_back</span>BACK
                                    </a>
                                </div>
                               
                                <div className="card">
                                    <div className="card-body my_account">
                                        <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                                            <li className="nav-item">
                                                <button id="userInfoTab" className="nav-link active" data-bs-toggle="tab" href="#USERINFO" role="tab">
                                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                    <span className="d-none d-sm-block">USER INFO</span>
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button id="noteTemplatesTab" className="nav-link" data-bs-toggle="tab" href="#notetemplates" role="tab">
                                                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                                    <span className="d-none d-sm-block">note templates</span>
                                                </button>
                                            </li>

                                        </ul>

                                        <div className="tab-content p-3 text-muted">
                                            <div className="tab-pane active" id="USERINFO" role="tabpanel">
                                                <div className='account_form user_info mt_20'>
                                                    <h3>USER INFO</h3>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className='form'>
                                                                <div className="form-group">
                                                                    <label>First Name<span className="rf">*</span> </label>
                                                                    <input id="userInfofirstName" type="text" className={`form-control capitalize-text ${userInfoErrors.firstname && 'invalid-input'}`}
                                                                        name='firstname' onFocus={() => { setUserInfoErrors({ ...userInfoErrors, firstname: null }) }} value={data?.firstname} onChange={(e) => handleChange(e)}
                                                                        placeholder='Enter First Name' tabIndex="1"/>
                                                                    {/* {userInfoErrors.username && (<span className="errormsg" style={{  color: "red" }}> {userInfoErrors.username} </span>)}     */}
                                                                </div>

                                                                <div className="form-group">
                                                                    <label>State<span className="rf">*</span></label>
                                                                    <select id="userInfoState" name="state" value={data.state} onChange={handleChange} onFocus={()=>{setUserInfoErrors({...userInfoErrors,location : null })}} className={`form-control ${userInfoErrors?.state && 'invalid-input'}`} tabIndex="5">
                                                                        <option value=''>Select</option>
                                                                        {states.map((key, index) => (<option value={key}>{key}</option>))}
                                                                    </select>
                                                                </div>

                                                                {/* <div className="form-group">
                                                                    <label>Location <span className="rf">*</span> </label>
                                                                    <input type="text" name='location' className={`form-control ${userInfoErrors.location && 'invalid-input'}`} onFocus={() => { setUserInfoErrors({ ...userInfoErrors, location: null }) }} value={data.location} onChange={(e) => handleChange(e)}
                                                                        placeholder='Enter Location' tabIndex="5"/>
                                                                    {/* {userInfoErrors.location && (<span className="errormsg" style={{  color: "red" }}> {userInfoErrors.location} </span>) } */}
                                                                {/* </div> */} 

                                                                {/* <div className="form-group me-3">
                                                                    <label>User Name<span className="rf">*</span> </label>
                                                                    <input type="text" className={`form-control ${userInfoErrors.username && 'invalid-input'}`}
                                                                        name='username' onFocus={() => { setUserInfoErrors({ ...userInfoErrors, username: null }) }} value={data?.username} onChange={(e) => handleChange(e)}
                                                                        placeholder='Enter User Name' tabIndex="9"/> */}
                                                                    {/* {userInfoErrors.username && (<span className="errormsg" style={{  color: "red" }}> {userInfoErrors.username} </span>)}     */}
                                                                {/* </div> */}

                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className='form form1'>
                                                                <div className="form-group">
                                                                    <label>Last Name <span className="rf">*</span> </label>
                                                                    <input id="userInfoLastName" type="tel" name='lastname' className={`form-control capitalize-text ${userInfoErrors.lastname && 'invalid-input'}`} onFocus={() => { setUserInfoErrors({ ...userInfoErrors, lastname: null }) }} value={data.lastname} onChange={(e) => handleChange(e)}
                                                                        placeholder='Enter Last Name' tabIndex="2"/>
                                                                    {/* {userInfoErrors.lastname && (<span className="errormsg" style={{  color: "red" }}> {userInfoErrors.lastname} </span>) } */}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>NMLS License#  </label>
                                                                    <input id="userInfoLicense" type="tel" className={`form-control ${userInfoErrors.nmlsLicense && 'invalid-input'}`} name='nmlsLicense' onFocus={() => { setUserInfoErrors({ ...userInfoErrors, nmlsLicense: null }) }} value={data.nmlsLicense} onChange={(e) => handleChange(e)}
                                                                        placeholder='Enter NMLS License#' tabIndex="6"/>
                                                                    {/* {userInfoErrors.nmlsLicense && (<span className="errormsg" style={{  color: "red" }}> {userInfoErrors.nmlsLicense} </span>) } */}
                                                                </div>
                                                               

                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label>Phone Number</label>
                                                                    <input id="userInfoPhone" placeholder="(xxx) xxx-xxxx"  onInput={(e) => e.target.value = formatPhoneNumber(e.target.value)}  onChange={(e)=>handleChange(e)} value={data?.phone} type='tel' name="phone" className="form-control" 
                                                                    pattern="\(\d{3}\) \d{3}-\d{4}" title="Phone number must be in the format: (123) 456-7890"  tabIndex="3"/>
                                                                </div>
                                                                {/* <div className="form-group">
                                                                    <label>State License #</label>
                                                                    <input type="text" name='stateLicense' className="form-control" value={data.stateLicense} onChange={(e) => handleChange(e)}
                                                                        placeholder='Enter State License #' tabIndex="7"/>
                                                                </div> */}
                                                                <div className="form-group">
                                                                    <label>Default Pipeline Load History days<span className="rf">*</span></label>
                                                                    <select id="userInfoLoadHistoryDays" value={data?.pipelineLoanHistoryDays} className={`form-control ${userInfoErrors?.pipelineLoanHistoryDays && 'invalid-input'}`} onFocus={()=>{setUserInfoErrors({...userInfoErrors,pipelineLoanHistoryDays : null })}} name='pipelineLoanHistoryDays' onChange={handleChange} tabIndex={7}>
                                                                        {pipelineLoanOptions.map((data,index)=>(
                                                                            <option value={data.value}>{data.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Email </label>
                                                                <input id="userInfoEmail" readOnly={true} type="text" name='emailid' className={`form-control ${userInfoErrors.emailid && 'invalid-input'}`} onFocus={() => { setUserInfoErrors({ ...userInfoErrors, emailid: null }) }} onChange={(e) => handleChange(e)}
                                                                    placeholder='Enter Email' value={data?.emailid} tabIndex="4"/>
                                                            </div>
                                                            {/* <div className="form-group">
                                                                <label>Default Note Sender</label>
                                                                <input type="text" name='defaultNote' value={data.defaultNote} className="form-control" onChange={(e) => handleChange(e)}
                                                                    placeholder='Enter Default Note Sender' tabIndex="8"/>
                                                            </div> */}
                                                        </div>
                                                        <div className="col-md-6 user_management user_info note_templates">
                                                            <div className="uploads">
                                                                <div className="item">
                                                                    <div className="left">
                                                                        <img src={(userData?.profileImage && configdata?.common?.awsImgix) ? configdata?.common?.awsImgix + userData?.profileImage : "assets/images/icons/mode-portrait.svg"}
                                                                            className="img" />
                                                                        <div>
                                                                            <h4>Profile Picture</h4>
                                                                            <p>Upload clear Picture</p>

                                                                        </div>
                                                                    </div>
                                                                    <label htmlFor="file-input" className="upload">
                                                                        <span className="material-icons icon">file_upload</span>
                                                                        Upload
                                                                    </label>
                                                                    <input type="file" accept="image/*" id="file-input" name={'photoid'} onChange={handleFileChange} style={{ display: 'none' }} tabIndex="8"/>

                                                                </div>
                                                            </div>
                                                            <div className="btn-group mt_10 mb-30">
                                                                <button id="userInfoReset" onClick={handleUserReset} className="border_btn mr_10" tabIndex="9">reset</button>
                                                                <button id="userInfoUpdate" onClick={handleUpdate} className="btn solid_btn" style = {styles} disabled = {updateFirst} tabIndex="10">update</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='account_form user_info note_templates user_management about_me'>

                                                     {/* <AboutMe data={data} setData={setData} handleUpdate={handleUpdate} myAccountOld = {myAccountOld}/> */}
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                            <div className="form">
                                                                <h3>ABOUT ME</h3>
                                                                <div className="form-group">
                                                                <ReactQuill
                                                                    id="userInfoAboutMe"
                                                                    style={{ height: "200px" }}
                                                                    theme="snow"
                                                                    value={quillValue}
                                                                    onChange={setQuillValue}
                                                                    modules= {modules}
                                                                    // formats={["header"]}
                                                                    formats={["header", "bold", "italic", "underline", "strike", "link", "image", "blockquote", "code-block","list", "indent","align", "color", 
                                                                    "background","script" ]}
                                                                />
                                                                {/* <MyEditor /> */}
                                                                </div>
                                                                <div className="btn-group mt_30 mb-30">
                                                                <button
                                                                onClick={handleUserReset}
                                                                    // onClick={() =>  handleReset()}
                                                                    className="border_btn mr_10" tabIndex="11"
                                                                    id="aboutMeReset"
                                                                >
                                                                    reset
                                                                </button>
                                                                <button  id="aboutMeUpdate" className="btn solid_btn" onClick={handleUpdate} style = {stylesComp} disabled={updateTwo} tabIndex="12">update</button>
                                                                </div>
                                                            </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                            <div className="form">
                                                                <h3>EMAIL SIGNATURE</h3>
                                                                {/* <div className="form-group" style={{ border: '1px solid #ccc', padding: '10px' }}>
                                                                <SignatureCanvas
                                                            ref={signatureRef}
                                                            // penColor='blue'
                                                            canvasProps={{ width: 750, height: 215, className: 'signature-canvas' }}
                                                        />
                                                        <button onClick={saveSignature}>Save</button>
                                                        <button onClick={clearSignature}>Clear</button>
                                                                </div> */}
                                                                <div className="form-group">
                                                                <ReactQuill
                                                                    id="userInfoEmailSignature"
                                                                    style={{ height: "225px" }}
                                                                    theme="snow"
                                                                    value={signaturetext}
                                                                    onChange={setSignatureText}
                                                                    modules={{
                                                                    // Override Quill's default behavior
                                                                    clipboard: { matchVisual: false },
                                                                    toolbar: {
                                                                        container: [
                                                                        [{ header: [1, 2, false] }],
                                                                        ["bold", "italic", "underline", "strike"],
                                                                        ["link", "image"],
                                                                        ["blockquote", "code-block"],
                                                                        ],
                                                                    },
                                                                    }}
                                                                    // formats={["header"]}
                                                                    
                                                                    formats={["header", "bold", "italic", "underline", "strike", "link", "image", "blockquote", "code-block"]}
                                                                />
                                                                {/* <MyEditor /> */}
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                        <PasswordSettings data={data} setData={setData} handleUpdate={handleUpdate} userInfoErrors = {userInfoErrors} setUserInfoErrors = {setUserInfoErrors} myAccountOld = {myAccountOld}/>

                                                </div>
                                            </div>
                                            <div className="tab-pane" id="notetemplates" role="tabpanel">
                                                <div className='account_form user_info'>
                                                    <h3 className="mt_20">NOTE TEMPLATES</h3>
                                                    <div className="form_section">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className='form'>
                                                                    <div className="form-group mt_10">
                                                                        <label>Select Template<span className="rf">*</span>
                                                                        </label>
                                                                        <select id="noteTemplate" value={dropDownValue} onChange={handleTemplateChange} className="form-control select" name="templateSelection">
                                                                            <option value="">Select a Template</option>
                                                                            {dropdownTemplates.map((option)=>{
                                                                                return(
                                                                                    <option value={option._id}>{option.notename}</option>
                                                                                )
                                                                            })}
                                                                            
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className='account_form user_info note_templates user_management about_me mt_20'>
                                                        <h3 className="mt_20 mb_15">{dropDownValue === "" ? "create template" : "update template" }</h3>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className='form'>
                                                                    <div className="form-group">
                                                                        <label>Note Name</label>
                                                                        <input id="noteName" name='noteName' type="text" className={`form-control ${templateError?.noteName && 'invalid-input'}`} value={templateValues.noteName} onChange={(e) => handleTempValueChaneg(e)}
                                                                          onFocus={() => { setTemplateError({ ...templateError, noteName: null }) }}   placeholder='Enter Name' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className='form form1'>

                                                                    <div className="form-group">
                                                                        <label>Note Subject</label>
                                                                        <input id="noteSubject" type="text" name='noteSubject' className={`form-control ${templateError?.noteSubject && 'invalid-input'}`}  value={templateValues.noteSubject} onChange={(e) => handleTempValueChaneg(e)}
                                                                         onFocus={() => { setTemplateError({ ...templateError, noteSubject: null }) }}   placeholder='Enter Subject' />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <ValuesDropdown onChange={handleChangeValues}/>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-8">
                                                                <div className='form'>

                                                                    {/* <div className={`form-group ${validateContent&& 'invalid-input'}`} onFocus={() => { setValidateContent(false) }} > */}
                                                                    <div className={`form-group ${templateError?.content && 'invalid-input'}`} onFocus={() => { setTemplateError({ ...templateError, content: null }) }} >
                                                                    {/* <ReactQuill
                                                                       style={{ height: "200px" }}
                                                                       theme="snow"
                                                                       value={quillValue}
                                                                       onChange={setQuillValue}
                                                                       modules= {modules}
                                                                       formats={["header", "bold", "italic", "underline", "strike", "link", "image", "blockquote", "code-block"]}
                                                                    /> */}
                                                                
                                                                   <EditorEdit content={content} setContent={setContent} editorValidate = {editorValidate} setEditorValidate = {setEditorValidate} formValuesOption={formValuesOption} valueDependency={valueDependency}/>
                                                                  
                                                                    </div>
                                                                    <div className="btn-group mt_20 mb-30">
                                                                        <button id="noteTemplateResest" onClick={handleReset} className="border_btn mr_10">reset</button>
                                                                        <button id="noteTemplateSubmit" onClick={dropDownValue === "" ? handleTemplateSave : handleTemplateUpdate} className="btn solid_btn">{dropDownValue === "" ? "save" : "update"}</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>








                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default MyAccount;