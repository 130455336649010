import React,{useContext, useState} from "react";

const LoanInfoContext = React.createContext({
    loanInfo: {},
    setLoanInfo: () => {},
  });
  
  // Custom hook to use the LoanInfoContext
  export const useLoanInfo = () => {
    return useContext(LoanInfoContext);
  };
  
  // LoanInfoProvider component
 export const LoanInfoProvider = ({ children }) => {
    const [loanInfo, setLoanInfo] = useState({
      propertyinfo: {},
      strategyinfo: {},
      borrowerinfo: {},
      dealinfo: {},
      requestor:''
    });
  
    return (
      <LoanInfoContext.Provider value={{ loanInfo, setLoanInfo }}>
        {children}
      </LoanInfoContext.Provider>
    );
};
