import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector} from 'react-redux';
const Submit = () => {
    const navigate = useNavigate();
    const configdata = useSelector(state => state.configdata);
    return (
        <div className="create_process">
            <div className="header">
                <div className="wrapper">
                    <div className="logo">
                        <img src="assets/images/brand/logo-light.png" className="img" alt="" />
                    </div>
                    <a href="#" className="btn"> <span className="material-icons"> close </span>EXIT</a>
                </div>

            </div>
            <div className="content_wrp">
            <div className="content_blk create_success">
                <div className="block">
                    <h3>Find out what Easy Street capital can do for you.</h3>
                    <div className="icon">
                        <img src="assets/images/icons/success_icon.svg" className="img" alt="" />
                    </div>
                    <p>Thank You ! <br />
                        Your application has been submitted
                        Our team will follow up with you Shortly.</p>
                    <a href="#" className="cp_btn">Back to Home</a>
                </div>


            </div>
            </div>
            <div className="cp_footer">
                <div className="wrapper">
                    <p>{configdata?.admin?.rights}</p>
                    <ul>
                        <li><a href="privacy">Privacy </a></li>
                        <li><a href=""> | </a></li>
                        <li><a href="terms">Terms of Use</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Submit;