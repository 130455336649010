import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useLocation, useNavigate} from 'react-router-dom';
import EditorEdit from './EditorEdit.js';
import {updateFormPackages, deleteLOSFees, addFormPackage,deletePackage,getStates} from "../utils/reducer";
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Loader';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import PopupMessage from './PopupMessage.js';
import SweetAlert from 'react-bootstrap-sweetalert';
import HomeFooter from './HomeFooter';
import {getFormTemplates} from "../utils/reducer";
import formValuesKeys from '../JsonData/formValuesKeys.json';
import ValuesDropdown from './ValuesDropdown.js';

const CreatePackage = () => {
    const navigate = useNavigate();
    const [losFeesJson, setlosFeesJson] = useState([]);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loaderVisible);
    const location = useLocation();
    // console.log('location data --->',location);
    const [content, setContent] = useState(location?.state?.data?.description);
    const [type , setType] = useState(location?.state?.typeOfPage==="edit" ? 'update' :location?.state?.typeOfPage==="view" ? "view": 'add');
    const [editorValidate,setEditorValidate] = useState(false);
    const [documentPackage , setDocumentPackage] = useState(location?.state?.data || {
        "packagename": "",
        "state": "",
        "loantype": "",
        "propertyType": "",
        "specificborrower": "",
        "statestandaloneguaranty": "",
        "statepromissorynote": "",
        "statespousalconsent": "",
        "loanfund": "",
        "interestreserves": ""
    });
    const [selectedDocuments, setSelectedDocuments] = useState(location?.state?.data?.statesaddtopackage || []);
    const [errors , setErrors] = useState({});
    const [confirmation , setConfirmation] = useState(false);
    const [message, setMessage] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [formChanged, setFormChanged] = useState(false);
    const [states, setStates] = useState([]);
    const [formVariables,setFormVariables] = useState([]);
    const [formValuesOption,setFormValuesOption] = useState(null);
    const [valueDependency,setValuesDependency] = useState(0);
    let formValues = formValuesKeys;

    useEffect(() => {
        const isFormChanged =
            JSON.stringify(location?.state?.data) !== JSON.stringify(documentPackage) ||
            location?.state?.data?.statesaddtopackage !== selectedDocuments || location?.state?.data?.description !== content;
        setFormChanged(isFormChanged);
        console.log(isFormChanged);
    }, [documentPackage, content, selectedDocuments]);

    const styles = {
        'opacity': formChanged ? '1' : '0.6',
        'backgroundColor': '#7CC149',
        'color': '#fff'
    };
    useEffect(() => {
        getForms();
        getstates();
      }, []);
      const getstates = async () => {
        getStates().then(function (response) {
            if (response?.result) {
                const stateOptions = response.result.map(state => ({ label: state, value: state }));
               setStates(stateOptions);
               console.log(response?.result);
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
    };

    const getForms = (form) => {
        // if(type!=="view")
            dispatch(showLoader());
        getFormTemplates().then(function (response) {
            if (response?.result) {
                console.log("form temp===?",response?.result);
               const transformedResult = response?.result?.data.map(item => ({
                    value: item.templateid,
                    label: item.name
                  }));
                setDocumentsToSelect(transformedResult)
                dispatch(hideLoader());
            }
            }).catch((err) => {
                dispatch(hideLoader());
              console.error.bind("my account error", err);
            })
      }

    const handleChange = (e) => {
        setDocumentPackage((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSelectChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setSelectedDocuments(selectedValues);
    };

    useEffect(() => {
        // console.log('documentPackage --->',documentPackage)
      }, [documentPackage]);
      const fieldsToCheck = 
      {
          "packagename": "packagename",
          "state": "state",
          "loantype": "loantype",
          "propertyType": "programType",
          "specificborrower": "specificborrower",
          "statestandaloneguaranty": "statestandaloneguaranty",
          "statepromissorynote": "statepromissorynote",
          "statespousalconsent": "statespousalconsent",
          "loanfund": "loanfund",
          "interestreserves": "interestreserves"
      };
      const handleTemplateSave = () => {
        let error = {}
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            if (!documentPackage[fieldName].trim()) {
                error[fieldName] = `Please Enter ${fieldLabel} `;
            }
        };
        setErrors(error);
        console.log('error----->',error);

        if (Object.keys(error).length === 0) {
            dispatch(showLoader());
            let apiData = {...documentPackage, 'description' : content, 'statesaddtopackage' : selectedDocuments}
            apiData['packagename'] = apiData?.packagename?.trim();
            addFormPackage(apiData).then(function (response) {
                console.log('Add Package response', response);
                dispatch(hideLoader());
                if(response.result === 'Document Package modified successfully'){
                setMessage('Created Package Successfully');
                setConfirmation(true);
                }else if(response.result === 'package name already exists'){
                    setMessage('Package Name Already Exists');
                    setConfirmation(true);
                }
            }).catch((err) => {
                console.error.bind("create note templates  error", err);
                dispatch(hideLoader());
            })
        }
     };

     const handleTemplateUpdate = () => {
         let error = {}
         for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
             if (!documentPackage[fieldName].trim()) {
                 error[fieldName] = `Please Enter ${fieldLabel} `;
              }
          };
          setErrors(error);
         console.log('error----->',error);
         if (Object.keys(error).length === 0) {
             dispatch(showLoader());
             let apiData = {...documentPackage, 'description' : content, 'statesaddtopackage' : selectedDocuments};
             if(apiData?.packagename === location?.state?.data?.packagename){
                delete apiData['packagename']
             }else{
                apiData['packagename'] = apiData?.packagename?.trim();
             }
             updateFormPackages(apiData).then(function (response) {
                 console.log('Update Template  response', response);
                 if(response.result === 'Document Package modified successfully'){
                    
                     setMessage('Updated Package Successfully');
                     setConfirmation(true);
                  }else if(response.result === 'package name already exists'){
                    
                    setMessage('Package Name Already Exists');
                    setConfirmation(true);
                    }
                    dispatch(hideLoader());
             }).catch((err) => {
                 console.error.bind("create note templates  error", err);
                 dispatch(hideLoader());
             })
         }
    }

     
      const [documentsToSelect , setDocumentsToSelect] = useState(
        
    //     [
    //     { value: "2911", label: "02.2 GA - Lien Note - Draw Non-Dutch w/ IR - Indiv 4" },
    //     { value: "1265", label: "0 - Test Mapping" },
    //     { value: "2234", label: "01.0 All States - Closing Instructions - Draw w/ IR - Indiv" },
    //     { value: "2835", label: "01.0 GA - Closing Instructions - Draw w/ IR - Indiv" },
    //     { value: "2235", label: "01.1 All States - Closing Instructions - Draw - Corp" },
    //     { value: "2229", label: "01.1 All States - Closing Instructions - Draw - Indiv" },
    //     { value: "2236", label: "01.1 All States - Closing Instructions - Draw w/ IR - Corp" },
    //     { value: "1147", label: "01.1 Closing Instructions DRAW - INDIV" },
    //     { value: "1028", label: "01.1 Closing Instructions DRAW - TX" },
    //     { value: "1459", label: "01.1 Closing Instructions Draw w IR - Corp" },
    //     { value: "2952", label: "01.1 FL - Closing Instructions - Draw - Corp" },
    //     { value: "2953", label: "01.1 FL - Closing Instructions - Draw - Indiv" },
    //     { value: "2954", label: "01.1 FL - Closing Instructions - Draw w/ IR - Corp" },
    //     { value: "2955", label: "01.1 FL - Closing Instructions - Draw w/ IR - Indiv" },
    //     { value: "2824", label: "01.1 GA - Closing Instructions - Draw - Corp (selected)"},
    //     { value: "2825", label: "01.1 GA - Closing Instructions - Draw - Indiv" },
    //     { value: "2826", label: "01.1 GA - Closing Instructions - Draw w/ IR - Corp" },
    //     { value: "2823", label: "01.1 GA - Closing Instructions - Draw w/ IR - Indiv" },
    //     { value: "3596", label: "01.1 PA - Closing Instructions - Draw - Corp" },
    //     { value: "3607", label: "01.1 PA - Closing Instructions - Draw - Indiv" },
    //     { value: "3608", label: "01.1 PA - Closing Instructions - Draw w/ IR - Corp" },
    //     { value: "3609", label: "01.1 PA - Closing Instructions - Draw w/ IR - Indiv" },
    //   ]
    
    );
    //   const [propertyStates, setPropertyStates] = useState([
    //     {"label": "Alabama", "value": "Alabama"},
    //     {"label": "Alaska", "value": "Alaska"},
    //     {"label": "Arizona", "value": "Arizona"},
    //     {"label": "Arkansas", "value": "Arkansas"},
    //     {"label": "California", "value": "California"},
    //     {"label": "Colorado", "value": "Colorado"},
    //     {"label": "Connecticut", "value": "Connecticut"},
    //     {"label": "Delaware", "value": "Delaware"},
    //     {"label": "District of Columbia", "value": "District of Columbia"},
    //     {"label": "Florida", "value": "Florida"},
    //     {"label": "Georgia", "value": "Georgia"},
    //     {"label": "Hawaii", "value": "Hawaii"},
    //     {"label": "Idaho", "value": "Idaho"},
    //     {"label": "Illinois", "value": "Illinois"},
    //     {"label": "Indiana", "value": "Indiana"},
    //     {"label": "Iowa", "value": "Iowa"},
    //     {"label": "Kansas", "value": "Kansas"},
    //     {"label": "Kentucky", "value": "Kentucky"},
    //     {"label": "Louisiana", "value": "Louisiana"},
    //     {"label": "Maine", "value": "Maine"},
    //     {"label": "Maryland", "value": "Maryland"},
    //     {"label": "Massachusetts", "value": "Massachusetts"},
    //     {"label": "Michigan", "value": "Michigan"},
    //     {"label": "Minnesota", "value": "Minnesota"},
    //     {"label": "Mississippi", "value": "Mississippi"},
    //     {"label": "Missouri", "value": "Missouri"},
    //     {"label": "Montana", "value": "Montana"},
    //     {"label": "Nebraska", "value": "Nebraska"},
    //     {"label": "Nevada", "value": "Nevada"},
    //     {"label": "New Hampshire", "value": "New Hampshire"},
    //     {"label": "New Jersey", "value": "New Jersey"},
    //     {"label": "New Mexico", "value": "New Mexico"},
    //     {"label": "New York", "value": "New York"},
    //     {"label": "North Carolina", "value": "North Carolina"},
    //     {"label": "North Dakota", "value": "North Dakota"},
    //     {"label": "Ohio", "value": "Ohio"},
    //     {"label": "Oklahoma", "value": "Oklahoma"},
    //     {"label": "Oregon", "value": "Oregon"},
    //     {"label": "Pennsylvania", "value": "Pennsylvania"},
    //     {"label": "Rhode Island", "value": "Rhode Island"},
    //     {"label": "South Carolina", "value": "South Carolina"},
    //     {"label": "South Dakota", "value": "South Dakota"},
    //     {"label": "Tennessee", "value": "Tennessee"},
    //     {"label": "Texas", "value": "Texas"},
    //     {"label": "Utah", "value": "Utah"},
    //     {"label": "Vermont", "value": "Vermont"},
    //     {"label": "Virginia", "value": "Virginia"},
    //     {"label": "Washington", "value": "Washington"},
    //     {"label": "West Virginia", "value": "West Virginia"},
    //     {"label": "Wisconsin", "value": "Wisconsin"},
    //     {"label": "Wyoming", "value": "Wyoming"},
    //     {"label": "American Samoa", "value": "American Samoa"},
    //     {"label": "Federated States of Micronesia", "value": "Federated States of Micronesia"},
    //     {"label": "Guam", "value": "Guam"},
    //     {"label": "Marshall Islands", "value": "Marshall Islands"},
    //     {"label": "Northern Mariana Islands", "value": "Northern Mariana Islands"},
    //     {"label": "Palau", "value": "Palau"},
    //     {"label": "Puerto Rico", "value": "Puerto Rico"},
    //     {"label": "U.S. Minor Outlying Islands", "value": "U.S. Minor Outlying Islands"},
    //     {"label": "U.S. Virgin Islands", "value": "U.S. Virgin Islands"}
    // ]);

    const optionsData = {
        state: {label: 'State Property is Located in', options: states },
        loantype: { label: 'Loan Type', options: ['Acquisition', 'Refinance', 'Acquisition/Rehab'] },
        propertyType: {   label: 'Property Type',options: [ 'Single Family', 'Townhome', 'Condominium', '5+ Unit', '2 Unit', '3 Unit', '4 Unit', 'Lot/New Construction' ] },
        specificborrower: {label: 'Specific Borrower',options: ['None','Gary Dragul'] },
        statestandaloneguaranty: {  label: 'Does State Have Standalone Guaranty?',options: ['Yes', 'No']   },
        statepromissorynote: {label: 'State Has Promissory Note?',options: ['Yes', 'No'] },
        statespousalconsent: {   label: 'State Requires Spousal Consent?',   options: ['Yes', 'No'] },
        loanfund: {label: 'Loan Funded as Disbursed?',options: ['Yes', 'No']},
        interestreserves: { label: 'Does Loan have Interest Reserves?', options: ['Yes', 'No'] }
    };
    const handleFocus = key => {
        setErrors({
            ...errors,
            [key]: null
        });
    };

    const handleDelete =()=>{
             setShowConfirmation(true);
    }

    const handleDeleteTemplate = ()=>{
        let apiData = {...documentPackage, 'description' : content, 'statesaddtopackage' : selectedDocuments}
        console.log('termSheetsData',documentPackage);
        setShowConfirmation(false);
        dispatch(showLoader());
        deletePackage(apiData).then(function (response) {
          console.log('response delete',response);
          if(response.result === 'deleted successfully'){
            dispatch(hideLoader());
            setMessage('Deleted Successfully');
            setConfirmation(true);
          }
          }).catch((err) => {
            dispatch(hideLoader());
            navigate('/FormPackages');
            console.error.bind("my account error", err);
          })
    }

    const handleChangeValues = (value) => {
        const selectedValue = value;
    
        // Update formVariables by appending the selected value to the array
        setFormVariables((prevFormVariables) => {
            // Check if selectedValue is already in the array to avoid duplicates
            if (!prevFormVariables.includes(selectedValue)) {
                return [...prevFormVariables, selectedValue];
            }
            return prevFormVariables; // Return the same array if the value already exists
        });
    
        // Update formValuesOption with the selected value
        setFormValuesOption(selectedValue);
        setValuesDependency(valueDependency + 1);
        // Uncomment if you want to prepend the selected value to the template
        // setTemplate((prevContent) => selectedValue + prevContent);
    };

    const closePopup = () => {
        if(message.includes('Successfully')){
            setConfirmation(false);
            navigate('/FormPackages');
        }else{

        }
        setConfirmation(false);
    };
    

    return (
        <div id="layout-wrapper">
             {loading ? <Loader /> : null}
            {confirmation && (
                <PopupMessage
                type={message.includes('Successfully') ? 'success' : "warning"} 
                    message={message}
                    onClose={closePopup}
                />
            )}
 {showConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={handleDeleteTemplate}
                onCancel={()=>setShowConfirmation(false)}
                focusCancel={true}
            >
                    <p>Do you really want to delete this item?</p>
            </SweetAlert>}
           <MainHeader />


            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management add_default">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0 font-size-18">Document Package</h4>
                            <a href="#" id="navigateFormPackageBack" onClick={(e) => {e.preventDefault(); navigate(-1);}} className="back_btn"><span className="material-icons icon"> arrow_back
                                    </span>BACK</a>
                            </div>
                            <div className="card">
                                <div className="card-body ">
                                    <div className="form_section">
                                        <h3 className="title">{type === 'update' ? 'Update Document Package' : type === "view" ? 'View Document Package': 'Add a New Document Package'}</h3>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Package Name</label>
                                                    <input id="formPackageName" type="text" placeholder="Enter" disabled={type==="view"} value={documentPackage?.packagename} onFocus={() => { setErrors({ ...errors, packagename: null }) }} name='packagename' onChange={handleChange} className={`form-control ${errors.packagename && 'invalid-input'}`} tabIndex={1}/>
                                                </div>
                                            </div>
                                            <ValuesDropdown onChange={handleChangeValues}/>
                                            <div className="col-md-9">
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                <EditorEdit content={content} disabled={type==="view"} ReadOnlyflag={type==="view"? true : false} setContent={setContent} editorValidate = {editorValidate} setEditorValidate = {setEditorValidate} formVariables={formVariables} formValuesOption={formValuesOption} valueDependency = {valueDependency}/>
                                                </div>

                                            </div>
                                            <div className="col-md-6">
                                            </div>
                                            {Object.keys(optionsData).map((key, index) => (
                                                   <div className="col-md-3" key={key}>
                                                       <div className="form-group">
                                                           <label>{optionsData[key].label}</label>
                                                           <select
                                                           disabled={type==="view"} 
                                                               id={`formPackage-${key}`}
                                                               name={key}
                                                               value={documentPackage?.[key]}
                                                               onChange={handleChange}
                                                               onFocus={() => handleFocus(key)}
                                                               className={`form-control ${errors[key] && 'invalid-input'}`}
                                                               tabIndex={index + 2}                                                
                                                           >
                                                               <option value="">Select</option>
                                                               {optionsData[key].options.map(option => (
                                                                   typeof option === 'string'
                                                                       ? <option key={option} value={option}>{option}</option>
                                                                       : <option key={option.value} value={option.value}>{option.label}</option>
                                                               ))}
                                                           </select>
                                                       </div>
                                                 </div>
                                             ))}

                                        </div>
                                        <hr className="hr" />
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Select documents to add  to package</label>
                                                    <Select
                                                    
                                                    isDisabled={type==="view"} 
                                                    options={documentsToSelect}
                                                    isMulti
                                                    value={documentsToSelect?.filter(option => selectedDocuments?.includes(option.value))}
                                                    className={`w-100`}  
                                                    id="w3review"
                                                    onChange={handleSelectChange}
                                                    name='statesaddtopackage'
                                                   
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {type!="view" && <>
                                        {type==='add' && (<div className="btn-group mt_10">
                                            <button id="saveNewFormPackage"  onClick={(e)=>{handleTemplateSave()}} className="fs_btn">Save New Package</button>
                                        </div>) } 
                                        
                                        {type==='update' && (<><div className="btn-group mt_10">
                                            <button  id="deleteFormPackage" onClick={(e)=>{handleDelete()}} className="fs_btn mr_10">Delete</button>
                                        </div>
                                        <div className="btn-group mt_10">
                                            <button id="updateFormPackage"  onClick={(e)=>{handleTemplateUpdate()}} disabled={!formChanged} style={styles} className="fs_btn" >Update Package</button>
                                        </div></>)}
                                        </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal popup fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Checklist</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div className="modal-body">

                                    <div className="form-group">
                                        <label>Line Item name</label>
                                        <div className="dsp_flx">
                                            <input type="label" placeholder="Enter" className="form-control" />
                                            <button type="button" className="fs_btn ml_20">Add</button>
                                        </div>
                                    </div>

                                    <p><b>Checklist</b> <br />
                                        Begin creating your checklist by typing the <span className="color">Line item name
                                        </span> and clicking<span className="color"> Add</span> button.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="modal message_popup popup fade" id="messagepopup" tabindex="-1"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">

                                <div className="modal-body">
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                    <span className="material-symbols-outlined icon"> delete </span>
                                    <h3>Delete</h3>
                                    <p>This action cannot be undone. <br />
                                        Do you want to continue?</p>
                                    <button type="button" className="fs_btn">Yes</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default CreatePackage;