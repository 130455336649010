import React from 'react';
import { useSelector} from 'react-redux';
const PipelineFooter = () => {
   
    const configdata = useSelector(state => state.configdata);
    const replaceEasyStreetCapital = configdata?.admin?.rights?.replace(/Easy Street Capital LLC/g,"easystreetcapital.com");

    return (
        
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-sm-12">
                            <div className="text-sm-end d-none d-sm-block">
                            {/* {configdata?.admin?.rights} */}
                            {replaceEasyStreetCapital}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
    );
};

export default PipelineFooter;