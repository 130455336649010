import React from 'react';
const Loader = () => {


  return (
    <div className='loader_block'>
      <div className="loader">
      </div>
    </div>

  );
};

export default Loader;
