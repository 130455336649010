import React, { createContext, useState, useContext, useRef } from "react";

// Create the Pipeline context
export const PipelineContext = createContext();

// Custom hook to access the Pipeline context
export const usePipeline = () => useContext(PipelineContext);

// Provider to store Pipeline state
export const PipelineProvider = ({ children }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [route, setRoute] = useState("");  // Track route changes
    const prevRouteRef = useRef();
    const[advFormData,setAdvFormData]=useState({});
    const [advFlag, setAdvFlag] = useState(false);
    const [showPage, setShowPage] = useState('');
    const [advanceNameSearch, setAdvanceNameSearch] = useState('');
    const [filteredStage, setFilteredStage] = useState('Account Executive Review');
    const [filteredLoans, setfilteredLoans] = useState([]);
    const [sortObj, setSortObj] = useState({});
    const [getTaskData, setGetTaskData] = useState({});

    function usePreviousRoute() {
        React.useEffect(() => {
            prevRouteRef.current = route;
        }, [route]);

        return prevRouteRef.current;
    }

    return (
        <PipelineContext.Provider value={{ currentPage, setCurrentPage, perPage, setPerPage, route, setRoute, usePreviousRoute,advFormData,setAdvFormData ,advFlag, setAdvFlag,showPage, sortObj, setSortObj,setShowPage,advanceNameSearch, setAdvanceNameSearch,filteredStage, setFilteredStage,filteredLoans, setfilteredLoans,getTaskData, setGetTaskData}}>
            {children}
        </PipelineContext.Provider>
    );
};
