import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import {getFormPackage,getFormTemplates} from "../utils/reducer";
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Loader';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import HomeFooter from './HomeFooter';
const FormPackages = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loaderVisible);
    const [allFormPackages, setAllFormPackages] = useState([]);
    const [documentsToSelect , setDocumentsToSelect] = useState([]);


      useEffect(() => {
        getForms();
        dispatch(showLoader());
        getFormPackage().then(function (response) {
            console.log('response---->',response);
          if (response?.result) {
            setAllFormPackages(response?.result);

          }
          dispatch(hideLoader());
          }).catch((err) => {
            console.error.bind("my account error", err);
            dispatch(hideLoader());
          })
      }, []);
      const editSheet = (data) => {
        console.log('data',data)
        navigate('/createpackage', { state: { data ,typeOfPage:'edit'} });
      };
      const viewSheet = (data) => {
        console.log('data',data)
        navigate('/createpackage', { state: { data ,typeOfPage:'view'} });
      };
      const getForms = (form) => {
        getFormTemplates().then(function (response) {
            if (response?.result) {
                console.log("form temp",response?.result);
               const transformedResult = response?.result?.data.map(item => ({
                    value: item.templateid,
                    label: item.name
                  }));
                setDocumentsToSelect(transformedResult)
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
      }
      console.log('documentsToSelect',documentsToSelect);

    return (
        <div id="layout-wrapper">
            {loading ? <Loader /> : null}

           <MainHeader />


            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0 font-size-18">Form Packages 
                                <button id="createFormPackages" type="button"
                                        className="border_btn" onClick={(e) => {navigate('/createpackage'); }}>Create</button> </h4>
                            </div>
                                <div className='card '>
                                    <div className='card-body'>
                                        <div className="table-responsive form_package">
                                            <table className="table db_table">
                                                <thead className="table-light">
                                                    <tr> 
                                                        <th width="17%">Package Name</th>
                                                        <th width="10%">Loan Type</th>
                                                        <th width="10%">Property State</th>
                                                        <th width="10%">Property Type</th>
                                                        <th width="8%">State Guaranty</th>
                                                        <th width="12%">Required Spousal Consent</th>
                                                        <th width="8%">Funded As Disbursed</th>
                                                        <th width="10%">Loan Has Reserves</th>
                                                        <th width="10%">Documents</th>
                                                        <th width="5%">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {allFormPackages.map((data,index)=>{
                                                        return(
                                                   
                                                            <tr key={index}>
                                                            <td>{data?.packagename}</td>
                                                            <td>{data?.loantype}</td>
                                                            <td>{data?.state}</td>
                                                            <td>{data?.propertyType}</td>
                                                            <td>{data?.statestandaloneguaranty}</td>
                                                            <td>{data?.statespousalconsent}</td>
                                                            <td>{data?.loanfund}</td>
                                                            <td>Yes</td>
                                                            <td>
                                                                <div className="btn-group">
                                                                    <button id={`viewAttachedDoc-${index}`} className="border_btn" disabled={!data?.statesaddtopackage || data.statesaddtopackage.length === 0}  type="button" data-bs-toggle="dropdown"
                                                                        aria-expanded="false">
                                                                        View <i className="mdi mdi-chevron-down"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu">
                                                                   
                                                                    {documentsToSelect.filter(option => data?.statesaddtopackage?.includes(option.value))?.map((doc,ind)=>{
                                                                       
                                                                        return(
                                                                        <div className="dropdown-item"> <span
                                                                        className="material-symbols-outlined icon"> description
                                                                    </span> {doc?.label} </div>
                                                                       )})}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="btn-group">
                                                                    <button  id={`editFormPackage-${index}`} onClick={()=>editSheet(data)} type="button" className="btn btn-outline-secondary"><span
                                                                        className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Edit</span> edit
                                                                    </span></button>
                                                                    <button id={`viewFormPackage-${index}`} onClick={()=>viewSheet(data)} type="button" className="btn btn-outline-secondary"
                                                                       
                                                                        data-bs-whatever="@mdo"><span
                                                                            className="material-symbols-outlined icon tooltip-container"><span className="tooltip">View</span> visibility
                                                                        </span></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        )})}
                                                   
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                              {/* {allFormPackages.map((data,index)=>{
                                  return(
                                    <div className="card package" key={index}>
                                <div className="card-body">
                                           
                                    <div className="title_block">
                                        <h2 className="title">Package Name <span className="sub"> : {data?.packagename}</span> </h2>
                                        <button type="button" onClick={()=>editSheet(data)} className="edit_btn"> <span
                                                className="material-symbols-outlined icon"> edit </span> Edit</button>
                                    </div>
                                    <div className="package_info">
                                        <div className="block">
                                            <div className="form-group">
                                                <label>Loan Type</label>
                                                <p>{data?.loantype}</p>
                                            </div>
                                        </div>
                                        <div className="block">
                                            <div className="form-group">
                                                <label>Property State</label>
                                                <p>{data?.state}</p>
                                            </div>
                                        </div>
                                        <div className="block">
                                            <div className="form-group">
                                                <label>Property Type</label>
                                                <p>{data?.propertyType}</p>
                                            </div>
                                        </div>
                                        <div className="block">
                                            <div className="form-group">
                                                <label>State Guaranty</label>
                                                <p>{data?.statestandaloneguaranty}</p>
                                            </div>
                                        </div>
                                        <div className="block">
                                            <div className="form-group">
                                                <label>Required Spousal Consent</label>
                                                <p>{data?.statespousalconsent}</p>
                                            </div>
                                        </div>
                                        <div className="block">
                                            <div className="form-group">
                                                <label>Funded As Disbursed</label>
                                                <p>{data?.loanfund}</p>
                                            </div>
                                        </div>
                                        <div className="block">
                                            <div className="form-group">
                                                <label>Loan Has Reserves</label>
                                                <p>{data?.interestreserves}</p>
                                            </div>
                                        </div>
                                        <div className="block">
                                            <div className="form-group">
                                                <label>Particular Borrower</label>
                                                <p>{data?.specificborrower}</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="form-group">
                                        <label>Loan Type</label>
                                    </div>
                                    <div className="package_info cc">

                                        <div className="block">

                                            <button type="button" className="pkg_btn"> <span
                                                    className="material-symbols-outlined icon"> description </span> {data?.loantype}</button>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                                  )
                              })} */}
                            
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default FormPackages;