import React , { useState ,useEffect } from 'react';
import MainHeader from './MainHeader';
import SideMenu from './SideMenu';
import HomeFooter from './HomeFooter';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';
import Loader from './Loader';
import PopupMessage from './PopupMessage';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { getAllFundManagmentAdmin , getAllInvestors, investorStatements} from '../utils/reducer';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import * as XLSX from "xlsx";
import EditorEdit from './EditorEdit.js';

const AdminInvestorStatements = () => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loaderVisible);
    const navigate = useNavigate();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [message , setMessage] = useState('Updated Successfully');
    const [fundsDropdown,setFundsDropdown] = useState([]);
    const [investorFundDropdown,setInvestorFundDropdown] = useState([]);
    const [singleInvestorData,setSingleInvestorData] = useState({});
    const [bulkInvestorData,setBulkInvestorData] = useState({});
    const [singleInvestorErrors,setSingleInvestorErrors] = useState({});
    const [bulkInvestorErrors,setBulkInvestorErrors] = useState({});
    const [content, setContent] = useState('');
    const [totalcount,setTotalCount] = useState(null);
    const [currentPage,setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(15);
    const [fundId,setFundId] = useState(null);//uuid
    const [investorId,setInvestorId] = useState(null);
    let singleErrors = {};
    let bulkErrors = {};

    const investorOptions = [
        { value: "1504", label: "Easy Street Admin" },
        { value: "1510", label: "Stephen Hagerman" },
        { value: "1511", label: "Ashton Matthias" },
        { value: "1512", label: "Steven and Brooke Malfatto" },
        { value: "1517", label: "Kingdom Trust FBO Michael Denton Traditional IRA" },
        { value: "1518", label: "Richard Hagerman" },
        { value: "1519", label: "Erik and Tasha Wahl" }
    ];

    const bulkInvestorSample = [
        {
            pulledDate: "4th March, 2024",
            pulledBy: "Casey Denton",
            status: "Complete",
            uploadToBucketFailed: "32 Investors Failed To Pull. Please See The Text File Included In The Pull For The Missing Investors. File Not Found, Please Choose Another Pull Or Run The Pull Again."
        },
        {
            pulledDate: "4th March, 2024",
            pulledBy: "Casey Denton",
            status: "Complete",
            uploadToBucketFailed: "32 Investors Failed To Pull. Please See The Text File Included In The Pull For The Missing Investors. File Not Found, Please Choose Another Pull Or Run The Pull Again."
        },
        {
            pulledDate: "4th March, 2024",
            pulledBy: "Casey Denton",
            status: "Complete",
            uploadToBucketFailed: "32 Investors Failed To Pull. Please See The Text File Included In The Pull For The Missing Investors. File Not Found, Please Choose Another Pull Or Run The Pull Again."
        },
        {
            pulledDate: "4th March, 2024",
            pulledBy: "Casey Denton",
            status: "Complete",
            uploadToBucketFailed: "32 Investors Failed To Pull. Please See The Text File Included In The Pull For The Missing Investors. File Not Found, Please Choose Another Pull Or Run The Pull Again."
        },
        // Add more entries as needed
    ];

    const columnWidth = '20%'; // Adjust the width for each column as needed

    const columns = [
        {
            name: '#',
            selector: (row, index) => (currentPage - 1) * perPage + index + 1,
            // width: '10%', 
            minWidth: '4%',
            maxWidth: '4%',
        },
        {
            name: 'Pulled Date',
            selector: (row) => row?.pulledDate, // Matches the "Pulled Date" column
            sortable: true,
            minWidth: '13%',
            maxWidth: '13%',
        },
        {
            name: 'Pulled By',
            selector: (row) => row?.pulledBy, // Matches the "Pulled By" column
           sortable: true,
            minWidth: '15%',
            maxWidth: '15%',
        },
        {
            name: 'Status',
            selector: (row) => row?.status, // Matches the "Status" column
            sortable: true,
            minWidth: '13%',
            maxWidth: '13%',
        },
        {
            name: 'Upload to Bucket Failed',
            selector: (row) => row?.uploadToBucketFailed, // Matches the "Upload to Bucket Failed" column
            minWidth: '46%',
            maxWidth: '46%',
            sortable: false, // Assuming this column is not sortable
            cell: (row) => (
                <span className="truncate-text" title={row?.uploadToBucketFailed} style={{color : 'red'}}>
                    {row?.uploadToBucketFailed}
                </span>
            ),
        },
        {
            name: 'Actions',
            minWidth: '9%',
            maxWidth: '9%',
            cell: (row, index) => (
                <>
                    <button id={`editRecord-${index}`} type="button" onClick={() => editSheet(row)} className="icon_btn edit">
                        <span style={{ color: '#444', fontSize: '16px' }} className="material-icons icon tooltip-container">
                            <span className="tooltip">Edit</span>edit
                        </span>
                    </button>
                    <button id={`deleteRecord-${index}`} type="button" onClick={() => deleteSheet(row)} className="icon_btn delete">
                        <span style={{ color: '#444', fontSize: '16px' }} className="material-icons icon tooltip-container">
                            <span className="tooltip">Delete</span>delete
                        </span>
                    </button>
                </>
            ),
            // width: "10%", 
        },
    ];

    

    useEffect(() => {
        getFundsName()
    }, []);

    const getFundsName =()=>{
        dispatch(showLoader());
        getAllFundManagmentAdmin()
        .then(function(response) {
            console.log('funds name',response)
            if (response?.result) {
                setFundsDropdown(response?.result?.data);
            }
            dispatch(hideLoader());
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
        
    }

    useEffect(() => {
        if(fundsDropdown?.length > 0){
            setSingleInvestorData({
                ...singleInvestorData,
                ["name"] : "Easy Street Capital LLC"
            });
            setBulkInvestorData({
                ...bulkInvestorData,
                ["name"] : "Easy Street Capital LLC"
            });
        }
    },[fundsDropdown])

    const handleTab = (param) => {
        if(param === "singleinvestor") {
            setSingleInvestorErrors({});
        }else if(param === "bulkinvestor") {
            setBulkInvestorErrors({});
        }
    }

    //call API and set value in investor fund dropdown
    const getInvestorFunds = () => {
        dispatch(showLoader());
        getAllInvestors(0,0,singleInvestorData?.name)
            .then(function(response) {
            console.log('funds name',response)
            if (response?.result?.data?.length > 0) {
                setInvestorFundDropdown(response?.result?.data);
            }
            dispatch(hideLoader());
        })
            .catch((err) => {
                console.error("error", err); 
                dispatch(hideLoader());
        });
       
       
    }

    useEffect(() => {
        if(singleInvestorData?.name){
            console.log("calling get investor funds api");
            getInvestorFunds();
        }else{
            setInvestorFundDropdown([]);
        }
    },[singleInvestorData?.name])

    const handleChange = (e,param) => {
        let name = e.target.name;
        let value = e.target.value;
        let type = e.target.type;

        // Check if the input type is "date" and format it
        //if (type === "date") {
            // const date = new Date(value);
            // value = date.toLocaleDateString("en-US", {
            //     year: 'numeric',
            //     month: '2-digit',
            //     day: '2-digit'
            // }).replace(/\//g, "-"); // Format to MM-dd-yyyy
            
            // // Store in ISO format (yyyy-MM-dd) for compatibility with input type="date"
            // value = date.toISOString().split('T')[0];
            //value = moment.utc(value).format("MM-DD-YYYY");
        //}

        if(param == "singleinvestor"){
            setSingleInvestorData((prevData) => ({
                ...prevData,
                [name] : value
            }));
        }else{
            setBulkInvestorData((prevData) => ({
                ...prevData,
                [name] : value
            }));
        }

        // Set fundId based on selected fund name
        if (name === "name") {
            const selectedFund = fundsDropdown.find((fund) => fund.name === value);
            if (selectedFund) {
                setFundId(selectedFund.uuid); // Update fundId with uuid
            } else {
                setFundId(null); // Reset if no match is found
            }
        }else if(name === "investorFund"){
            const selectedFund = investorFundDropdown.find(
                (fund) => `${fund.firstname} ${fund.lastname}` === value
            );
            if (selectedFund) {
                setInvestorId(selectedFund.investorid); // Update fundId with uuid
            } else {
                setInvestorId(null); // Reset if no match is found
            }
        }
        
    }

    const handleFocus = (e,param) => {
        let name = e.target.name;
       

        if(param === "singleinvestor"){
            setSingleInvestorErrors((prevErrors) => ({
                ...prevErrors,
                [name] : null
            }));
        }else if(param === "bulkinvestor"){
            setBulkInvestorErrors((prevErrors) => ({
                ...prevErrors,
                [name] : null
            }));
        }

        
    }

    const handleValidation = (param) => {
        singleErrors = {};
        const singleInvestorFields = ["name","startdate","enddate","investorFund"];
        bulkErrors = {};
        const bulkInvestorFields = ["name","pullstartdate","pullenddate"];
        
        if(param === "singleinvestor"){
            singleInvestorFields?.forEach((field) => {
                if(!singleInvestorData[field]){
                    singleErrors[field] = "Required";
                }
            })
            setSingleInvestorErrors(singleErrors);
            return Object.keys(singleErrors)?.length === 0;
        }else if(param === "bulkinvestor"){
            bulkInvestorFields?.forEach((field) => {
                if(!bulkInvestorData[field]){
                    bulkErrors[field] = "Required";
                }
            })
            setBulkInvestorErrors(bulkErrors);
            return Object.keys(bulkErrors)?.length === 0;
        }

        return true;
       
    }

    /* Single Investor - start */

    useEffect(() => {
        console.log('quillValuequillValue',singleInvestorData)
        setSingleInvestorData((prevData) => ({
            ...prevData,
            ['template']: content,
        }));
        
    }, [content]);

    const handleGenerate = () => {
        const valid = handleValidation("singleinvestor");
        if(valid){
            dispatch(showLoader());
            investorStatements(singleInvestorData?.startdate,singleInvestorData?.enddate,fundId,investorId)
                .then(function(response) {
                console.log('funds name',response)
                if (response?.result) {
                    setPopupOpen(true);
                    setMessage("Generated Succesfully");
                    setContent(response?.result?.template);
                    console.log("singleinestor response",response?.result);
                }
                dispatch(hideLoader());
            })
                .catch((err) => {
                    console.error("error", err); 
                    dispatch(hideLoader());
            });
        }
    }

    
    /* single Investor End */

    /* bulk investor tab start */

    const handlePull = () => {
        const valid = handleValidation("bulkinvestor");
        if(valid){

        }
    }

    const editSheet = (data) => {
        console.log('data',data)
        //navigate('/createfundmanagement', { state: { fundId : data?.uuid,selectedfund: data, fundManagementData:fundManagementData , mode : "Edit" ,fundNames } });
    };
    
    const deleteSheet = (data) => {
        // setShowConfirmation(true);
        // setSelectedOption(data?.uuid);
        // console.log('data',data);
    };

    const handlePerPageChange = (newperPage,page) => {
        setPerPage(newperPage);
        setCurrentPage(page); // Reset to first page
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const customStyles = {
        //   headRow: {
      // 	style: {
        //         fontWeight: 'bold',
        //         backgroundColor: '#E9F0E6',
      // 	},
      // },
        headCells: {
        style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',
        
        },
      },
            table: {
          style: {
            border: '1px solid #CCD8F6', 
          },
        },
    };

    const handleExport = () => {
        const exportData = (bulkInvestorSample?.length > 0) ? bulkInvestorSample : [];

        if(exportData?.length > 0){
            // Prepare the worksheet data from the provided `data` array
            const worksheetData = exportData?.map((item) => ({
                "Pulled Date": item.pulledDate,
                "Pulled By": item.pulledBy,
                "Status": item.status,
                "Upload to Bucket Failed": item.uploadToBucketFailed,
                
            }));
            
            console.log('Exporting data:', worksheetData);
            
            // Create a new workbook
            const wb = XLSX.utils.book_new();
            // Create a worksheet with the prepared data
            const ws = XLSX.utils.json_to_sheet(worksheetData);

             // Set column widths to encourage text wrapping in Excel
            ws['!cols'] = [
                { wch: 15 },  // Width for "Pulled Date"
                { wch: 20 },  // Width for "Pulled By"
                { wch: 10 },  // Width for "Status"
                { wch: 10 },  // Width for empty column D
                { wch: 10 },  // Width for empty column E
                { wch: 10 }   // Width for "Upload to Bucket Failed" (column F) to allow wrapping
            ];

            // Append the worksheet to the workbook
            XLSX.utils.book_append_sheet(wb, ws, "Statements Log");
            
            // Define the filename based on the context
            const filename = "bulk_investors_statement.xlsx";
            // Export the workbook
            XLSX.writeFile(wb, filename);
            
        }

        
    }
    
    

    /* bulk investor tab end */

    const closePopup = () => {
        setPopupOpen(false);
        //navigate('/fundmanagement');
    };

    return (
        
        <div id="layout-wrapper">
            {loading ? <Loader/> : null} 
            {isPopupOpen && (
                <PopupMessage
                type={message === "Sheet Name or Program Type already Exists." ? 'warning' : 'success'}
                message={message}
                onClose={closePopup}
                />
            )}

          <MainHeader />
          <SideMenu />

            <div className="main-content">

            <div className="page-content user_management fund_summary investor_statement">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Investor Statement</h4>
                      
                            </div>
                            <div className="card">
                            <div className="card-body my_account">
                            
                            <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-bs-toggle="tab" href="#single" role="tab" onClick={() => handleTab("singleinvestor")}>
                                    <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                                    <span class="d-none d-sm-block">SINGLE investor statements</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link " data-bs-toggle="tab" href="#bulk" role="tab" onClick={() => handleTab("bulkinvestor")}>
                                    <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                                    <span class="d-none d-sm-block">BULK INVESTOR STATEMENTS</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link " data-bs-toggle="tab" href="#statementslog" role="tab" onClick={() => handleTab("statementslog")}>
                                    <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                                    <span class="d-none d-sm-block">STATEMENTS LOG</span>
                                </a>
                            </li>

                        </ul>


                        <div class="tab-content pt-15 text-muted">
                            <div class="tab-pane active" id="single" role="tabpanel">
                                <div className='search'>
                                    <div className="form-group">
                                        <label>Select a Fund</label>
                                        <select id="fundName" name="name"  tabindex="1" 
                                        className={`form-control ${singleInvestorErrors?.name && 'invalid-input'}`}
                                        onFocus={(e) => handleFocus(e,"singleinvestor")}
                                        onChange={(e) => handleChange(e,"singleinvestor")} value={singleInvestorData?.name}>
                                            <option value="">Select Fund</option>
                                            {fundsDropdown?.map((fundoption,index)=>{
                                                return(
                                                    <option key={index} value={fundoption?.name}>{fundoption?.name}</option>
                                                )
                                            })}
                                        
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Select a Start date</label>
                                        <input id="actionDateHmda" type="date" placeholder="mm-dd-yyy" name="startdate" tabindex="2" 
                                            className={`form-control ${singleInvestorErrors?.startdate && 'invalid-input'}`}
                                            onFocus={(e) => handleFocus(e,"singleinvestor")}
                                            onChange={(e) => handleChange(e ,"singleinvestor")} value={singleInvestorData?.startDate}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Select a End date</label>
                                        <input id="actionDateHmda" type="date" placeholder="mm-dd-yyy" name="enddate" tabindex="3" 
                                            className={`form-control ${singleInvestorErrors?.enddate && 'invalid-input'}`}
                                            onFocus={(e) => handleFocus(e,"singleinvestor")}
                                            onChange={(e) => handleChange(e ,"singleinvestor")} value={singleInvestorData?.startDate}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Select an Investor</label>
                                        <select id="prefillLoanProgram" name="investorFund" tabindex="4" 
                                        className={`form-control ${singleInvestorErrors?.investorFund && 'invalid-input'}`}
                                        onFocus={(e) => handleFocus(e,"singleinvestor")}
                                        onChange={(e) => handleChange(e,"singleinvestor")} value={singleInvestorData?.investorFund}>
                                            <option value="">Select Investor</option>
                                            {investorFundDropdown?.map((fundoption,index) => {
                                                return(
                                                    // <option key={index} value={fundoption?.firstname + fundoption?.lastname}>{fundoption?.firstname + fundoption?.lastname}</option>
                                                    <option key={index} value={`${fundoption.firstname} ${fundoption.lastname}`}>
                                                        {fundoption.firstname} {fundoption.lastname}
                                                    </option>
                                                )
                                            })}
                                            
                                        </select>
                                    </div>
                                    <div className="form-group generate">
                                        <label></label>
                                        <button type="button" className="create_btn " onClick={handleGenerate}>Generate <span className="material-symbols-outlined">arrow_outward</span></button>
                                    </div>
                                        
                                </div>
                                <hr className="hr"></hr>
                                <div className="md-container">
                                    <div className='search mt-4'>
                                        <h5 className="title mt-0 mb-0">Single investor statement</h5>
                                        <button id="" type="button" class="border_btn">Send Email<span className="material-symbols-outlined">mail</span></button>
                                    </div>
                                        {/* <img src="assets/images/investor-statement.png" class="img w-100" alt=""/> */}
                                        <EditorEdit content={content} setContent={setContent} name = "template" />
                                </div>
                            </div>
                            <div class="tab-pane bulk_investor" id="bulk" role="tabpanel">
                                <div className='search'>
                                    <div className="form-group">
                                        <label>Select a Fund</label>
                                        <select id="fundName" name="name"  tabindex="1" 
                                            className={`form-control ${bulkInvestorErrors?.name && 'invalid-input'}`}
                                            onFocus={(e) => handleFocus(e,"bulkinvestor")}
                                            onChange={(e) => handleChange(e,"bulkinvestor")} value={bulkInvestorData?.name}>
                                                <option value="">Select Fund</option>
                                                {fundsDropdown?.map((fundoption,index)=>{
                                                    return(
                                                        <option key={index} value={fundoption?.name}>{fundoption?.name}</option>
                                                    )
                                                })}
                                        
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Statement Pull Start Date</label>
                                        <input id="actionDateHmda" type="date" placeholder="mm-dd-yyy" name="pullstartdate" tabindex="2" 
                                            className={`form-control ${bulkInvestorErrors?.pullstartdate && 'invalid-input'}`}
                                            onFocus={(e) => handleFocus(e,"bulkinvestor")}
                                            onChange={(e) => handleChange(e ,"bulkinvestor")} value={bulkInvestorData?.pullstartdate}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Statement Pull End Date</label>
                                        <input id="actionDateHmda" type="date" placeholder="mm-dd-yyy" name="pullenddate" tabindex="2" 
                                            className={`form-control ${bulkInvestorErrors?.pullenddate && 'invalid-input'}`}
                                            onFocus={(e) => handleFocus(e,"bulkinvestor")}
                                            onChange={(e) => handleChange(e ,"bulkinvestor")} value={bulkInvestorData?.pullenddate}
                                        />
                                    </div>
                            
                                    <div className="form-group generate">
                                        <label></label>
                                        <button type="button" className="create_btn" onClick={handlePull}>Send Email Statements<span className="material-symbols-outlined">arrow_outward</span></button>
                                    </div>
                                    
                                </div>
                                {/* <hr className="hr"></hr> */}
                          
                                {/* <div className='search mt-4'>
                                    <h5 className="title mt-0 mb-0">STATEMENTS LOG</h5>
                                    
                                    <div className="d-flex align-items-center">
                                        <button id="" type="button" class="border_btn">Send Email<span className="material-symbols-outlined">mail</span></button>
                                        <button id="bulkInvestorExport" type="button" class="border_btn ms-2" onClick={handleExport}>Export<span className="material-symbols-outlined">arrow_outward</span></button>
                                        <button id="" type="button" class="border_btn ms-2">Refresh<span className="material-symbols-outlined">reply</span></button>
                                    </div>
                                </div> */}
                               
                           

                            </div>
                            <div class="tab-pane statements_log" id="statementslog" role="tabpanel">
                               
                          
                                <DataTable className='termsheetprefills_table'
                                columns={columns}
                                data={bulkInvestorSample}
                                pagination
                                paginationServer
                                // paginationTotalRows={(!searchTerm) ? totalcount : filteredFundData?.length}
                                paginationTotalRows={totalcount}
                                customStyles={customStyles}
                                paginationPerPage={perPage}
                                paginationRowsPerPageOptions={[15, 30, 45, 60,75,90]}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Per Page',
                                }}
                                persistTableHead
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handlePerPageChange}
                                id="prefillPagination"
                                    // sortServer={false}  // If you're handling sorting on the server-side, use this prop
                                    // onSort={handleSort}
                                /> 
                           

                            </div>

                        </div>
                  
                        </div>
                        </div>
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default AdminInvestorStatements;