export const servicingMenus = [
    { name: "Servicing Setup", route: "/servicingservicingsetup", icon: "settings" },
    { name: "Loan Summary", route: "/servicingloansummary", icon: "summarize" },
    { name: "Contacts", route: "/servicingcontacts", icon: "contacts"},
    { name: 'Tools And Workflow', route: '/servicingtoolsworkflow', icon: 'service_toolbox' },
    // { name: "Servicing Setup", route: "/servicingservicingsetup", icon: "settings" },
    { name: "Recurring Billing", route: "/servicesrecurringbills", icon: "repeat" },
    { name: "Fee Management", route: "/feemanagement", icon: "attach_money" },
    { name: "Payments", route: "/servicespayments", icon: "payment" },
    { name: "Loan Modifications", route: "/servicesloanmodification", icon: "settings" },
    { name: "Payoff", route: "/servicespayoff", icon: "paid" },
    { name: "Servicing History", route: "/servicinghistory", icon: "history" },
    { name: "Form 1098", route: "/servicesform1098", icon: "description" },
  ];

  export  const dealMenus = [
    { name: 'Loan Details', route: '/dealdetailedapp', icon: 'space_dashboard' },
    { name: 'Contacts', route: '/dealcontacts', icon: 'contacts' },
    { name: 'HMDA', route: '/dealhmda', icon: 'demography' },
    { name: 'Lead', route: '/deallead', icon: 'social_leaderboard' },
    { name: 'Underwriting Term Sheet', route: '/dealunderwritingtermsheet', icon: 'border_color' },
];

export const processingMenus = [
    { name: 'Forms', route: '/processingforms', icon: 'assignment' },
    { name: 'Credit', route: '/dealcredit', icon: 'payments' },
    { name: 'Files', route: '/dealfiles', icon: 'folder_copy' },
];

export const underwritingMenus = [
    { name: 'Borrower Exposure', route: '/borrowerexposure', icon: 'business_center' },
    { name: 'Underwriting Term Sheet', route: '/underwritingtermsheet', icon: 'assignment' },
    { name: 'Files', route: '/underwritingfiles', icon: 'folder_copy' },
    { name: 'Tools And Workflow', route: '/dealtoolsworkflow', icon: 'service_toolbox' },
];

export const closingMenus = [
    { name: 'Clear To Close', route: '/cleartoclose', icon: 'assignment' },
    { name: 'Fees', route: '/dealfees', icon: 'paid' },
    { name: 'Rate And Points', route: '/dealratepoints', icon: 'monitoring' },
    { name: 'Wire Instructions', route: '/instructions', icon: 'import_export' }
];
    
export const drawsMenus = [
    { name: 'Loan Summary', route: '/drawloansummary', icon: 'assignment' },
    { name: 'Order Draw Request', route: '/orderdrawrequest', icon: 'inventory' },
    // { name: 'Supporting Docs', route: '/supportingdocs', icon: 'description' },
    // { name: 'Draw Requests Status', route: '/drawrequestsstatus', icon: 'insert_drive_file' },
    // { name: 'Draw History', route: '/orderdrawhistory', icon: 'history' }
];