import React,{useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import InfoBar from '../InfoBar';
import Footer from '../Footer';
import StrategyInfo1 from './StrategyInfo1';
import StrategyInfo4 from './StrategyInfo4';
import { useLoanInfo } from '../../utils/loanInfoContext';
import { useUserType } from '../../utils/UserTypeContext';
const StrategyInfo = () => {
    const { useType, setUseType } = useUserType();
    console.log('useType',useType);
    let formElementsbrockerJson = [
        {
          "type": "buttonGroup",
          "label": "What is your source of down payment?",
          "options": [
            { "value": "Borrower Cash", "label": "Borrower Cash" ,"id" : "downPaymentBroker"},
            { "value": "2nd loan on Subject Project", "label": "2nd loan on Subject Project" ,"id" : "subjectProjectBroker"},
            { "value": "Outside Investors", "label": "Outside Investors" ,"id" : "outsideInvestorBroker"},
            { "value": "Other", "label": "Other" ,"id" : "otherPaymentBroker"}
          ],
          "name": "downpaymentsource"
        },

        {
            "type": "buttonGroup",
            "label": "What is your plan for paying off the loan?",
            "options": [
              { "value": "Sale", "label": "Sale" ,"id" : "saleBroker"},
              { "value": "Refinance", "label": "Refinance" ,"id" : "refinanceBroker"},
              { "value": "Other", "label": "Other" ,"id" : "otherLoanBroker"}
            ],
            "name": "payoffloan"
          },
          {
            "type": "buttonGroup",
            "label": "Does the borrower have any applicable licenses?",
            "options": [
              { "value": "General Contractor", "label": "General Contractor" ,"id" : "generalContractorBroker"},
              { "value": "Real Estate Broker/Agent", "label": "Real Estate Broker/Agent" ,"id" : "realEstateBroker"},
              { "value": "Not Applicable", "label": "Not Applicable" ,"id" : "notApplicableBroker" },
              { "value": "Other", "label": "Other" ,"id" : "otherLicenseBroker"}
            ],
            "name": "applicationlicense"
          },
          {
            "type": "textarea",
            "label": "What is the borrower's execution strategy?",
            "name": "executionstrategysummary",
            "description": "*REHAB/CONSTRUCTION PLAN, STRENGTHS/WEAKNESSES OF THE DEAL, BORROWER EXPERIENCE LEVEL/CREDIT, AND MISCELLANEOUS INFO* (PLEASE PROVIDE AS MUCH DETAIL AS POSSIBLE)",
            "id" : "executionStrategySummaryBroker"
          },
        
      ]
      let formElementsborrowerJson = [
        {
          "type": "buttonGroup",
          "label": "What is your source of down payment?",
          "options": [
            { "value": "Borrower Cash", "label": "Borrower Cash" ,"id" : "downPaymentBorrower"},
            { "value": "2nd loan on Subject Project", "label": "2nd loan on Subject Project" ,"id" : "subjectProjectBorrower"},
            { "value": "Outside Investors", "label": "Outside Investors" ,"id" : "outsideInvestorBorrower"},
            { "value": "Other", "label": "Other" ,"id" : "otherPaymentBorrower"}
          ],
          "name": "downpaymentsource"
        },
        {
            "type": "buttonGroup",
            "label": "What is your plan for paying off the loan?",
            "options": [
              { "value": "Sale", "label": "Sale" ,"id" : "saleBorrower"},
              { "value": "Refinance", "label": "Refinance" ,"id" : "refinanceBorrower"},
              { "value": "Other", "label": "Other" ,"id" : "otherLoanBorrower"}
            ],
            "name": "payoffloan"
          },
          {
            "type": "buttonGroup",
            "label": "Do you have any applicable licenses?",
            "options": [
              { "value": "General Contractor", "label": "General Contractor" ,"id" : "generalContractorBorrower" },
              { "value": "Real Estate Broker/Agent", "label": "Real Estate Broker/Agent" ,"id" : "realEstateBorrower"},
              { "value": "Not Applicable", "label": "Not Applicable" ,"id" : "notApplicableBorrower" },
              { "value": "Other", "label": "Other" ,"id" : "otherLicenseBorrower"}
            ],
            "name": "applicationlicense"
          },
          {
            "type": "textarea",
            "label": "Summary of your specific execution strategy?",
            "name": "executionstrategysummary",
            "description": "*Rehab/ Construction Plan, Strengths/ Weakness of the deal, Borrower Experience Level/ Credit, and Miscellaneous Info* (Please Provide as much detail as possible)",
            "id" : "executionStrategySummaryBorrower" 
          }
        
      ]
    const [formJSON, setformjson] = useState(useType == 'broker' ? formElementsbrockerJson : formElementsborrowerJson);
    const { loanInfo, setLoanInfo } = useLoanInfo();
    const [strategyInfo , setStrategyInfo] = useState(loanInfo?.strategyinfo);
    const [currentState, setCurrentState] = useState('StrategyInfo1');
    const navigate = useNavigate();
console.log('strategyInfo',loanInfo,strategyInfo);

  useEffect(() => {
    const isLoanProgramEmpty = !loanInfo.propertyinfo.programtype;
    if (isLoanProgramEmpty) {
      navigate('/loanprogram');
    } else if (loanInfo?.propertyinfo?.loanfor == "Refinance") {
      const isDealinfoEmpty = Object.keys(loanInfo.dealinfo).length === 0;
      if (isDealinfoEmpty) {
        navigate('/dealinfo');
      }
    }
  }, [loanInfo]);

useEffect(() => {
    if (strategyInfo?.hasOwnProperty('executionstrategysummary')) {
        setCurrentState('StrategyInfo4');
    } 
  }, []);
    useEffect(() => {
        console.log('currentState----->',currentState);
    }, [currentState]);

    useEffect(() => {
        console.log('strategyInfo----->',strategyInfo);
    }, [strategyInfo]);

    const updateStrategyInfo = (newData) => {
        console.log('newData',newData);
        setStrategyInfo((prevStrategyInfo) => ({
          ...prevStrategyInfo,
          ...newData,
        }));
        
      };

    const handleBack =()=>{
        if(currentState === 'StrategyInfo1'){
            if(loanInfo?.propertyinfo?.loanfor == "Refinance" || (loanInfo?.propertyinfo?.stepIntheProcess != "I'm doing research" && loanInfo?.propertyinfo?.stepIntheProcess != "Doing research for borrower")){
                navigate('/dealinfo');
            }else{
                navigate('/loanProgram');
            }
        }
        else if(currentState === "StrategyInfo2"){
            setCurrentState('StrategyInfo1')
        }
        else if(currentState === "StrategyInfo3"){
            setCurrentState('StrategyInfo2')
        }
        else if(currentState === "StrategyInfo4"){
            setCurrentState('StrategyInfo3')
        }
    }

    return (
        <div className="create_process">
            <Header />
            <div className="content_wrp">
            <InfoBar currentStage={'strategyinfo'} progress={'100%'} dealProgress={'100%'} infoProgress={(currentState === 'StrategyInfo1') ? '0%': (currentState === 'StrategyInfo2') ? '25%' :(currentState === 'StrategyInfo3') ? '50%' :(currentState === 'StrategyInfo4') ? '75%' : '0%'} />

                <div className="content_blk">
                    <div className="hed">
                    <a href="#" className="back material-icons" onClick={(e) => {e.preventDefault(); handleBack();}}> keyboard_backspace </a>
                        <h2 className="title"> <span className="title2">{loanInfo?.propertyinfo
                            ?.programtype?.split(' ')[0]} <span className="sub">{loanInfo?.propertyinfo
                                ?.programtype?.split(' ').slice(1).join(' ')}</span></span>Strategy Info</h2>
                    </div>
                    {currentState === "StrategyInfo1" ? (<StrategyInfo1 formJSON={formJSON[0]} strategyInfo={strategyInfo} updateStrategyInfo={updateStrategyInfo} setCurrentState={() =>setCurrentState('StrategyInfo2')} handleBack={handleBack}/>) : null}
                    {currentState === "StrategyInfo2" ? (<StrategyInfo1 formJSON={formJSON[1]} strategyInfo={strategyInfo} updateStrategyInfo={updateStrategyInfo} setCurrentState={() =>setCurrentState('StrategyInfo3')} handleBack={handleBack} />) : null}
                    {currentState === "StrategyInfo3" ? (<StrategyInfo1 formJSON={formJSON[2]} strategyInfo={strategyInfo} updateStrategyInfo={updateStrategyInfo} setCurrentState={() =>setCurrentState('StrategyInfo4')} handleBack={handleBack}/>) : null}
                    {currentState === "StrategyInfo4" ? (<StrategyInfo4 formJSON={formJSON[3]} strategyInfo={strategyInfo} updateStrategyInfo={updateStrategyInfo} setCurrentState={setCurrentState} handleBack={handleBack}/>) : null}

                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default StrategyInfo;