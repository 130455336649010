import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const FileViewer = (props) => {
    console.log(props.type);
    const docs = [
        { uri: props.source ,fileType: props.type,
            // fileName: props.name
         }  // Local File
      ];

   console.log('docs',docs);
    return (
        <>
         <div className="modal popup new_popup fade pdf_doc show" id="pdfdoc" tabindex="-1"
                        aria-labelledby="pdfdoc_achLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel"> {props.name}</h5>
                                    <button id="fileViewClose" type="button" className="pop_close" onClick={(e) => props.close(false)} data-bs-dismiss="modal" aria-label="Close"><span
                                        className="material-symbols-outlined">close</span></button>
                                </div>
                                <div className="modal-body pdf-popup" style={{ height: '100%', overflowY: 'auto' }}>
                                <DocViewer
                            style={{ width: '100%', height: '100%' }}
                            pluginRenderers={DocViewerRenderers}
                            documents={docs}
                        />
                        {/* <a
                            href={props.source} // Replace with the actual URL of your Excel file
                            download={props.type} // Specify the desired filename for download
                            style={{
                                position: 'absolute',
                                bottom: '20px',
                                right: '20px',
                                backgroundColor: 'blue',
                                color: 'white',
                                padding: '10px 20px',
                                textDecoration: 'none',
                                borderRadius: '5px',
                            }}
                        >
                            Download
                        </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
           
            {/* <Modal show={true} className="video-popup document-popup">
                <button className="close-btn" onClick={(e) => props.close(false)}>
                    <span className="material-icons">close</span>
                </button>
                <Modal.Body>
                    <div style={{ width: '100%', height: '90vh', position: 'relative' }}>
                        <DocViewer
                            style={{ width: '100%', height: '100%' }}
                            pluginRenderers={DocViewerRenderers}
                            documents={docs}
                        />
                        <a
                            href={props.source} // Replace with the actual URL of your Excel file
                            download={props.type} // Specify the desired filename for download
                            style={{
                                position: 'absolute',
                                bottom: '20px',
                                right: '20px',
                                backgroundColor: 'blue',
                                color: 'white',
                                padding: '10px 20px',
                                textDecoration: 'none',
                                borderRadius: '5px',
                            }}
                        >
                            Download
                        </a>
                    </div>
                </Modal.Body>
            </Modal> */}

        </>
    );
};


export default FileViewer;



