import React, { useRef, useState,useEffect } from 'react';

const CustomQuill = () => {
  const editorRef = useRef(null);

  const [content, setContent] = useState();

  useEffect(() => {
   console.log('content--->',content);
  }, [content]);

  useEffect(() => {
    const initialContent = document.getElementById('quill').value;
    setContent(initialContent);
  }, []);

  

  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('text/html') || clipboardData.getData('text/plain');
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = pastedData;
    // Manipulate styles (add your own logic here)
  
    // Insert the manipulated content at the cursor position
    document.execCommand('insertHTML', false, tempDiv.innerHTML);
    updateContent();
  }; 

  const handleInput =(e)=>{
    console.log('content------>',e.target.innerHTML);
    // setContent(e.target.innerHTML);
  }

  const handleClick =()=>{
    let data = document.getElementById('quill').innerHTML;
    console.log('data---->',data);
  }


  const updateContent = () => {
    if (editorRef.current) {
      const newContent = editorRef.current.innerHTML;
      setContent(newContent);
    }
  };

  return (
    <div>
    
      <div
      id='quill'
        ref={editorRef}
        contentEditable
        style={{ border: '1px solid #ccc', padding: '10px' }}
        onPaste={handlePaste}
        dangerouslySetInnerHTML={{ __html: content }}
        onChange={()=>console.log('hello')}
        onInput={ handleInput }
        
      />
      <button onClick={handleClick}>Print</button>
    </div>

  );
};

export default CustomQuill;
