import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoanInfo } from '../utils/loanInfoContext';
const Header = () => {

  const navigate = useNavigate();

  const { loanInfo, setLoanInfo } = useLoanInfo();
  const close = () => {
    const token = localStorage.getItem('accessToken');

    if (!token) {
      window.location.href = '/';
    }else{
      setLoanInfo({})
      navigate('/pipeline')
    }
};
  return (
    <div className="header">
                <div className="wrapper">
                    <div className="logo">
                        <img src="assets/images/brand/logo-light.png" className="img" alt="" />
                    </div>
                    <a href="#" id ="exitButton" onClick={(e) => {e.preventDefault(); close(); }} className="btn"> <span className="material-icons"> close </span>EXIT</a>
                </div>

            </div>
  );
};

export default Header;