import React, {useEffect, useState} from 'react';
import { getUser } from "./../utils/reducer";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../utils/UserContext';
const MainHeader = (props) => {
  const navigate = useNavigate();
  const user = useUser();
//   console.log('useruser',user);
  const [userData, setUserData] = useState(user);
  const [fulluserData, setfulUserData] = useState(user);
  const configdata = useSelector(state => state.configdata);
  useEffect(() => {
    // console.log('useruseruser',user);
    getUserFun();
    setUserData(user)
    setfulUserData(user)
      }, [user]);


      const getUserFun = async () =>{
        try {
            const response = await getUser();
            // console.log('privacymenunewwe', response);
            if (response?.result) {
                const user = response?.result?.[0]?.userInfo || response?.result?.[0];
                setUserData(user);
                setfulUserData(user);
            }
          } catch (error) {
            console.error('Error fetching user:', error);
          }
      }
      
    //   useEffect(() => {
    //     setUserData(props.userData)
    //   }, [props.userData]);
      

      useEffect(() => {
        // Check if token is present in local storage
        const token = localStorage.getItem('accessToken');
  
        if (!token) {
          // Redirect to the login page
          window.location.href = '/login'; // Replace with your login page route
        }
      }, []); 

      const handleLogout = () => {
        // Clear the token from local storage
        localStorage.clear();
    
        // Redirect to the login page
        window.location.href = '/login'; // Replace with your login page route
      };
    // console.log('userData?.username',userData);

  return (
    <header id="page-topbar">
    <div className="navbar-header">
        <div className="d-flex">

            <div className="navbar-brand-box">
                <a className="logo logo-dark">
                    <span className="logo-sm">
                        <img src="assets/images/brand/logo-light.png" alt="" height="22" />
                    </span>
                    <span className="logo-lg">
                        <img src="assets/images/brand/logo-light.png" alt="" height="17" />
                    </span>
                </a>

                <a className="logo logo-light">
                    <span className="logo-sm">
                        <img src="assets/images/brand/logo-light.png" alt="" height="7" />
                    </span>
                    <span className="logo-lg">
                        <img src="assets/images/brand/logo-light.png" alt="" height="30" />
                    </span>
                </a>
            </div>

        </div>

        <div className="d-flex">

            <div className="dropdown d-inline-block d-lg-none ms-2">
                <button type="button" className="btn header-item noti-icon waves-effect"
                    id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i className="mdi mdi-magnify"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                    aria-labelledby="page-header-search-dropdown">

                    <form className="p-3">
                        <div className="form-group m-0">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search ..."
                                    aria-label="Recipient's username" />
                                <div className="input-group-append">
                                    <button className="btn btn-primary" type="submit"><i
                                        className="mdi mdi-magnify"></i></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="dropdown d-inline-block">
                <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img className="rounded-circle header-profile-user" src={(userData?.profileImage && configdata?.common?.awsImgix) ? configdata?.common?.awsImgix+userData?.profileImage : "assets/images/avatar.jpg"}
                        alt="Header Avatar" />
                       <span className="d-none d-xl-inline-block ms-1" key="t-henry">{userData?.username} <br />
                    {fulluserData?.securityInfo?.role && <span className="role">{fulluserData?.securityInfo?.role.charAt(0).toUpperCase() + fulluserData?.securityInfo?.role.slice(1)}</span>}
                    </span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-end">
                    <a id="userAccount" className="dropdown-item" href="#" onClick={(e) => {e.preventDefault(); navigate('/myaccount');}} ><i className="bx bx-user font-size-16 align-middle me-1"></i>
                        <span key="t-profile">My Account</span></a>
                    <div className="dropdown-divider"></div>
                    <button id="userLogout" className="dropdown-item text-danger" onClick={(e) => {handleLogout()}}><i
                        className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> <span
                            key="t-logout">Logout</span></button>
                </div>
            </div>
        </div>
    </div>
</header>
  );
};

export default MainHeader;