import React, { useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { savePassword} from "./../utils/reducer";
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import Loader from './Loader';
import PopupMessage from './PopupMessage';
import { useLocation } from 'react-router-dom';
import PasswordErrors from './PasswordErrors.js';
const Createpassword = () => {
    const navigate = useNavigate();
    const loading = useSelector((state) => state.loaderVisible);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const id = urlParams.get('id');

    console.log('ididid',id);
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMatchError, setPasswordMatchError] = useState('');
    const [popupMessage, setmessage] = useState('Your password has been updated successfully. Feel free to log in using the new password.');
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [isFocused, setIsFocused] = useState(true);
    const [passwordErrors , setPasswordErrors] = useState({
      number : false,
      specialCharacter : false,
      characterLength : false,
    });

    const updatePassword = (e) => {
    let passcheck = passwordErrors.characterLength  && passwordErrors.number && passwordErrors.specialCharacter ;
    console.log(passcheck);
      e.preventDefault();
      // console.log('setPasswordComplexityErrorsdfsfsf', newPassword, confirmPassword);
       // Check if password is empty
        if (!newPassword.trim()) {
            setPasswordMatchError('Password is required');
            return;
        } else {
            setPasswordMatchError('');
        }
      if (newPassword !== confirmPassword) {
        setPasswordMatchError('Passwords do not match');
        return
      } else {
        // Clear the error if passwords match, and proceed with other actions
        setPasswordMatchError('');
        // Add additional logic for handling form submission
        console.log('Form submitted successfully');
      }

      if (!passcheck) {
        setPasswordMatchError('Please enter the password as per the instructions');
        return;
      } else {
        setPasswordMatchError('');
       }
        
      
      dispatch(showLoader());
      let userdata = {"id":id, "password":newPassword}
      console.log('userdata',userdata);
      savePassword(userdata).then(function (response) {

        dispatch(hideLoader());
        console.log('res', response);
        if (response.statusCode == 200 && response.result?.token) {
          setmessage('Your password has been updated successfully. Feel free to log in using the new password.')
          setPopupOpen(true)
        }else{
          setmessage('Please try with new reset link')
          setPopupOpen(true)
        }
      }).catch((err) => {
        console.error.bind("Login error", err);
      })
      
    };
    
    const closePopup = () => {
      setPopupOpen(false);
      navigate('/login');
    };

    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

  const handleNewPass = (e) => {
    const newPassword = e.target.value.trim();
    const hasValidLength = newPassword.length >= 8;
    const hasNumber = /\d/.test(newPassword);
    const hasSpecialCharacter = /[&'"]/.test( newPassword);
    
    hasValidLength
      ? setPasswordErrors((prevErrors) => ({...prevErrors,characterLength: true}))
      : setPasswordErrors((prevErrors) => ({...prevErrors,characterLength: false }));
    hasNumber
      ? setPasswordErrors((prevErrors) => ({ ...prevErrors, number: true }))
      : setPasswordErrors((prevErrors) => ({ ...prevErrors, number: false }));
    hasSpecialCharacter
      ? setPasswordErrors((prevErrors) => ({...prevErrors,specialCharacter: false }))
      : setPasswordErrors((prevErrors) => ({...prevErrors,specialCharacter: true}));

    setNewPassword(newPassword);
  };

  const handleConfirmPass = (e) => {
    const confirmPass = e.target.value.trim();
    setConfirmPassword(confirmPass);
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (<>
    {loading ? <Loader/> : null} 
    {isPopupOpen && (
        <PopupMessage
          type={popupMessage ==  'Please try with new reset link' ? 'warning' : 'success'}
          message={popupMessage}
          onClose={closePopup}
        />
      )}
    <div className="account-pages my-5">
    <div className="container">
        <div className="row justify-content-center login_box">
            <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="signin-header mb-4">
                    <img src="../assets/images/brand/logo-light.png" className="signin-logo" />
                   <a className="xray-btn" href="#" onClick={(e) => {e.preventDefault(); navigate('/');}}> <span className="material-symbols-outlined"> west </span>Back</a>
                </div>
                <div className="card overflow-hidden">
            
                    <div className="card-body text-center"> 
                        <p>I am affiliated with Easy Street Capital LLC as</p>
                        <h2>Team Member</h2>
                        <div className="icon-circle center-position">
                            <img src="../assets/images/icons/team-member-icon.svg" />
                        </div>
                        <div className="p-2">
                            <form className="form-horizontal" action="#">
        
                                <div className="mb-3">
                                    <label className="form-label">New Password</label>
                                        <input className="form-control" onBlur={handleBlur} onFocus={handleFocus} placeholder="Enter password" aria-label="Password" aria-describedby="password-addon" type={showPassword ? 'text' : 'password'} value={newPassword} onChange={(e) => handleNewPass(e)} />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Confirm Password</label>
                                        <input  className="form-control" onBlur={handleBlur} onFocus={handleFocus} placeholder="Enter password" aria-label="Password" aria-describedby="password-addon" type={showPassword ? 'text' : 'password'} value={confirmPassword} onChange={(e) => handleConfirmPass(e)} />
                                </div>
                                {passwordMatchError && <p style={{ color: 'red' }}>{passwordMatchError}</p>}
                                <div className="form-check">
                                    <input className="form-check-input" onChange={handleTogglePassword} type="checkbox" id="show-password" />
                                    <label className="form-check-label" htmlFor="show-password">
                                        Show Password
                                    </label>
                                </div>
                                <div className="btn_wrp">
                                    <button className="btn btn-primary waves-effect waves-light" type="submit"  onClick={(e) => updatePassword(e)}>Save</button>
                                    
                                </div>
                            </form>
                        </div>
                    </div>
                    
                </div>
                 {
              isFocused ? (
                <PasswordErrors passwordErrors={passwordErrors}/>
              ) : null
            }
                <div className="mt-4 text-center">
                    <div>
                       <p>© 2024 easystreetcapital.com. All rights reserved.</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div></>
  );
};

export default Createpassword;