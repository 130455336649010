import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import { getallUsers } from "./../utils/reducer";
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Loader';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import HomeFooter from './HomeFooter.js';
import { getUserDataFromLocalStorage } from '../utils/local';

const UserManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loaderVisible);
  const [users, setUsers] = useState([]);
  const [copyUsers, setCopyUsers] = useState([]);
  const [searchValue,setSearchValue] = useState('');
  let userData = getUserDataFromLocalStorage();
  useEffect(() => {
    dispatch(showLoader());
    getallUsers().then(function (response) {
        // console.log('privacymenunewwegetallUsers', response.result);
        if (response?.result?.data) {
          setUsers(response?.result?.data);
          setCopyUsers(response?.result?.data);
          dispatch(hideLoader());
        }
      })
      .catch((err) => {
        console.error.bind("my account error", err);
        dispatch(hideLoader());
      })
  }, []);
  
  const editUser = (user) => {
    navigate('/createuser', { state: { user } });
  };

  const columns = [
    {
      name: 'FIRST NAME',
      selector: (row) => row?.userInfo?.firstname,
      sortable: true,
      sortFunction: (a, b) => {
    
        const nameA = a?.userInfo?.firstname?.toLowerCase();
        const nameB = b?.userInfo?.firstname?.toLowerCase();
  
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; 
      },
      minWidth: '160px',
      maxWidth: '170px',
    },
    {
      name: 'LAST NAME',
      selector: (row) => row?.userInfo?.lastname,
      sortable: true,
      sortFunction: (a, b) => {
    
        const nameA = a?.userInfo?.lastname?.toLowerCase();
        const nameB = b?.userInfo?.lastname?.toLowerCase();
  
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; 
      },
      minWidth: '160px',
      maxWidth: '170px',
    },
    
    {
      name: 'EMAIL ID',
      selector: (row) => row?.userInfo?.emailid,
      sortable: true,
      sortFunction: (a, b) => {
       
        const nameA = a?.userInfo?.emailid?.toLowerCase();
        const nameB = b?.userInfo?.emailid?.toLowerCase();
  
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; 
      },
      minWidth: '350px',
      maxWidth: '380px',
    },
    {
      name: 'USER NAME',
      selector: (row) => row?.userInfo?.username,
      sortable: true,
      sortFunction: (a, b) => {
        
        const nameA = a?.userInfo?.username?.toLowerCase();
        const nameB = b?.userInfo?.username?.toLowerCase();
  
        if (nameA < nameB) {
          return -1; 
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; 
      },
      minWidth: '160px',
      maxWidth: '170px',
    },
    {
      name: 'PHONE NUMBER',
      selector: (row) => row?.userInfo?.phone,
      sortable: true,
      sortFunction: (a, b) => {
        const preprocessPhoneNumber = (phoneNumber) => {
          return phoneNumber ? phoneNumber.replace(/\D/g, '') : '';
        };
        const phoneNumberA = preprocessPhoneNumber(a?.userInfo?.phone);
        const phoneNumberB = preprocessPhoneNumber(b?.userInfo?.phone);
    
        if (phoneNumberA < phoneNumberB) {
          return -1;
        }
        if (phoneNumberA > phoneNumberB) {
          return 1;
        }
        return 0;
      },
      minWidth: '160px',
      maxWidth: '170px',
    },
    {
      name: 'STATE',
      selector: (row) => row?.userInfo?.state,
      sortable: true,
      sortFunction: (a, b) => {
       
        const nameA = a?.userInfo?.state?.toLowerCase();
        const nameB = b?.userInfo?.state?.toLowerCase();
  
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; 
      },
      minWidth: '120px',
      maxWidth: '170px',
    },
    {
      name: 'CREATED BY',
      selector: (row) => row?.userInfo?.manager,
      sortable: true,
      sortFunction: (a, b) => {
   
        const nameA = a?.userInfo?.manager?.toLowerCase();
        const nameB = b?.userInfo?.manager?.toLowerCase();
  
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; 
      },
      minWidth: '120px',
      maxWidth: '170px',
    },
    {
      name: 'STATUS',
      selector: (row) => <span className={`badge ${row?.securityInfo?.status !== 'ACTIVE' ? 'inactive' : ''}`}>{row?.securityInfo?.status ? row?.securityInfo?.status : 'INACTIVE'}</span> ,
      
    },
    {
        name: 'ACTIONS',
        cell: (row,index) => (
          <button id = {`editUser-${index}`} onClick={(e) => { editUser(row); }} className="icon_btn edit" disabled={userData?.securityInfo?.role != "admin"}>
            <span style={{ color: '#444', fontSize: '16px' }} className="material-icons icon tooltip-container"><span className="tooltip">Edit</span>edit</span>
          </button>
        ),
        minWidth: '100px',
        maxWidth: '100px',
      },
  ];


  const handleSearch = (e) => {
    const searchValue = e.target.value;
    if (searchValue.trim() === '') {
      setUsers(copyUsers);
    } else {
      const filteredUsers = copyUsers.filter(user => 
        user?.userInfo?.username && user.userInfo.username.toLowerCase().includes(searchValue.trim().toLowerCase())
      );
      setUsers(filteredUsers);
    }
    setSearchValue(searchValue);
  };
  const customStyles = {
    //   headRow: {
	// 	style: {
    //         fontWeight: 'bold',
    //         backgroundColor: '#E9F0E6',
	// 	},
	// },
    headCells: {
		style: {
            fontWeight: 'bold',
            backgroundColor: '#E9F0E6',
		
		},
	},
        table: {
      style: {
        border: '1px solid #CCD8F6', 
      },
    },
  };

  return (
    <div id="layout-wrapper">
{loading ? <Loader/> : null}

      <MainHeader />
      <SideMenu />

      <div className="main-content">
        <div className="page-content user_management">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">User Management</h4>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className='search'>
                      <button id="createUser" onClick={(e) => { e.preventDefault(); navigate('/createuser'); }} className="create_btn">Create User <span className="material-symbols-outlined icon"> add </span></button>
                      <div className="right">
                        <div className='form-group search_block'>
                          <input id = "searchUserName" type="text" className='form-control' onChange={(e)=>handleSearch(e)} placeholder='Search User Name' />
                          <button id ="searchUser" className="material-symbols-outlined btn">search</button>
                        </div>
                        {/* <div className='select_box'>
                          Per Page
                          <select className="form-control">
                            <option>10</option>
                            <option>20</option>
                            <option>30</option>
                            <option>40</option>
                          </select>
                        </div> */}
                      </div>
                    </div>
                    { users.length > 0 &&   <DataTable
                      columns={columns}
                      data={users}
                      pagination
                      customStyles={customStyles}
                      paginationPerPage={10} 
                      paginationRowsPerPageOptions={[10, 20, 30, 40]} 
                      paginationComponentOptions={{
                        rowsPerPageText: 'Per Page',
                      }}
                    />}
                   {users?.length ===0 && searchValue ?  <> <div className="inprogress_page"><span className="material-symbols-outlined icon"> content_paste_search </span><p>No users found </p></div></> : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="text-sm-end d-none d-sm-block">
                © 2024 easystreetcapital.com. All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </footer> */}
        <HomeFooter />
      </div>
    </div>
  );
};

export default UserManagement;