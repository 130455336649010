import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import Select from 'react-select';
import {getTypeLookups, getStatewiseCities, updateLookupsData, updateCitiesData} from "../utils/reducer";
import { useDispatch, useSelector} from 'react-redux';
import Loader from './Loader';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import PopupMessage from './PopupMessage.js';
import { useNavigate } from 'react-router-dom';
import HomeFooter from './HomeFooter';
const StatesCities = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loaderVisible);
      const [selectedState, setSelectedState] = useState('');
      const [states, setStates] = useState(null);
      const [cities, setCities] = useState(null);     
      const [statesData, setStatesData] = useState(null); 
      const [selectAll, setSelectAll] = useState(false); 
      const [isPopupOpen, setPopupOpen] = useState(false);
      const [oldCitiesData, setOldCitiesData] = useState([]);  
      const [message , setMessage] = useState('Updated Successfully');
      const [citiesBool,setCitiesBool] = useState(false);
      const [isSaved,setIsSaved] = useState(false);

      useEffect(() => {
        getStates()
      }, []);

    const getStates= (stateName) => { 
      dispatch(showLoader());
        getTypeLookups('states').then(function (response) {
        if (response?.result) {

           setStates(response?.result?.data[0].lookupOptions)
           setStatesData(response?.result?.data[0])
        }
        dispatch(hideLoader());
        }).catch((err) => {
          console.error.bind("my account error", err);
        })
    }

      useEffect(() => {
        if(selectedState){
          dispatch(showLoader());
          getStatewiseCities(selectedState).then(function (response) {
            if (response?.result) {
                //console.log("get state wise cities api",response?.result)
               setCities(response?.result);
               setOldCitiesData(response?.result);
               dispatch(hideLoader());
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
              dispatch(hideLoader());
            })
        }

      }, [selectedState ,isSaved]);

      // disable save cities button when no changes done
      const compareArrays = (arr1, arr2) => {
        // Filter arrays to find all objects that match the selected state
        const arr1Filtered = arr1?.filter(obj => obj?.regionname === selectedState);
        const arr2Filtered = arr2?.filter(obj => obj?.regionname === selectedState);
    
        
        // Compare each object in arr1Filtered with the corresponding object 
        for (const obj1 of arr1Filtered) {
          const obj2 = arr2Filtered.find(obj => obj.name === obj1.name);
          if (!obj2 || obj1.status !== obj2.status) {
            return false;
          }
        }
    
        return true;
      };

      useEffect(() => {
        console.log("cities",cities);
        console.log("selected state",selectedState);
        const compareCities = compareArrays(oldCitiesData,cities);
        console.log("cities bool",compareCities);
        setCitiesBool(compareCities);

      },[cities]);


      const handleStatusChange = (stateName) => {
        // Map through states to find the state to update
        const updatedStates = states.map((state) => {
          if (state.name === stateName) {
            // Toggle the status based on current status
            return { ...state, status: state.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' };
          }
          return state;
        });
  
        setStates(updatedStates); // Update the states array with the new status
      };

      const handleCitiesStatusChange = (cityName) => {
        // Map through states to find the state to update
        const updatedCities = cities.map((city) => {
          if (city.name === cityName) {
            // Toggle the status based on current status
            return { ...city, status: city.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',  changed: true};
          }
          return city;
        });
  
        setCities(updatedCities); // Update the states array with the new status
      };

      const handleSave= () => {
        if(!selectedState || citiesBool){
          dispatch(showLoader());
          setPopupOpen(true);
          setMessage("No state or city has been selected.");
         
          dispatch(hideLoader());
        }else{
          dispatch(showLoader());
          console.log('updatedStateshere',states, cities);
          delete statesData._id;
          statesData.lookupOptions = states;
          updateLookupsData(statesData).then(function (response) {
            getStates()
            dispatch(hideLoader());
            console.log('responseresponse',response);
            if(response.result === 'Updated Successfully'){
              setMessage('Saved Successfully');
              setPopupOpen(true);
            }
          }).catch((err) => {
              dispatch(hideLoader());
            console.error.bind("Login error", err);
          })
        }
       
      }

      const handleCitiesSave= () => {
        dispatch(showLoader());
        const changedCities = cities.filter(city => city.changed).map(({ changed, ...rest }) => rest);
        console.log('changedCities', changedCities);
        if (changedCities.length) {
          console.log('ran-----')
        updateCitiesData(changedCities).then(function (response) {
          getStates()
         
          console.log('responseresponse',response);
          if(response.result === 'modified successfully'){
            setMessage('Modified successfully');
            setPopupOpen(true);
            setIsSaved(!isSaved);
            dispatch(hideLoader());
          }
          
        }).catch((err) => {
            dispatch(hideLoader());
          console.error.bind("Login error", err);
        })
          // Optionally reset the changed flag here if necessary
        } 
        else{
          dispatch(hideLoader());
          setMessage('No Changes to Save');
          setPopupOpen(true);
        }
      }

      const handleSelectAllChange = () => {
        if (selectAll) {
          // If currently selected, deactivate all
          setStates(states.map(state => ({ ...state, status: 'INACTIVE' })));
        } else {
          // Activate all
          setStates(states.map(state => ({ ...state, status: 'ACTIVE' })));
        }
        setSelectAll(!selectAll); // Toggle selectAll state
      };

      const closePopup = () => {
        setPopupOpen(false);
      }; 
    return (
        <div id="layout-wrapper">
 {loading ? <Loader/> : null} 

 {isPopupOpen && (
        <PopupMessage
          type={message === "No state or city has been selected." ? 'warning' : 'success'}
          message={message}
          onClose={closePopup}
        />
      )}

           <MainHeader />


            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management states_cities">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">States & Cities</h4>
                                <a href="#" id="navigateStatesBack" onClick={(e) => {e.preventDefault(); navigate(-1);}}  className="back_btn"><span className="material-icons icon"> arrow_back
                                    </span>BACK</a>
                            </div>
                            <div className="card">
                                <div className="card-body ">
                                    <div className="row">
                                        <div className="col-md-6 lp_additem mt-0">
                                            <h4>States</h4>
                                            <label className="toggle">
                                            <input id="selectAllToggle" className="toggle-checkbox" type="checkbox" checked={selectAll} onChange={handleSelectAllChange}/>
                                                <div className="toggle-switch"></div>
                                                <span className="toggle-label">select all</span>
                                            </label>
                                           

                                        </div>
                                    </div>
                                    <div className="form_section status">
                                        <div className="sl_block">
                                            <div className="left">
                                            {states?.map((state, chunkIndex) => (
                                                <div className={['states_block', selectedState === state?.name ? 'active' : ''].join(' ')} >
                                                    <label className="toggle">
                                                    <input id={`stateToggle-${chunkIndex}`} onChange={() => handleStatusChange(state.name)}  className="toggle-checkbox" type="checkbox" checked={state.status === 'ACTIVE'} />
                                                        <div className="toggle-switch"></div>
                                                        <span className="toggle-label">{state?.name}</span>
                                                    </label>
                                                    <button id={`stateArrowBtn-${chunkIndex}`} type="button" className="btn btn-outline-secondary" key={chunkIndex} onClick={(e) => {e.preventDefault(); setSelectedState(state?.name);}}>
                                                        <span className="material-icons">arrow_forward_ios</span>
                                                    </button>
                                                </div>
                                            ))}
                                              
                                               
                                            </div>
                                            <div className="right">
                                                <h5>cities</h5>
                                                {/* <div className="row">
                                                    {selectedState && cities?.map((city, chunkIndex) => (
                                                        <div className="col-md-3" key={chunkIndex}>
                                                            <label className="toggle">
                                                            <input onChange={() => handleCitiesStatusChange(city.name)}  className="toggle-checkbox" type="checkbox" checked={city.status === 'ACTIVE'} />
                                                                <div className="toggle-switch"></div>
                                                                
                                                            </label>
                                                            <span className="toggle-label">{city?.name}</span>
                                                        </div>
                                                    ))}
                                                    
                                                </div> */}
                                                <div className="row">
                                                    {selectedState && cities?.map((city, chunkIndex) => (
                                                        <div className="col-md-3" key={chunkIndex}>
                                                            <div className="toggle-container d-flex">
                                                                <label className="toggle">
                                                                    <input id={`cityToggle-${chunkIndex}`} onChange={() => handleCitiesStatusChange(city.name)} className="toggle-checkbox" type="checkbox" checked={city.status === 'ACTIVE'} />
                                                                    <div className="toggle-switch"></div>
                                                                </label>
                                                                <span className="toggle-label ms-3">{city?.name}</span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>

                                            </div>
                                        </div>
                                        <div className="btn-group mt_20">
                                   
                                            <button id="saveStates" onClick={(e) => {e.preventDefault(); handleSave()}}  className="fs_btn mr_10">Save States</button>
                                            <button id="saveCities" style = {{'opacity' : (citiesBool) ? '0.6' : '1'}} disabled = {citiesBool} onClick={(e) => {e.preventDefault(); handleCitiesSave()}} className="fs_btn">Save Cities</button>
                                        </div>
                                
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default StatesCities;