import React,{useState} from 'react';
import { useLoanInfo } from '../utils/loanInfoContext';
const InfoBar = (props) => {
    const { loanInfo, setLoanInfo } = useLoanInfo();
    const [properyInfo, setProperyInfo] = useState(loanInfo?.propertyinfo || {});
console.log('properyInfoproperyInfo',loanInfo?.propertyinfo,properyInfo);
  return (
    <div className="steps">
                <div className={props.currentStage == 'propertyinfo' ? "box bx_progress active" : "box"} >
                {props?.progress && <div className="fill" style={{ width: props?.progress }}></div>}
                    <span className="text">Property
                        Info</span>
                    <img src="assets/images/icons/home_work.svg" className="img" alt="" />
                </div>
                <div className={props.currentStage == 'programtype' ? "box bx_progress active" : "box"}>
                {props?.infoProgress && <div className="fill" style={{ width: '100%' }}></div>}
                    <span className="text">Loan
                        Program</span>
                    <img src="assets/images/icons/web_stories.svg" className="img" alt="" />
                </div>
                {(loanInfo?.propertyinfo?.loanfor == "Refinance" || (loanInfo?.propertyinfo?.stepIntheProcess != "I'm doing research" && loanInfo?.propertyinfo?.stepIntheProcess != "Doing research for borrower")) && <div className={props.currentStage == 'dealinfo' ? "box bx_progress active" : "box"}>
                {props?.dealProgress && <div className="fill" style={{ width: '100%' }}></div>}
                    <span className="text">Deal
                        Info</span>
                    <img src="assets/images/icons/handshake.svg" className="img" alt="" />
                </div>}
                <div className={props.currentStage == 'strategyinfo' ? "box bx_progress active" : "box"}>
                {props?.strategyProgress && <div className="fill" style={{ width: props?.strategyProgress }}></div>}
                    <span className="text">Strategy
                        Info</span>
                    <img src="assets/images/icons/emoji_objects.svg" className="img" alt="" />
                </div>
                <div className={props.currentStage == 'borrowerinfo' ? "box bx_progress active" : "box"}>
                {props?.borroweProgress && <div className="fill" style={{ width: props?.borroweProgress }}></div>}
                    <span className="text">Borrower
                        Info</span>
                    <img src="assets/images/icons/location_away.svg" className="img" alt="" />
                </div>
                <div className={props.currentStage == 'submitapp' ? "box bx_progress active" : "box"}>
                    <span className="text">Submit
                        App</span>
                    <img src="assets/images/icons/rocket_launch.svg" className="img" alt="" />
                </div>
            </div>
  );
};

export default InfoBar;