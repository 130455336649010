import React from 'react';
import MainHeader from './MainHeader';
import SideMenu from './SideMenu';
import HomeFooter from './HomeFooter';
const PageNotFound = () => {
    return (
        <div id="layout-wrapper">


           <MainHeader />
           <SideMenu />

            <div className="main-content">

            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                                <div className="page_not_found">
                                    <div className="no_data">
                                        <img src="assets/images/page-img.png" className="img" alt="" />
                                        <h3>Sorry, the page not found</h3>
                                        <p>The link you followed probably broken
                                            or the page has been removed.</p>
                                    </div>
                                </div>
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default PageNotFound;