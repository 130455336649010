import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import { useSelector } from 'react-redux';
import { getMenus } from "./../utils/reducer";
const Terms = (props) => {
    const menusdata = useSelector(state => state.menusdata);
    const [termsContent, settermsContent] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {

        getMenus('200010').then(function (response) {
            console.log('termsmenunewwe', response);
            //  // Find the item with type "footermenu" and menu "Privacy"
            //  const foundItem = apiResponse.find(item => item.type === "footermenu" && item.menu === "Privacy");

            //  if (foundItem) {
            if (response?.result) {
                settermsContent(response?.result[0].content);
            }
            //  }


        }).catch((err) => {
            console.error.bind("Login error", err);
        })

    }, []);


    return (

        <div style={{
            backgroundImage: 'url(assets/images/common-img/landingBg.jpg)',
            backgroundPosition: '50% 50%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        }}>
            <div className="content_page">
                <div className="container">
                    <div className="header">
                        <div className="logo">
                            <img src="assets/images/brand/logo-light.png" />
                        </div>
                        <a className="xray-btn" href='#' onClick={(e) => { e.preventDefault(); navigate(-1); }}> <span className="material-symbols-outlined icon" > arrow_left_alt </span>Back</a>
                    </div>
                    {termsContent ? <div className="content" dangerouslySetInnerHTML={{ __html: termsContent }} /> :
                        <div className="content"><h1>Terms of Use</h1><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including version of Lorem Ipsumh.</p></div>
                    }

                </div>
                <Footer />
            </div>

        </div>
    );
};

export default Terms;