import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import ReactQuill from "react-quill";
import MainHeader from './MainHeader';
import { useNavigate, useLocation} from 'react-router-dom';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import { addFormTamplate,getSingleFormTemplate, getValuesDropdown} from "../utils/reducer";
import EditorEdit from './EditorEdit.js';
import HomeFooter from './HomeFooter';
import jsPDF from 'jspdf';
//import formValuesKeys from '../JsonData/formValuesKeys.json';
import {transformData} from '../utils/commonUtils.js';
import ValuesDropdown from './ValuesDropdown.js';

const CreateFormTemplates = () => {
    const dispatch = useDispatch();
    const [editorValidate,setEditorValidate] = useState(false);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const loading = useSelector((state) => state.loaderVisible); 
    const [popupMessage, setmessage] = useState('Form saved successfully.');
    const navigate = useNavigate();
    const [showConfirmation,setShowConfirmation] = useState(false);
    // const [formTemplatesData, setFormTemplatesData] = useState({
    //     name : '',docTag : '', chooseFromGroup : '', phase : '', folder : '',stage : '',milestone :'',signature : '',activeEmails : '',customPlaceHolder : []
    // });
    const location = useLocation();
    const formTemplate = location.state?.formTemplate;
    const [formTemplatesData, setFormTemplatesData] = useState( {
        name : '',docTag : '',signature : '' , formValues : ''
    });
    const[checkForm,setCheckForm] = useState({
        name : '',docTag : '',signature : ''
    })
    const [template, setTemplate] = useState("");
    const [documentFolder,setDocumentFolder] = useState(["", "", ""]);
    const [editorCheck,setEditorCheck] = useState({
        template : '',
        documentFolder : '',
    })
    const [newFieldName, setNewFieldName] = useState('');
    const [errors, setErrors] = useState({});
    const [editIndex, setEditIndex] = useState('');
    const [buttonType , setButtonType] = useState('add');
    const [formChanged, setFormChanged] = useState(false);
    const [formVariables,setFormVariables] = useState([]);
    const [formValuesOption,setFormValuesOption] = useState(null);
    const [formattedVariables,setFormattedVariables] = useState(null);
    const [valueDependency,setValuesDependency] = useState(0);
    

    useEffect(()=>{
if(formTemplate?.templateid){
    getSingleFormTemplate(formTemplate?.templateid).then(function (response) {
        dispatch(showLoader());
        if (response?.result) {
            let result = response?.result
            setFormTemplatesData({
                name : result?.data?.[0]?.name,docTag : result?.data?.[0]?.docTag,signature : result?.data?.[0]?.signature,
                templateid :result?.data?.[0]?.templateid,_id : result?.data?.[0]?._id
            });
            setCheckForm({
                name : result?.data?.[0]?.name,docTag : result?.data?.[0]?.docTag,signature : result?.data?.[0]?.signature,
                templateid :result?.data?.[0]?.templateid,_id : result?.data?.[0]?._id
            })
            setTemplate(result?.data?.[0]?.template);
            setDocumentFolder(result?.data?.[0]?.documentFolder);
            setEditorCheck({
                template : result?.data?.[0]?.template,
                documentFolder : result?.data?.[0]?.documentFolder,
            })
            dispatch(hideLoader());
            console.log('response?.result--->',response?.result)
        }
        }).catch((err) => {
          dispatch(hideLoader());
          console.error.bind("my account error", err);
        })
}else{
    setFormTemplatesData({
        name : '',docTag : '',signature : ''
    });
    setCheckForm({
        name : '',docTag : '',signature : ''
    })
    setTemplate('');
    setDocumentFolder(["", "", ""]);
    setEditorCheck({
        template : '',
        documentFolder : '',
    })
}
    },[])

    useEffect(() => {
        const isFormChanged =
            JSON.stringify(formTemplatesData) !== JSON.stringify(checkForm) ||
             editorCheck?.template !== template || editorCheck?.documentFolder !== documentFolder;
        setFormChanged(isFormChanged);
        console.log(isFormChanged,checkForm,formTemplatesData,editorCheck?.template ===template,documentFolder);
    }, [checkForm, editorCheck?.template,editorCheck?.documentFolder,formTemplatesData, template, documentFolder]);

    const modules = {
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ color: [] }, { background: [] }],
          [{ script: 'sub' }, { script: 'super' }],
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }],
          ['link', 'image', 'video'],
          [{ align: [] }],
        ],
        clipboard: {
          matchVisual: false,
        },
    };

      // dropdown vales 
      const chooseFromGroup = [
        { "label": "Please Select", "value": "" },
        { "label": "California Docs", "value": "California Docs" },
        { "label": "Texas Docs", "value": "Texas Docs" },
        { "label": "Servicing Docs", "value": "Servicing Docs" },
        { "label": "CFL Individual Borrower - TEST", "value": "CFL Individual Borrower - TEST" },
        { "label": "CFL 2 Individual Borrowers", "value": "CFL 2 Individual Borrowers" },
        { "label": "TN Docs", "value": "TN Docs" }
     ]
     const phase = [
        { "label": "Please Select", "value": "" },
        { "label": "Lead", "value": "Lead" },
        { "label": "Pre-Approval", "value": "Pre-Approval" },
        { "label": "Credit Pulled", "value": "Credit Pulled" },
        { "label": "Initial Submission", "value": "Initial Submission" },
        { "label": "Lock Requested", "value": "Lock Requested" },
        { "label": "Manual Lock Requested", "value": "Manual Lock Requested" },
        { "label": "Locked", "value": "Locked" },
        { "label": "Change Lock Requested", "value": "Change Lock Requested" },
        { "label": "Lock Withdrawn", "value": "Lock Withdrawn" },
        { "label": "Set Up", "value": "Set Up" },
        { "label": "Conditions", "value": "Conditions" },
        { "label": "Underwriting", "value": "Underwriting" },
        { "label": "Closing", "value": "Closing" },
        { "label": "Closed", "value": "Closed" },
        { "label": "Funded", "value": "Funded" },
        { "label": "Committed to Hedge", "value": "Committed to Hedge" },
        { "label": "Closed - Denied", "value": "Closed - Denied" },
        { "label": "Sold", "value": "Sold" },
        { "label": "Sold - Funds Received", "value": "Sold - Funds Received" },
        { "label": "Clear to Close", "value": "Clear to Close" },
        { "label": "Suspended", "value": "Suspended" },
        { "label": "Cancelled", "value": "Cancelled" },
        { "label": "Withdrawn", "value": "Withdrawn" },
        { "label": "Credit App", "value": "Credit App" },
        { "label": "Credit App Denied", "value": "Credit App Denied" },
        { "label": "Credit App Withdrawn", "value": "Credit App Withdrawn" },
        { "label": "Acquired", "value": "Acquired" },
        { "label": "Use Service Link", "value": "Use Service Link" },
        { "label": "Closed Update REO and Experience", "value": "Closed Update REO and Experience" },
        { "label": "Use TitleEQ", "value": "Use TitleEQ" }
     ]
     const folder =[
        { "label": "Please Select", "value": "" },
        { "label": "Active Balance Sheet", "value": "Active Balance Sheet" },
        { "label": "Active Balance Sheet - Foreclosure", "value": "Active Balance Sheet - Foreclosure" },
        { "label": "Active Sold - 1Sharpe/FCI", "value": "Active Sold - 1Sharpe/FCI" },
        { "label": "Active Sold - Churchill/FCI", "value": "Active Sold - Churchill/FCI" },
        { "label": "Active Sold - EF/BSI", "value": "Active Sold - EF/BSI" },
        { "label": "Active Sold - Fidelis/FCI", "value": "Active Sold - Fidelis/FCI" },
        { "label": "Active Sold - Reigo/BSI", "value": "Active Sold - Reigo/BSI" },
        { "label": "Cancelled", "value": "Cancelled" },
        { "label": "EF REO", "value": "EF REO" },
        { "label": "NLF - Active Balance Sheet", "value": "NLF - Active Balance Sheet" },
        { "label": "NLF - Active Sold - EF/BSI", "value": "NLF - Active Sold - EF/BSI" },
        { "label": "NLF - Active Sold - Fidelis/FCI", "value": "NLF - Active Sold - Fidelis/FCI" },
        { "label": "NLF - Paid Balance Sheet", "value": "NLF - Paid Balance Sheet" },
        { "label": "NLF - Paid Sold - Fidelis/FCI", "value": "NLF - Paid Sold - Fidelis/FCI" },
        { "label": "NLF - Paid Sold EF/BSI", "value": "NLF - Paid Sold EF/BSI" },
        { "label": "Paid Balance Sheet", "value": "Paid Balance Sheet" },
        { "label": "Paid Sold - 1Sharpe/FCI", "value": "Paid Sold - 1Sharpe/FCI" },
        { "label": "Paid Sold - EF REO", "value": "Paid Sold - EF REO" },
        { "label": "Paid Sold - Fidelis/FCI", "value": "Paid Sold - Fidelis/FCI" },
        { "label": "Paid Sold - Peer Street", "value": "Paid Sold - Peer Street" },
        { "label": "Paid Sold - Reigo/BSI", "value": "Paid Sold - Reigo/BSI" },
        { "label": "Paid Sold Churchill/FCI", "value": "Paid Sold Churchill/FCI" },
        { "label": "Paid Sold EF/BSI", "value": "Paid Sold EF/BSI" },
        { "label": "Pipeline Campaign", "value": "Pipeline Campaign" },
        { "label": "Pre-Close", "value": "Pre-Close" },
        { "label": "REO", "value": "REO" },
        { "label": "Sold Released - Palladius", "value": "Sold Released - Palladius" },
        { "label": "Sold Released - Trinity", "value": "Sold Released - Trinity" },
        { "label": "Sold REO", "value": "Sold REO" }
     ]
     const stage = [
        { "label": "Please Select", "value": "" },
        { "label": "L01. Account Executive Review", "value": "L01. Account Executive Review" },
        { "label": "L05. Underwriting", "value": "L05. Underwriting" },
        { "label": "L10. Term Sheet Issued", "value": "L10. Term Sheet Issued" },
        { "label": "L15. Processing", "value": "L15. Processing" },
        { "label": "L16. Reviewed/Requested", "value": "L16. Reviewed/Requested" },
        { "label": "L20. On Hold", "value": "L20. On Hold" },
        { "label": "L25. Docs Out", "value": "L25. Docs Out" },
        { "label": "L30. Funded", "value": "L30. Funded" },
        { "label": "L35. Closed", "value": "L35. Closed" },
        { "label": "L40. Cancelled", "value": "L40. Cancelled" },
        { "label": "L41. Terms Not Issued", "value": "L41. Terms Not Issued" },
        { "label": "S05. Servicing Setup", "value": "S05. Servicing Setup" },
        { "label": "S10. Servicing", "value": "S10. Servicing" }
     ]
     const milestone= [
        { "label": "Please Select", "value": "" },
        { "label": "Critical", "value": "Critical" },
        { "label": "Major", "value": "Major" },
        { "label": "Minor", "value": "Minor" },
        { "label": "Normal", "value": "Normal" }
     ]
     const signature = [
        { "label": "Please Select", "value": "" },
        { "label": "Auto Send as E Sign", "value": "Auto Send as E Sign" },
        { "label": "Auto Send as Need to Sign", "value": "Auto Send as Need to Sign" }
     ]
     const activeEmails =[
        { "label": "Please Select", "value": "" },
        { "label": "Application Complete", "value": "Application Complete" },
        { "label": "Asset -- Assign Agent Email", "value": "Asset -- Assign Agent Email" },
        { "label": "Asset -- Assign Maintenance", "value": "Asset -- Assign Maintenance" },
        { "label": "Collaborator Invitation", "value": "Collaborator Invitation" },
        { "label": "Condition Suspended", "value": "Condition Suspended" },
        { "label": "Deal Approved", "value": "Deal Approved" },
        { "label": "Deal Cancellation", "value": "Deal Cancellation" },
        { "label": "Deal Created", "value": "Deal Created" },
        { "label": "Deal Denied", "value": "Deal Denied" },
        { "label": "Invite Inspector", "value": "Invite Inspector" },
        { "label": "Lead Created", "value": "Lead Created" },
        { "label": "Loan Closed", "value": "Loan Closed" },
        { "label": "Lock Request Notification", "value": "Lock Request Notification" },
        { "label": "Maturity Date 15 Days", "value": "Maturity Date 15 Days" },
        { "label": "Maturity Date 45 Days", "value": "Maturity Date 45 Days" },
        { "label": "Password Email", "value": "Password Email" },
        { "label": "Post Close", "value": "Post Close" },
        { "label": "Register", "value": "Register" },
        { "label": "Schedule an Appointment", "value": "Schedule an Appointment" },
        { "label": "Underwriting Term Sheet", "value": "Underwriting Term Sheet" },
        { "label": "Welcome", "value": "Welcome" },
        { "label": "Welcome 2", "value": "Welcome 2" }
     ]

    //  const formValues = [
    //     { value : '' , label : 'Please Select values'},
    //     { value: '{borrower1}', label: 'Borrower 1', subOptions: ['Sub Option 1.1', 'Sub Option 1.2'] },
    //     { value: '{borrower2}', label: 'Borrower 2', subOptions: ['Sub Option 2.1', 'Sub Option 2.2'] },
    //     { value: '{borrower3}', label: 'Borrower 3', subOptions: ['Sub Option 3.1', 'Sub Option 3.2'] },
    // ];
   


     const styles = {
        'opacity': formChanged ? '1' : '0.6',
        'backgroundColor': '#7CC149',
        'color': '#fff'
    };

      const handleEdit =(index)=>{
        setButtonType ('update');
        // setInputError(false);
        setEditIndex(index);
        console.log('----------->',index);
        setNewFieldName(formTemplatesData?.customPlaceHolder[index]);
        console.log('edit value---->',formTemplatesData?.customPlaceHolder[index]);
        setErrors({ ...errors, fieldName: null })
      };

      const handleChange = (e) => {
        setFormTemplatesData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
      };

      const handleAdd = ()=>{
        let error = {}
        if(newFieldName.trim().length===0){
            error.fieldName = 'Empty'
        }else{
            setFormTemplatesData({...formTemplatesData,customPlaceHolder : [...formTemplatesData.customPlaceHolder ,newFieldName ] });
            setNewFieldName('');
        }
        setErrors({...errors,...error});
      };

      const handleUpdate=()=>{
        let error = {}
        if(newFieldName.trim().length===0){
            error.fieldName = 'Empty'
        }else{
            let copiedData = formTemplatesData;
            copiedData.customPlaceHolder[editIndex] = newFieldName;
            setFormTemplatesData(copiedData);
            setButtonType('add');
            setNewFieldName('');
            setEditIndex('');   
        }
        setErrors({...errors,...error});
      };

      const handleDelete = (index) => {
        const updatedFields = formTemplatesData.customPlaceHolder?.filter((_, i) => i !== index);
        console.log('delete index', index, updatedFields);
        setFormTemplatesData({ ...formTemplatesData, customPlaceHolder: updatedFields });
        setNewFieldName('');
        setEditIndex('');
        setButtonType('add');
      }
      
      const handleModalClose =()=>{
        setErrors({ ...errors, fieldName: null });
        setEditIndex('');
        setNewFieldName('');
        setButtonType('add');
      }

      const handleSave = ()=>{
        const fieldsToCheck = {
            name : 'name',docTag : 'docTag', signature : 'signature'
        };
        let error = {}
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            if (!formTemplatesData[fieldName].trim()) {
                error[fieldName] = `Please Enter ${fieldLabel} `;
            }
        };
        setErrors(error);
        if (Object.keys(error).length === 0) {
            console.log('formTemplatesData to api----->',{...formTemplatesData, 'template' : template ,'documentFolder': documentFolder});
            dispatch(showLoader());
            let mainData = {...formTemplatesData, 'template' : template ,'documentFolder': documentFolder};
            addFormTamplate(mainData).then(function (response) {
                dispatch(hideLoader());
                if (response?.result) {
                    mainData?._id ? setmessage('Form Updated successfully') : setmessage('Form saved successfully')
                    setPopupOpen(true);
                }
                }).catch((err) => {
                  dispatch(hideLoader());
                  console.error.bind("my account error", err);
                })
        }
      }

      const closePopup = () => {
        setPopupOpen(false);
        navigate('/formmanager')
    };
    const SaveFile = () => {
        let replacedContent = template;

        // Create new jsPDF instance with custom width
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'px', // Units are in pixels
            format: 'a4'  // Custom width and height
        });

        // Convert HTML content to PDF
        doc.html(replacedContent, {
            x: 20, // Horizontal position (in pixels)
            y: 0, // Vertical position (in pixels)
            html2canvas: {
                scale: 0.565 // Adjust scale to fit content within the page
            },

            callback: () => {
                // // Save PDF file after rendering is complete
                // let propertyCity = underWritingData?.abouttheproperty?.propertycity || 'defaultCity';
                // let termSheet = termSheetName || 'defaultTermSheet';
                // let loanId = loanid || 'defaultLoanId';
                let fileName = `${formTemplate?.templateid}.pdf`;
                doc.save(fileName);
                dispatch(hideLoader());
            }
        });
    }

    // const handleChangeValues = (e) => {
    //     // setFormTemplatesData((prev) => ({
    //     //     ...prev,
    //     //     [e.target.name]: e.target.value,
    //     // }));
    //     const selectedValue = e.target.value;
    //     setFormVariables((prevFormVariables) => [...prevFormVariables, selectedValue]);
    //     setFormValuesOption(selectedValue);
    //     //setTemplate((prevContent) => selectedValue + prevContent);

    // };

    const handleChangeValues = (value) => {
        const selectedValue = value;
    
        // Update formVariables by appending the selected value to the array
        setFormVariables((prevFormVariables) => {
            // Check if selectedValue is already in the array to avoid duplicates
            if (!prevFormVariables.includes(selectedValue)) {
                return [...prevFormVariables, selectedValue];
            }
            return prevFormVariables; // Return the same array if the value already exists
        });
    
        // Update formValuesOption with the selected value
        setFormValuesOption(selectedValue);
        setValuesDependency(valueDependency + 1);
        // Uncomment if you want to prepend the selected value to the template
        // setTemplate((prevContent) => selectedValue + prevContent);
    };

    
    

    // useEffect(() => {
    //     // Format content
    //     const formattedContent = formVariables?.join('\t');
    //     setFormattedVariables(formattedContent);
    // }, [formVariables]);

    const handleInputChange = (index, value) => {
        const newDocumentFolders = [...documentFolder];
        newDocumentFolders[index] = value;
        setDocumentFolder(newDocumentFolders);
    };



    return (
        <div id="layout-wrapper">
{loading ? <Loader/> : null} 
        {isPopupOpen && (
            <PopupMessage
             type={popupMessage ==  'Please try with new reset link' ? 'warning' : 'success'}
             message={popupMessage}
             onClose={closePopup}
            />
        )}

           <MainHeader />


            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management states_cities">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Template</h4>
                                <a href="#" id="navigateFormTemplateBack" onClick={(e) => {e.preventDefault(); navigate(-1);}} className="back_btn"><span className="material-icons icon"> arrow_back
                                    </span>BACK</a>
                            </div>
                            <div className="card">
                                <div className="card-body ">
                                    <div className="form_section">

                                        <div className="form_section">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Template Name</label>
                                                        <input id = "formTemplateName" onChange={handleChange} value={formTemplatesData.name} name='name' type="text" className={`form-control ${errors?.name && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, name: null }) }}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Template Tag</label>
                                                        <input id = "formTemplateTag" onChange={handleChange} value={formTemplatesData.docTag} name='docTag' type="text" className={`form-control ${errors?.docTag && 'invalid-input'}`}  onFocus={() => { setErrors({ ...errors, docTag: null }) }}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                 <div className="form-group">
                                                        <label>Signature</label>
                                                        <select id = "formTemplateSignature" onChange={handleChange} value={formTemplatesData?.signature}  name='signature' className={`form-control ${errors?.signature && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, signature: null }) }}>
                                                            {signature.map((data,index)=>(
                                                                <option value={data.value}>{data.label}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div> 
                                                {/* <div className="col-md-3">
                                                 <div className="form-group">
                                                        <label>Values</label>
                                                        <select id = "formTemplateValues" onChange={handleChangeValues} value={formValuesOption}  name='formValues' className={`form-control`}>
                                                            {formValues.map((data,index)=>(
                                                                <option value={data.value}>{data.label}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>  */}
                                                <ValuesDropdown onChange={handleChangeValues}/>



                                              
                                                {/* <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Choose From Group</label>
                                                        <select onChange={handleChange}  name='chooseFromGroup' className={`form-control ${errors?.chooseFromGroup && 'invalid-input'}`}  onFocus={() => { setErrors({ ...errors, chooseFromGroup: null }) }}>
                                                        
                                                        {chooseFromGroup.map((data,index)=>(
                                                            <option value={data.value}>{data.label}</option>
                                                        ))}
                                                            
                                                        </select>
                                                    </div>
                                                </div> */}

                                            </div>
                                            {/* <hr className="hr" />
                                            <h3 className="title">WORKFLOW TrIGgERS</h3>
                                            <div className="row ">
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Phase</label>
                                                        <select onChange={handleChange}  name='phase' className={`form-control ${errors?.phase && 'invalid-input'}`}  onFocus={() => { setErrors({ ...errors, phase: null }) }}>
                                                            {phase.map((data,index)=>(
                                                                <option value={data.value}>{data.label}</option>
                                                            ))}
                                                            <option>Please Select Option</option>
                                                        </select>
                                                    </div>


                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Folder</label>
                                                        <select onChange={handleChange}  name='folder' className={`form-control ${errors?.folder && 'invalid-input'}`}  onFocus={() => { setErrors({ ...errors, folder: null }) }}>
                                                            {folder.map((data,index)=>(
                                                                <option value={data.value}>{data.label}</option>
                                                            ))}
                                                            
                                                        </select>
                                                    </div>

                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Stage</label>
                                                        <select onChange={handleChange}  name='stage' className={`form-control ${errors?.stage && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, stage: null }) }}>
                                                            {stage.map((data,index)=>(
                                                                <option value={data.value}>{data.label}</option>
                                                            ))}
                                                            
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Milestone</label>
                                                        <select onChange={handleChange}  name='milestone' className={`form-control ${errors?.milestone && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, milestone: null }) }}>
                                                            {milestone.map((data,index)=>(
                                                                 <option value={data.value}>{data.label}</option>
                                                            ))}
                                                           
                                                        </select>
                                                    </div>
                                                </div>
                                            </div> */}
                                           
                                            {/* <hr className="hr" />
                                            <h3 className="title">Attach Form to email</h3>
                                            <div className="row ">
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Active Emails</label>
                                                        <select onChange={handleChange}  name='activeEmails' className={`form-control ${errors?.activeEmails && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, activeEmails: null }) }}>
                                                            {activeEmails.map((data,index)=>(
                                                                <option value={data.value}>{data.label}</option>
                                                            ))}
                                                            
                                                        </select>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <hr className="hr" />
                                            {/* <button onClick={(e) => { e.preventDefault(); SaveFile() }} className="fs_btn">Download</button> */}
                                            <div className='print_title'>
                                            <h3 className="title">Template Content</h3>
                                            {/* <button className="border_btn print  mt-0"><span className="material-symbols-outlined icon"> print </span> Print</button> */}
                                            </div>
                                            <div className="row cf_print">
                                                <div className="col-md-12">
                                                    <div className="form-group">

                                                    <EditorEdit content={template} setContent={setTemplate} editorValidate = {editorValidate} setEditorValidate = {setEditorValidate} formVariables={formVariables} formValuesOption={formValuesOption} valueDependency = {valueDependency} />
                                                    {/* <ReactQuill
                                                    name='template'
                                                    style={{ height: "200px" }}
                                                    theme="snow"
                                                    value={template}
                                                    onChange={template}
                                                    modules= {modules}
                                                    formats={["header", "bold", "italic", "underline", "strike", "link", "image", "blockquote", "code-block","list", "indent","align", "color", 
                                                    "background" ,"script"]}                    
                                                    /> */}
                                                    </div>

                                                </div>
                                            </div>
                                            {/* <div className="btn-group mt_10 mb_20">
                                                <button onClick={handleSave} className="fs_btn" disabled={!formChanged} style={styles}>Save</button>
                                            </div> */}
                                            <br />
                                            {/* <div className='print_title'>
                                            <h3 className="title">Template Footer</h3>
                                            </div> */}
                                                 <br />
                                            <div className="row cf_print">
                                                <div className="col-md-12"> 
                                                    <h3 className="title">Template Footer</h3> {/* Title above the inputs */}
                                            <div className="form-group" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <input
                                                                type="text"
                                                                name="documentFolder"
                                                                value={documentFolder[0]}
                                                                onChange={(e) => { handleInputChange(0, e.target.value) }}
                                                                placeholder="Enter left text"
                                                                className="form-control"
                                                                style={{ marginRight: '10px' }} // Optional spacing
                                                            />
                                                            <input
                                                                type="text"
                                                                name="documentFolder"
                                                                value={documentFolder[1]}
                                                                onChange={(e) => { handleInputChange(1, e.target.value) }}
                                                                placeholder="Enter center text"
                                                                className="form-control"
                                                                style={{ marginRight: '10px' }} // Optional spacing
                                                            />
                                                            <input
                                                                type="text"
                                                                name="documentFolder"
                                                                value={documentFolder[2]}
                                                                onChange={(e) => { handleInputChange(2, e.target.value) }}
                                                                placeholder="Enter right text"
                                                                className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            <div className="btn-group mt_10">
                                                <button id = "submitFormTemplate" onClick={handleSave} className="fs_btn" disabled={!formChanged} style={styles}>{formTemplate?.templateid ? 'Update' : 'Save'}</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal popup fade" id="staticBackdrop" data-bs-backdrop="static" tabIndex="-1" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                {/* <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Custom Place Holder</h5>
                                    <button onClick={handleModalClose} type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div> */}
                                <div className="modal-body">

                                    <div className="form-group">
                                        <label>Field Name</label>
                                        <div className="dsp_flx">
                                            <input type="text" name='fieldName' value={newFieldName} onChange={(e)=>{setNewFieldName(e.target.value)}} placeholder="Enter" className={`form-control ${errors?.fieldName && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, fieldName: null }) }}/>
                                            {/* <button onClick={handleAdd} type="button" className="fs_btn ml_20">Add</button> */}
                                            {buttonType==='add' ? (<button onClick={handleAdd} className="fs_btn ml_20">
                                                        Add 
                                                        </button>) : <button 
                                                        onClick={handleUpdate}
                                                         className="fs_btn ml_20">
                                                        Update 
                                                        </button>}
                                        </div>
                                    </div>
                                    <ul className="lp_feature">
                                    {/* {formTemplatesData.customPlaceHolder.map((data,index)=>{
                                        return(
                                        <li key={index}> <span className="text">{data}</span>
                                            <span className="right">
                                                 <a href="#" onClick={(e) => {e.preventDefault(); handleEdit(index)}} className="icon material-symbols-outlined">edit</a>
                                            
                                            <a href="#" onClick={(e) => {e.preventDefault(); handleDelete(index)}} className="icon material-symbols-outlined">delete</a> </span>
                                        </li>
                                        )
                                    })} */}
                                        
                                   
                                    </ul>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
}

export default CreateFormTemplates;