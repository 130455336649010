import React from 'react';

const Blockingtextmsg = (props)=>{
    return(
        <div class="card mb-0 ip_block"><div class="card-body inprogress_page">
            <span class="material-symbols-outlined icon"> handyman </span>
            <p>{props?.textmessage}</p></div></div> 
    )
};

export default Blockingtextmsg;