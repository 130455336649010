import React, { useEffect, useState, useRef } from 'react';
import { getMenus, getAllLoans } from "./../redux/reducers/reducer";
import { useDispatch, useSelector } from 'react-redux';
import { getallUsers, getSavedSearchListApi, addShareSearchApi} from "./../utils/reducer";
import MainHeader from './MainHeader';
import SideMenu from './SideMenu';
import Loader from './Loader';
import Select from 'react-select';

import { showLoader, hideLoader } from '../redux/reducers/Actions';
import { useNavigate } from 'react-router-dom';
import { useLoanInfo } from '../utils/loanInfoContext';
import PopupMessage from './PopupMessage';

const ShareSearch = (props) => {
  const navigate = useNavigate();
  // 

  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [savedSearchData, setSavedSearchData] = useState({});
  const [shareSearchData, setShareSearchData] = useState({});
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('Shared successfully');


  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const configdata = useSelector(state => state.configdata);

  useEffect(() => {
    getAllUserApiFunction();
    getSavedSearchListFun();

  }, [])
  const handleShareSearch = () => {
    let newErrors = {};
   
    if (shareSearchData?.['search'] === undefined || shareSearchData?.['search'].length <= 0 ) {
      newErrors['search'] = `Enter a search`;
    }
    if (shareSearchData?.['user'] === undefined || shareSearchData?.['user'].length <= 0 ) {
      newErrors['user'] = `Enter a user`;
    }
    setErrors(newErrors);
    //  console.log('errors ----> ', newErrors)

    if (Object.keys(newErrors).length === 0) {
      shareSearchFun();
    } else {
      //  console.log('errors ----> ', newErrors)
    }

  }
  const shareSearchFun = () => {
    const payload = {
      searchid: shareSearchData?.search?.map(item => item.searchid),
      userid: shareSearchData?.user?.map(item => item.userid)
  };
  dispatch(showLoader());
    addShareSearchApi(payload).then(function (response) {
      //  console.log('get all advFormData response', response);
      if (response?.result) {
        // props.setLoans(response?.result)
        // props.setShowPage('')
        // setSavedSearchData(response?.result);
        if(response?.result == 'updated'){
            setAlertMessage('Shared successfully')
        }else {
          setAlertMessage('You have already shared this saved search with the user.')
        }
        setAlert(true);
        setShareSearchData({});
      }
      dispatch(hideLoader());
    })
      .catch((err) => {
        //  console.error.bind("my account error", err);
        dispatch(hideLoader());
      })
  }
  const getSavedSearchListFun = () => {
    getSavedSearchListApi().then(function (response) {
      //  console.log('get all advFormData response', response);
      if (response?.result) {
        // props.setLoans(response?.result)
        // props.setShowPage('')
        const sercheddata = response?.result?.map(item => { return { 'value': item.name, 'label': item.name, 'searchid': item.searchid } })
        setSavedSearchData(sercheddata);

      }
    })
      .catch((err) => {
        //  console.error.bind("my account error", err);
      })
  }
  //  console.log('userssssssssssssssss', users)
  //  console.log('savedsearch', savedSearchData)
  const getAllUserApiFunction = () => {
    dispatch(showLoader());
    getallUsers().then(function (response) {
      //  console.log('privacymenunewwegetallUsers', response.result);
      if (response?.result?.data) {
        const mappedUsernames = response?.result?.data.map(item => item.userInfo.username);
        //  console.log('mappedUsernames', mappedUsernames)
        const userdata = response?.result?.data.map(item => { return { 'value': item.userInfo.username, 'label': item?.userInfo?.firstname + ' '+ item?.userInfo?.lastname, 'userid': item.userid } })
        setUsers(userdata);
        dispatch(hideLoader());
      }
    })
      .catch((err) => {
        //  console.error.bind("my account error", err);
        dispatch(hideLoader());
      })
  }

  const handleMultiSelect = (selected, key) => {
    //  console.log('selected', selected)
    const updatedObject = { ...shareSearchData, [key]: selected.map(item => ({ value: item.value, label: item.label, [`${key}id`]: item[`${key}id`] })) };

    for (const key in updatedObject) {
      if (updatedObject.hasOwnProperty(key)) {
        const value = updatedObject[key];

        if (value === '' || (Array.isArray(value) && value.length === 0) || value === undefined) {
          delete updatedObject[key];
        }
      }
    }
    setShareSearchData(updatedObject)
  };

  const handleSavedSearchClose = () => {
    props.setShowPage('');
    props.handleClearSearch();
    props.setfilteredLoans([])
    
  }

  //  console.log('shareSearchData', shareSearchData)
  return (
    <>    {alert &&  <PopupMessage
      type={'success'}
      message={alertMessage}
      onClose={()=>{setAlert(false);}}
      />}
    <div className="columns share_block">
      <div className="title_block">
        <h3 className="title">Share You Saved Searches</h3>
        {/* <button type="button" className="btn close_btn" onClick={() => {props.setShowPage('');props.handleClearSearch();props.setfilteredLoans([])}}><span className="material-icons icon"> close </span></button> */}
        <button type="button" className="btn close_btn" onClick={handleSavedSearchClose}><span className="material-icons icon"> close </span></button>
      </div>
      <div className="form_section">
          <div className="row">

            <div className="col-md-5">
              <div className="form-group">
                <label className='bold'>All Views</label>
                <Select
                  inputId='sharesearch-allviews'
                  className={` ${errors?.search && 'invalid-input'}`}
                  onFocus={() => { setErrors({ ...errors, search: null }) }}
                  name="search"

                  value={shareSearchData?.search && Array.isArray(shareSearchData?.search) ? shareSearchData?.search : []}


                  options={savedSearchData}
                  onChange={(e) => handleMultiSelect(e, 'search')}
                  isMulti
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className='bold'>Everyone</label>
                <Select
                  inputId='sharesearch-everyone'
                  className={` ${errors?.user && 'invalid-input'}`}
                  onFocus={() => { setErrors({ ...errors, user: null }) }}
                  name="user"

                  value={shareSearchData?.user && Array.isArray(shareSearchData?.user) ? shareSearchData?.user : []}
                  options={users}
                  onChange={(e) => handleMultiSelect(e, 'user')}
                  isMulti
                />
              </div>
            </div>


            <div className="col-md-1">
              <div className="form-group">
              <label className='bold'>&nbsp;</label>
                <button id="sharesearch-share" type="button" onClick={(e) => handleShareSearch()} className="add_button fillbtn_new ">Share</button>
              </div>
            </div>
          </div>
        </div>
    </div>
      </>
  )

}

export default ShareSearch;