import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import {getRecurringBillingData, updateRecurringBillingData} from "./../utils/reducer";
import { useLoanInfo } from '../utils/loanInfoContext';
import { hideLoader, showLoader } from '../redux/reducers/Actions';
import { useDispatch, useSelector} from 'react-redux';
import Loader from './Loader';
import PopupMessage from './PopupMessage';
const ServicesRecurringBills = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loaderVisible);
    const { loanInfo, setLoanInfo} = useLoanInfo();
    const [loanid,setLoanid] = useState(loanInfo?.loanid);
    const [popupMessage, setmessage] = useState('Changes have been saved successfully.');
    const [isPopupOpen, setPopupOpen] = useState(false);
    // 
    const [recurringBilling, setRecurringBilling] = useState({
        paymentSetup: {
          principal: false,
          interest: false,
          impounds: false,
          fees: false,
          lateFees: false,
        },
        achPayments: {
          fundingAccountName: '',
          bankName: '',
          bankRoutingNumber: '',
          bankAccountNumber: '',
          billingStartDate: '',
          achPostedDay: 1,
          numberOfBillingPeriods: '',
          descriptionOfCharge: '',
          trustAccountForACH: 'Easy Street Capital LLC',
        },
        // creditCardPayments: {
        //   creditCardType: '',
        //   creditCardNumber: '',
        //   cardholderFirstName: '',
        //   cardholderLastName: '',
        //   securityCode: '',
        //   expirationDate: '',
        //   frequency: '',
        //   numberOfBillingPeriods: '',
        //   initialAmount: '',
        //   rebillAmount: '',
        //   rebillStartDate: '',
        // },
      });
      useEffect(() => {
        if(loanid){
            initializer();
        }
      }, [loanid]);

      const initializer = () => {
        dispatch(showLoader());
        getRecurringBillingData(loanid)
        .then(function(response) {
            if (response) {
                console.log(' getPayOffDetails api response', response);
                if(response?.result?.[0]){
                    setRecurringBilling(response.result[0]);
                }
                
                dispatch(hideLoader());
            }
        })
        .catch((err) => {
            console.error("getPayOffDetails api response", err); 
            dispatch(hideLoader());
        });
        
        }

      useEffect(() => {
        setLoanid(loanInfo?.loanid)
    }, [loanInfo?.loanid]);
      const handlePaymentSetupChange = (field) => {
        setRecurringBilling((prevState) => ({
          ...prevState,
          paymentSetup: {
            ...prevState.paymentSetup,
            [field]: !prevState.paymentSetup[field],
          },
        }));
      };

      const handleAchPaymentsChange = (field, value) => {
        setRecurringBilling((prevState) => ({
          ...prevState,
          achPayments: {
            ...prevState.achPayments,
            [field]: value,
          },
        }));
      };

      const handleCreditCardPaymentsChange = (field, value) => {
        setRecurringBilling((prevState) => ({
          ...prevState,
          creditCardPayments: {
            ...prevState.creditCardPayments,
            [field]: value,
          },
        }));
      };

      const handleSave = () => {
        // Save the entire recurringBilling object
        console.log('Saving recurring billing data:', recurringBilling);
        dispatch(showLoader());
        updateRecurringBillingData(recurringBilling, loanid).then(function (response) {
            console.log('responsehere',response);
            if(response?.result == "Success"){
                setmessage("Changes have been saved successfully.")
            }else{
                setmessage("Something went wrong. Please try again later.")
            }
            dispatch(hideLoader());
            setPopupOpen(true);
          }).catch((err) => {
            console.error.bind("Login error", err);
            dispatch(hideLoader());
          })
        // Implement your API call here
      };
      const closePopup = () => {
        setPopupOpen(false);
   
      };

    return (
        <div id="layout-wrapper" className="dashboard">
             {loading ? <Loader /> : null}
            {isPopupOpen && !loading && (
                <PopupMessage
                    type={popupMessage == 'Changes have been saved successfully.' ? 'success' : 'warning'}
                    message={popupMessage}
                    onClose={closePopup}
                />
            )}
            <PipelineHeader />
            <div className="dashboard_block">
            <PipelineStatus />
            <h2 className="form_title"> <span>Servicing <span className="space">&#10095;</span> <span className="sub">Recurring Bill</span></span> 
            </h2>
                <div className="card user_management detailed_app">
                    <div className="card-body my_account">
                        <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">

                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#PROPERTY" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">Recurring payment setup</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#LOAN" role="tab">
                                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                    <span className="d-none d-sm-block">recurring ach payments</span>
                                </a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#REGULATION" role="tab">
                                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                    <span className="d-none d-sm-block">recurring credit card payments</span>
                                </a>
                            </li> */}


                        </ul>


                        <div className="tab-content pt-15 text-muted">

                            <div className="tab-pane active property" id="PROPERTY" role="tabpanel">

                                <div className="form_section">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-center select_categories">
                                        <h4 className="mb-sm-0 font-size-18 co_borrower">Recurring payment setup</h4>

                                    </div>
                                    <div className="mailt_template categories_list">
                                        <label className="toggle">
                                            <span className="toggle-label">Principal</span>
                                            <input className="toggle-checkbox" type="checkbox"  checked={recurringBilling.paymentSetup.principal}
                                            onChange={(e) => handlePaymentSetupChange('principal', e.target.checked)}/>
                                            <div className="toggle-switch"></div>
                                        </label>
                                        <label className="toggle">
                                            <span className="toggle-label">Interest</span>
                                            <input className="toggle-checkbox" type="checkbox" checked={recurringBilling?.paymentSetup.interest} onChange={(e) => handlePaymentSetupChange('interest', e.target.checked)}/>
                                            <div className="toggle-switch"></div>
                                        </label>
                                        {/* <label className="toggle">
                                            <span className="toggle-label">Impounds</span>
                                            <input className="toggle-checkbox" type="checkbox" value={recurringBilling?.paymentSetup.impounds} onChange={(e) => handlePaymentSetupChange('impounds', e.target.checked)}/>
                                            <div className="toggle-switch"></div>
                                        </label> */}
                                        <label className="toggle ">
                                            <span className="toggle-label">Fees</span>
                                            <input className="toggle-checkbox" type="checkbox" checked={recurringBilling?.paymentSetup.fees} onChange={(e) => handlePaymentSetupChange('fees', e.target.checked)} />
                                            <div className="toggle-switch"></div>
                                        </label>
                                        {/* <label className="toggle">
                                            <span className="toggle-label">Late Fees</span>
                                            <input className="toggle-checkbox" type="checkbox"  value={recurringBilling?.paymentSetup.lateFees} onChange={(e) => handlePaymentSetupChange('lateFees', e.target.checked)} />
                                            <div className="toggle-switch"></div>
                                        </label> */}

                                    </div>
                                    <div className="send_button">
                                    <button className="fs_btn" onClick={handleSave}>Save</button>
                                    </div>
                                </div>

                            </div>
                            <div className="tab-pane" id="LOAN" role="tabpanel">
                                <div className="page-title-box pb-0">

                                </div>
                                <div className="form_section">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Funding Account Name</label>
                                                <input type="text" className="form-control"
                                                value={recurringBilling.achPayments.fundingAccountName}
                                                onChange={(e) => handleAchPaymentsChange('fundingAccountName', e.target.value)}
                                                    placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Bank Name</label>
                                                <input type="text" className="form-control"
                                                value={recurringBilling.achPayments.bankName}
                                                onChange={(e) => handleAchPaymentsChange('bankName', e.target.value)}
                                                    placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Bank Routing Number</label>
                                                <input type="text" className="form-control"
                                                value={recurringBilling.achPayments.bankRoutingNumber}
                                                onChange={(e) => handleAchPaymentsChange('bankRoutingNumber', e.target.value)}
                                                    placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Bank Account Number</label>
                                                <input type="text" className="form-control"
                                                value={recurringBilling.achPayments.bankAccountNumber}
                                                onChange={(e) => handleAchPaymentsChange('bankAccountNumber', e.target.value)}
                                                    placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Billing Start Date</label>
                                                <input type="date" className="form-control" placeholder="mm-dd-yyy" 
                                                value={recurringBilling.achPayments.billingStartDate}
                                                onChange={(e) => handleAchPaymentsChange('billingStartDate', e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>ACH Posted Day</label>
                                                <select className="form-control"
                                                    value={recurringBilling.achPayments.achPostedDay}
                                                    onChange={(e) => handleAchPaymentsChange('achPostedDay', e.target.value)}>
                                                    <option selected="">Select</option>
                                                    <option selected="selected" value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                    <option value="17">17</option>
                                                    <option value="18">18</option>
                                                    <option value="19">19</option>
                                                    <option value="20">20</option>
                                                    <option value="21">21</option>
                                                    <option value="22">22</option>
                                                    <option value="23">23</option>
                                                    <option value="24">24</option>
                                                    <option value="25">25</option>
                                                    <option value="26">26</option>
                                                    <option value="27">27</option>
                                                    <option value="28">End Of The Month</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Number of Billing Periods</label>
                                                <input type="text" className="form-control"
                                                value={recurringBilling.achPayments.numberOfBillingPeriods}
                                                onChange={(e) => handleAchPaymentsChange('numberOfBillingPeriods', e.target.value)}
                                                    placeholder="Enter" />
                                            </div>
                                        </div><div className="col-md-3">
                                            <div className="form-group">
                                                <label>Description of Charge</label>
                                                <input type="text" className="form-control"
                                                value={recurringBilling.achPayments.descriptionOfCharge}
                                                onChange={(e) => handleAchPaymentsChange('descriptionOfCharge', e.target.value)}
                                                    placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Trust Account for ACH</label>
                                                <select className="form-control" 
                                                value={recurringBilling.achPayments.trustAccountForACH}
                                                onChange={(e) => handleAchPaymentsChange('trustAccountForACH', e.target.value)}>
                                                    <option selected="">Select</option>
                                                    <option>Easy Street Capital LLC</option>
                                                </select>
                                            </div>
                                        </div>


                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <button className="fs_btn" onClick={handleSave}>Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="tab-pane property loan_audit" id="REGULATION" role="tabpanel">
                                <div className="form_section">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Credit Card Type</label>
                                                <select className="form-control"
                                                value={recurringBilling.creditCardPayments.creditCardType}
                                                onChange={(e) => handleCreditCardPaymentsChange('creditCardType', e.target.value)}>
                                                    <option selected="">Select</option>
                                                    <option>NA</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Credit Card Number</label>
                                                <input type="text" className="form-control"
                                                value={recurringBilling.creditCardPayments.creditCardNumber}
                                                onChange={(e) => handleCreditCardPaymentsChange('creditCardNumber', e.target.value)}
                                                    placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>3-Digit Security Code (CVV)</label>
                                                <input type="text" className="form-control"
                                                value={recurringBilling.creditCardPayments.securityCode}
                                                onChange={(e) => handleCreditCardPaymentsChange('securityCode', e.target.value)}
                                                    placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Expiration</label>
                                                <input type="date" className="form-control" placeholder="mm-dd-yyy" 
                                                value={recurringBilling.creditCardPayments.expirationDate}
                                                onChange={(e) => handleCreditCardPaymentsChange('expirationDate', e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Cardholder First Name</label>
                                                <input type="text" className="form-control"
                                                value={recurringBilling.creditCardPayments.cardholderFirstName}
                                                onChange={(e) => handleCreditCardPaymentsChange('cardholderFirstName', e.target.value)}
                                                    placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Cardholder Last Name</label>
                                                <input type="text" className="form-control"
                                                value={recurringBilling.creditCardPayments.cardholderLastName}
                                                onChange={(e) => handleCreditCardPaymentsChange('cardholderLastName', e.target.value)}
                                                    placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Initial Amount</label>
                                                <input type="text" className="form-control"
                                                 value={recurringBilling.creditCardPayments.initialAmount}
                                                 onChange={(e) => handleCreditCardPaymentsChange('initialAmount', e.target.value)}
                                                    placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Rebill Start Date</label>
                                                <input type="date" className="form-control" placeholder="mm-dd-yyy" 
                                                value={recurringBilling.creditCardPayments.rebillStartDate}
                                                onChange={(e) => handleCreditCardPaymentsChange('rebillStartDate', e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Frequency</label>
                                                <select className="form-control" 
                                                value={recurringBilling.creditCardPayments.frequency}
                                                onChange={(e) => handleCreditCardPaymentsChange('frequency', e.target.value)}>
                                                    <option selected="">Select</option>
                                                    <option>NA</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Number of Billing Periods</label>
                                                <input type="text" className="form-control"
                                                value={recurringBilling.creditCardPayments.numberOfBillingPeriods}
                                                onChange={(e) => handleCreditCardPaymentsChange('numberOfBillingPeriods', e.target.value)}
                                                    placeholder="Enter" />
                                            </div>
                                        </div><div className="col-md-3">
                                            <div className="form-group">
                                                <label>Rebill Amount</label>
                                                <input type="text" className="form-control"
                                                value={recurringBilling.creditCardPayments.rebillAmount}
                                                onChange={(e) => handleCreditCardPaymentsChange('rebillAmount', e.target.value)}
                                                    placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <a href="#" className="fs_btn" onClick={handleSave}>Sumbit</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div> */}

                        </div>

                    </div>
                </div>
            </div>
            <PipelineFooter />
        </div>
    );
};

export default ServicesRecurringBills;