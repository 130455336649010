import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

const PopupMessage = ({ message, onClose, type ,customButtons }) => {
    return (
      <SweetAlert type={type ? type: 'success'}  onConfirm={onClose} onCancel={onClose} 
        customButtons={
          customButtons ? customButtons : (
              <button
                  id="closePopupBtn"
                  className="btn btn-primary"
                  onClick={onClose}
                  style={{ fontSize: '16px' }}
              >
                  Ok
              </button>
          )
        }
    >
        <p>{message}</p>

</SweetAlert>
    );
  };
export default PopupMessage;