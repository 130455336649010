import React from 'react';
import HomeFooter from './HomeFooter';
const LoanProgramsEasyFix = () => {
    return (
        <div id="layout-wrapper">


            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">

                        <div className="navbar-brand-box">
                            <a className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src="assets/images/brand/logo-light.png" alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src="assets/images/brand/logo-light.png" alt="" height="17" />
                                </span>
                            </a>

                            <a className="logo logo-light">
                                <span className="logo-sm">
                                    <img src="assets/images/brand/logo-light.png" alt="" height="7" />
                                </span>
                                <span className="logo-lg">
                                    <img src="assets/images/brand/logo-light.png" alt="" height="30" />
                                </span>
                            </a>
                        </div>

                    </div>

                    <div className="d-flex">

                        <div className="dropdown d-inline-block d-lg-none ms-2">
                            <button type="button" className="btn header-item noti-icon waves-effect"
                                id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <i className="mdi mdi-magnify"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                aria-labelledby="page-header-search-dropdown">

                                <form className="p-3">
                                    <div className="form-group m-0">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search ..."
                                                aria-label="Recipient's username" />
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" type="submit"><i
                                                    className="mdi mdi-magnify"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>


                        <div className="dropdown d-inline-block">
                            <button type="button" className="btn header-item noti-icon waves-effect"
                                id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <i className="bx bx-bell bx-tada"></i>
                                <span className="badge bg-danger rounded-pill">3</span>
                            </button>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                aria-labelledby="page-header-notifications-dropdown">
                                <div className="p-3">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h6 className="m-0" key="t-notifications"> Notifications </h6>
                                        </div>
                                        <div className="col-auto">
                                            <a href="#!" className="small" key="t-view-all"> View All</a>
                                        </div>
                                    </div>
                                </div>
                                <div data-simplebar >
                                    <a className="text-reset notification-item">
                                        <div className="d-flex">
                                            <div className="avatar-xs me-3">
                                                <span className="avatar-title bg-primary rounded-circle font-size-16">
                                                    <i className="bx bx-cart"></i>
                                                </span>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1" key="t-your-order">Your order is placed</h6>
                                                <div className="font-size-12 text-muted">
                                                    <p className="mb-1" key="t-grammer">If several languages coalesce the
                                                        grammar</p>
                                                    <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span
                                                        key="t-min-ago">3 min ago</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a className="text-reset notification-item">
                                        <div className="d-flex">
                                            <img src="assets/images/users/avatar-3.jpg"
                                                className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1">James Lemire</h6>
                                                <div className="font-size-12 text-muted">
                                                    <p className="mb-1" key="t-simplified">It will seem like simplified English.
                                                    </p>
                                                    <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span
                                                        key="t-hours-ago">1 hours ago</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a className="text-reset notification-item">
                                        <div className="d-flex">
                                            <div className="avatar-xs me-3">
                                                <span className="avatar-title bg-success rounded-circle font-size-16">
                                                    <i className="bx bx-badge-check"></i>
                                                </span>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1" key="t-shipped">Your item is shipped</h6>
                                                <div className="font-size-12 text-muted">
                                                    <p className="mb-1" key="t-grammer">If several languages coalesce the
                                                        grammar</p>
                                                    <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span
                                                        key="t-min-ago">3 min ago</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>

                                    <a className="text-reset notification-item">
                                        <div className="d-flex">
                                            <img src="assets/images/users/avatar-4.jpg"
                                                className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1">Salena Layfield</h6>
                                                <div className="font-size-12 text-muted">
                                                    <p className="mb-1" key="t-occidental">As a skeptical Cambridge friend of
                                                        mine occidental.</p>
                                                    <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span
                                                        key="t-hours-ago">1 hours ago</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="p-2 border-top d-grid">
                                    <a className="btn btn-sm btn-link font-size-14 text-center" >
                                        <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">View
                                            More..</span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="dropdown d-inline-block">
                            <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img className="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg"
                                    alt="Header Avatar" />
                                   <span className="d-none d-xl-inline-block ms-1" key="t-henry">John Goldsmith <br />
                                <span className="role">Team member</span> </span>
                                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href="#"><i className="bx bx-user font-size-16 align-middle me-1"></i>
                                    <span key="t-profile">My Account</span></a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item text-danger" href="#"><i
                                    className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> <span
                                        key="t-logout">Logout</span></a>
                            </div>
                        </div>



                    </div>
                </div>
            </header>

            <div className="vertical-menu">
                <div className="h-100">
                    <div id="sidebar-menu" className="sub_menu">
                        <ul className="metismenu list-unstyled">
                            <li>
                                <a href="#" className="waves-effect">
                                    <img src="assets/images/icons/menu-icons/pipelines.svg" className="icon" alt="" />

                                    <span key="t-chat">Pipelines</span>
                                </a>
                            </li>
                            <li className="has-sub">
                                <a href="#" className="waves-effect">
                                    <span className="icon"> <img src="assets/images/icons/menu-icons/reports.svg" alt="" />
                                    </span>
                                    <span key="t-file-manager">Reports</span>
                                </a>
                                <ul>
                                    <li> <a href="#" className="sub_item"> <span className="icon"> <img
                                        src="assets/images/icons/menu-icons/Branches_icon.svg" alt="" /> </span>
                                        Branches</a> </li>

                                    <li> <a href="#" className="sub_item"> <span className="icon"> <img
                                        src="assets/images/icons/menu-icons/LoanPrograms_icon.svg" alt="" />
                                    </span>Loan Programs</a> </li>
                                    <li> <a href="#" className="sub_item"> <span className="icon"> <img
                                        src="assets/images/icons/menu-icons/UserManagement_icon.svg" alt="" />
                                    </span>User Management</a> </li>
                                    <li> <a href="privacyadmin" className="sub_item"><span className="icon"> <img
                                        src="assets/images/icons/menu-icons/PrivacyPolicy_icon.svg" alt="" />
                                    </span>Privacy Policy</a> </li>

                                </ul>
                            </li>
                            <li>
                                <a href="#" className="waves-effect">
                                    <span className="icon"> <img src="assets/images/icons/menu-icons/administration.svg"
                                        alt="" /> </span>
                                    <span key="t-file-manager">Administration</span>
                                </a>
                            </li>
                            <li className="logout">
                                <a href="#" className="logout_btn">
                                    <span className="material-symbols-outlined icon"> arrow_insert </span>LOG OUT
                                </a>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>

            <div className="main-content">

            <div className="page-content user_management">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Loan Programs</h4>
                                <a href="#" className="back_btn"><span className="material-icons icon"> arrow_back
                                    </span>BACK</a>
                            </div>

                            <div className="card">
                                <div className="card-body ">
                                    <div className="form_section lp">

                                        <div className="sl_block">
                                            <div className="left">
                                                <div className="cont">
                                                    <h3 className="title">program defaults</h3>
                                                    <div className="form-group">
                                                        <label>Program <span className="rf">*</span> </label>
                                                        <select className="form-control">
                                                            <option selected="">Easy Fix</option>
                                                            <option>Easy Build</option>
                                                            <option>Easy Rent</option>
                                                        </select>
                                                    </div>
                                                    <h3 className="title mb_10 mt_20">FEATURES</h3>
                                                    <div className="form-group">
                                                        <textarea id="w3review" name="w3review" rows="4" cols="50"
                                                            className="form-control"> </textarea>
                                                    </div>
                                                    <div className="form-group">
                                                        <a href="#" className="fs_btn">Add </a>
                                                    </div>
                                                    <ul className="lp_feature">
                                                        <li> <span className="text"> <span
                                                                    className="material-symbols-outlined icon"> check_small
                                                                </span> Minimum Cash to Close</span> <a href="#"
                                                                className="icon material-symbols-outlined">delete</a> </li>
                                                        <li> <span className="text"> <span
                                                                    className="material-symbols-outlined icon"> check_small
                                                                </span> No Appraisal</span> <a href="#"
                                                                className="icon material-symbols-outlined">delete</a> </li>
                                                        <li> <span className="text"> <span
                                                                    className="material-symbols-outlined icon"> check_small
                                                                </span> No Junk Fees</span> <a href="#"
                                                                className="icon material-symbols-outlined">delete</a> </li>
                                                        <li> <span className="text"> <span
                                                                    className="material-symbols-outlined icon"> check_small
                                                                </span>No Prepayment Penalty</span> <a href="#"
                                                                className="icon material-symbols-outlined">delete</a> </li>
                                                        <li> <span className="text"> <span
                                                                    className="material-symbols-outlined icon"> check_small
                                                                </span> $ for Renovation</span> <a href="#"
                                                                className="icon material-symbols-outlined">delete</a> </li>
                                                        <li> <span className="text"> <span
                                                                    className="material-symbols-outlined icon"> check_small
                                                                </span>48 Hour Draw Fundings</span> <a href="#"
                                                                className="icon material-symbols-outlined">delete</a> </li>
                                                        <li> <span className="text"> <span
                                                                    className="material-symbols-outlined icon"> check_small
                                                                </span> 48 Hour Closings</span> <a href="#"
                                                                className="icon material-symbols-outlined">delete</a> </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="right">
                                                <div className="form_section">

                                                    <div className="row">
                                                        <div className="col-md-4">

                                                            <div className="form-group">
                                                                <label>State</label>
                                                                <select className="form-control">
                                                                    <option selected>Arizona</option>
                                                                    <option>...</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4"></div>
                                                        <div className="col-md-4"></div>
                                                        <div className="col-md-12">
                                                            <h3 className="title">underwriting sheet</h3>
                                                        </div>

                                                        <div className="col-md-3">

                                                            <div className="form-group">
                                                                <label>Points</label>
                                                                <input type="text" placeholder="0"
                                                                    className="form-control" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Select Fund</label>
                                                                <select className="form-control">
                                                                    <option selected>Easy Street Capital, LLC</option>
                                                                    <option>...</option>
                                                                </select>
                                                            </div>

                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Max LTV</label>
                                                                <input type="text" placeholder="0.00%"
                                                                    className="form-control" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Loan term</label>
                                                                <select className="form-control">
                                                                    <option selected>9 Months</option>
                                                                    <option>...</option>
                                                                </select>
                                                            </div>

                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Max LTC</label>
                                                                <input type="text" placeholder="0.00%"
                                                                    className="form-control" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Insurance Percent</label>
                                                                <input type="text" placeholder="1.00%"
                                                                    className="form-control" />
                                                            </div>


                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Interest Rate</label>
                                                                <input type="text" placeholder="0.00%"
                                                                    className="form-control" />
                                                            </div>


                                                        </div>
                                                    </div>
                                                    <hr className="hr" />
                                                    <h3 className="title">TERM sheet</h3>
                                                    <div className="row ">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Doc fee ( USD)</label>
                                                                <input type="text" placeholder="$ 1,465.00"
                                                                    className="form-control" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Property Insurance ( USD)</label>
                                                                <input type="text" placeholder="$ 2,000.00"
                                                                    className="form-control" />
                                                            </div>

                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Title</label>
                                                                <input type="text" placeholder="0.500%"
                                                                    className="form-control" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Draw Fee ( USD)</label>
                                                                <input type="text" placeholder="$ 295.00"
                                                                    className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Escrow ( USD)</label>
                                                                <input type="text" placeholder="$ 365.00"
                                                                    className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Origination Fee ( USD)</label>
                                                                <input type="text" placeholder="$ 2,500.00"
                                                                    className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className="hr" />
                                                    <h3 className="title">Originator instructions</h3>
                                                    <div className="row ">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Cash Reserves <span className="rf">*</span> </label>
                                                                <textarea id="w3review" name="w3review" rows="4"
                                                                    cols="50" className="form-control"
                                                                    spellcheck="false"> </textarea>
                                                            </div>


                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Flood Insurance Required<span
                                                                        className="rf">*</span></label>
                                                                <textarea id="w3review" name="w3review" rows="4"
                                                                    cols="50" className="form-control"
                                                                    spellcheck="false"> </textarea>
                                                            </div>

                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Year Built before 1940<span
                                                                        className="rf">*</span></label>
                                                                <input type="text" className="form-control" />
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="btn-group mt_20">

                                                        <a href="#" className="fs_btn">Save</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default LoanProgramsEasyFix;