import React,{useEffect, useState} from 'react';
import {getMenuTermsandPrivacy, updateMenu} from "./../utils/reducer";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PopupMessage from './PopupMessage';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import Loader from './Loader';
import HomeFooter from './HomeFooter';
const PrivacyAdmin = () => {
  const dispatch = useDispatch();
    const navigate = useNavigate();
    const [testState , setTestState] = useState([]);
    const useQuillMixin = (defaultValue = '') => {
        const [value, setValue] = useState(defaultValue);
      
        useEffect(() => {
          // Perform any additional setup or side effects here
          console.log('Quill setup completed');
        }, []);
      
        const handleChange = (content, delta, source, editor) => {
          setValue(content);
          // Perform any additional logic on change if needed
        };
      
        const modules = {
          // Add your modules here if needed
          // Example: toolbar: [['bold', 'italic', 'underline', 'strike']],
        };
      
        const formats = [
          // Add your formats here if needed
          // Example: 'bold', 'italic', 'underline', 'strike'
        ];
      
        const quillElement = (
          <ReactQuill
            id="privacyContent"
            theme="snow"
            value={value}
            onChange={handleChange}
            modules={{
        // Override Quill's default behavior
        clipboard: { matchVisual: false },
        toolbar: {
          container: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['link', 'image'],
            ['blockquote', 'code-block'],
          ],
        },
      }}
            formats={['header']}
          />
        );
      
        return { quillElement, value, setValue };
      };
    const { quillElement, value, setValue } = useQuillMixin();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const loading = useSelector((state) => state.loaderVisible);
    const [popupMessage, setmessage] = useState('Your changes have been successfully updated.');
    useEffect(() => {
      dispatch(showLoader());
        getMenuTermsandPrivacy('200009').then(function (response) {
        console.log('privacymenuhere',response);
    
        if (response.result) {
            setValue(response?.result[0].content);
            setTestState(response?.result[0].content);
            dispatch(hideLoader());
        }
           
        }).catch((err) => {
          console.error.bind("Login error", err);
          dispatch(hideLoader());
        })
        
        }, []);

        const handleUpdate = () => { 
            console.log('value',value);
            
            if(value === testState){
              console.log('no difference ')
            }else{
              dispatch(showLoader())
              updateMenu('200009', value).then(function (response) {
                console.log('privacymenu',response);
                if(response.statusCode == 200){
                    setmessage('Your changes have been successfully updated.')
                    setPopupOpen(true);
                    dispatch(hideLoader())
                }
            
                }).catch((err) => {
                    setmessage('Oops! Something went wrong. Your changes were not updated. Please try again.')
                    setPopupOpen(true);
                    dispatch(hideLoader())
                  console.error.bind("Login error", err);
                })
            }
            
    
        }
        const closePopup = () => {
            setPopupOpen(false);
        };

    return (
        <div id="layout-wrapper">
{loading ? <Loader/> : null} 
{isPopupOpen && (
        <PopupMessage
          type={popupMessage == 'Your changes have been successfully updated.' ? 'success' : 'warning'}
          message={popupMessage}
          onClose={closePopup}
        />
      )}
           <MainHeader />


          <SideMenu />

            <div className="main-content">

            <div className="page-content user_management">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Privacy Policy</h4>
                                <a id="navigatePrivacy" className="back_btn" href="#" onClick={(e) => {e.preventDefault(); navigate('/pipeline');}}><span className="material-icons icon"> arrow_back
                                    </span>BACK</a>
                            </div>

                            <div className="card">
                                <div className="card-body ">
                                    <div className="form_section">
                                        <div className="row">
                                        
                                            <div className="col-md-10">
                                             {value?.length > 0 && quillElement}
                                                {/* <img className="w-100" src="assets/images/texteditor.png" alt="" /> */}
                                            
                                            </div>
                                        </div>
                                        <div className="btn-group mt_30">
                                            <button id="cancelPrivacy" onClick={(e) => {e.preventDefault(); navigate('/pipeline');}} className="border_btn mr_10">Cancel</button>
                                            {/* <a href="#" className="fs_btn" onClick={(e) => {e.preventDefault(); handleUpdate();}}>Next</a> */}
                                            <button id="savePrivacy" onClick={(e) => {e.preventDefault(); handleUpdate();}}className="fs_btn" style = {{'opacity' : (value === testState) ? '0.6' : '1'}}  disabled={(value === testState) ? true : false}>Save</button>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default PrivacyAdmin;