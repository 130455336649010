
export const saveTokenToLocalStorage = (token) => {
    localStorage.setItem('accessToken', token);
  };


  export const getTokenFromLocalStorage = () => {
    return localStorage.getItem('accessToken');
  };

  export const saveUserDataLocalStorage = (userData) => {
    localStorage.setItem('userdata', JSON.stringify(userData));
  };

  export const saveContactDataLocalStorage = (contactData) => {
    localStorage.setItem('contactdata', JSON.stringify(contactData));
  };


  export const getUserDataFromLocalStorage = () => {
    return JSON.parse(localStorage.getItem('userdata'));
  };

  export const getContactDataFromLocalStorage = () => {
    return JSON.parse(localStorage.getItem('contactdata'));
  };
  