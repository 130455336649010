import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import InfoBar from '../InfoBar';
const BorrowerInfo7 = (props) => {
    const navigate = useNavigate();
    const { currentPage,setCurrentPage} = props;
    const {borrowerInfoOneData,setBorrowerInfoOneData} = props;
    const [network,setNetwork] = useState("");
    const [isMedia,setIsMedia] = useState("");
    const regexMedia = /^[a-zA-Z\s]+$/;

    const handleNetwork = (e) => {
        setIsMedia(false);
        setNetwork(e.target.value);
    }

    const handleBorrowerSeven = () => {
        const mediaBool = regexMedia.test(network);
        if(mediaBool ===  true){
            setIsMedia(false);
            setBorrowerInfoOneData({...borrowerInfoOneData,['network']:network});
            navigate('/submitapp');
            // setCurrentPage(1);//Redirect here to desired page 
        }else{
            setIsMedia(true);
            return true;
        }
        
        
    }

    const paragraphStyle = {
        color : '#FF0000'
    }


    return (
                <div className="content_blk">
                    <div className="hed">
                        {/* <a href="#" className="back material-icons" onClick={(e) => { navigate(-1); }}> keyboard_backspace </a> */}
                        <a href="#" className="back material-icons" onClick={(e) => {e.preventDefault(); props.onPrevious();}}> keyboard_backspace </a>
                        <h2 className="title"> <span className="title2">EASY <span className="sub"> BUILD</span></span> Borrower Info
                        </h2>
                    </div>
                    <div className="cont pi3 bi bi2">
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">My Name
                            <strong> : {borrowerInfoOneData.firstName} {borrowerInfoOneData.className}</strong> </span>
                    </div>
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">My Email
                            <strong> : {borrowerInfoOneData.emailid}</strong> </span>
                    </div>
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">My Phone
                            <strong> : {borrowerInfoOneData.phone}</strong> </span>
                    </div>
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">Address
                            <strong> : {borrowerInfoOneData.address}</strong> </span>
                    </div>
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">Borrower is
                            <strong> {borrowerInfoOneData.condition === 'no' ? 'not an Entity' : 'an Entity' }</strong> </span>
                    </div>
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">I am currently working
                            with Easy Street account executive as
                            <strong> {borrowerInfoOneData.role}</strong> </span>
                    </div>
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">Estimated credit score -
                            <strong> {borrowerInfoOneData.creditScore}</strong> </span>
                    </div>
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">Current liquid cash
                            reserves -
                            <strong> {borrowerInfoOneData.reserves}</strong> </span> 
                            <a href="#" className="back_btn" onClick={(e) => {e.preventDefault(); props.onPrevious();}}>
                            <span className="material-icons-outlined">
                                shortcut
                            </span>Change
                        </a>
                    </div>
                    <h3 className="feild_title">Where did you hear about us?</h3>

                    <div className="select_box">
                        <input type="text" placeholder="Social Media-Bigger Pockets" className="form-control" defaultValue={network} onChange={handleNetwork}/>
                        {isMedia === true && <p style={paragraphStyle}>Alphabets and spaces are allowed</p>}
                    </div>
                    
                    <div className="text-center"><button type="button" className="cp_btn mt-10" onClick={handleBorrowerSeven}>Next</button></div>
            </div>
        </div>
    );
};

export default BorrowerInfo7;