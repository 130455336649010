// UserContext.js
import React, {useContext, createContext, useState, useEffect } from 'react';
import { getUser } from "./reducer";
const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
        try {
          const response = await getUser();
          // console.log('privacymenunewwe', response);
          if (response?.result) {
            setUser(response?.result?.[0]?.userInfo || response?.result?.[0]);
          }
        } catch (error) {
          console.error('Error fetching user:', error);
        }
      };
    

    fetchUser();

    // Cleanup function if necessary
    return () => {
      // Cleanup code here if needed
    };
  }, []); // Empty dependency array ensures it runs only once

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  return useContext(UserContext);
};