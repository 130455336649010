import React, { useState, useEffect , useRef} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import { useDispatch, useSelector } from 'react-redux';
import { searchLoanApi } from '../utils/reducer.js';
import PopupMessage from './PopupMessage';
import moment from 'moment';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useLoanInfo } from '../utils/loanInfoContext';
import Loader from './Loader';
import DataTable from 'react-data-table-component';
import { formatCurrency } from '../utils/commonUtils.js';
import { getBorrowerExposure ,addBorrowerExposure,getLoanDetails } from '../utils/reducer.js';
import SweetAlert from 'react-bootstrap-sweetalert';  
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
const BorrowerExposure = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loaderVisible);
    const { loanInfo, setLoanInfo} = useLoanInfo();
    const [loanid,setLoanid] = useState(loanInfo?.loanid);
    const [showOptions, setShowOptions] = useState(false);
    const loansData = useSelector(state => state.loansdata);
    const [searchErr, setSearchErr] = useState("");
    const [totalBorrowerExposure , setTotalBorrowerExposure] = useState('');
    const [borrowerExposure,setBorrowerExposure] = useState([]);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [loans, setLoans] = useState([]);
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [searchLoader, setSearchLoader] = useState(null);
    const [message,setMessage] = useState('');
    const [isPopupOpen,setIsPopUpOpen] = useState(false);
    const [deleteId,setDeleteId]= useState('');
    const [confirmationPopUp,setConfirmationPopUp] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const inputRef = useRef(null);
    const [LoanidToAdd,setLoanidToAdd] = useState('');
    const [searchCheck,setSearchCheck] = useState(false);

    const handleSearchLoans = (e) => { 
       console.log('loannn idddddd ---->', typeof loanid,typeof e.target.value);
       setLoanidToAdd('')
       const inputValue = e.target.value?.trim();
            setSearchInputValue(e.target.value); 
            setSearchErr("") 
            // if(inputValue == loanid){
            //     setLoans([]);
            // }else{
                if (inputValue.length > 2) {
                    setSearchLoader(true)
                    clearTimeout(typingTimeout);
                    const newTypingTimeout = setTimeout(() => {
                    searchLoanApi(inputValue).then(function (response) {
                      
                        if (response?.result) {
                            // console.log('sdfsfsfd',response?.result);
                            setLoans(response?.result)
                        }
                        setSearchCheck(true)
                        setSearchLoader(false)
                    }).catch((err) => {
                        console.error.bind("error", err);
                        setSearchLoader(false)
                    })
                }, 300);
                setTypingTimeout(newTypingTimeout);
        
                }else {
                    clearTimeout(typingTimeout);
                    setLoans([]);
                    setSearchCheck(false)
                }
            // }

        
      
    };
    const selectSearched = (loan) => {
        let borrowerfullname = loan?.entity ? loan?.entity?.[0]?.legalname:loan?.applicant?.[0]?.borrowerfirstname + ' '+loan?.applicant?.[0]?.borrowerlastname;
        let title =  loan?.loanid +' - '+loan?.propertyinfo[0]?.propertyaddress+', '+borrowerfullname;
        setSearchInputValue(title);
        handleModalHidden();
        setLoanidToAdd(loan);
        // const intIndex = loansData.findIndex(item => item.loanid === loan?.loanid);
        // const existingLoans = borrowerExposure.filter(item => item?.loanid === loan?.loanid);
        // console.log('loannnn---->',existingLoans);
        // if(loan?.loanid === loanid){
        //     setMessage('Cannot add your own loan');
        //     setIsPopUpOpen(true);
        //     setShowOptions(false);
        //     setSearchInputValue('');
        // }else if (existingLoans?.length > 0) {
        //     setMessage('This loan already exists');
        //     setIsPopUpOpen(true);
        //     setShowOptions(false);
        //     setSearchInputValue('');
        //  } else{
        //     handleAddBorrower(loan?.loanid)
        // }
    };
    const handleAddToBroker = ()=>{
        if(searchInputValue?.trim() && LoanidToAdd){
            const existingLoans = borrowerExposure.filter(item => item?.loanid === LoanidToAdd?.loanid);
            if(LoanidToAdd?.loanid === loanid){
                setMessage('Cannot add your own loan');
                setIsPopUpOpen(true);
                setShowOptions(false);
                setSearchInputValue('');
                //same loan
            }else if (existingLoans?.length > 0) {
                setMessage('This loan already exists');
                setIsPopUpOpen(true);
                setShowOptions(false);
                setSearchInputValue('');
             } else{
                handleAddBorrower(LoanidToAdd?.loanid)
            }
        }
        
    }
    
    const handleModalHidden = () => {
        setLoans([]);
        setSearchCheck(false)
        // setSearchInputValue('')
    }
    const setLoandetails =()=>{
        getLoanDetails(loanid)
        .then(response => {
          console.log('getLoanDetails', response);
          if (response?.result[0]) {
            setLoanInfo(response?.result[0]);
            console.log(response);
          }
        })
        .catch(err => {
          console.error('my account error', err);
        });
      }
    
    useEffect(() => {
        if(loanid){
            setBorrowerExposure([]);
            setLoans([]);
            setSearchCheck(false)
            setSearchInputValue('');
            setLoanidToAdd('');
            getBorrower();
        }
      }, [loanid]);
      useEffect(() => {
        setLoanid(loanInfo?.loanid);
      }, [loanInfo?.loanid]);

      const getBorrower= () => { 
        dispatch(showLoader());
        getBorrowerExposure(loanid).then(function (response) {
            // console.log('response--->',response);
            dispatch(hideLoader());
        if (response?.result.data !== 0) {
            if(props.isSubcomponent){
                props.onLengthChange(response?.result.data.length)
            }
            setBorrowerExposure(response?.result.data);
            console.log('response?.result',response?.result.data);
            let totalExposure = 0;
            for (const item of response.result.data) {
                if(item.loanamount){
                    totalExposure += item.loanamount;
                }
            }if (!isNaN(totalExposure)) { // Check if totalExposure is a valid number
                setTotalBorrowerExposure(totalExposure); // Set the total exposure
            } else {
                setTotalBorrowerExposure(''); // Set to empty if totalExposure is not a valid number
            }
            // setTotalBorrowerExposure(totalExposure);
        }else{
            setBorrowerExposure([]);
            setTotalBorrowerExposure('');
            if(props.isSubcomponent){
                props.onLengthChange(0)
            }
        }
        }).catch((err) => {
          console.error.bind("error", err);
          dispatch(hideLoader());
          setBorrowerExposure([])
        })
     };
     const handleAddBorrower = (id)=>{
        setMessage('Loan Added');
        let apiData = {
            "borrowerExposure":id
        };
        UpdateBorrower(apiData);
     }
     const handleDeleteClick=(id)=>{
        setDeleteId(id); 
        setConfirmationPopUp(true);
     }
     const handleDelete = ()=>{
        setMessage('Connection Deleted');
        setConfirmationPopUp(false);
        let apiData = {
            "borrowerExposureRemove":deleteId
        };
        setLoanidToAdd('');
        UpdateBorrower(apiData);
     }
     const UpdateBorrower= (apiData) => { 
        dispatch(showLoader());
        setShowOptions(false)
        addBorrowerExposure(loanid,apiData).then(function (response) {
            // console.log('response--->',response);
            dispatch(hideLoader());
        if (response?.result) {
            setSearchInputValue('');
            getBorrower();
            setIsPopUpOpen(true);
            setLoandetails()
        }
        }).catch((err) => {
          console.error.bind("error", err);
          dispatch(hideLoader());
        })
     };
     const handleExportToExcel = () => {
        const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        // Format table data for Excel
        const formattedData = borrowerExposure.map(item => ({
            'LOAN ID': item.loanid || '',
            'BORROWER': item.applicant?.[0]?.borrowerfirstname + ' ' + item.applicant?.[0]?.borrowerlastname || '',
            'EMAIL': item.applicant?.[0]?.borroweremail || '',
            'ADDRESS': item.propertyinfo?.[0]?.propertyfulladdress || '',
            'ENTITY': item.entity?.[0]?.legalname || '',
            // 'PHASE': item.updateworkflow?.phase || '',
            'STAGE': item.updateworkflow?.status || '',
            'FOLDER': item.updateworkflow?.folder || '',
            'LOAN AMOUNT': item.loanamount ? formatCurrency(item.loanamount) : '',
            'HOLDBACK AMOUNT' :  item.rehabfunding ? formatCurrency(item.rehabfunding) : '',
            'PAID OFF' : item?.paidoff || 'N/A',
            'LATE PAYMENT STATUS' : item?.lastpaymentstatus || 'N/A',
            'LAST DRAW DATE' : item?.lastdrawdate || 'N/A',
            'DRAW BALANCE' : item?.drawbalance || 'N/A',
            'DRAWS PAID OUT' : item?.drawspaidout || 'N/A',
        }));
        const ws = XLSX.utils.json_to_sheet(formattedData);
            // Calculate column widths based on content
            const columnWidths = Object.keys(formattedData[0]).map(column => {
                const maxContentLength = formattedData.reduce((max, row) => {
                    const cellContent = row[column] || '';
                    return Math.max(max, cellContent.toString().length);
                }, column.length); // Set initial width to column name length

                return { wch: maxContentLength + 2 }; // Add some padding
            });

            ws['!cols'] = columnWidths;
      

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const fileData = new Blob([excelBuffer], { type: fileType });

        saveAs(fileData, 'borrower_exposure' + fileExtension);
    };

      const columns = [
        {
            name: 'LOAN ID',
            selector: (row) => row?.loanid || '',
            minWidth: '5%',
            maxWidth: '5%',
        },
        {
            name: 'BORROWER',
            selector: (row) => row?.applicant?.[0]?.borrowerfirstname+' '+row?.applicant?.[0]?.borrowerlastname,
            minWidth: '8%',
            maxWidth: '8%',
        },
        {
            name: 'BORROWER EMAIL',
            selector: (row) => row?.applicant?.[0]?.borroweremail || '',
            minWidth: '8%',
            maxWidth: '8%',
        },
        {
            name: 'ADDRESS',
            selector: (row) => row?.propertyinfo?.[0]?.propertyfulladdress || '',
            minWidth: '8%',
            maxWidth: '8%',
        },
        {
            name: 'ENTITY',
            selector: (row) => row?.entity?.[0]?.legalname,
            minWidth: '8%',
            maxWidth: '8%',
        },
        // {
        //     name: 'PHASE',
        //     selector: (row) => row?.updateworkflow?.phase,
        //     minWidth: '7%',
        //     maxWidth: '7%',
        // },
        {
            name: 'STAGE',
            selector: (row) => row?.status,
            minWidth: '7%',
            maxWidth: '7%',
        },
        {
            name: 'FOLDER',
            selector: (row) => row?.updateworkflow?.folder,
            minWidth: '8%',
            maxWidth: '8%',
        },
        {
            name: 'LOAN AMOUNT',
            selector: (row) => row?.loanamount ? formatCurrency(row?.loanamount) : '',
            sortable: true,
            minWidth: '6%',
            maxWidth: '6%',
        },
        {
            name: 'HOLDBACK',
            selector: (row) => row?.rehabfunding ? formatCurrency(row?.rehabfunding) : '',
            minWidth: '8%',
            maxWidth: '8%',
        },
        {
            name: 'DRAW BALANCE',
            // selector: (row) => row?.drawbalance,
            selector: (row) => 'N/A',
            minWidth: '6%',
            maxWidth: '6%',
        },
        {
            name: 'DRAWS PAID OUT',
            // selector: (row) => row?.drawspaidout,
            selector: (row) => 'N/A',
            minWidth: '6%',
            maxWidth: '6%',
        },
        {
            name: 'PAID OFF',
            // selector: (row) => row?.paidoff,
            selector: (row) => 'N/A',
            minWidth: '5%',
            maxWidth: '5%',
        },

        {
            name: 'LATE PAYMENT STATUS',
            // selector: (row) => row?.lastpaymentstatus,
            selector: (row) => 'N/A',
            minWidth: '7%',
            maxWidth: '7%',
        },
        {
            name: 'LAST DRAW DATE',
            // selector: (row) => moment(row?.lastpaymentdate).format("MM-DD-YYYY"),
            // selector: (row) => row?.lastdrawdate,
            selector: (row) => 'N/A',
            minWidth: '6%',
            maxWidth: '6%',
        },
       
        {
            name: 'ACTIONS',
            cell: (row,index) => (
                <>{loanInfo?.borrowerExposure?.includes(row?.loanid) && 
                    <button id={`powerOffLoan-${index}`} onClick={(e)=>{handleDeleteClick(row?.loanid)}} className="icon_btn view"><span
                    className="material-symbols-outlined">
                    power_off
                </span></button>
                }
                </>
            ),
            minWidth: '4%',
            maxWidth: '4%',
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',

            },
        },
        table: {
            style: {
                border: '1px solid #CCD8F6',
            },
        },
    };
    const closePopUp =()=>{
        setIsPopUpOpen(false);
        setConfirmationPopUp(false);
    }

      //added press key down and key up to search loan
      const handleKeyDown = (e) => {
        if (showOptions && loans.length > 0) {
            if (e.key === 'ArrowDown') {
                
                setFocusedIndex((prevIndex) => (prevIndex + 1) % loans.length);
                e.preventDefault();
            } else if (e.key === 'ArrowUp') {
                //setFocusedIndex((prevIndex) => (prevIndex - 1 + filteredLoans.length) % filteredLoans.length);
                setFocusedIndex((prevIndex) => {
                    if (prevIndex === -1) {
                      return loans.length - 1;
                    } else {
                      return (prevIndex - 1 + loans.length) % loans.length;
                    }
                });
                  
                e.preventDefault();
            } else if (e.key === 'Enter') {
                if (focusedIndex >= 0 && focusedIndex < loans.length) {
                    selectSearched(loans[focusedIndex]);
                    e.preventDefault();
                    setFocusedIndex(-1);
                    
                }
            }
            
            // else if (e.key === 'Escape') {
            //     // Reset focused index when Escape key is pressed
            //     setFocusedIndex(-1);
            // }
        }
    };

    useEffect(() => {
        setFocusedIndex(-1); // Reset focus index when search input changes
    }, [searchInputValue]);

    return (
        <div id="layout-wrapper" className={props.isSubcomponent ? 'exposurePopup':"dashboard"}>

            {!props.isSubcomponent && <PipelineHeader />}
             {loading ? <Loader/> : null} 
             {isPopupOpen && (
            <PopupMessage
             type={message === 'Cannot add your own loan' || message === 'This loan already exists' ? 'warning' : 'success' }
             message={message}
             onClose={closePopUp}
            />
            )}
        {confirmationPopUp && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={handleDelete}
                onCancel={closePopUp}
                focusCancel={true}
                customButtons={[
                    React.createElement('button', {
                        id: 'delete-exposure-yes',
                        className: 'btn btn-primary',
                        onClick: handleDelete,
                        style: { marginRight: '8px' ,fontSize: '16px'}
                    }, 'Yes'),
                    React.createElement('button', {
                        id: 'delete-exposure-no',
                        className: 'btn btn-light',
                        onClick: closePopUp,
                        style: { fontSize: '16px'}
                    }, 'No')
                ]}
            >
                    <p>Do you want to delete this Connection</p>
            </SweetAlert>}
            <div className="dashboard_block">
                {!props.isSubcomponent && <PipelineStatus />}
                {!props.isSubcomponent &&  <h2 className="form_title">Underwriting<span className="space">&#10095;</span> <span className="sub">Borrower
                    Exposure</span> </h2>}
            <div className="card user_management detailed_app expo loan_summary">
                <div className="card-body my_account">
                {!props.isSubcomponent &&  <h3 className="title">Borrower exposure</h3>}
                    <div className="main_details payments_details mt-3">
                        <div className="detail_block">
                            <p>Total Loans</p>
                            <h4>{borrowerExposure ? borrowerExposure?.length + 1 : 1} </h4>
                        </div>
                        <div className="detail_block">
                            <p>Total Exposure</p>
                            <h4>{(totalBorrowerExposure && loanInfo?.loanamount) 
    ? formatCurrency(totalBorrowerExposure + loanInfo?.loanamount) 
    : (totalBorrowerExposure || loanInfo?.loanamount) 
        ? formatCurrency(totalBorrowerExposure || loanInfo?.loanamount) 
        : '$0.00'}
</h4>
                        </div>
                        <div className="detail_block">
                            <p>Total Active Loans</p>
                            <h4>N/A</h4>
                        </div>
                        <div className="detail_block">
                            <p>Total Active Exposure</p>
                            <h4>N/A</h4>
                        </div>
                        <div className="detail_block">
                            <p>Total Loans Paid Off</p>
                            <h4>N/A</h4>
                        </div>
                        <div className="detail_block">
                            <p>Total Paid Off</p>
                            <h4>N/A</h4>
                        </div>
                        <div className="detail_block">
                            <p>Total Fix and Flip</p>
                            <h4>N/A</h4>
                        </div>
                        {/* <div className="detail_block">
                            <p>Total Late Payments</p>
                            <h4>N/A</h4>
                        </div> */}
                        {/* <div className="detail_block">
                            <p>Total Line Of Credits</p>
                            <h4>$0.00</h4>
                        </div> */}
                        <div className="detail_block">
                            <p>Total Rental</p>
                            <h4>N/A</h4>
                        </div>
                    </div>
                    <div className="search">
                        <div className="d-flex align-items-center">
                            <div className="title_block ml_0">
                            <input  id="searchBorrowerLoan" ref={inputRef} onFocus={() => setShowOptions(true)} type="text" value={searchInputValue} className="form-control" placeholder='Search Loan ID, First/Last Name, or Address' onChange={handleSearchLoans} onKeyDown={handleKeyDown}/>
                            {searchLoader && searchInputValue.length>0 && <img src='assets/images/loader.gif' className='search_spinner' />}
                                                {!searchLoader && searchInputValue.length>0 && <img src='assets/images/close.svg' onClick={()=>{setLoans([]);setSearchInputValue("");setLoanidToAdd('')}} className='search_close' />}
                                <button type="button" id='addborrowerclick' onClick={handleAddToBroker}>Add Loan To Borrower</button>
                                {showOptions && (
                                              <div className="dropdown">
                                                  {searchInputValue?.trim()?.length>=2 && loans?.length > 0 ? loans?.map((option, index) => {
                                                     let borrowerfullname = option?.entity ? option?.entity?.[0]?.legalname:option?.applicant?.[0]?.borrowerfirstname + ' '+option?.applicant?.[0]?.borrowerlastname;
                                                     let title =  option?.loanid +' - '+option?.propertyinfo[0]?.propertyaddress+', '+borrowerfullname
                                                      return(<div id="addLoanBorrower" key={index} className={`item ${focusedIndex === index ? 'focused' : ''}`} onClick={() => { selectSearched(option);}}>
                                                          {title}
                                                      </div>)
                                                  }) : loans?.length === 0 && searchCheck && searchInputValue?.trim()?.length >= 2 && !searchLoader && <div className='item'>No searched Data found</div> }
                                              </div>
                                          )}  
                            </div>
                                 {borrowerExposure?.length !== 0 && 
                                 <button id="exportBorrowerLoanExcel" type="button" onClick={handleExportToExcel} disabled={borrowerExposure?.length === 0} className="border_btn icon">Export Table to Excel<span>&nbsp;</span><img
                                 className="icon" src="assets/images/icons/excel.svg" /></button>
                                 }
                        </div>
                
                    </div>
                    {borrowerExposure?.length !== 0 && 
                    <div className="table-responsive">

                        <DataTable className='be_table'
                        columns={columns}
                        data={borrowerExposure}
                        pagination
                        customStyles={customStyles}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 20, 30, 40]}
                        paginationComponentOptions={{
                            rowsPerPageText: 'Per Page',
                        }}
                    />
 </div>
                    }
                   {borrowerExposure?.length === 0 && 
                   <div className="inprogress_page">
                   <span className="material-symbols-outlined icon"> content_paste_search </span><p>No Data Found</p></div>
          
                   } 
                    </div>
               
            </div>
            </div>
           {!props.isSubcomponent && <PipelineFooter />}
        </div>
    );
};

export default BorrowerExposure;