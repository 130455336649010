import React, { useCallback, useState, useMemo, useEffect, useRef } from "react";
import JoditEditor from "jodit-react";

export default function EditorEdit(props) {
  const editor = useRef(null);
  const [range, setRange] = useState(null);
  const selectedValue = props.formValuesOption;

  // const handleFocus = useCallback(() => {
  //   if (editor.current) {
  //     const joditInstance = editor.current;
  //     const selection = joditInstance.selection;

  //     if (selection && selection.sel) {
  //       const currentRange = selection.sel.getRangeAt(0);
  //       setRange(currentRange);
  //       console.log("Current range on focus:", currentRange);
  //     } else {
  //       console.log("No selection object found.");
  //     }
  //   } else {
  //     console.log("Jodit editor instance not found.");
  //   }
  // }, []);

  const handleFocus = useCallback(() => {
    if (editor.current) {
        const joditInstance = editor.current;
        const selection = joditInstance.selection;
        
        if (selection && selection.sel) {
            // First, check if there is a selection range available
            if (selection.sel.rangeCount > 0) {
                const currentRange = selection.sel.getRangeAt(0);
                setRange(currentRange);
                console.log("Current range on focus:", currentRange);
            } else {
                console.log("No text selected.");
                setRange(null); // Clear the range state if no text is selected
            }
        } else {
            console.log("No selection object found.");
        }
    } else {
        console.log("Jodit editor instance not found.");
    }
  }, []);


  const config = useMemo(
    () => ({
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      placeholder: "",
      readonly: props?.ReadOnlyflag ?? false,
      events: {
        focus: handleFocus,
        afterInit: (instance) => {
          editor.current = instance;
        },
      },
    }),
    [props.ReadOnlyflag, handleFocus]
  );

  const onChange = useCallback((newContent) => {
    if (props?.setEditorValidate) {
      props?.setEditorValidate(false);
    }
    props.setContent(newContent);
  }, [props]);

  const onBlur = useCallback((newContent) => {
    props.setContent(newContent);
  }, [props.setContent]);

  const handlePrintContent = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Print</title>");
    printWindow.document.write("</head><body >");
    printWindow.document.write(props.content);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.focus();

    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 250);
  };

  const insertAtCursor = (value) => {
    if (editor.current) {
      const joditInstance = editor.current;
      const selection = joditInstance.selection;

      if (range) {
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        joditInstance.selection.insertHTML(value);
      } else {
        joditInstance.selection.insertHTML(value);
      }
    } else {
      props?.setContent((prevContent) => value + prevContent);
    }
  };

  useEffect(() => {
    if (selectedValue && selectedValue !== "") {
      const valueToInsert = selectedValue;
      insertAtCursor(valueToInsert);
    }
  }, [props?.valueDependency]);

  return (
    <div className="text_editor">
      {!props?.flag && (props?.content === "" || props?.content === "<p><br></p>" || props?.content === undefined) ? (
        ""
      ) : (
        <button
          id={`print${props?.name || "quillvalue"}`}
          onClick={handlePrintContent}
          className="border_btn print"
          style={{ display: props?.hidePrint === "no" && "none" }}
        >
          <span className="material-symbols-outlined icon"> print </span> Print
        </button>
      )}
      {props?.flag && (
        <button
          id={`print${props?.name || "quillvalue"}`}
          onClick={handlePrintContent}
          className="border_btn print"
          style={{ display: props?.hidePrint === "no" && "none" }}
        >
          <span className="material-symbols-outlined icon"> print </span> Print
        </button>
      )}
      <JoditEditor
        value={props?.content}
        config={config}
        onBlur={onBlur}
        onChange={onChange}
        name={props?.name || "quillvalue"}
        id={props?.name || "quillvalue"}
        ref={editor}
        onFocus={handleFocus}
      />
    </div>
  );
}
