import React , { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import InfoBar from '../InfoBar';
const BorrowerInfo5 = (props) => {
    const navigate = useNavigate();
    const {currentPage,setCurrentPage} = props;
    const {borrowerInfoOneData,setBorrowerInfoOneData} = props;
    const [score,setScore] = useState("");
    const [isScoreGiven,setIsScoreGiven] = useState("");
    const isOnlyNumbers = /^[0-9]+$/;

    const handleScore = (e)  => {
        setScore(e.target.value);
        setIsScoreGiven(false);
    }

    const handleNextFive = () => {
        const boolValue = isOnlyNumbers.test(score);
        if(boolValue === true){
            setIsScoreGiven(false);
            setBorrowerInfoOneData({...borrowerInfoOneData,['creditScore']:score});
            setCurrentPage(currentPage + 1);
        }else{
            setIsScoreGiven(true);
            return true;
        }
        
    }

    const paragraphStyle = {
        color : '#FF0000'
    }

    return (

                <div className="content_blk">
                    <div className="hed">
                        {/* <a href="#" className="back material-icons" onClick={(e) => { navigate(-1); }}> keyboard_backspace </a> */}
                        <a href="#" className="back material-icons" onClick={(e) => {e.preventDefault(); props.onPrevious();}}> keyboard_backspace </a>
                        <h2 className="title"> <span className="title2">EASY <span className="sub"> BUILD</span></span> Borrower Info
                        </h2>
                    </div>
                    <div className="cont pi3 bi bi2">
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">My Name
                            <strong> : {borrowerInfoOneData.firstName} {borrowerInfoOneData.className}</strong> </span>
                    </div>
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">My Email
                            <strong> : {borrowerInfoOneData.emailid}</strong> </span>
                    </div>
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">My Phone
                            <strong> : {borrowerInfoOneData.phone}</strong> </span> 
                    </div>
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">Address
                            <strong> : {borrowerInfoOneData.address}</strong> </span> 
                    </div>
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">Borrower is 
                            <strong> {borrowerInfoOneData.condition === 'no' ? 'not an Entity' : 'an Entity' }</strong> </span> 
                    </div>
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">I am currently working with Easy Street account executive as
                            <strong> {borrowerInfoOneData.role}</strong> </span> 
                            <a href="#" className="back_btn" onClick={(e) => {e.preventDefault(); props.onPrevious();}}>
                            <span className="material-icons-outlined">
                                shortcut
                            </span>Change
                        </a>
                    </div>
                    <h3 className="feild_title">What is your estimated credit score ?</h3>
                    <p>(Credit will not be pulled until term sheet is signed)</p>
                  <div className="select_box">
                    <input type="text" placeholder="Enter Credit Score" className="form-control" name="creditscore" onChange={handleScore} defaultValue={score}/>
                    {isScoreGiven === true && <p style={paragraphStyle}>Credit score field is mandatory and only numbers are allowed</p>}
                  </div>
                  {/* <div className="text-center"><button type="button" className="cp_btn mt-10"  onClick={(e) => {navigate('/BorrowerInfo6');}}>Next</button></div> */}
                  <div className="text-center"><button type="button" className="cp_btn mt-10"  onClick={handleNextFive}>Next</button></div>
            </div>
        </div>
    );
};

export default BorrowerInfo5;