import React, { useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import { useLoanInfo } from '../utils/loanInfoContext';
import { servicingUpdate,getLoanDetails,getallUsers} from '../utils/reducer';
import { getloanSummary} from '../utils/reducer.js';
import moment from 'moment';
import Blockingtextmsg from './Blockingtext';
import { useDispatch, useSelector} from 'react-redux';
import { formatCurrency } from '../utils/commonUtils';
import DatePicker from 'react-datepicker';
import Loader from './Loader';
import { hideLoader, showLoader } from '../redux/reducers/Actions';
import { useLocation } from "react-router-dom";
const ServicingLoanSummary = () => {
    const location = useLocation();
    const isDrawLoanSummary = location.pathname.includes("drawloansummary");
    const blockingtext = 'Loan Summary cannot be accessed until servicing setup gets completed';
    const configdata = useSelector(state => state.configdata);
    let showServicingForms = configdata?.common?.showServicingForms || true;
    const { loanInfo, setLoanInfo} = useLoanInfo();
    const [loanid,setLoanid] = useState(loanInfo?.loanid);
    const navigate = useNavigate();
    const [loanSummary,setLoanSummary] = useState({});
    const [feeTablesummaryData,setFeetableSummaryData] = useState({});
    const [monthsLeft, setMonthsLeft] = useState(loanInfo?.loanterm);
    const [nextDueDate, setNextDueDate] = useState('');
    const loading = useSelector((state) => state.loaderVisible); 
    const dispatch = useDispatch();
    const [loanassignment,setLoanassignment] = useState({});
    const[users,setUsers]=useState([]);
    const [editableFieldsData,setEditableFieldsData] = useState({
        solddate : '',
        paidoffdate : '',
        fcireferencenumber : '',
        bsireferencenumber : '',
        foreclosuredate : '',
    });
    const [editingFields,setEditingFields] = useState({
        solddate : false,
        paidoffdate : false,
        fcireferencenumber : false,
        bsireferencenumber : false,
        foreclosuredate : false,
        servicer : false,
    });
    useEffect(() => {
        function calculateNextDueDate(firstPaymentDate) {
          const today = moment();
          const dueDate = moment(firstPaymentDate);
    
          let nextDueDate;

          if (dueDate.isAfter(today)) {
            nextDueDate = dueDate.add(1, 'month').startOf('month');
          } else {
            nextDueDate = today.add(1, 'month').startOf('month');
          }
          return nextDueDate;
        }
    
        const calculatedDate = calculateNextDueDate(loanInfo?.cleartoclose?.scheduledfirstpaymentdate);
        setNextDueDate( loanInfo?.cleartoclose?.scheduledfirstpaymentdate ? calculatedDate : '');
      }, [loanInfo?.cleartoclose?.scheduledfirstpaymentdate]);

    useEffect(() => {
        calculateMonthsLeft();
    }, [loanInfo?.wireinstructions?.actualwire_funddate, loanInfo?.loanterm]);

    const calculateMonthsLeft = () => {
        const fundDate = moment(loanInfo?.wireinstructions?.actualwire_funddate);
        const currentDate = moment();
        const monthsPassed = currentDate.diff(fundDate, 'months');
        const remainingMonths = (loanInfo?.loanterm + ((loanInfo?.extensionDataCount || 0) * 3)) - monthsPassed;
        console.log(monthsPassed,remainingMonths)
        setMonthsLeft(remainingMonths > 0 ? remainingMonths : 0);
    };
    // 
    useEffect(() => {
        if(loanInfo?.loanid){
            loanSummaryGet(loanInfo?.loanid);
        }
        console.log('loanInfo',loanInfo);
        let summaryVariable = {
            loanamount : loanInfo?.loanamount || '',
            disbursedloanamount : loanInfo?.loanamount - loanInfo?.rehabfunding || '',
            loanterm : loanInfo?.loanterm || '', 
            interestrate : loanInfo?.interestrate || '',
            closedate : loanInfo?.cleartoclose?.actualclosingdate || '',
            // maturitydate : loanInfo?.cleartoclose?.declaredmaturitydate || '',
            proposedmaturitydate : loanInfo?.cleartoclose?.proposedmaturitydate || '',
            currentmaturitydate : loanInfo?.cleartoclose?.currentmaturitydate || '',
            holdbackbalance : loanInfo?.rehabfunding || '',
            servicer : loanInfo?.loanassignment?.changeservicerto?.name || '',
            underwriter : loanInfo?.loanassignment?.changeunderwriterto?.name || '',
            guarantoremail : loanInfo?.applicant?.[0]?.borroweremail || '',
            guarantornumber : loanInfo?.applicant?.[0]?.borrowerphone || '',
            guarantatoraddress : loanInfo?.applicant?.[0]?.borrowerfulladdress || '',
            paidoffdate : loanInfo?.paidoffdate || '',
            solddate : loanInfo?.solddate || '',
            fcireferencenumber : loanInfo?.fcireferencenumber || '',
            bsireferencenumber : loanInfo?.bsireferencenumber || '',
            foreclosuredate : loanInfo?.foreclosuredate || '',
            noofdraws : loanInfo?.totalDrawsCount || 0,
            approveddraws : loanInfo?.approvedDrawsCount || 0,
            drawspaid : loanInfo?.paidDrawsCount || 0,
            projectcompletepercentage : loanInfo?.projectcompletepercentage || 0,
            servicedloanamount : loanInfo?.servicedLoanAmount || 0,
        }
        setLoanSummary({
            ...summaryVariable,
        });
       
        setEditingFields({
            solddate: false,
            paidoffdate: false,
            fcireferencenumber: false,
            bsireferencenumber : false,
            foreclosuredate: false,
            servicer : false,
        });
        setEditableFieldsData({
            paidoffdate : loanInfo?.paidoffdate || '',
            solddate : loanInfo?.solddate || '',
            fcireferencenumber : loanInfo?.fcireferencenumber || '',
            bsireferencenumber : loanInfo?.bsireferencenumber || '',
            foreclosuredate : loanInfo?.foreclosuredate || ''
        });
        setLoanassignment(loanInfo?.loanassignment?.changeservicerto?.name || '');
        setLoanid(loanInfo.loanid)
    }, [loanInfo]);

    const loanSummaryGet=(loanid)=>{
        dispatch(showLoader());
        getloanSummary(loanid).then(function (response) {
            if (response?.result) {
                console.log('pay getPayments here',response?.result?.principalObj);
                // // setLoanSummary(response?.result);
                // const extractedFees = Object.keys(response?.result)
                //     .filter(key => !['principalObj', 'interestObj', 'totalAmountDue', 'dueAsOf', 'lateFeeObj'].includes(key))
                //     .map(key => {
                //         // Convert the key to a readable fee type
                //         const feeType = key?.replace(/([A-Z])/g, ' $1')?.replace('Obj', '')?.trim();                    // Remove extra spaces

                //         // Capitalize first letter of each word
                //         const formattedFeeType = feeType?.split(' ')?.map(word => word.charAt(0)?.toUpperCase() + word?.slice(1))?.join(' ');

                //         return {
                //             feeType: formattedFeeType, // e.g., 'Servicing Fee'
                //             ...response?.result?.[key]                // Include the fee details
                //         };
                //     });
                // console.log('extractedFees', extractedFees);
                setFeetableSummaryData(response?.result)
            }
            dispatch(hideLoader());
        })
            .catch((err) => {
                console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    };

    useEffect(() => {
        dispatch(showLoader());
        getallUsers().then(function (response) {
            console.log('privacymenunewwegetallUsers', response.result);
            if (response?.result?.data) {
                const activeUsers = response?.result?.data.filter(user => user.securityInfo && user.securityInfo.status === "ACTIVE");
                const options = activeUsers.map(user => ({
                    value: `${user?.userInfo?.firstname} ${user?.userInfo?.lastname}`,
                    label: `${user?.userInfo?.firstname} ${user?.userInfo?.lastname}`,
                    userid : user?.userid
                  }));
              setUsers(options);
              dispatch(hideLoader());
            }
          })
          .catch((err) => {
            console.error.bind("my account error", err);
            dispatch(hideLoader());
          })
    }, []);

    const handleShowInputs = (field,type) => {
        setEditingFields(prevFields => ({
            ...prevFields,
            [field]: type === 'show' ?  true : false
        }));
    };

    const handleInputsChange = (e)=>{
        let {name , value} = e.target;
        console.log(value);
        if(name === 'changeservicerto'){
            setLoanassignment(value);
        }else{
            setEditableFieldsData(prevFields => ({
                ...prevFields,
                [name]: value
            }));
        }
    };

    const handleDateChange = (date, field) => {
        console.log('field',date,field)
        setEditableFieldsData(prevFields => ({
            ...prevFields,
            [field]: date,
        }));
    };

    const handleFieldSave = (fieldname)=>{

        let fieldValue = editableFieldsData[fieldname];
        console.log(fieldname,fieldValue,editableFieldsData[fieldname]);
        if(fieldValue){
            let apiData = {
                loanid : loanid,
                [fieldname] : editableFieldsData[fieldname]
            }
            console.log("apiData",apiData);
            ApiCallForUpdate(apiData);
        }
        
    };
    const handleServicerUpdate = ()=>{
        const selectedUser = users.find(user => user.value === loanassignment);
        let apiData = {
            loanid : loanid,
            loanassignment : {
                ...loanInfo?.loanassignment,
                changeservicerto : {name : selectedUser?.value,
                                   userid : selectedUser?.userid},
            }
        }
        ApiCallForUpdate(apiData);
    }
    const ApiCallForUpdate = (apiData)=>{
        dispatch(showLoader());
        servicingUpdate(apiData,loanid).then(function (response) {
            if (response) {
               console.log('response?.result',response.result);
               setLoandetails();
               dispatch(hideLoader());
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
              dispatch(hideLoader());
            })
    };
    const setLoandetails =()=>{
        getLoanDetails(loanid)
        .then(response => {
          console.log('getLoanDetails', response);
          if (response?.result[0]) {
            setLoanInfo(response?.result[0]);
            console.log(response);
          };
        //   dispatch(hideLoader());
        })
        .catch(err => {
          console.error('my account error', err);
        });
      };
    



    return (
        <div id="layout-wrapper" className="dashboard">
             {loading ? <Loader/> : null} 
              <PipelineHeader />
            <div className="dashboard_block">
                <PipelineStatus />
                <h2 className="form_title">{isDrawLoanSummary ? "Draw" : "Servicing"}  <span className="space">&#10095;</span>  <span className="sub">Loan Summary</span></h2>
                {!loanInfo?.servicingsetup?.isServiceSetupCompleted && !configdata?.common?.showServicingForms ? 
            <Blockingtextmsg  textmessage={blockingtext}/> : 
            <div className="card user_management detailed_app loan_summary">
                    <div className="card-body my_account">
                        <div className="search">
                            <h4 className="mb-sm-0 font-size-18">Loan summary</h4>
                        </div>
                        <div className="form_section">
                            <div className="main_details">
                                <div className="detail_block">
                                    <p>Loan Amount</p>
                                    <h4>{loanSummary?.loanamount ? formatCurrency(loanSummary?.loanamount) : 'N/A'}</h4>
                                </div>
                                <div className="detail_block">
                                    <p>Disbursed Loan Amount</p>
                                    <h4> {loanSummary?.disbursedloanamount ? formatCurrency(loanSummary?.disbursedloanamount) : 'N/A'}</h4>
                                </div>
                                <div className="detail_block">
                                    <p>Current Loan Amount</p>
                                    <h4>{loanInfo?.currentloanamount ? formatCurrency(loanInfo?.currentloanamount) : '$0.00'}</h4>
                                </div>
                                <div className="detail_block">
                                    <p>Original Term</p>
                                    <h4>{loanSummary?.loanterm ? loanSummary?.loanterm + ' Months' : 'N/A'}</h4>
                                </div>
                                <div className="detail_block">
                                    <p>Remaining Term</p>
                                    <h4>{monthsLeft ? monthsLeft + ' Months' : 'N/A'}</h4>
                                </div>
                               </div>
                            <div className="main_details">
                            <div className="detail_block">
                                    <p>Number of Extensions</p>
                                    <h4>{loanInfo?.extensionDataCount ? loanInfo?.extensionDataCount : 0}</h4>
                                </div>
                                <div className="detail_block">
                                    <p>Interest Rate</p>
                                    <h4>{loanSummary?.interestrate ? loanSummary?.interestrate + ' %' : '0.00%'}</h4>
                                </div>
                                <div className="detail_block">
                                    <p>Total Interest Paid</p>
                                    <h4>{feeTablesummaryData?.interestObj?.amountPaid ? formatCurrency(feeTablesummaryData?.interestObj?.amountPaid) : 'N/A'}</h4>
                                </div>
                                <div className="detail_block">
                                    <p>Next Due Date</p>
                                    <h4> {loanInfo?.nextpaymentDate ? moment.utc(loanInfo?.nextpaymentDate).format("MM-DD-YYYY") : 'N/A'}</h4>
                                </div>
                                <div className="detail_block">
                                    <p>Close Date</p>
                                    <h4>{loanSummary?.closedate ? moment.utc(loanSummary?.closedate).format("MM-DD-YYYY")  : 'N/A'}</h4>
                                </div>
                            </div>
                            <div className="main_details more_details">
                                <div className="detail_block">
                                    <p>Serviced Loan Amount</p>
                                    <h4>{loanInfo?.servicedloanamount ? formatCurrency(loanInfo?.servicedloanamount) : 'N/A'}</h4>
                                </div>
                                <div className="detail_block">
                                    <p>Principal Paydowns</p>
                                    <h4>{feeTablesummaryData?.principalObj?.amountPaid ? formatCurrency(feeTablesummaryData?.principalObj?.amountPaid) : 'N/A'}</h4>
                                </div>
                                <div className="detail_block">
                                    <p>Currently Due</p>
                                    <h4>{feeTablesummaryData?.interestObj?.amountDue ? formatCurrency(feeTablesummaryData?.interestObj?.amountDue) : 'N/A'}</h4>
                                </div>
                                 <div className="detail_block">
                                    <p>1098 Reportable Fees Paid</p>
                                    <h4>{loanInfo?.reportable1098feespaid ? formatCurrency(loanInfo?.reportable1098feespaid) : 'N/A'}</h4>
                                </div>
                            </div>
                            <div className="main_details more_details">
                                
                               
                                <div className="detail_block">
                                    <p>Remaining Reserves</p>
                                    <h4>{loanInfo?.balanceinterestreserveamount || loanInfo?.interestreserveamount}</h4>
                                </div>
                                {!editingFields?.solddate ?
                                <div className="detail_block">
                                <p>Sold Date</p>
                                  <h4>{loanSummary?.solddate ? moment.utc(loanSummary?.solddate).format("MM-DD-YYYY") : 'N/A'}</h4>
                                <span id='showsolddate' onClick={()=>handleShowInputs('solddate','show')} className="material-symbols-outlined">edit_square</span>
                            </div> : <div className="detail_block edit_detail_block">
                                    <p>Sold Date</p>
                                    <DatePicker
                                                id='solddate'
                                                name='solddate'
                                                selected={editableFieldsData?.solddate ? new Date(editableFieldsData?.solddate) : null}
                                                onChange={(date) => handleDateChange(date, 'solddate')}
                                                dateFormat="MM-dd-yyyy"
                                                placeholderText="Select Date"
                                                className="form-control"
                                            />
                                    {/* <input id="solddate" type="date" name="solddate" class="form-control"/> */}
                                    <div className="d-flex align-items-center edit_action">
                                    <span onClick={()=>handleShowInputs('solddate','hide')} id='hidesolddate' className="material-symbols-outlined">close</span>
                                    <span onClick={()=>handleFieldSave('solddate')} id='savesolddate' className="material-symbols-outlined fill-btn">check</span>
                                    </div>
                                </div>
                            }
                                
                                {/*  */}
                                {/* <div className="detail_block">
                                    <p>Remaining Impounds</p>
                                    <h4>$ 0.00</h4>
                                </div> */}
                                <div className="detail_block">
                                    <p>Current Maturity Date</p>
                                    <h4>{loanSummary?.currentmaturitydate ? moment.utc(loanSummary?.currentmaturitydate).format("MM-DD-YYYY") : 'N/A'}</h4>
                                </div>
                                <div className="detail_block">
                                    <p>Original Maturity Date</p>
                                    <h4>{loanSummary?.proposedmaturitydate ? moment.utc(loanSummary?.proposedmaturitydate).format("MM-DD-YYYY") : 'N/A'}</h4>
                                </div>
                            </div>
                            <div className="main_details more_details">
                                
                            <div className="detail_block">
                                    <p>Holdback Balance</p>
                                    <h4>{loanSummary?.holdbackbalance ? formatCurrency(loanSummary?.holdbackbalance) : 'N/A'}</h4>
                                </div>
                                <div className="detail_block">
                                    <p>Number of Draw Requests</p>
                                    <h4>{loanSummary?.noofdraws || 0}</h4>
                                </div>
                                <div className="detail_block">
                                    <p>Number of Approved Draw Requests</p>
                                    <h4>{loanSummary?.approveddraws || 0}</h4>
                                </div>
                                <div className="detail_block">
                                    <p>Number of Paid Out Draw Requests</p>
                                    <h4>{loanSummary?.drawspaid || 0}</h4>
                                </div>
                              
                            </div>
                            <div className="main_details more_details">
                            {!editingFields?.foreclosuredate ? 
                             <div className="detail_block">
                             <p>Foreclosure Date</p>
                             <h4>{loanSummary?.foreclosuredate ? moment.utc(loanSummary?.foreclosuredate).format("MM-DD-YYYY") : 'N/A'}</h4>
                              
                             <span onClick={()=>handleShowInputs('foreclosuredate','show')} id='showforeclosuredate' className="material-symbols-outlined">edit_square</span>
                         </div> : 
                         <div className="detail_block edit_detail_block">
                                    <p>Foreclosure Date</p>
                                            <DatePicker
                                            id='foreclosuredate'
                                                name='foreclosuredate'
                                                selected={editableFieldsData?.foreclosuredate ? new Date(editableFieldsData?.foreclosuredate) : null}
                                                onChange={(date) => handleDateChange(date, 'foreclosuredate')}
                                                dateFormat="MM-dd-yyyy"
                                                placeholderText="Select Date"
                                                className="form-control"
                                            />
                                    {/* <input id="foreclosuredate" type="date" name="foreclosuredate" class="form-control capitalize-text"/> */}
                                    <div className="d-flex align-items-center edit_action">
                                    <span onClick={()=>handleShowInputs('foreclosuredate','hide')} id='hideforeclosuredate' className="material-symbols-outlined">close</span>
                                    <span onClick={()=>handleFieldSave('foreclosuredate')} id='saveforeclosuredate' className="material-symbols-outlined fill-btn">check</span>
                                    </div>
                                </div>
                            }
                               
                                {/*  */}
                                {!editingFields?.fcireferencenumber ? 
                                 <div className="detail_block">
                                    <p>FCI Reference Number</p>
                                    <h4>{loanSummary?.fcireferencenumber || 'N/A'}</h4>
                                    <span onClick={()=>handleShowInputs('fcireferencenumber','show')} id='referencenumbershow' className="material-symbols-outlined">edit_square</span>
                                </div> : 
                                <div className="detail_block edit_detail_block">
                                    <p>FCI Reference Number</p>
                                    <input id="fcireferencenumber" type="text" onChange={handleInputsChange} value={editableFieldsData?.fcireferencenumber || ''} name="fcireferencenumber" class="form-control"/>
                                    <div className="d-flex align-items-center edit_action">
                                    <span onClick={()=>handleShowInputs('fcireferencenumber','hide')} id='referencenumberhide' className="material-symbols-outlined">close</span>
                                    <span onClick={()=>handleFieldSave('fcireferencenumber')} id='referencenumbersave' className="material-symbols-outlined fill-btn">check</span>
                                    </div>
                                </div>
                            }
                            {!editingFields?.bsireferencenumber ? 
                                 <div className="detail_block">
                                    <p>BSI Reference Number</p>
                                    <h4>{loanSummary?.bsireferencenumber || 'N/A'}</h4>
                                    <span onClick={()=>handleShowInputs('bsireferencenumber','show')} id='referencenumbershow' className="material-symbols-outlined">edit_square</span>
                                </div> : 
                                <div className="detail_block edit_detail_block">
                                    <p>BSI Reference Number</p>
                                    <input id="bsireferencenumber" type="text" onChange={handleInputsChange} value={editableFieldsData?.bsireferencenumber || ''} name="bsireferencenumber" class="form-control"/>
                                    <div className="d-flex align-items-center edit_action">
                                    <span onClick={()=>handleShowInputs('bsireferencenumber','hide')} id='referencenumberhide' className="material-symbols-outlined">close</span>
                                    <span onClick={()=>handleFieldSave('bsireferencenumber')} id='referencenumbersave' className="material-symbols-outlined fill-btn">check</span>
                                    </div>
                                </div>
                            }
                                
                             
                                {/* <div className="detail_block">
                                    <p>PPP# of Months</p>
                                    <h4>None Entered</h4>
                                </div> */}
                                {/* <div className="detail_block">
                                    <p>PPP Value</p>
                                    <h4>N/A</h4>
                                </div> */}
                                {!editingFields?.paidoffdate ? 
                                <div className="detail_block">
                                <p>Paid off Date</p>
                                 <h4>{loanSummary?.paidoffdate ? moment.utc(loanSummary?.paidoffdate).format("MM-DD-YYYY") : 'N/A'}</h4>
                                <span onClick={()=>handleShowInputs('paidoffdate','show')} id='showpayoffinput' className="material-symbols-outlined">edit_square</span>
                            </div> : 
                            <div className="detail_block edit_detail_block">
                                    <p>Paid off Date</p>
                                    <DatePicker
                                    id='paidoffdate'
                                                name='paidoffdate'
                                                selected={editableFieldsData?.paidoffdate ? new Date(editableFieldsData?.paidoffdate) : null}
                                                onChange={(date) => handleDateChange(date, 'paidoffdate')}
                                                dateFormat="MM-dd-yyyy"
                                                placeholderText="Select Date"
                                                className="form-control"
                                            />
                                    {/* <input id="paidoffdate" type="date" name="paidoffdate" class="form-control"/> */}
                                    <div className="d-flex align-items-center edit_action">
                                    <span onClick={()=>handleShowInputs('paidoffdate','hide')} id='hidepayoffinput' className="material-symbols-outlined">close</span>
                                    <span  onClick={()=>handleFieldSave('paidoffdate')} id='savepayoff' className="material-symbols-outlined fill-btn">check</span>
                                    </div>
                                </div>
                            }
                                
                              
                               
                            </div>
                            <div className="main_details more_details">
                            {/* <div className="detail_block">
                                    <p>Servicer</p>
                                    <h4>{loanassignment || 'N/A'}</h4>
                                </div> */}
                                {!editingFields?.servicer ? 
                                <div className="detail_block">
                                <p>Servicer</p>
                                <h4>{loanSummary?.servicer || 'N/A'}</h4>
                                <span onClick={()=>handleShowInputs('servicer','show')} id='showservicer' className="material-symbols-outlined">edit_square</span>
                            </div> : 
                            <div className="detail_block edit_detail_block">
                                    <p>Servicer</p>
                                 <select className="form-control" name='changeservicerto' value={loanassignment} onChange={handleInputsChange}>
                                    <option value={''}>Select</option>
                                    {users?.map((user,index)=>{
                                        return(
                                          <option value={user?.value}>{user?.label}</option>
                                        )
                                    }) }
                                 </select>
                                    <div className="d-flex align-items-center edit_action">
                                    <span onClick={()=>handleShowInputs('servicer','hide')} id='hideservicer' className="material-symbols-outlined">close</span>
                                    <span  onClick={()=>handleServicerUpdate()} id='saveservicer' className="material-symbols-outlined fill-btn">check</span>
                                    </div>
                                </div>
                            }
                                <div className="detail_block">
                                    <p>Underwriter</p>
                                    <h4>{loanSummary?.underwriter || 'N/A'}</h4>
                                </div>
                                <div className="detail_block">
                                    <p>Guarantor Name</p>
                                        <h4>
                                            {loanInfo?.applicant?.[0]?.guarantorstitle ? loanInfo?.applicant?.[0]?.guarantorstitle :
                                                <>
                                                    {
                                                        loanInfo?.applicant?.[0].borrowerfirstname && loanInfo?.applicant?.[0].borrowerfirstname + ' '}
                                                    {loanInfo?.applicant?.[0].borrowerlastname && loanInfo?.applicant?.[0].borrowerlastname}</>
                                            }
                                        </h4>
                                    {/* <h4>Tammy Niehart</h4> */}
                                </div>
                                <div className="detail_block">
                                    <p>Guarantor Email</p>
                                    <h4>{loanSummary?.guarantoremail || 'N/A'}</h4>
                                </div>
                                
                            </div>
                            <div className="main_details more_details">
                            <div className="detail_block">
                                    <p>Guarantor Number</p>
                                    <h4>{loanSummary?.guarantornumber || 'N/A'}</h4>
                                </div>
                                <div className="detail_block">
                                    <p>Guarantor Address</p>
                                    <h4>{loanSummary?.guarantatoraddress || 'N/A'}</h4>

                                </div>
                               
                                <div className="detail_block">
                                    <p>Total Project Percent Complete</p>
                                    <h4>{loanSummary?.projectcompletepercentage ? loanSummary?.projectcompletepercentage + ' %' : '0%'}</h4>
                                </div>
                                <div className="detail_block empty_block">
                                    
                                </div>
                                
                            </div>

                        </div>
                    </div>
                </div>
            }
                
            </div>
            <PipelineFooter />
        </div>
    );
};

export default ServicingLoanSummary;