import React,{useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
const Landing = (props) => {
    const navigate = useNavigate();
    const configdata = useSelector(state => state.configdata);

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            navigate('/pipeline')
        }
    }, []);

  return (
    <div style={{
        backgroundImage: 'url(assets/images/common-img/landingBg.jpg)',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}>
     <div className="account-pages landing_page">
            <div className="container wrapper">
                <div className="row justify-content-center login_box">
                    <div className="col-md-12 col-lg-12">
                        <div className="signin-header mb-4">
                            <img src="assets/images/brand/logo-light.png" className="signin-logo" />
                           <div className="xray-btn"> <span className="material-icons-outlined"> support_agent</span>(866) 828-0062</div>
                        </div>
                        <div className='landing_wrp'>
                        <div className="card overflow-hidden ">
                    
                            <div className="card-body text-center"> 
                                <div className="row landing_content"> 
                                    <div className="col-md-7"> 
                                          {configdata?.common?.landingpageImage && <img src={configdata?.common?.landingpageImage+'?auto=compress,format&width=600&q=200'} className="landing_logo" />}
                     
                                    </div>

                                    <div className="col-md-5 hello_welcome"> 
                                            <div className="welcome"> 
                                                    <h1>Hello & Welcome,</h1>
                                                    <div className="d-flex">    
                                                           <button id="newBorrowerButton" className="btn" onClick={(e) => {navigate('/propertyinfo/?usertype=borrower');}}><img src="assets/images/borrower.svg" />New <br/>Borrower<span>+</span></button>
                                                           <button id="newBrokerButton" className="btn"  onClick={(e) => {navigate('/propertyinfo/?usertype=broker');}}><img src="assets/images/broker1.svg" />New<br/> Broker<span>+</span></button>
                                                   </div>
                                               </div>
                                               <div className="welcome returning_users"> 
                                                    <h1>Returning Users, Please Login</h1>
                                                    <p>I am affiliated with Easy Street Capital LLC as</p>
                                                    <div className="d-flex"> 
                                                           <button className="btn" id="teamMemberButton" onClick={(e) => {navigate('login');}}><img src="assets/images/team-member.svg" />Team<br/> Member</button>
                                                           <button className="btn" id="borrowerButton" onClick={(e) => {navigate('/propertyinfo/?usertype=borrower');}}><img src="assets/images/borrower.svg" />Borrower <br/>(Guarantor)</button>
                                                           <button className="btn" id="brokerButton" onClick={(e) => {navigate('/propertyinfo/?usertype=broker');}}><img src="assets/images/broker1.svg" />Broker<br/>(Correspondent)</button>
                                                   </div>
                                               </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
</div>
  );
};

export default Landing;