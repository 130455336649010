import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useLoanInfo } from '../utils/loanInfoContext';
import { getForm1098Details, generateForm1098, deleteForm1098, copyForm1098, emailForm1098 ,getOrganization} from "./../utils/reducer";
import { hideLoader, showLoader } from '../redux/reducers/Actions';
import moment from 'moment';
import Blockingtextmsg from './Blockingtext';
import FileViewer from './DocViewer';
const ServicesForm1098 = () => {
    const blockingtext = 'Form 1098 cannot be accessed until servicing setup gets completed';
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loanInfo, setLoanInfo } = useLoanInfo();
    console.log("loaninfo",loanInfo);
    const [loanid, setLoanid] = useState(loanInfo?.loanid);
    const loading = useSelector((state) => state.loaderVisible);

    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [selectedRows, setSelectedRows] = useState(false);
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [showDoc, setshowDoc] = useState(false);
    const [docPage, setDoc] = useState({});
    const [form1098Data, setForm1098Data] = useState([]);
    const [selectedYear, setYear] = useState(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [messageType, setMessageType] = useState('success');
    const [successMessage, setSuccessMessage] = useState('');
    const [yearValid, setYearValid] = useState(false);
    
    const configdata = useSelector(state => state.configdata);
    let showServicingForms = configdata?.common?.showServicingForms || true;
    

    const [organizationData,setOrganizationData] = useState([]);
    const [namesDropdown,setNamesDropdown] = useState([]);
    const [formValues,setFormValues] = useState({});

    const initializer = () => {
        setPage(1); setPerPage(10); setTotalRows(0); handleClearRows(); setSelectedRows(false);
    }

    useEffect(() => {
        if (loanid) {
            getForm1098(loanid);
            initializer();
            getOrganizationData();
        }
    }, [loanid]);
    useEffect(() => {
        setLoanid(loanInfo?.loanid)

    }, [loanInfo?.loanid]);


    const getForm1098 = (loanid) => {
        dispatch(showLoader());
        getForm1098Details(loanid)
            .then(function (response) {
                if (response?.statusCode === 200 && response?.result?.length !== 0) {
                    setForm1098Data(response?.result?.data);

                }
                dispatch(hideLoader());
            })
            .catch((err) => {
                console.error("error", err);
                dispatch(hideLoader());
            });
    };

    const generateForm = (loanid, year , lendername , lenderlin) => {
        let lenName = null;
        let lenLin = null;
        if(year == null || year == ""){
            setYearValid(true)
            dispatch(showLoader());
            setPopupOpen(true);
            setSuccessMessage("Year is Required");
            dispatch(hideLoader());
            //setTimeout(() => setYearValid(false) , 500);
            //return
        }else{

            if(lendername && lenderlin){
                lenName = lendername;
                lenLin = lenderlin;
            }
    
            console.log("lender" + lenName + lenLin);
            dispatch(showLoader());
            console.log(loanid, year);
            generateForm1098(loanid, parseInt(year),lenName,lenLin)
            .then(function (response) {
                if (response?.statusCode === 200 && response?.result?.length !== 0) {
                    console.log("response?.result ", response?.result)
                    setPopupOpen(!isPopupOpen)
                    setSuccessMessage('Form 1098 Generated Successfully')
                    getForm1098(loanid)
                }
                dispatch(hideLoader());
            })
            .catch((err) => {
                setPopupOpen(!isPopupOpen)
                setMessageType('warning')
                setSuccessMessage(err)
                console.error("error", err);
                dispatch(hideLoader());
            });
        }

       
        
    };

    const deleteForm = (loanid) => {
        let formData = {
            formid: []
        }
        if(selectedRows && selectedRows?.length > 0){
            selectedRows.forEach(item => {
                formData.formid.push(item.formid)
            })
        } else {
            return 
        }
       
        console.log(formData, 'formData')

        dispatch(showLoader());
        deleteForm1098(formData)
            .then(function (response) {
                if (response?.statusCode === 200) {
                    console.log("response?.result ", response?.result)
                    setPopupOpen(!isPopupOpen)
                    setSuccessMessage('Form 1098 Deleted Successfully')
                    getForm1098(loanid)
                    handleClearRows()
                }
                dispatch(hideLoader());
            })
            .catch((err) => {
                setPopupOpen(!isPopupOpen)
                setMessageType('warning')
                setSuccessMessage(err)
                console.error("error", err);
                dispatch(hideLoader());
                handleClearRows()
            });
    };

    const copyForm = (loanid) => {
        let formData = {
            formid: []
        }
        if(selectedRows && selectedRows?.length > 0){
            selectedRows.forEach(item => {
                formData.formid.push(item.formid)
            })
        } else {
            return 
        }
       
        console.log(formData, 'formData')

        dispatch(showLoader());
        copyForm1098(loanid, formData)
            .then(function (response) {
                if (response?.statusCode === 200) {
                    console.log("response?.result ", response?.result)
                    setPopupOpen(!isPopupOpen)
                    setSuccessMessage('Form 1098 Copied Successfully')
                    getForm1098(loanid)
                    handleClearRows()
                }
                dispatch(hideLoader());
            })
            .catch((err) => {
                setPopupOpen(!isPopupOpen)
                setMessageType('warning')
                setSuccessMessage(err)
                console.error("error", err);
                dispatch(hideLoader());
                handleClearRows()
            });
    };

    const emailForm = (loanid) => {
        let formData = {
            formid: []
        }
        if(selectedRows && selectedRows?.length > 0){
            selectedRows.forEach(item => {
                formData.formid.push(item.formid)
            })
        } else {
            return 
        }
       
        console.log(formData, 'formData')

        dispatch(showLoader());
        emailForm1098(loanid, formData)
            .then(function (response) {
                if (response?.statusCode === 200) {
                    console.log("response?.result ", response?.result)
                    setPopupOpen(!isPopupOpen)
                    setSuccessMessage('Form 1098 Email Sent Successfully')
                    getForm1098(loanid)
                    handleClearRows()
                }
                dispatch(hideLoader());
            })
            .catch((err) => {
                setPopupOpen(!isPopupOpen)
                setMessageType('warning')
                setSuccessMessage(err)
                console.error("error", err);
                dispatch(hideLoader());
                handleClearRows()
            });
    };


    const columns = [
        {
            name: 'FILE NAME',
            selector: (row) => row?.filename || '',
            //   sortable: true,
            width: '30%',
        },
        {
            name: 'UPLOAD DATE',
            selector: (row) => row?.uploaddate ? moment.utc(row?.uploaddate).format("MM-DD-YYYY") : '',
            //   sortable: true,
            width: '17%',
        },
        {
            name: 'VERIFIED',
            cell: (row, index) => (
                <td className="text-center" key={index}><label className="toggle mb-0">
                    <input className="toggle-checkbox" type="checkbox" disabled={true}/>
                    <div className="toggle-switch"></div>

                </label></td>
            ),
            width: '20%',
        },
        {
            name: 'DOC TYPE',
            selector: (row) => row?.doctype || '',
            width: '20%',
        },
        {
            name: 'Actions',
            cell: (row, index) => (
                <div className="btn-group" key={index}>
                    {/* <button type="button" className="btn btn-outline-secondary"><span className="material-symbols-outlined icon">
                        edit </span></button> */}
                    <button type="button" className="btn btn-outline-secondary"><span className="material-symbols-outlined icon" onClick={(e) => handleFile(e, row)}>
                        visibility </span></button>
                    <button type="button" className="btn btn-outline-secondary" onClick={(e) => handleDownloadFile(e, row)}><span className="material-symbols-outlined icon">
                        download </span></button>
                </div>
            ),
            width: '10%',

        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',

            },
        },
        table: {
            style: {
                border: '1px solid #CCD8F6',
            },
        },
    };

    const handleFile = (e, row) => {
        e.preventDefault()
        setshowDoc(true);
        let source = configdata?.common?.resourcesCloudfront + row.filepath;
        let fileObject = {
            url: source,
            filename: row.filename,
            type: "pdf"
        }
        setDoc(fileObject)
    };

    const handleDownloadFile = (e, row) => {
        e.preventDefault()

        console.log("row", row)
        let source = configdata?.common?.resourcesCloudfront + row.filepath;
        const link = document.createElement('a');
        link.href = source;
        link.setAttribute('download', row.filename); //or any other extension
        link.setAttribute('target', "_blank");
        document.body.appendChild(link);
        link.click();
    };



    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
        console.log('Selected Rows: ', selectedRows);
    };
    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }

    const closePopup = () => {
        setPopupOpen(false);
    }
    
    /*const startYear = loanInfo?.wireinstructions?.actualwire_funddate ? new Date(loanInfo?.wireinstructions?.actualwire_funddate).getFullYear() : new Date().getFullYear();
    let endYear = loanInfo?.cleartoclose?.proposedmaturitydate || '';
    let closingDate = loanInfo?.cleartoclose?.actualclosingdate;
    console.log('loanInfo', loanInfo?.loanterm);
    if (!endYear && loanInfo?.loanterm && closingDate) {
        const closingDateClone = new Date(closingDate);
        closingDateClone.setMonth(closingDateClone.getMonth() + loanInfo.loanterm);
        closingDateClone.setDate(1);
        closingDateClone.setMonth(closingDateClone.getMonth() + 1);
        let enddate = closingDateClone.toISOString().split('T')[0];
        endYear = enddate ? new Date(enddate).getFullYear() : '';
        console.log('closingDateClone', closingDateClone.toDateString());
    }
    console.log('wireinstructions',startYear, endYear)
    const years = [];
    for (let i = startYear; i <= endYear; i++) {
        years.push(i);
    }
    console.log('wireinstructions',years);*/

    const startYear = loanInfo?.wireinstructions?.actualwire_funddate 
    ? new Date(loanInfo.wireinstructions.actualwire_funddate).getFullYear() 
    : new Date().getFullYear();

    let endYear = loanInfo?.cleartoclose?.proposedmaturitydate 
        ? new Date(loanInfo.cleartoclose.proposedmaturitydate).getFullYear() 
        : '';

    if (!endYear && loanInfo?.loanterm && loanInfo?.cleartoclose?.actualclosingdate) {
        const closingDateClone = new Date(loanInfo.cleartoclose.actualclosingdate);
        closingDateClone.setMonth(closingDateClone.getMonth() + loanInfo.loanterm);
        closingDateClone.setDate(1);
        endYear = closingDateClone.getFullYear();
        console.log('Calculated End Year from Closing Date:', closingDateClone.toDateString());
    }

    console.log('Start Year:', startYear, 'End Year:', endYear);

    const years = [];
    if (startYear && endYear && startYear <= endYear) {
        for (let i = startYear; i <= endYear; i++) {
            years.push(i);
        }
    }
    console.log('Years Array:', years);


    //organization relatd functionlity
    const getOrganizationData = () => {
        dispatch(showLoader());
        getOrganization().then(function (response) {
          console.log('getOrganization',response);
          if (response?.result) {
            //   setFormData({...formData,...response?.result?.[0]});
            setOrganizationData(response?.result);
          
          }
          dispatch(hideLoader());
          }).catch((err) => {
            dispatch(hideLoader());
            console.error.bind("getOrganization error", err);
          })
      }

      useEffect(() => {
            if(organizationData?.length !== 0){
                const orgNames = organizationData.map(org => org.name);
                setNamesDropdown(orgNames);
            }
      },[organizationData])

      const handleForm = (e) => {
        let {name , value} = e.target;
        //console.log(name + " " +value);
        let linExists = organizationData?.find(org => org.name === value);
        console.log("lin exists",linExists);
        // Prepare the updated form values.
        const newFormValues = {
            ...formValues,
            [name]: value // Update the current field based on the input
        };

        // If the organization exists, update lenderlin accordingly
        if (linExists) {
            console.log("lenderlin",linExists.lenderlin);
            newFormValues.lenderlin = linExists.lenderlin;
        }else {
            // Clear lenderlin if no match is found
            newFormValues.lenderlin = "";
        }
        
        setFormValues(newFormValues);
        
       
      }
    return (
        <div id="layout-wrapper" className="dashboard">
            <PipelineHeader />
            <div className="dashboard_block">
                <PipelineStatus />
                <h2 className="form_title">Servicing <span className="space">&#10095;</span>  <span className="sub">Form 1098</span> </h2>

                {loading ? <Loader/> : null} 
                
                {!loanInfo?.servicingsetup?.isServiceSetupCompleted && !configdata?.common?.showServicingForms ?
                    <Blockingtextmsg textmessage={blockingtext} /> :
                    <div className="card user_management detailed_app  services_form">
                        <div className="card-body my_account">

                            <div className='search'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <p className="mb-sm-0 font-size-18">year</p>
                                    <select className="form-control" value={selectedYear} onChange={(e) => setYear(e.target.value)}>
                                        <option value="">Select Year</option>
                                        {years.map((year) => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                    
                                    <label>Lender Name</label>
                                    <select className="form-control" name="lendername" value={formValues?.lendername} onChange={handleForm}>
                                        <option value="">Select Organization</option>
                                        {namesDropdown.map((name,index) => (
                                            <option key={index} value={name} >
                                                {name}
                                            </option>
                                        ))}
                                    </select>
                                    <label>Lender EIN</label>
                                    <input type='text' className='form-control' name ="lenderlin" 
                                        value={formValues?.lenderlin || ''} 
                                     readOnly
                                     style={{background:'rgb(233, 236, 239)'}}
                                     />

                                    <button type="button" onClick={(e) => generateForm(loanInfo?.loanid, selectedYear , formValues?.lendername,formValues?.lenderlin)} className="create_btn">Generate 1098 Form</button>
                                    
                                </div>
                                <div className="btn-group">
                                    <button type="button" onClick={(e) => copyForm(loanInfo?.loanid)} className="create_btn">COPY<span className="material-symbols-outlined">content_copy</span></button>
                                    <button type="button" onClick={(e) => deleteForm(loanInfo?.loanid)} className="create_btn">DELETE<span className="material-symbols-outlined">delete</span></button>
                                    <button type="button" onClick={(e) => emailForm(loanInfo?.loanid)} className="create_btn">EMAIL<span className="material-symbols-outlined">mail</span></button>
                                </div>
                            </div>
                            {/* {yearValid && <p className='error yearError'> Please select year</p>} */}

                            {isPopupOpen &&
                            <PopupMessage
                               // type={popupMessage == 'Please try with new reset link' ? 'warning' : 'success'}
                               // message={mode === "edit" ? "Default Need Updated" : "Default Need Added"}
                                //  message={popupMessage}
                                type={successMessage === "Year is Required" ? 'warning' : 'success'}
                                message={successMessage}
                                onClose={closePopup}
                            />}



                            <div className="table-responsive mt-4">
                                <DataTable
                                    // key={tableKey}
                                    columns={columns}
                                    data={form1098Data}
                                    pagination
                                    persistTableHead
                                    customStyles={customStyles}
                                    paginationTotalRows={totalRows}
                                    paginationServer
                                    paginationPerPage={perPage}
                                    paginationDefaultPage={page}
                                    selectableRows
                                    clearSelectedRows={toggledClearRows}
                                    onSelectedRowsChange={handleChange}
                                    // onChangeRowsPerPage={handlePerRowsChange}
                                    // onChangePage={handlePageChange}
                                    // onSort={handleSort}
                                    // sortServer={true}
                                    paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                    paginationComponentOptions={{
                                        rowsPerPageText: 'Per Page',
                                    }}
                                />
                                {/* <table className="table db_table">

                        <thead className="table-light">
                            <tr>
                                <th width="1%">
                                    <div className="font-size-16">
                                        <input className="form-check-input" type="checkbox" id="checkAll" checked="" />
                                        <label className="form-check-label" for="checkAll"></label>
                                    </div>
                                </th>
                                <th width="16%">File Name</th>
                                <th width="7%">Upload Date</th>
                                <th width="24%" className="text-center">Verified</th>
                                <th width="11%">Doc Type</th>
                                <th width="1%">Actions</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><div className="font-size-16">
                                    <input className="form-check-input" type="checkbox" id="checkAll" checked="" />
                                    <label className="form-check-label" for="checkAll"></label>
                                </div></td>
                                <td>1098 Form.pdf</td>
                                <td>4th March, 2024</td>
                                <td className="text-center"><label className="toggle mb-0">
                                    <input className="toggle-checkbox" type="checkbox" />
                                    <div className="toggle-switch"></div>

                                </label></td>
                                <td>Statement 1098</td>

                                <td>
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo"><span className="material-symbols-outlined icon">
                                            edit </span></button>
                                        <button type="button" className="btn btn-outline-secondary"><span className="material-symbols-outlined icon">
                                            visibility </span></button>
                                        <button type="button" className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#messagepopup" data-bs-whatever="@mdo"><span className="material-symbols-outlined icon">
                                            download </span></button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><div className="font-size-16">
                                    <input className="form-check-input" type="checkbox" id="checkAll" checked="" />
                                    <label className="form-check-label" for="checkAll"></label>
                                </div></td>
                                <td>1098 Form.pdf</td>
                                <td>4th March, 2024</td>
                                <td className="text-center"><label className="toggle mb-0">
                                    <input className="toggle-checkbox" type="checkbox" />
                                    <div className="toggle-switch"></div>

                                </label></td>
                                <td>Statement 1098</td>

                                <td>
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo"><span className="material-symbols-outlined icon">
                                            edit </span></button>
                                        <button type="button" className="btn btn-outline-secondary"><span className="material-symbols-outlined icon">
                                            visibility </span></button>
                                        <button type="button" className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#messagepopup" data-bs-whatever="@mdo"><span className="material-symbols-outlined icon">
                                            download </span></button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><div className="font-size-16">
                                    <input className="form-check-input" type="checkbox" id="checkAll" checked="" />
                                    <label className="form-check-label" for="checkAll"></label>
                                </div></td>
                                <td>1098 Form.pdf</td>
                                <td>4th March, 2024</td>
                                <td className="text-center"><label className="toggle mb-0">
                                    <input className="toggle-checkbox" type="checkbox" />
                                    <div className="toggle-switch"></div>

                                </label></td>
                                <td>Statement 1098</td>

                                <td>
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo"><span className="material-symbols-outlined icon">
                                            edit </span></button>
                                        <button type="button" className="btn btn-outline-secondary"><span className="material-symbols-outlined icon">
                                            visibility </span></button>
                                        <button type="button" className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#messagepopup" data-bs-whatever="@mdo"><span className="material-symbols-outlined icon">
                                            download </span></button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><div className="font-size-16">
                                    <input className="form-check-input" type="checkbox" id="checkAll" checked="" />
                                    <label className="form-check-label" for="checkAll"></label>
                                </div></td>
                                <td>1098 Form.pdf</td>
                                <td>4th March, 2024</td>
                                <td className="text-center"><label className="toggle mb-0">
                                    <input className="toggle-checkbox" type="checkbox" />
                                    <div className="toggle-switch"></div>

                                </label></td>
                                <td>Statement 1098</td>

                                <td>
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo"><span className="material-symbols-outlined icon">
                                            edit </span></button>
                                        <button type="button" className="btn btn-outline-secondary"><span className="material-symbols-outlined icon">
                                            visibility </span></button>
                                        <button type="button" className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#messagepopup" data-bs-whatever="@mdo"><span className="material-symbols-outlined icon">
                                            download </span></button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><div className="font-size-16">
                                    <input className="form-check-input" type="checkbox" id="checkAll" checked="" />
                                    <label className="form-check-label" for="checkAll"></label>
                                </div></td>
                                <td>1098 Form.pdf</td>
                                <td>4th March, 2024</td>
                                <td className="text-center"><label className="toggle mb-0">
                                    <input className="toggle-checkbox" type="checkbox" />
                                    <div className="toggle-switch"></div>

                                </label></td>
                                <td>Statement 1098</td>

                                <td>
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo"><span className="material-symbols-outlined icon">
                                            edit </span></button>
                                        <button type="button" className="btn btn-outline-secondary"><span className="material-symbols-outlined icon">
                                            visibility </span></button>
                                        <button type="button" className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#messagepopup" data-bs-whatever="@mdo"><span className="material-symbols-outlined icon">
                                            download </span></button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><div className="font-size-16">
                                    <input className="form-check-input" type="checkbox" id="checkAll" checked="" />
                                    <label className="form-check-label" for="checkAll"></label>
                                </div></td>
                                <td>1098 Form.pdf</td>
                                <td>4th March, 2024</td>
                                <td className="text-center"><label className="toggle mb-0">
                                    <input className="toggle-checkbox" type="checkbox" />
                                    <div className="toggle-switch"></div>

                                </label></td>
                                <td>Statement 1098</td>

                                <td>
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo"><span className="material-symbols-outlined icon">
                                            edit </span></button>
                                        <button type="button" className="btn btn-outline-secondary"><span className="material-symbols-outlined icon">
                                            visibility </span></button>
                                        <button type="button" className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#messagepopup" data-bs-whatever="@mdo"><span className="material-symbols-outlined icon">
                                            download </span></button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table> */}
                            </div>

                            {showDoc && <FileViewer source={docPage?.url} type={docPage?.type} close={setshowDoc} name={docPage?.filename} />}

                        </div>
                    </div>
                }

            </div>
            <PipelineFooter />
        </div>
    );
};

export default ServicesForm1098;