import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
const PricingRateLock = () => {
    const navigate = useNavigate();
    // 



    return (
        <div id="layout-wrapper" className="dashboard">
             <PipelineHeader />
            <div className="dashboard_block">
                <PipelineStatus />
                <h2 className="form_title">Pricing <span className="space">&#10095;</span>  <span className="sub"> Rate Lock</span> </h2>
            <div className="card user_management detailed_app rate_lock">
                <div className="card-body my_account">
                    <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" data-bs-toggle="tab" href="#CreateChangeRequests" role="tab">
                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                <span className="d-none d-sm-block">Create Change Requests</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " data-bs-toggle="tab" href="#ManageChangeRequests" role="tab">
                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                <span className="d-none d-sm-block">CREATE CHANGE REQUEST</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " data-bs-toggle="tab" href="#LockHistory" role="tab">
                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                <span className="d-none d-sm-block">Lock History</span>
                            </a>
                        </li>
                    </ul>
                     <div className="tab-content pt-15 text-muted">
                        <div className="tab-pane active" id="CreateChangeRequests" role="tabpanel">
                          
                            
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Create Change Requests</h4>
                                  
                                </div>
                                <div className="form_section">
                                            <div className="form-group mt_10">
                                                <label>Prime Rate:</label>
                                                <a href="" className="fs_btn small">Fetch</a>
                                                <label>UPB Loan Amount: $1,00.0 <span className="what">?</span></label>
                                            </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Rate</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Default Fund Rate</label>
                                                <input type="text" className="form-control" placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Points</label>
                                                <input type="text" className="form-control"
                                                    placeholder="Enter Points" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Spread</label>
                                                <input type="text" className="form-control"
                                                    placeholder="Enter" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Fund</label>
                                                <select className="form-control">
                                                    <option selected="">Please Select</option>
                                                    <option>NA</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Paid By</label>
                                                <select className="form-control">
                                                    <option selected="">Please Select</option>
                                                    <option>NA</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Adjustment Comments</label>
                                                <input type="text" className="form-control"
                                                    placeholder="Enter Adjustment Comments" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <a href="#" className="fs_btn">Submit</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            

                        </div>
                        <div className="tab-pane" id="ManageChangeRequests" role="tabpanel">
                          
                           
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18 co_borrower">CREATE CHANGE REQUEST</h4>
                                     </div>
                                     <div className="table-responsive">
                                        <table className="table db_table">
        
                                            <thead className="table-light">
                                                <tr>
                                                    <th width="9%">Rate</th>
                                                    <th width="10%">Points</th>
                                                    <th width="8%">Spread</th>
                                                    <th width="10%">Fund</th>
                                                    <th width="8%">Paid By Type</th>
                                                    <th width="12%">Req Date</th>
                                                    <th width="14%">Req Date</th>
                                                    <th width="5%">Comments</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>45</td>
                                                    <td>6</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>4TH MARCH,2024</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                </tr>
                                                <tr>
                                                    <td>45</td>
                                                    <td>6</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>4TH MARCH,2024</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                </tr>
                                                <tr>
                                                    <td>45</td>
                                                    <td>6</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>4TH MARCH,2024</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                </tr>
                                                <tr>
                                                    <td>45</td>
                                                    <td>6</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>4TH MARCH,2024</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                </tr>
                                                <tr>
                                                    <td>45</td>
                                                    <td>6</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>4TH MARCH,2024</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                </tr>
                                                <tr>
                                                    <td>45</td>
                                                    <td>6</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>4TH MARCH,2024</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                </tr>
                                                <tr>
                                                    <td>45</td>
                                                    <td>6</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>4TH MARCH,2024</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                </tr>
                                                <tr>
                                                    <td>45</td>
                                                    <td>6</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>4TH MARCH,2024</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                </tr>
                                                <tr>
                                                    <td>45</td>
                                                    <td>6</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>4TH MARCH,2024</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                </tr>
                                                <tr>
                                                    <td>45</td>
                                                    <td>6</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>4TH MARCH,2024</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>Lorem Ipsum</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                            
                        </div>
                        <div className="tab-pane" id="LockHistory" role="tabpanel">
                          
                           
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18 co_borrower">Lock History</h4>
                                 </div>
                            <div className="table-responsive">
                                <table className="table db_table">

                                    <thead className="table-light">
                                        <tr>
                                            <th width="9%">Rate</th>
                                            <th width="10%">Points</th>
                                            <th width="8%">Spread</th>
                                            <th width="10%">Is Locked</th>
                                            <th width="10%">Updated/Approved</th>
                                            <th width="12%">Base</th>
                                            <th width="10%">FUND NAME</th>
                                            <th width="8%">Locked Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>45</td>
                                            <td>6</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>4TH MARCH,2024</td>
                                        </tr>
                                        <tr>
                                            <td>45</td>
                                            <td>6</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>4TH MARCH,2024</td>
                                        </tr>
                                        <tr>
                                            <td>45</td>
                                            <td>6</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>4TH MARCH,2024</td>
                                        </tr>
                                        <tr>
                                            <td>45</td>
                                            <td>6</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>4TH MARCH,2024</td>
                                        </tr>
                                        <tr>
                                            <td>45</td>
                                            <td>6</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>4TH MARCH,2024</td>
                                        </tr>
                                        <tr>
                                            <td>45</td>
                                            <td>6</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>4TH MARCH,2024</td>
                                        </tr>
                                        <tr>
                                            <td>45</td>
                                            <td>6</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>4TH MARCH,2024</td>
                                        </tr>
                                        <tr>
                                            <td>45</td>
                                            <td>6</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>4TH MARCH,2024</td>
                                        </tr>
                                        <tr>
                                            <td>45</td>
                                            <td>6</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>4TH MARCH,2024</td>
                                        </tr>
                                        <tr>
                                            <td>45</td>
                                            <td>6</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>4TH MARCH,2024</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        
                    </div>

                    </div>

                </div>
            </div>
            </div>
            <PipelineFooter />
        </div>
    );
};

export default PricingRateLock;