import React ,{useState}from "react";

const PasswordSettings = (props) => {
    const [data, setData] = useState({  newPass : '' , oldPass : '', confirmPass : '',emailid : props?.data?.emailid || '' , });
    // const [errors, setErrors] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [validErrors, setValidErrors] = useState({
      length: false,
      number: false,
      specialCharacter: false,
    });
    const [regex,setRegex] = useState({});
    let regexes = {};
   

    const handleChange = (e) => {
      const { name, value } = e.target;
      //  props?.setData((prev) => ({
      //    ...prev,
      //    [e.target.name]: e.target.value?.trim(),
      //   }));
      // if(e.target.name === "oldPass"){
        
          
      // }
      setData(prevData => ({
        ...prevData,
        [name]: value
      }));
      props?.setData(prevState => ({
        ...prevState,
        [name]: value
      }));
    };

    const handleNewPass = (e)=>{
      
      let name = e.target.name;
      let value = e.target.value;
      let regex1 = value.length < 8;
      let regex2 = /\d/;
      let regex3 = /[&'"\\]/;
      console.log("regex1",regex1);
      console.log("regex2",regex2.test(value));
      console.log("regex3",regex3.test(value));

      (regex1 === true) ? regexes.regex1 = true : regexes.regex1 = '';
      (regex2 === false) ? regexes.regex1 = false : regexes.regex2 = '';
      (regex3 === true) ? regexes.regex3 = true : regexes.regex3 = '';

      setRegex(regexes);
      setData(prevData => ({
        ...prevData,
        [name]: value
      }));
      props?.setData(prevState => ({
        ...prevState,
        [name]: value
      }));

      // handleChange(e)
      // let value = e.target.value;
      // const hasNumber = /\d/.test(value);
      // const specialCharsRegex = /[&'"\\]/.test(value);
      // value.length < 8 ? setValidErrors((prevErrors) => ({ ...prevErrors, length: true })): setValidErrors((prevErrors) => ({ ...prevErrors, length: false }));
      // !hasNumber ? setValidErrors((prevErrors) => ({ ...prevErrors, number: true })): setValidErrors((prevErrors) => ({ ...prevErrors, number: false }));
      // specialCharsRegex ? setValidErrors((prevErrors) => ({ ...prevErrors, specialCharacter: true })): setValidErrors((prevErrors) => ({ ...prevErrors, specialCharacter: false })) 
    }

    const fieldsToCheck = {
      newPass : 'New Password' , oldPass : 'Old Password', confirmPass : 'Confirm Password'
      // ,emailid : 'Userid/Email' 
    };

    
    const updatePassword = () =>{
      const errors = {};
      const regexExp = /^(?=.*\d)[^&'" ]{8,}$/;
      const areEqual = (data?.oldPass === props?.data?.oldPass);
      console.log("arequal",areEqual);
      

      if(data?.oldPass === ""){
          errors.oldPass = "Old password is required";
      }
      
      // if(data?.oldPass !== ""){
      //   console.log(data?.oldPass === props?.myAccountOld?.data?.oldPass );
      //   // console.log((data?.oldPass).localeCompare(props?.myAccountOld?.data?.oldPass))
      //   // console.log(data?.oldPass);
      //   errors.checkOldPass = "Old password is wrong ";
      // }

      if(data?.newPass === ""){
        errors.newPass = "New password is required";
      }

      if(data?.confirmPass === ""){
        errors.confirmPass = "Confirm password is required";
      }

      if(data?.newPass && regexExp.test(data?.newPass)){
        // console.log(data?.newPass);
        // console.log(regexExp.test(data?.newPass));
        errors.newPass = "";
      }

      if(data?.confirmPass && regexExp.test(data?.confirmPass)){
        // console.log(data?.confirmPass);
        // console.log(regexExp.test(data?.confirmPass));
        errors.confirmPass = "";
      }

      if(data?.newPass !== data?.confirmPass){
        errors.confirmNewPass = "New password and confirm password must be same ";
      }

      
      
      console.log(data);
      console.log("errors-->",errors);
      console.log("reegxes-->",regex);
      setFormErrors(errors);

      // for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
      //     if (!props?.data?.fieldName?.trim()) {
      //       errors[fieldName] = `${fieldLabel} is required.`;
      //     }
      // };

      // if (props.data.newPass?.trim() && props.data.confirmPass?.trim()) {
      //   // console.log('hello');
      //   if (props.data.confirmPass?.trim() !== props.data.newPass?.trim()) {
      //     errors.check = 'New And Confirm Password Must Be Same';
      //   }

      //   // if(props?.data?.oldPass?.trim() !== ''){
      //   //   errors.oldPass = '';
      //   // }

      //   else if(validErrors.length || validErrors.number || validErrors.specialCharacter){
      //       errors.check = 'New Password Should Follow The Instructions'
      //   }
        
      //   // else{
      //   //   errors.confirmPass = '';
      //   //   errors.newPass = '';
      //   // }
       

        
        
        
        
        
      // }
      // setErrors(errors?.check);
      // setFormErrors(errors);
      // console.log('errors-----> ',errors);
      const isDataEmpty = Object.values(errors).every(value => value === '');

      // Check if the object has a length of zero
      // const isDataLengthZero = Object.keys(errors).length === 0;
    
      // Check if both conditions are met
      // const isDataEmptyAndZeroLength = isDataEmpty && isDataLengthZero;

      if (isDataEmpty) {
         console.log('ready to api call ----->pass ', props.data);
         props.handleUpdate();
      }


    }
  return (
    <>
      <div className="account_form password_settings">
        <h3 className="mb-30">password settings</h3>
        <div className="row">
          <div className="col-md-4">
            <div className="form">
                <div className="form-group">
                  <label>User Name<span className="rf">*</span> </label>
                    <input id="myAccountUsername" type="text" className={`form-control ${props?.userInfoErrors.username && 'invalid-input'}`} name='username' onFocus={() => { props?.setUserInfoErrors({ ...props?.userInfoErrors, username: null }) }} value={props?.data?.username} onChange={(e) =>
                    handleChange(e)} placeholder='Enter User Name' tabIndex="13" readOnly={true} />
                    {/* {userInfoErrors.username && (<span className="errormsg" style={{  color: "red" }}> {userInfoErrors.username} </span>)}     */}
                </div>
                <div className="form-group">
                <label>Old Password</label>
                <input
                  type="text"
                  name="oldPass"
                  // onFocus={() => setFormErrors({...formErrors,oldPass : null })}
                  className={`form-control ${formErrors?.oldPass && 'invalid-input'}`}
                  onChange={(e) => handleChange(e)}
                  placeholder="Enter Old Password"
                  tabIndex="15"
                  value={props?.data?.oldPass}
                  id="myAccountOldPassword" 
                />
                 {formErrors?.checkOldPass !== ""? 
                  <span className="errormsg" style={{ color: "red" }}>
                  
                    {formErrors?.checkOldPass}
                  </span>
               : ""}
                {/* {formErrors.oldPass && (
                <span className="errormsg" style={{ color: "red" }}>
                  {" "}
                  {formErrors.oldPass}{" "}
                </span>
              ) } */}
              </div>
              <div className="form-group">
                <label>Confirm Password</label>
                <input
                  type="text"
                  name="confirmPass"
                  // onFocus={() => setFormErrors({...formErrors,confirmPass : null })} 
                  className={`form-control ${formErrors?.confirmPass && 'invalid-input'}`}
                  onChange={(e) => handleChange(e)}
                  placeholder="Enter Confirm Password"
                  tabIndex="17"
                  value={props?.data?.confirmPass}
                  id="myAccountConfirmPassword" 
                />
                {/* {formErrors.confirmPass && (
                <span className="errormsg" style={{ color: "red" }}>
                  {" "}
                  {formErrors.confirmPass}{" "}
                </span>
              ) } */}
               {formErrors?.confirmNewPass? 
                <span className="errormsg" style={{ color: "red" }}>
                 
                  {formErrors?.confirmNewPass}
                </span>
               : ""}
              </div>
             
             
            

            
             
              <div className="form-group btns">
                <button id="updatePassword" onClick={updatePassword} className="solid_btn" tabIndex="18">
                  Update Password
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form form1">
            <div className="form-group">
                <label>Email ID</label>
                <input
                  type="text"
                  name="emailid"
                  // onFocus={() => setFormErrors({...formErrors,emailid : null })}
                  value={props?.data.emailid}
                  className={`form-control ${formErrors.emailid && 'invalid-input'}`}
                  onChange={(e) => handleChange(e)}
                  placeholder="Enter"
                  readOnly={true}
                  tabIndex="14"
                  id="myAccountEmailId" 
                  
                />
                {/* {formErrors.emailid && (
                <span className="errormsg" style={{ color: "red" }}>
                  {" "}
                  {formErrors.emailid}{" "}
                </span>
              ) } */}
              </div>
              <div className="form-group">
                <label>New Password</label>
                <input
                  type="text"
                  name="newPass"
                  // onFocus={() => setFormErrors({...formErrors,newPass : null })}
                  className={`form-control ${formErrors?.newPass && 'invalid-input'}`}
                  onChange={(e) => {
                    handleNewPass(e);
                  }}
                  placeholder="Enter New Password"
                  tabIndex="16"
                  value={props?.data?.newPass}
                  id="myAccountNewPassword" 
                />
                {/* {formErrors.newPass && (
                <span className="errormsg" style={{ color: "red" }}>
                  {" "}
                  {formErrors.newPass}{" "}
                </span>
              ) } */}
              </div>
            
              
            </div>
          </div>
          <div className="col-md-4">
            <div className="form password_instructions">
              <div className="instructions">
                <h4>INSTRUCTIONS</h4>
                <p>
                  {regex?.regex1 !== "" ? (
                    <span
                      className="material-symbols-outlined icon"
                      style={{ color: "red" }}
                    >
                      close
                    </span>
                  ) : (
                    <span className="material-symbols-outlined icon">
                      adjust
                    </span>
                  )}
                  New Password must be 8 characters long.{" "}
                </p>
                <p>
                  {regex?.regex2 !== ""  ? (
                    <span
                      className="material-symbols-outlined icon"
                      style={{ color: "red" }}
                    >
                      close
                    </span>
                  ) : (
                    <span className="material-symbols-outlined icon">
                      adjust
                    </span>
                  )}
                  Must contain at least one numeric value.{" "}
                </p>
                <p className="mb-0">
                  {regex?.regex3 !== ""  ? (
                    <span
                      className="material-symbols-outlined icon"
                      style={{ color: "red" }}
                    >
                      close
                    </span>
                  ) : (
                    <span className="material-symbols-outlined icon">
                      adjust
                    </span>
                  )}
                  It cannot contain the following special characters: &'"
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordSettings;