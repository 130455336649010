import React,{useState} from 'react'

const StrategyInfo1 = (props) => {
   const [showOtherinput, setShowOtherinput] = useState(false);
   const [customdata, setCustomdata] = useState(props?.strategyInfo?.[props.formJSON.name] || '');
   const [errors,setErrors] = useState({});
   let err = {};

  const handleClick = (data)=>{
    if(data === 'Other' && customdata.trim() === ''){
      // return
      err.error = 'invalid';
      setErrors(err) ;
    }else{
        err.error = '';
        setErrors(err) ;
       
      let val = data == 'Other' ? customdata : data
      props.updateStrategyInfo({
        [props.formJSON.name] : val, 
      });
       props.setCurrentState()
    }
    }
      
    const handleChangeOther = (e) => {
        // const regex = /^[1-9A-Z\s]+$/;

        // console.log(e.target.name);
        if(e.target.value === ''){
          err.error = 'invalid';
          setErrors(err) ;
          // setCustomdata(e.target.value);
          // return false;
        }
        err.error = '';
        setErrors(err) ;
        setCustomdata(e.target.value);
        
        
    }
    const allowedPaymentOptions = props.formJSON?.options.filter(option => option.value !== 'Other').map(option => option.value);
  return (
    <>
    <div className="cont si">
    
    
        {props?.strategyInfo?.downpaymentsource && (props.formJSON.name == 'payoffloan' || props.formJSON.name == 'applicationlicense' ) && <div className="seleted_item">
          <span className="material-icons-outlined"> done </span> <span className="text">My Source of down payment -
            <strong> {props?.strategyInfo?.downpaymentsource}</strong> </span>
            {props.formJSON.name == 'payoffloan' &&<a href="#" onClick={(e) => { e.preventDefault(); props.handleBack(); }} className="back_btn">
            <span className="material-icons-outlined">
              shortcut
            </span>Change
          </a>}
        </div>}
        {props?.strategyInfo?.payoffloan && props.formJSON.name == 'applicationlicense' && <div className="seleted_item">
          <span className="material-icons-outlined"> done </span> <span className="text">My plan for paying off the loan -
            <strong> {props?.strategyInfo?.payoffloan}</strong> </span> <a href="#" onClick={(e) => { e.preventDefault(); props.handleBack(); }} className="back_btn">
            <span className="material-icons-outlined">
              shortcut
            </span>Change
          </a>
        </div>}
            <h3 className="feild_title">{props.formJSON.label}</h3>
            <div className="btn-group2">
            {props.formJSON.options.map((option,index) => (
              option?.label == 'Other' ? <button id={option.id} key={index} type="button" className="cp_btn" style={(showOtherinput || props?.strategyInfo?.[props.formJSON.name] && !allowedPaymentOptions.includes(props?.strategyInfo?.[props.formJSON.name])) ? { background: 'rgba(149, 201, 113, 0.20)' } : {}} onClick={(e) => {setShowOtherinput(true); setCustomdata('')}}>Other</button>  : <button id={option.id} key={index} type="button" className="cp_btn" style={props?.strategyInfo?.[props.formJSON.name] === option.value && !showOtherinput ? { background: 'rgba(149, 201, 113, 0.20)' } : {}} onClick={(e) => {handleClick(option.value)}}>{option.label}</button>
              
            ))}
            <div className='search_block'>
            
              {(showOtherinput || props?.strategyInfo?.[props.formJSON.name] && !allowedPaymentOptions.includes(props?.strategyInfo?.[props.formJSON.name])) && <>   <div className={`search_wrp ${errors?.error === 'invalid' && 'invalid-input'}`}><div><input placeholder='Enter'
                  type='text'
                  className={`form-control ml_0`}
                  name='sourceofdownpayent'
                  // onChange={(e) => setCustomdata(e.target.value)} 
                  onChange={handleChangeOther} 
                  
                  // onBlur={() => handleClick('Other')}
                  value={customdata || ''}  
                               
                /> </div> <button className="search_btn" onClick={(e) => {handleClick('Other')}}>
                <span className="material-symbols-outlined">
                navigate_next
                                   </span>
                               </button> </div></>}
                  
               
              </div>
            </div>
             
                
           
        </div>
    </>
  )
}

export default StrategyInfo1