import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import InfoBar from '../InfoBar';
const BorrowerInfo6 = (props) => {
    const navigate = useNavigate();
    const { currentPage,setCurrentPage} = props;
    const {borrowerInfoOneData,setBorrowerInfoOneData} = props;
    const [reserversData,setReservesData] =  useState("");
    const [isReserves,setIsReserves] = useState("");
    const reservesRegex = /^[a-zA-Z ]+$/; // only alphabets are allowed

    const handleBorrowerSix = () => {
        const reserveBool = reservesRegex.test(reserversData);
        if(reserveBool === true){
            setIsReserves(false);
            setBorrowerInfoOneData({...borrowerInfoOneData,['reserves']:reserversData});
            setCurrentPage(currentPage + 1);
        }else{
            setIsReserves(true);
            return true;
        }
        
    }

    const handleReserves = (e) => {
        setReservesData(e.target.value);
        setIsReserves(false);     
    }

    const paragraphStyle = {
        color : '#FF0000'
    }

    return (
                <div className="content_blk">
                    <div className="hed">
                        {/* <a href="#" className="back material-icons" onClick={(e) => { navigate(-1); }}> keyboard_backspace </a> */}
                        <a href="#" className="back material-icons" onClick={(e) => {e.preventDefault(); props.onPrevious();}}> keyboard_backspace </a>
                        <h2 className="title"> <span className="title2">EASY <span className="sub"> BUILD</span></span> Borrower Info
                        </h2>
                    </div>
                    <div className="cont pi3 bi bi2">
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">My Name
                            <strong> : {borrowerInfoOneData.firstName} {borrowerInfoOneData.className}</strong> </span>
                    </div>
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">My Email
                            <strong> : {borrowerInfoOneData.emailid}</strong> </span>
                    </div>
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">My Phone
                            <strong> : {borrowerInfoOneData.phone}</strong> </span>
                    </div>
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">Address
                            <strong> : {borrowerInfoOneData.address}</strong> </span>
                    </div>
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">Borrower is
                            <strong> {borrowerInfoOneData.condition === 'no' ? 'not an Entity' : 'an Entity' }</strong> </span>
                    </div>
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">I am currently working
                            with Easy Street account executive as
                            <strong> {borrowerInfoOneData.role}</strong> </span>
                    </div>
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">Estimated credit score -
                            <strong> {borrowerInfoOneData.creditScore}</strong> </span> 
                            <a href="#" className="back_btn" onClick={(e) => {e.preventDefault(); props.onPrevious();}}>
                            <span className="material-icons-outlined">
                                shortcut
                            </span>Change
                        </a>
                    </div>
                    <h3 className="feild_title">What are the Borrower's current liquid cash reserves ?</h3>

                    <div className="select_box">
                        <input type="text" placeholder="Bank Account, Stocks etc" className="form-control" name="reserves" onChange={handleReserves}/>
                        {isReserves === true && <p style={paragraphStyle}>Only alphabets and space is allowed for cash reserves</p>}
                    </div>
                    {/* <div className="text-center"><button type="button" className="cp_btn mt-10"  onClick={(e) => {navigate('/BorrowerInfo7');}}>Next</button></div> */}
                    <div className="text-center"><button type="button" className="cp_btn mt-10"  onClick={handleBorrowerSix}>Next</button></div>
            </div>
        </div>
    );
};

export default BorrowerInfo6;