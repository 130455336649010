import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import Header from './Header';
import { useLoanInfo } from '../utils/loanInfoContext';
import InfoBar from './InfoBar';
import {getLoanprograms} from "../utils/reducer";
import { useSelector} from 'react-redux';
const LoanProgram = () => {
  const configdata = useSelector(state => state.configdata);
    const navigate = useNavigate();
    const { loanInfo, setLoanInfo } = useLoanInfo();
    
    const [properyInfo, setProperyInfo] = useState(loanInfo?.propertyinfo ? loanInfo?.propertyinfo : {});
    console.log('statestate',properyInfo);
   
      const [loanprograms, setLoanprograms] = useState([]);
      useEffect(() => {
       
        getLoanprograms().then(function (response) {
          // console.log('privacymenunewwe',response);
          if (response?.result) {
            setLoanprograms(response?.result);
           
          }
          }).catch((err) => {
            console.error.bind("my account error", err);
            
          })
      }, []);
    useEffect(() => {
      const isPropertyInfoEmpty = Object.keys(loanInfo.propertyinfo).length === 0;
        if (isPropertyInfoEmpty) {
            navigate('/propertyinfo');
        }
    }, [loanInfo]);

    const handleInputChange = (key, value) => {
        // Update the formData with the new key-value pair
       if(value ==  "EASY RENT"){
        window.open('https://www.easystreetcap.com/', '_blank');
        return
       }
        setProperyInfo((prevData) => {
          // Create a copy of the previous data
          let updatedData = { ...prevData };
    
          
      
          // Update the copied data with the new key-value pair
          updatedData = {
            ...updatedData,
            [key]: value,
          };
          console.log('loanInfoloanInfoloanInfo',updatedData);
          setLoanInfo((prevLoanInfo) => ({
            ...prevLoanInfo,
            propertyinfo: updatedData,
          }));
          if(updatedData?.loanfor == "Refinance" || (updatedData?.stepIntheProcess != "I'm doing research" && updatedData?.stepIntheProcess != "Doing research for borrower")){
            navigate('/dealinfo');
          }else{
          navigate('/strategyinfo');
          }
      console.log('updateddddata', updatedData);
          return updatedData;
        });
    };

    // Function to convert a string to camel case
    const toCamelCase = (str) => {
      return str
          .toLowerCase()
          .replace(/(?:^\w|[A-Z]|\b\w|\s+|\W+)/g, (match, index) => {
              if (/[\s\W]/.test(match)) {
                  return ''; // Remove spaces and non-word characters
              }
              return index === 0 ? match.toLowerCase() : match.toUpperCase();
          });
    };

    return (
        <div className="create_process">
              <Header />
            <div className="content_wrp">
                <InfoBar currentStage={'programtype'} properyInfo={properyInfo} progress={'100%'} />
                <div className="content_blk">
                    <div className="hed">
                    <a href="#" className="back material-icons" onClick={(e) => {e.preventDefault(); navigate('/propertyinfo');}}> keyboard_backspace </a>
                        <h2 className="title">Loan Program</h2>
                    </div>
                    <div className="cont loan_program">
                        <h3 className="feild_title">Select to Continue</h3>
                        <div className="row ">
                        {loanprograms.map((key, index) => (
                            <div id={toCamelCase(key?.loanProgramType) + "LoanProgram"} key={index} className="col-md-4" onClick={(e) => {handleInputChange('programtype', key.loanProgramType); }}>
                                <div className="lp_box">
                                    <h2>{key.loanProgramType.split(' ')[0]} <span>{key?.loanProgramType?.split(' ').slice(1).join(' ')}</span></h2>
                                    <p className="sub">{key.description}</p>
                                    <div className="lp_icon_block">
                                        <div className="lp_icon">
                                            <img src={key?.image} alt="" />
                                        </div>
                                    </div>
                                    <ul>
                                    {key?.features.map((item, i) => (<li key={i}> <img src="assets/images/verified.svg" className="img" alt="" />{item}
                                        </li>))}</ul>
                                </div>
                            </div>))}
                        
                      
                        </div>
                    </div>

                </div>
            </div>
            <div className="cp_footer">
                <div className="wrapper">
                    <p>{configdata?.admin?.rights}</p>
                    <ul>
                        <li><a href="privacy">Privacy </a></li>
                        <li><a href=""> | </a></li>
                        <li><a href="terms">Terms of Use</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default LoanProgram;