import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import { useLoanInfo } from '../utils/loanInfoContext';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { formatCurrency } from '../utils/commonUtils';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Loader from './Loader';

const OrderDrawHistory = () => {
    const navigate = useNavigate();
    const { loanInfo, setLoanInfo} = useLoanInfo();
    const [loanid,setLoanid] = useState(loanInfo?.loanid);
    const loading = useSelector((state) => state.loaderVisible);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage,setPerPage] = useState(5);
    const [tableKey,setTableKey] = useState(0);
    let drawVariable = [{
        draw : 1,
        description : 'lorem ipsum',
        created : '2024-07-26T07:16:08.916Z',
        aprinspordered : 'Lorem Ipsum',
        aprinsposcheduled : 'lorem ipsum',
        aprinspocompleted : 'lorem ipsum',
        lockbox : 'lorem ipsum',
        rehabstatus : 'lorem ipsum',
        requestamount : 30000,
        verified : 'lorem ipsum',
        approval : 'true',
        paymethod : 'lorem ipsum',
        authamount : 25000,
        drawdate : '2024-07-26T07:16:08.916Z',
        drawid : 96345
    }]
    const [orderDrawData,setOrderDrawData] = useState([])

    useEffect(() => {
        if(loanid){
            setCurrentPage(1);
            setPerPage(5);
            setTableKey(prevKey => prevKey + 1);
            // add apicall to get data and update the state variable
            setOrderDrawData([...drawVariable]);
        }
      }, [loanid]);

      useEffect(() => {
        setLoanid(loanInfo?.loanid);
    }, [loanInfo?.loanid]);
   
    const columns = [
        {
            name: 'DRAW',
            selector: (row) => row?.draw || '',

            minWidth: '4%',
            maxWidth: '4%',
        },
        {
            name: 'DESCRIPTION',
            selector: (row) => row?.description || '',

            minWidth: '6%',
            maxWidth: '6%',
        },
        {
            name: 'CREATED',
            selector: (row) => row?.created ? moment.utc(row?.created).format("MM-DD-YYYY") : '',

            minWidth: '6%',
            maxWidth: '6%',
        },
        {
            name: 'APR/INSP ORDERED',
            selector: (row) => row?.aprinspordered || '',

            minWidth: '8%',
            maxWidth: '8%',
        },
        {
            name: 'APR/INSP SCHEDULED',
            selector: (row) => row?.aprinsposcheduled || '',

            minWidth: '8%',
            maxWidth: '8%',
        },
        {
            name: 'APR/INSP COMPLETED',
            selector: (row) => row?.aprinspocompleted || '',

            minWidth: '8%',
            maxWidth: '8%',
        },
        {
            name: 'LOCK BOX',
            selector: (row) => row?.lockbox || '',

            minWidth: '6%',
            maxWidth: '6%',
        },
        {
            name: 'REHAB STATUS',
            selector: (row) => row?.rehabstatus || '',

            minWidth: '6%',
            maxWidth: '6%',
        },
        {
            name: 'REQUEST AMOUNT(USD)',
            selector: (row) => row?.requestamount ? formatCurrency(row?.requestamount) : '',

            minWidth: '9%',
            maxWidth: '9%',
        },
        {
            name: 'VERIFIED',
            selector: (row) => row?.verified,

            minWidth: '6%',
            maxWidth: '6%',
        },
        {
            name: 'APPROVAL',
            selector: (row) => row?.approval,

            minWidth: '6%',
            maxWidth: '6%',
        },
        {
            name: 'PAY METHOD',
            selector: (row) => row?.paymethod,

            minWidth: '6%',
            maxWidth: '6%',
        },
        {
            name: 'AUTH AMOUNT(USD)',
            selector: (row) => row?.authamount ? formatCurrency(row?.authamount) : '',

            minWidth: '8%',
            maxWidth: '8%',
        },
        {
            name: 'DRAW DATE',
            selector: (row) => row?.drawdate ? moment.utc(row?.drawdate).format("MM-DD-YYYY") : '',

            minWidth: '6%',
            maxWidth: '6%',
        },
        {
            name: 'DRAW ID',
            selector: (row) => row?.drawid,

            minWidth: '5%',
            maxWidth: '5%',
        },
        {
            name: 'LINE ITEM DETAILS',
            cell: (row, index) => (
                <a href="#" className="icon_btn view"> <span className="material-symbols-outlined icon">
                    visibility
                </span></a>
            ),
            minWidth: '5%',
            maxWidth: '5%',

        },
    ];
    // 

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',

            },
        },
        table: {
            style: {
                border: '1px solid #CCD8F6',
            },
        },
    };
  
    const handleExport = () => {
        const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
         
        // Format table data for Excel
        const formattedData = orderDrawData.map(item => ({
            'DESCRIPTION': item.description || '',
        }));
        const ws = XLSX.utils.json_to_sheet(formattedData);
            // Calculate column widths based on content
            const columnWidths = Object.keys(formattedData[0]).map(column => {
                const maxContentLength = formattedData.reduce((max, row) => {
                    const cellContent = row[column] || '';
                    return Math.max(max, cellContent.toString().length);
                }, column.length); // Set initial width to column name length

                return { wch: maxContentLength + 2 }; // Add some padding
            });

            ws['!cols'] = columnWidths;
      

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const fileData = new Blob([excelBuffer], { type: fileType });

        saveAs(fileData, 'orderdrawhistory' + fileExtension);
    };

    return (
        <div id="layout-wrapper" className="dashboard">
             <PipelineHeader />
            <div className="dashboard_block">
                <PipelineStatus />
                <h2 className="form_title">Draws <span className="space">&#10095;</span> <span className="sub">Draw History</span>
            </h2>
            <div className="card user_management ctc">
                <div className="card-body my_account">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h3 className="title mb-0">Draw History</h3>
                        <div className="form-group">
                            <button type="button" disabled={orderDrawData?.length===0} onClick={handleExport} className="border_btn icon">Export <img className="icon" src="assets/images/icons/excel.svg" /> </button>
                        </div>
                    </div>
                    
                    <div className="table-responsive">
                    {orderDrawData?.length > 0 ?  
                                <div className="table-responsive">
                                <DataTable className='contacts'
                                    key={tableKey}
                                    columns={columns}
                                    data={orderDrawData}
                                    pagination
                                    customStyles={customStyles}
                                    paginationTotalRows={totalRows}
                                    paginationServer
                                    paginationPerPage={perPage}
                                    paginationDefaultPage={currentPage}
                                    // onChangeRowsPerPage={handlePerRowsChange}
                                    // onChangePage={handlePageChange}
                                    // onSort={handleSort}
                                    sortServer={true}
                                    paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                    paginationComponentOptions={{
                                        rowsPerPageText: 'Per Page',
                                    }}
                                />
                            </div> : 
<div className="inprogress_page"><span className="material-symbols-outlined icon"> content_paste_search </span><p>No Data Found</p></div>
                                }
                        {/* <table className="table db_table tsr_table odh">

                            <thead className="table-light">
                                <tr>
                                    <th width="2%">Draw#</th>
                                    <th width="7%">Description</th>
                                    <th width="9%">Created</th>
                                    <th width="7%">Apr/Insp Ordered</th>
                                    <th width="7%">Apr/Insp Scheduled</th>
                                    <th width="7%">Apr/Insp Completed</th>
                                    <th width="7%">Lock Box</th>
                                    <th width="7%">Rehab Status</th>
                                    <th width="6%">Request Amount(USD)</th>
                                    <th width="5%">Verified</th>
                                    <th width="5%">Approval</th>
                                    <th width="7%">Pay Method</th>
                                    <th width="5%">Auth Amount(USD)</th>
                                    <th width="9%">Draw Date</th>
                                    <th width="5%">Draw id</th>
                                    <th width="5%">Line Item Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Lorem Ipsum</td>
                                    <td>4th March, 2024</td>
                                    <td>Lorem Ipsum</td>
                                    <td>Lorem Ipsum</td>
                                    <td>Lorem Ipsum</td>
                                    <td>Lorem Ipsum</td>
                                    <td>Lorem Ipsum</td>
                                    <td>30,000</td>
                                    <td>Lorem Ipsum</td>
                                    <td>True</td>
                                    <td>Lorem Ipsum</td>
                                    <td>30,000</td>
                                    <td>4th March, 2024</td>
                                    <td>96656</td>
                                    <td><a href="#" className="icon_btn view"> <span className="material-symbols-outlined icon">
                                                visibility
                                            </span></a></td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Lorem Ipsum</td>
                                    <td>4th March, 2024</td>
                                    <td>Lorem Ipsum</td>
                                    <td>Lorem Ipsum</td>
                                    <td>Lorem Ipsum</td>
                                    <td>Lorem Ipsum</td>
                                    <td>Lorem Ipsum</td>
                                    <td>30,000</td>
                                    <td>Lorem Ipsum</td>
                                    <td>True</td>
                                    <td>Lorem Ipsum</td>
                                    <td>30,000</td>
                                    <td>4th March, 2024</td>
                                    <td>96656</td>
                                    <td><a href="#" className="icon_btn view"> <span className="material-symbols-outlined icon">
                                                visibility
                                            </span></a></td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Lorem Ipsum</td>
                                    <td>4th March, 2024</td>
                                    <td>Lorem Ipsum</td>
                                    <td>Lorem Ipsum</td>
                                    <td>Lorem Ipsum</td>
                                    <td>Lorem Ipsum</td>
                                    <td>Lorem Ipsum</td>
                                    <td>30,000</td>
                                    <td>Lorem Ipsum</td>
                                    <td>True</td>
                                    <td>Lorem Ipsum</td>
                                    <td>30,000</td>
                                    <td>4th March, 2024</td>
                                    <td>96656</td>
                                    <td><a href="#" className="icon_btn view"> <span className="material-symbols-outlined icon">
                                                visibility
                                            </span></a></td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Lorem Ipsum</td>
                                    <td>4th March, 2024</td>
                                    <td>Lorem Ipsum</td>
                                    <td>Lorem Ipsum</td>
                                    <td>Lorem Ipsum</td>
                                    <td>Lorem Ipsum</td>
                                    <td>Lorem Ipsum</td>
                                    <td>30,000</td>
                                    <td>Lorem Ipsum</td>
                                    <td>True</td>
                                    <td>Lorem Ipsum</td>
                                    <td>30,000</td>
                                    <td>4th March, 2024</td>
                                    <td>96656</td>
                                    <td><a href="#" className="icon_btn view"> <span className="material-symbols-outlined icon">
                                                visibility
                                            </span></a></td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Lorem Ipsum</td>
                                    <td>4th March, 2024</td>
                                    <td>Lorem Ipsum</td>
                                    <td>Lorem Ipsum</td>
                                    <td>Lorem Ipsum</td>
                                    <td>Lorem Ipsum</td>
                                    <td>Lorem Ipsum</td>
                                    <td>30,000</td>
                                    <td>Lorem Ipsum</td>
                                    <td>True</td>
                                    <td>Lorem Ipsum</td>
                                    <td>30,000</td>
                                    <td>4th March, 2024</td>
                                    <td>96656</td>
                                    <td><a href="#" className="icon_btn view"> <span className="material-symbols-outlined icon">
                                                visibility
                                            </span></a></td>
                                </tr>
                            </tbody>
                        </table> */}
                    </div>
                </div>
            </div>
            </div>
            <PipelineFooter />
        </div>
    );
};

export default OrderDrawHistory;