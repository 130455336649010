import React , {useState , useEffect } from 'react';
import MainHeader from './MainHeader';
import SideMenu from './SideMenu';
import HomeFooter from './HomeFooter';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';
import Loader from './Loader';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { getAllFundManagmentAdmin} from '../utils/reducer';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import * as XLSX from "xlsx";

const AdminFundDistribution = () => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loaderVisible);
    const navigate = useNavigate();
    const [fundsDropdown,setFundsDropdown] = useState([]);
    const [editInvestorData,setEditInvestorData] = useState({});
    const [distributionData,setDistributionData] = useState({});
    const [investorFundDropdown,setInvestorFundDropdown] = useState([]);
    const [totalcount,setTotalCount] = useState(null);
    const [currentPage,setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(15);
   
    const investorOptions = [
        { value: "1504", label: "Easy Street Admin" },
        { value: "1510", label: "Stephen Hagerman" },
        { value: "1511", label: "Ashton Matthias" },
        { value: "1512", label: "Steven and Brooke Malfatto" },
        { value: "1517", label: "Kingdom Trust FBO Michael Denton Traditional IRA" },
        { value: "1518", label: "Richard Hagerman" },
        { value: "1519", label: "Erik and Tasha Wahl" }
    ];

    const data = [
        {
          fundName: "Easy Street Capital, LLC",
          investorName: "Ashton",
          startDate: "02/15/2024",
          endDate: "08/15/2024",
          startingBalance: "$50,000.00",
          additionalDeposits: "$10,000.00",
          distributionTotal: "$5,000.00",
          incomePaidOut: "$1,500.00",
          growthAccumulated: "$2,200.00",
          additionalWithdrawals: "$1,000.00",
          endingBalance: "$57,700.00",
          incomePercent: "3.75%",
          autoACH: "Yes"
        },
        {
          fundName: "Ellington Capital",
          investorName: "Blake",
          startDate: "01/05/2024",
          endDate: "07/05/2024",
          startingBalance: "$65,000.00",
          additionalDeposits: "$15,000.00",
          distributionTotal: "$8,000.00",
          incomePaidOut: "$2,500.00",
          growthAccumulated: "$3,500.00",
          additionalWithdrawals: "$500.00",
          endingBalance: "$77,500.00",
          incomePercent: "4.10%",
          autoACH: "No"
        },
        {
          fundName: "Easy Street Capital, LLC",
          investorName: "Jordan",
          startDate: "03/01/2024",
          endDate: "09/01/2024",
          startingBalance: "$40,000.00",
          additionalDeposits: "$8,000.00",
          distributionTotal: "$4,500.00",
          incomePaidOut: "$1,200.00",
          growthAccumulated: "$1,800.00",
          additionalWithdrawals: "$750.00",
          endingBalance: "$44,550.00",
          incomePercent: "3.25%",
          autoACH: "Yes"
        },
        {
          fundName: "Ellington Capital",
          investorName: "Taylor",
          startDate: "04/10/2024",
          endDate: "10/10/2024",
          startingBalance: "$30,000.00",
          additionalDeposits: "$5,000.00",
          distributionTotal: "$2,000.00",
          incomePaidOut: "$900.00",
          growthAccumulated: "$1,300.00",
          additionalWithdrawals: "$600.00",
          endingBalance: "$34,700.00",
          incomePercent: "3.00%",
          autoACH: "No"
        }
    ];

    const distributionDataArr = [
        {
            fundName: "Easy Street Capital, LLC",
            investorName: "Ashton",
            startDate: "02/15/2024",
            endDate: "08/15/2024",
            startingBalance: "$50,000.00",
            additionalDeposits: "$10,000.00",
            distributionTotal: "$5,000.00",
            incomePaidOut: "$1,500.00",
            growthAccumulated: "$2,200.00",
            additionalWithdrawals: "$1,000.00",
            endingBalance: "$57,700.00",
            incomePercent: "3.75%",
            autoACH: "Yes"
        },
        {
            fundName: "Ellington Capital",
            investorName: "Blake",
            startDate: "01/05/2024",
            endDate: "07/05/2024",
            startingBalance: "$65,000.00",
            additionalDeposits: "$15,000.00",
            distributionTotal: "$8,000.00",
            incomePaidOut: "$2,500.00",
            growthAccumulated: "$3,500.00",
            additionalWithdrawals: "$500.00",
            endingBalance: "$77,500.00",
            incomePercent: "4.10%",
            autoACH: "No"
        },
        {
            fundName: "Easy Street Capital, LLC",
            investorName: "Jordan",
            startDate: "03/01/2024",
            endDate: "09/01/2024",
            startingBalance: "$40,000.00",
            additionalDeposits: "$8,000.00",
            distributionTotal: "$4,500.00",
            incomePaidOut: "$1,200.00",
            growthAccumulated: "$1,800.00",
            additionalWithdrawals: "$750.00",
            endingBalance: "$44,550.00",
            incomePercent: "3.25%",
            autoACH: "Yes"
        },
        {
            fundName: "Ellington Capital",
            investorName: "Taylor",
            startDate: "04/10/2024",
            endDate: "10/10/2024",
            startingBalance: "$30,000.00",
            additionalDeposits: "$5,000.00",
            distributionTotal: "$2,000.00",
            incomePaidOut: "$900.00",
            growthAccumulated: "$1,300.00",
            additionalWithdrawals: "$600.00",
            endingBalance: "$34,700.00",
            incomePercent: "3.00%",
            autoACH: "No"
        }
    ];
    

    const columnWidth = '7%'; // Adjust the width for each column as needed

    const columns = [
        {
            name: '#',
            selector: (row, index) => (currentPage - 1) * perPage + index + 1,
            minWidth: '45px',
            maxWidth: '45px',
        },
        {
            name: 'Fund Name',
            selector: (row) => row?.fundName, // Correct key name for "Fund Name"
            sortable: true,
            sortFunction: (a, b) => {
                const nameA = a?.fundName?.toLowerCase(); // Correct key name
                const nameB = b?.fundName?.toLowerCase(); // Correct key name
                return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            },
            minWidth: '205px',
            maxWidth: '205px',
        },
        {
            name: 'Investor Name',
            selector: (row) => row?.investorName, // Correct key name for "Investor Name"
            minWidth: '160px',
            maxWidth: '160px',
            sortable: true,
        },
        {
            name: 'Start Date',
            selector: (row) => row?.startDate, // Correct key name for "Start Date"
            minWidth: '135px',
            maxWidth: '135px',
            sortable: true,
        },
        {
            name: 'End Date',
            selector: (row) => row?.endDate, // Correct key name for "End Date"
            minWidth: '135px',
            maxWidth: '135px',
            sortable: true,
        },
        {
            name: 'Starting Balance',
            selector: (row) => row?.startingBalance, // Correct key name for "Starting Balance"
            minWidth: '120px',
            maxWidth: '120px',
            sortable: true,
        },
        {
            name: 'Additional Deposits',
            selector: (row) => row?.additionalDeposits, // Correct key name for "Additional Deposits"
            minWidth: '125px',
            maxWidth: '125px',
            sortable: true,
        },
        {
            name: 'Distribution Total',
            selector: (row) => row?.distributionTotal, // Correct key name for "Distribution Total"
            minWidth: '150px',
            maxWidth: '150px',
            sortable: true,
        },
        {
            name: 'Income Paid Out',
            selector: (row) => row?.incomePaidOut, // Correct key name for "Income Paid Out"
            minWidth: '125px',
            maxWidth: '125px',
            sortable: true,
        },
        {
            name: 'Growth Accumulated',
            selector: (row) => row?.growthAccumulated, // Correct key name for "Growth Accumulated"
            minWidth: '150px',
            maxWidth: '150px',
            sortable: true,
        },
        {
            name: 'Additional Withdrawals',
            selector: (row) => row?.additionalWithdrawals, // Correct key name for "Additional Withdrawals"
            minWidth: '150px',
            maxWidth: '150px',
            sortable: true,
        },
        {
            name: 'Ending Balance',
            selector: (row) => row?.endingBalance, // Correct key name for "Ending Balance"
            minWidth: '125px',
            maxWidth: '125px',
            sortable: true,
        },
        {
            name: 'Income Percent',
            selector: (row) => row?.incomePercent, // Correct key name for "Income Percent"
            minWidth: '115px',
            maxWidth: '115px',
            sortable: true,
        },
        {
            name: 'Auto ACH',
            selector: (row) => row?.autoACH, // Correct key name for "Auto ACH"
            minWidth: '120px',
            maxWidth: '120px',
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row, index) => (
                <>
                    <button id={`editFund-${index}`} type="button" onClick={() => editSheet(row)} className="icon_btn edit">
                        <span style={{ color: '#444', fontSize: '16px' }} className="material-icons icon tooltip-container">
                            <span className="tooltip">Edit</span>edit
                        </span>
                    </button>
                    <button id={`deleteFund-${index}`} type="button" onClick={() => deleteSheet(row)} className="icon_btn delete">
                        <span style={{ color: '#444', fontSize: '16px' }} className="material-icons icon tooltip-container">
                            <span className="tooltip">Delete</span>delete
                        </span>
                    </button>
                </>
            ),
            minWidth: '120px',
            maxWidth: '120px',
        },
    ];
    
    const distributionColumns = [
        {
            name: '#',
            selector: (row, index) => (currentPage - 1) * perPage + index + 1,
            minWidth: '45px',
            maxWidth: '45px',
        },
        {
            name: 'Fund Name',
            selector: (row) => row?.fundName, // Correct key name for "Fund Name"
            sortable: true,
            sortFunction: (a, b) => {
                const nameA = a?.fundName?.toLowerCase();
                const nameB = b?.fundName?.toLowerCase();
                return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            },
            minWidth: '205px',
            maxWidth: '205px',
        },
        {
            name: 'Investor Name',
            selector: (row) => row?.investorName,
            minWidth: '160px',
            maxWidth: '160px',
            sortable: true,
        },
        {
            name: 'Start Date',
            selector: (row) => row?.startDate,
            minWidth: '135px',
            maxWidth: '135px',
            sortable: true,
        },
        {
            name: 'End Date',
            selector: (row) => row?.endDate,
            minWidth: '135px',
            maxWidth: '135px',
            sortable: true,
        },
        {
            name: 'Starting Balance',
            selector: (row) => row?.startingBalance,
            minWidth: '120px',
            maxWidth: '120px',
            sortable: true,
        },
        {
            name: 'Additional Deposits',
            selector: (row) => row?.additionalDeposits,
            minWidth: '125px',
            maxWidth: '125px',
            sortable: true,
        },
        {
            name: 'Distribution Total',
            selector: (row) => row?.distributionTotal,
            minWidth: '150px',
            maxWidth: '150px',
            sortable: true,
        },
        {
            name: 'Income Paid Out',
            selector: (row) => row?.incomePaidOut,
            minWidth: '125px',
            maxWidth: '125px',
            sortable: true,
        },
        {
            name: 'Growth Accumulated',
            selector: (row) => row?.growthAccumulated,
            minWidth: '150px',
            maxWidth: '150px',
            sortable: true,
        },
        {
            name: 'Additional Withdrawals',
            selector: (row) => row?.additionalWithdrawals,
            minWidth: '150px',
            maxWidth: '150px',
            sortable: true,
        },
        {
            name: 'Ending Balance',
            selector: (row) => row?.endingBalance,
            minWidth: '125px',
            maxWidth: '125px',
            sortable: true,
        },
        {
            name: 'Income Percent',
            selector: (row) => row?.incomePercent,
            minWidth: '115px',
            maxWidth: '115px',
            sortable: true,
        },
        {
            name: 'Auto ACH',
            selector: (row) => row?.autoACH,
            minWidth: '120px',
            maxWidth: '120px',
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row, index) => (
                <>
                    <button
                        id={`editFund-${index}`}
                        type="button"
                        onClick={() => editSheet(row)}
                        className="icon_btn edit"
                    >
                        <span style={{ color: '#444', fontSize: '16px' }} className="material-icons icon tooltip-container">
                            <span className="tooltip">Edit</span>edit
                        </span>
                    </button>
                    <button
                        id={`deleteFund-${index}`}
                        type="button"
                        onClick={() => deleteSheet(row)}
                        className="icon_btn delete"
                    >
                        <span style={{ color: '#444', fontSize: '16px' }} className="material-icons icon tooltip-container">
                            <span className="tooltip">Delete</span>delete
                        </span>
                    </button>
                </>
            ),
            minWidth: '120px',
            maxWidth: '120px',
        },
    ];
    
    

      

    useEffect(() => {
        getFundsName()
    }, []);

    const getFundsName =()=>{
        getAllFundManagmentAdmin()
        .then(function(response) {
            console.log('funds name',response)
            if (response?.result?.data) {
                setFundsDropdown(response?.result?.data)
            }else{
                setFundsDropdown([]);
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
        
    }

    const handleChange = (e,param) => {
        let name = e.target.name;
        let value = e.target.value;
        let type = e.target.type;

        // Check if the input type is "date" and format it
        //if (type === "date") {
            // const date = new Date(value);
            // value = date.toLocaleDateString("en-US", {
            //     year: 'numeric',
            //     month: '2-digit',
            //     day: '2-digit'
            // }).replace(/\//g, "-"); // Format to MM-dd-yyyy
            
            // // Store in ISO format (yyyy-MM-dd) for compatibility with input type="date"
            // value = date.toISOString().split('T')[0];
            //value = moment.utc(value).format("MM-DD-YYYY");
        //}

        if(param == "editinvestor"){
            setEditInvestorData((prevData) => ({
                ...prevData,
                [name] : value
            }));
        }else{
            setDistributionData((prevData) => ({
                ...prevData,
                [name] : value
            }));
        }
        
    }

    //call API and set value in investor fund dropdown
    const getInvestorFunds = () => {
        dispatch(showLoader());
        setInvestorFundDropdown(investorOptions);
        dispatch(hideLoader());
    }

    useEffect(() => {
        if(editInvestorData?.name || distributionData?.name){
            console.log("calling get investor funds api");
            getInvestorFunds();
        }else{
            setInvestorFundDropdown([]);
        }
    },[editInvestorData?.name,distributionData?.name])

    const handlePerPageChange = (newperPage,page) => {
        setPerPage(newperPage);
        setCurrentPage(page); // Reset to first page
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const customStyles = {
        //   headRow: {
      // 	style: {
        //         fontWeight: 'bold',
        //         backgroundColor: '#E9F0E6',
      // 	},
      // },
        headCells: {
        style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',
        
        },
      },
            table: {
          style: {
            border: '1px solid #CCD8F6', 
          },
        },
    };

    const handleTab = (e,param) => {
        if(param == "editinvestors"){
            setInvestorFundDropdown([]);
        }
    }

    const editSheet = (data) => {
        console.log('data',data)
        //navigate('/createfundmanagement', { state: { fundId : data?.uuid,selectedfund: data, fundManagementData:fundManagementData , mode : "Edit" ,fundNames } });
    };
    
    const deleteSheet = (data) => {
        // setShowConfirmation(true);
        // setSelectedOption(data?.uuid);
        // console.log('data',data);
    };

    const handleExport = (param) => {
        const exportData = (param == "editinvestor" && data?.length > 0) ? data :
                            (param == "distribution" && distributionDataArr?.length > 0) ? distributionDataArr :
                            [];

        if(exportData?.length > 0){
            // Prepare the worksheet data from the provided `data` array
            const worksheetData = exportData.map((item) => ({
                "Fund Name": item.fundName,
                "Investor Name": item.investorName,
                "Start Date": item.startDate,
                "End Date": item.endDate,
                "Starting Balance": item.startingBalance,
                "Additional Deposits": item.additionalDeposits,
                "Distribution Total": item.distributionTotal,
                "Income Paid Out": item.incomePaidOut,
                "Growth Accumulated": item.growthAccumulated,
                "Additional Withdrawals": item.additionalWithdrawals,
                "Ending Balance": item.endingBalance,
                "Income Percent": item.incomePercent,
                "Auto ACH": item.autoACH,
            }));

            console.log('Exporting data:', worksheetData);

            // Create a new workbook
            const wb = XLSX.utils.book_new();
            // Create a worksheet with the prepared data
            const ws = XLSX.utils.json_to_sheet(worksheetData);
            // Append the worksheet to the workbook
            XLSX.utils.book_append_sheet(wb, ws, "Investors Data");

            const filename = (param == "editinvestor") ? "investors_data.xlsx" : "distribution_data.xlsx";
            // Instead of timeout, just export immediately
            XLSX.writeFile(wb, filename);
        }

        
    }

    return (
        <div id="layout-wrapper">


          <MainHeader />
          <SideMenu />

            <div className="main-content">

            <div className="page-content user_management fund_summary investor_statement detailed_investor">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Fund Distribution</h4>
                      
                            </div>
                            <div className="card">
                            <div className="card-body my_account">
                            
                            <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-bs-toggle="tab" href="#single" role="tab" onClick={(e) => handleTab(e,"editinvestors")}>
                                    <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                                    <span class="d-none d-sm-block">edit Investor transactions</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link " data-bs-toggle="tab" href="#bulk" role="tab" onClick={(e) => handleTab(e,"editinvestors")}>
                                    <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                                    <span class="d-none d-sm-block">INVESTOR DISTRIBUTIONS REPORT</span>
                                </a>
                            </li>

                        </ul>


                        <div class="tab-content pt-15 text-muted">
                            <div class="tab-pane edit_investor_transaction active" id="single" role="tabpanel">
                                <div className='search'>
                                    <div className="d-flex align-items-center w-50 justify-content-between">
                                        <div className="form-group">
                                            <label>Select Fund</label>
                                            <select id="fundNames" name="name" className="form-control" tabindex="1" onChange={(e) => handleChange(e,"editinvestor")} value={editInvestorData?.name}>
                                                <option value="">Select a Fund</option>
                                                {fundsDropdown?.map((fundoption,index)=>{
                                                    return(
                                                        <option key={index} value={fundoption?.name}>{fundoption?.name}</option>
                                                    )
                                                })}
                                                {/* <option value="EASY BUILD">Easy Street Capital</option> */}
                                            
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Select Investor</label>
                                            <select id="investorFund" name="investorFund" className="form-control" tabindex="2" onChange={(e) => handleChange(e,"editinvestor")} value={editInvestorData?.investorFund}>
                                                <option value="">Select Investor Fund</option>
                                                {investorFundDropdown?.map((fundoption,index) => {
                                                    return(
                                                        <option key={index} value={fundoption?.label}>{fundoption?.label}</option>
                                                    )
                                                })}
                                        
                                            </select>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <button id="" type="button" class="border_btn">Send Email<span className="material-symbols-outlined">mail</span></button>
                                        <button id="editInvestorExport" type="button" class="border_btn ms-2" onClick={() => handleExport("editinvestor")}>Export<span className="material-symbols-outlined">arrow_outward</span></button>
                                    </div>
                                </div>
                                {/* <div class="table-responsive">
                                    <table class="table db_table tsr_table los_table">
                                        <thead class="table-light">
                                            <tr>
                                                <th width="12%">Transaction ID</th>
                                                <th width="14%">Transaction Type</th>
                                                <th width="11%">Amount</th>
                                                <th width="10%">Date</th>
                                                <th width="13%">Note</th>
                                                <th width="10%">Compounding</th>
                                                <th width="9%">MISC 1099</th>
                                                <th width="12%">Last Edited By</th>
                                                <th width="9%">Edited Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>234872</td>
                                                <td>Dividend Reinvestment</td>
                                                <td>$3,750.00</td>
                                                <td>10/15/2024</td>
                                                <td>Quarterly Payout</td>
                                                <td>True</td>
                                                <td>True</td>
                                                <td>Alex Johnson</td>
                                                <td>10/15/2024</td>
                                            </tr>
                                            <tr>
                                                <td>789564</td>
                                                <td>Capital Gains Distribution</td>
                                                <td>$2,150.45</td>
                                                <td>08/23/2024</td>
                                                <td>Annual Distribution</td>
                                                <td>False</td>
                                                <td>True</td>
                                                <td>Maria Lee</td>
                                                <td>08/25/2024</td>
                                            </tr>
                                            <tr>
                                                <td>569872</td>
                                                <td>Interest Income</td>
                                                <td>$1,230.00</td>
                                                <td>07/05/2024</td>
                                                <td>Monthly Interest</td>
                                                <td>True</td>
                                                <td>False</td>
                                                <td>Chris Evans</td>
                                                <td>07/06/2024</td>
                                            </tr>
                                            <tr>
                                                <td>982374</td>
                                                <td>Bonus Payment</td>
                                                <td>$4,800.00</td>
                                                <td>09/01/2024</td>
                                                <td>Performance Bonus</td>
                                                <td>False</td>
                                                <td>False</td>
                                                <td>Jordan Kim</td>
                                                <td>09/02/2024</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> */}
                                <DataTable className='termsheetprefills_table'
                                        columns={columns}
                                        data={data}
                                        pagination
                                        paginationServer
                                        // paginationTotalRows={(!searchTerm) ? totalcount : filteredFundData?.length}
                                        paginationTotalRows={totalcount}
                                        customStyles={customStyles}
                                        paginationPerPage={perPage}
                                        paginationRowsPerPageOptions={[15, 30, 45, 60,75,90]}
                                        paginationComponentOptions={{
                                            rowsPerPageText: 'Per Page',
                                        }}
                                        persistTableHead
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerPageChange}
                                        id="prefillPagination"
                                            // sortServer={false}  // If you're handling sorting on the server-side, use this prop
                                            // onSort={handleSort}
                                /> 

                            </div>
                            <div class="tab-pane investor_distribution_report" id="bulk" role="tabpanel">
                            <div className='search'>
                                <div className="form-group">
                                    <label>Select Fund</label>
                                    <select id="fundName" name="name" className="form-control" tabindex="1" onChange={(e) => handleChange(e,"distribution")} value={distributionData?.name}>
                                        <option value="">Select Fund</option>
                                        {fundsDropdown?.map((fundoption,index)=>{
                                            return(
                                                <option key={index} value={fundoption?.name}>{fundoption?.name}</option>
                                            )
                                        })}
                                    
                                    </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Select an Investor In Fund</label>
                                        <select id="prefillLoanProgram" name="investorFund" className="form-control" tabindex="1" onChange={(e) => handleChange(e,"distribution")} value={distributionData?.investorFund}>
                                            <option value="">Select Investor</option>
                                            {investorFundDropdown?.map((fundoption,index) => {
                                                return(
                                                    <option key={index} value={fundoption?.label}>{fundoption?.label}</option>
                                                )
                                            })}
                                        
                                        </select>
                                    </div>
                            <div className="form-group">
                                <label>Start date</label>
                                <input id="startDate" type="date" name="startDate" className="form-control" placeholder="mm-dd-yyy" onChange={(e) => handleChange(e ,"distribution")} value={distributionData?.startDate}/>
                            </div>
                            <div className="form-group">
                                <label>End date </label>
                                <input id="endDate" type="date" name="endDate" className="form-control" placeholder="mm-dd-yyy" onChange={(e) => handleChange(e,"distribution")} value={distributionData?.endDate}/>
                            </div>
                         
                            <div className="form-group generate">
                                <label></label>
                                <button type="button" className="create_btn ">Generate <span className="material-symbols-outlined">arrow_outward</span></button>
                            </div>
                                    
                             </div>
                            <div className='search mb-3 align-items-center'>
                                <div className="d-flex align-items-center w-50 justify-content-between">
                                    <h5 className="title mt-0 mb-0">INVESTOR DISTRIBUTIONS REPORT</h5>
                                </div>
                                <div className="d-flex align-items-center">
                                    <button id="investorDistributionEmail" type="button" class="border_btn">Send Email<span className="material-symbols-outlined">mail</span></button>
                                    <button id="investorDistributionExport" type="button" class="border_btn ms-2" onClick={() => handleExport("distribution")}>Export<span className="material-symbols-outlined">arrow_outward</span></button>
                                </div>
                            </div>
                            <DataTable className='termsheetprefills_table'
                                columns={distributionColumns}
                                data={distributionDataArr}
                                pagination
                                paginationServer
                                // paginationTotalRows={(!searchTerm) ? totalcount : filteredFundData?.length}
                                paginationTotalRows={totalcount}
                                customStyles={customStyles}
                                paginationPerPage={perPage}
                                paginationRowsPerPageOptions={[15, 30, 45, 60,75,90]}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Per Page',
                                }}
                                persistTableHead
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handlePerPageChange}
                                id="prefillPagination"
                                    // sortServer={false}  // If you're handling sorting on the server-side, use this prop
                                    // onSort={handleSort}
                            /> 
                                   

                           

                            </div>


                        </div>
                  
                        </div>
                        </div>
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default AdminFundDistribution;