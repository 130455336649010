import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import {getSingleLead, leadAdd,getLoanDetails,lookupsCallForPipeline} from "./../utils/reducer";
import { useDispatch, useSelector} from 'react-redux';
import Loader from './Loader';
import PopupMessage from './PopupMessage';
import { hideLoader, showLoader } from '../redux/reducers/Actions';
import { useLoanInfo } from '../utils/loanInfoContext';
const DealLead = () => {
    const dispatch = useDispatch();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const loading = useSelector((state) => state.loaderVisible);
    const [errors,setErrors] = useState({});
    let dealVariable = {
        // "leadsource":"",
        "channel":"",
        "lendercaseid":"",
        "referredBy" : "",
    }
    const [dealData, setDealData] = useState(dealVariable);
    const [dealCheck, setDealCheck] = useState(dealVariable);
    const [isDealChange,setIsDealChange] = useState(false);
    const navigate = useNavigate();
    const { loanInfo, setLoanInfo} = useLoanInfo();
    const [loanid,setLoanid] = useState(loanInfo?.loanid);
    const [referralSource,setRefferalSource] = useState([]);
    const [channel,setChannel] = useState([]);

  
    useEffect(() => {
        if(loanid){
            getLead();
        }
      }, [loanid]);
    useEffect(() => {
        setLoanid(loanInfo?.loanid)
    }, [loanInfo?.loanid]);
    useEffect(() => {
        const isFormChanged =
            JSON.stringify(dealData) !== JSON.stringify(dealCheck);
            setIsDealChange(isFormChanged);
        console.log(isFormChanged);
    }, [dealData, dealCheck]);

    useEffect(() => {
        loandropdown(10013);
        loandropdown(10005);
      console.log('changes in dealData ---->',dealData);
    }, []);
    const setLoandetails =()=>{
        getLoanDetails(loanid)
        .then(response => {
          console.log('getLoanDetails', response);
          if (response?.result[0]) {
            setLoanInfo(response?.result[0]);
            console.log(response);
          }
        })
        .catch(err => {
          console.error('my account error', err);
        });
      }
      const loandropdown = async (num) => {
        lookupsCallForPipeline(num).then(function (response) {
            if (response?.result) {
                console.log("categories from lookups --->",response?.result?.data[0]?.lookupOptions);
                let data = response?.result?.data[0]?.lookupOptions;
                if(num===10013){
                    setRefferalSource(data);
                }else if(num===10005){
                    setChannel(data);
                }
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
    };
    const handleChange = (e) => {
        let {name , value} = e.target;
        setDealData((prev) => ({
            ...prev,
            [name]: value,
        }));
     };

    const getLead = ()=>{
        dispatch(showLoader());
        getSingleLead(loanid)
        .then(function(response) {
            if (response.result.length !==0) {
                console.log('hmda response', response);
                    setDealData((prev) => ({
                        ...dealVariable,
                        ...response.result?.[0]?.lead, 
                        'referredBy' : response.result?.[0]?.referredBy
                    }));
                    setDealCheck((prev) => ({
                        ...dealVariable,
                        ...response.result?.[0]?.lead, 
                        'referredBy' : response.result?.[0]?.referredBy
                    }));
                dispatch(hideLoader());
                setErrors({})
            }else{
                setDealData({
                    // "leadsource":"",
                ...dealVariable})
                setDealCheck({
                    // "leadsource":"",
                    ...dealVariable})
                dispatch(hideLoader());
                setErrors({})
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    };

     const handleSave = () => {
        // const fieldsToCheck = {  "leadsource":"leadsource", "channel":"channel", "lendercaseid":"lendercaseid", "hubspotidentifier":"hubspotidentifier", "reasonfordenial":"reasonfordenial"};
        // const fieldsToCheck = {  "leadsource":"leadsource", "channel":"channel", "lendercaseid":"lendercaseid","referredBy" : "referredBy",};
        console.log('ran');
        const error = {};

        // for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
        //     if (!dealData[fieldName]?.trim()) {
        //         error[fieldName] = `Please Enter ${fieldLabel} `;
        //     }
        // };
        console.log(error);
        setErrors(error);
        if (Object.keys(error).length === 0) {
            let apiData = {...dealData,loanid : loanid};
            dispatch(showLoader());
            leadAdd(apiData).then(function (response) {
                if (response) {
                   console.log('response?.result',response?.result);
                   getLead();
                   setLoandetails();
                   dispatch(hideLoader());
                   setPopupOpen(true);
                }
                }).catch((err) => {
                  console.error.bind("error", err);
                  dispatch(hideLoader());
                })
        }
    };
    const closePopup = () => {
        setPopupOpen(false);
    };



    return (
        <div id="layout-wrapper" className="dashboard">
            {loading ? <Loader/> : null} 
            {isPopupOpen && (
            <PopupMessage
             type={'success'}
             message={'Saved Successfully'}
             onClose={closePopup}
            />
        )}
             <PipelineHeader />
            <div className="dashboard_block">
                <PipelineStatus />
                <h2 className="form_title">Deal <span className="space">&#10095;</span>  <span className="sub">Lead</span> </h2>
                <div className="card user_management detailed_app">
                <div className="card-body my_account">
                                <div className="form_section deal_lead">
                                <div className="row">
                                <div className="col-md-3">
                                <div className="row">
                                            {/* <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Lead/Referral Source</label>
                                                        <input type="text" name='leadsource' value={dealData?.leadsource} onChange={handleChange} className={`form-control ${errors?.leadsource && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, leadsource: null }) }} placeholder="Enter Lead/Referral Source" tabIndex={1}/>
                                                    </div>
                                            </div> */}
                                              <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Lead/Referral Source</label>
                                                     <select id="dealLeadSource" name='referredBy' value={dealData?.referredBy} onChange={handleChange} className={`form-control ${errors?.referredBy && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, referredBy: null }) }} tabIndex={4}>
                                                     <option selected value="">Select</option>
                                                     {referralSource?.map((data) => {
                                                            return (
                                                                <option key={data?.option} value={data?.option}>{data?.option}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                       
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                <button id="saveLead" onClick={(e) => { e.preventDefault(); handleSave(); }} disabled={!isDealChange}   style={{'opacity' : isDealChange ? 1 : 0.6}} className="fs_btn">Save</button>
                                                </div>
                                            </div>
                                </div>
                                </div>
                                <div className="col-md-3">
                                <div className="row">

                                <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Channel</label>
                                                    <select id="dealChannel" name='channel' value={dealData?.channel} onChange={handleChange} className={`form-control ${errors?.channel && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, channel: null }) }} tabIndex={2}>
                                                        <option selected value="">Select</option>
                                                        {channel?.map((data) => {
                                                            return (
                                                                <option key={data?.option} value={data?.option}>{data?.option}</option>
                                                            );
                                                        })}
                                                       
                                                    </select>
                                                </div>
                                            </div>
                                </div>

                                </div>
                                
                                <div className="col-md-3">
                                <div className="row">
                                        
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Lender Case Id</label>
                                                    <input id="deallenderId" type="text" name='lendercaseid' value={dealData?.lendercaseid} onChange={handleChange} className={`form-control ${errors?.lendercaseid && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, lendercaseid: null }) }} 
                                                        placeholder="Enter Lender Case Id" tabIndex={3} />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Hubspot Identifier</label>
                                                    <input type="text" name='hubspotidentifier' value={dealData?.hubspotidentifier} onChange={handleChange} className={`form-control ${errors?.hubspotidentifier && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, hubspotidentifier: null }) }} 
                                                        placeholder="Enter Hubspot Identifier" tabIndex={4} />
                                                </div>
                                            </div> */}
                                     
                                </div>
                                </div>
                                <div className="col-md-3">
                                <div className="row">
                                        
                                          
                                            {/* <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Hubspot Identifier</label>
                                                    <input type="text" name='hubspotidentifier' value={dealData?.hubspotidentifier} onChange={handleChange} className={`form-control ${errors?.hubspotidentifier && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, hubspotidentifier: null }) }} 
                                                        placeholder="Enter Hubspot Identifier" tabIndex={4} />
                                                </div>
                                            </div> */}
                                     
                                </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Reasons for Denial</label>
                                        <textarea onChange={handleChange} value={dealData?.reasonfordenial} id="w3review" name="reasonfordenial" rows="4" cols="50" className={`form-control ${errors?.reasonfordenial && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, reasonfordenial: null }) }}  spellcheck="false" placeholder="Add Reasons" tabIndex={5}> </textarea>
                                    </div>       
                                </div> */}
                                </div> 
                                </div>  
                </div>
            </div>
            </div>
            <PipelineFooter/>
        </div>
    );
};

export default DealLead;