import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
const PiplineInprogress = () => {
    const navigate = useNavigate();
    // 



    return (
        <div id="layout-wrapper" className="dashboard">
            <PipelineHeader />
            <div className="dashboard_block">
                <PipelineStatus />
                <h2 className="form_title"> <span className="sub">&nbsp;</span> </h2>
                <div className="card mb-0 ip_block">
                    <div className="card-body inprogress_page">
                    <span className="material-symbols-outlined icon"> handyman </span>
                    <p>In Progress</p>
                    </div>
                </div>
            </div>
            <PipelineFooter />
        </div>
    );
};

export default PiplineInprogress;