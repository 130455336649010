import React , { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import InfoBar from '../InfoBar';
const BorrowerInfo3 = (props) => {
    const navigate = useNavigate();
    const {currentPage , setCurrentPage} = props;
    const {borrowerInfoOneData,setBorrowerInfoOneData} = props;

    const handleBorrowerInfoYes = () => {
        setBorrowerInfoOneData({...borrowerInfoOneData,['condition']:'yes'});
        setCurrentPage(currentPage + 1);
    }

    const handleBorrowerInfoNo = () => {
        setBorrowerInfoOneData({...borrowerInfoOneData,['condition']:'no'});
        setCurrentPage(currentPage + 1);
    }
    console.log('borrowerInfoOneData',borrowerInfoOneData);
    return (

                <div className="content_blk">
                    <div className="hed">
                        {/* <a href="#" className="back material-icons" onClick={(e) => { navigate(-1); }}> keyboard_backspace </a> */}
                        <a href="#" className="back material-icons" onClick={(e) => {e.preventDefault(); props.onPrevious();}}> keyboard_backspace </a>
                        <h2 className="title"> <span className="title2">EASY <span className="sub"> BUILD</span></span> Borrower Info
                        </h2>
                    </div>
                    <div className="cont pi3 bi bi2">
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">My Name
                            <strong> : {borrowerInfoOneData.firstName}  {borrowerInfoOneData.lastName}</strong> </span>
                    </div>
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">My Email
                            <strong> : {borrowerInfoOneData.emailid}</strong> </span>
                    </div>
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">My Phone
                            <strong> : {borrowerInfoOneData.phone}</strong> </span>
                    </div>
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">Address
                            <strong> : {borrowerInfoOneData.address}</strong> </span> 
                            <a href="#" className="back_btn" onClick={(e) => {e.preventDefault(); props.onPrevious();}}>
                            <span className="material-icons-outlined">
                                shortcut
                            </span>Change
                        </a>
                    </div>
                    <h3 className="feild_title">Is Borrower an entity?</h3>
                    <div className="btn-group">
                        {/* <button type="button" className="cp_btn" onClick={(e) => {navigate('/BorrowerInfo4');}}>Yes</button>
                        <button type="button" className="cp_btn" onClick={(e) => {navigate('/BorrowerInfo4');}}>No</button> */}
                        <button type="button" className="cp_btn" onClick={handleBorrowerInfoYes}>Yes</button>
                        <button type="button" className="cp_btn" onClick={handleBorrowerInfoNo}>No</button>
                </div>
            </div>
        </div>
    );
};

export default BorrowerInfo3;