import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate} from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import ReactQuill from "react-quill";
import DataTable from 'react-data-table-component';
import moment from 'moment';
import CurrencyInput from 'react-currency-input-field';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Blockingtextmsg from './Blockingtext';
import { useLoanInfo } from '../utils/loanInfoContext';
import { useDispatch, useSelector} from 'react-redux';
import {getUserDataFromLocalStorage} from "./../utils/local";
import { getLoanDetails , getServicingSetupApi , getloanSummary , getPayoffData , savePayoffData} from "./../utils/reducer";
import { hideLoader, showLoader } from '../redux/reducers/Actions';
import SweetAlert from 'react-bootstrap-sweetalert';   
import PopupMessage from './PopupMessage.js';
import { v4 as uuidv4 } from 'uuid';
import EditorEdit from './EditorEdit.js';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { PDFDocument } from 'pdf-lib';


import Loader from './Loader';
const ServicesPayoff = () => {
    const dispatch = useDispatch();
   
    const loading = useSelector((state) => state.loaderVisible);
    const blockingtext = 'Payoff cannot be accessed until servicing setup gets completed';
    const configdata = useSelector(state => state.configdata);
    let showServicingForms = configdata?.common?.showServicingForms || true;
    const navigate = useNavigate();
   
    //const [savedFees,setSavedFees] = useState({});
    const [savedFees, setSavedFees] = useState({
        description: '',
        amount: '',
        credit: false // Initialize credit to false
    });

    
    
    //const [payoffData,setPayOffData] = useState({});
    const [payoffData, setPayOffData] = useState({
        toemail: [],
    });
    const [quillValue, setQuillValue] = useState('');
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(5);
    const [errors,setErrors] = useState({});
    const { loanInfo, setLoanInfo} = useLoanInfo();
    const [loanid,setLoanid] = useState(loanInfo?.loanid);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [message , setMessage] = useState('Updated Successfully');
    const [deleteData, setDeleteData] = useState(null); 
    const [deleteIndex, setDeleteIndex] = useState(null); 
    const [addConfirm,setAddConfirm] = useState(false);
    const [deleteConfirm,setDeleteConfirm] = useState(false);
    // const data = [
    //     {
    //       id: 1,
    //       createdby: '1098 Form.pdf',
    //       dateadded: '2024-07-26T07:16:08.916Z',
    //       description: 'Statement 1098',
    //       amount : '$3000'
    //     }, {
    //         id: 2,
    //         createdby: '1098 Form.pdf',
    //         dateadded: '2024-07-26T07:16:08.916Z',
    //         description: 'Statement 1098',
    //         amount : '$3000'
    //       }, {
    //         id: 3,
    //         createdby: '1098 Form.pdf',
    //         dateadded: '2024-07-26T07:16:08.916Z',
    //         description: 'Statement 1098',
    //         amount : '$3000'
    //       },
    //   ];
    let savedFeeErrors = {};
   
    const [showFormula, setShowFormula] = useState(false);
    const [savedFeesData,setSavedFeesData] = useState([]);
    // const [formData,setFormData] = useState((savedFeesData?.length !== 0 ) ? [...savedFeesData] : [...data]);
    const [formData,setFormData] = useState([]);
    const [loanSummary,setLoanSummary] = useState({});
   
    const [payOffId,setPayOffId] = useState(null);
    const [emailError,setEmailError] = useState(null);
    const [emailInput, setEmailInput] = useState("");
    

    useEffect(() => {
        if(loanid){
            // initializer();
            
            getLoanApi();
            getLoanSummaryDetails();
            getPayOffDetails();
            //getServicingSetupDetails();
           
        }
      }, [loanid]);
    useEffect(() => {
        setLoanid(loanInfo?.loanid)
    }, [loanInfo?.loanid]);

    const modules = {
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ color: [] }, { background: [] }],
          [{ script: 'sub' }, { script: 'super' }],
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }],
          ['link', 'image', 'video'],
          [{ align: [] }],
        ],
        clipboard: {
          matchVisual: false,
        },
      };

    const columns = [
        {
            name: 'AMOUNT',
            selector: (row) => (row?.credit === true) ? '($' + row?.amount + ')' : '$' + row?.amount,
            width: '20%',
        },
        {
            name: 'DESCRIPTION',
            selector: (row) => row?.description || '',
          //   sortable: true,
            width: '30%',
        },
        {
          name: 'CREATED BY',
          selector: (row) => row?.createdby || '',
        //   sortable: true,
          width: '20%',
        },
        {
          name: 'DATE ADDED',
          selector: (row) => row?.dateadded ?  moment.utc(row?.dateadded).format("MM-DD-YYYY") : '',
        //   sortable: true,
          width: '20%',
        },
        {
            name: 'DELETE',
            cell: (row, index) => (
              <div className="btn-group">
                  <button type="button" className="btn btn-outline-secondary" onClick={(e) => openDeleteConfirmation(row,index)}><span className="material-symbols-outlined icon"> delete </span></button>
              </div>
            ),
            width: '10%',
            
          },
    ];

   

      const handleSavedFeeChange=(e)=>{
        
        let {name, value , checked} = e.target;
        //console.log(name,value)
        if(name === 'credit'){
            
            setSavedFees({ ...savedFees, [name]: checked });
            
            
        }else if(name === 'netfunddrawbalance' || name === "sendtoborroweremail"){
           
            setPayOffData((prevData) => ({
                ...prevData,
               [name] : checked
           }))
            
            
        }else{
            setSavedFees({ ...savedFees, [name]: value });
        } 
      };

      

      const handleValidation = () => {
        // Reset errors state at the beginning of validation
        setErrors({});  // Clear all existing errors
        console.log('saved fees',savedFees);
    
        const fieldsCheck = [
            { fieldName: "description", fieldLabel: "Description" },
            { fieldName: "amount", fieldLabel: "Amount" },
        ];
    
        savedFeeErrors = {};
    
        // Validate each field
        fieldsCheck.forEach(({ fieldName, fieldLabel }) => {
            let value = savedFees[fieldName];
    
            // Check if value is empty or invalid
            if (!value) {
                savedFeeErrors[fieldName] = `Please Enter ${fieldLabel}`;
            } else if (fieldName === 'amount' && isNaN(value)) {
                savedFeeErrors[fieldName] = `Please Enter a valid ${fieldLabel}`;
            }
        });
    
        // Store errors in state
        setErrors(savedFeeErrors);
    
        // Log errors to debug
        console.log("Validation Errors:", savedFeeErrors);
    
        // Return true if there are no errors, false otherwise
        return Object.keys(savedFeeErrors).length === 0;
    };
    
    
    
     
    const handleAddSavedFees = () => {

        const valid = handleValidation();
        console.log("valid",valid);

        if (valid) {
           console.log('saved fees',savedFees);
           //dispatch(showLoader());
          


            // Create a new Date object
            const currentDate = new Date();

            // Get the month, day, and year
            const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
            const day = currentDate.getDate();
            const year = currentDate.getFullYear();
            let userData = getUserDataFromLocalStorage();
            let userName = userData?.username;

            // Format the date as MM/DD/YYYY
            const formattedDate = `${month}/${day}/${year}`;
            // const createdBy = loanInfo?.applicant[0]?.borrowerfirstname + "" + loanInfo?.applicant[0]?.borrowerlastname;
            const createdBy = userName;
            const creditDescription = savedFees?.description;
            const creditAmount = savedFees?.amount;
            const creditBool = savedFees?.credit;
            // setSavedFeesData([...savedFeesData,
            //     {
            //         id:1,
            //         createdby: createdBy,
            //         dateadded: formattedDate,
            //         description: creditDescription,
            //         amount : creditAmount

            //     }
            // ])
            // Update both savedFeesData and formData
            const newSavedFee = {
                //id: savedFeesData.length + 1, // Ensure you pass a unique ID
                createdby: createdBy,
                dateadded: currentDate,
                description: creditDescription,
                amount: creditAmount,
                credit : creditBool
            };
    
            setSavedFeesData(prevState => [...prevState, newSavedFee]);
           
            setFormData(prevState => [...prevState, newSavedFee]); // Add the new fee to the data table
            setAddConfirm(true);
            // setPopupOpen(true);
            // setMessage('Added Succesfully');
           // console.log("saved fees data",savedFeesData);


            //dispatch(hideLoader());
            
        
        }
    }
    const [selectedDate, setSelectedDate] = useState(null);

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//     console.log('Selected Date:', date); // You can also perform other actions with the date
//   };

   

    const handleDateChange = (e) => {
        const {name ,value} = e.target;
        setPayOffData({...payoffData,[name] : value});
        
    };


      const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',

            },
        },
        table: {
            style: {
                border: '1px solid #CCD8F6',
            },
        },
    };


    const handleCurrencyPayOffInputs = (name,value) => {
        if(name === "amount" || name == "totalsavedfeesamount"){
            setSavedFees({                                                  
                ...savedFees,
                [name]: value,
            });
        }else{
            setPayOffData((prevData) => ({
                ...prevData,
               [name] : value
           }))
        }
       
    }

    const handleNonCurrencyInputs = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        // if(name ==="toemail"){
        //     setPayOffData((prevData) => ({
        //         ...prevData,
        //         [name] : value
        //    }))
        // }else{
        //     setPayOffData((prevData) => ({
        //         ...prevData,
        //         [name] : value
        //    }))
        // }
        // setPayOffData((prevData) => {
        //     // Check if the input name is 'toemail' and handle it as an array
        //     // if (name === "toemail") {
        //     //     return {
        //     //         ...prevData,
        //     //         [name]: prevData[name] ? [...prevData[name], value] : [value] // Append value to the existing array or create a new array
        //     //     };
        //     // } else {
        //         return {
        //             ...prevData,
        //             [name]: value // For other fields, just set the value as normal
        //         };
        //     //}
        // });

        setPayOffData((prevData) => ({
            ...prevData,
            [name] : value
        }))
        
    }

    const getLoanApi = () => {
        dispatch(showLoader());
        getLoanDetails(loanid)
        .then(response => {
        console.log('getLoanDetails api', response);
        if (response?.result[0]) {
            setLoanInfo(response?.result[0]);
            console.log(response);
            dispatch(hideLoader());
        }
        })
        .catch(err => {
        console.error('getLoanDetails api', err);
        dispatch(hideLoader());
        });
    
    };

    const getServicingSetupDetails = ()=>{
        dispatch(showLoader());
        getServicingSetupApi(loanid)
        .then(function(response) {
            if (response) {
                console.log('ServicingSetup api response', response);
                // setSavedFees((prev) => ({
                //     ...prev,
                //     ...response.result[0], 
                // }));
                dispatch(hideLoader());
            }
        })
        .catch((err) => {
            console.error("ServicingSetup api response", err); 
            dispatch(hideLoader());
        });
    };

    const getLoanSummaryDetails = ()=>{
        dispatch(showLoader());
        getloanSummary(loanid)
        .then(function(response) {
            if (response) {
                console.log('getloanSummary api response', response);
                setLoanSummary(response?.result);
                dispatch(hideLoader());
            }
        })
        .catch((err) => {
            console.error("getloanSummary api response", err); 
            dispatch(hideLoader());
        });
    }

    // Modify the date to set the day as '01'
    const setDateToFirst = (dateString) => {
        const [year, month ,day] = dateString.split('-');
        return `${year}-${month}-01`; // Set day to '01'
    };

    // Function to format date for input
    const formatDateForInput = (dateStr) => {
        if (!dateStr) return ''; // If dateStr is undefined or empty, return an empty string
        const date = new Date(dateStr);
        
        // Check if the date is valid
        if (isNaN(date.getTime())) {
            console.error('Invalid date:', dateStr); 
            return ''; // Handle invalid date case
        }
        
        return date.toISOString().split("T")[0]; // Returns YYYY-MM-DD format
    };

    const calculateInputs = () => {

        // if(payOffId){
          
        //     setPayOffData((prevData) => ({
        //         ...prevData,
        //         'dailyperdiem': payoffData?.dailyperdiem,
        //         'dailyperdiemdays' : payoffData?.dailyperdiemdays,
        //         'payoffdate' : payoffData?.payoffdate,
        //         'perdiemdaystotal' : payoffData?.perdiemdaystotal,
        //         'bankaccountnumber' :payoffData?.bankaccountnumber,
        //         'remainingprincipalbalance' : payoffData?.remainingprincipalbalance,
        //         'totalpayoffamount' :payoffData?.totalpayoffamount,
        //         'outstandingpayment' : payoffData?.outstandingpayment,
        //         'remainingdrawbalance' : payoffData?.remainingdrawbalance,
        //         'totalsavedfeesamount' : payoffData?.totalsavedfeesamount,
        //         'outstandingpaymentasofdate' : payoffData?.outstandingdate,
        //         'remainingreserves' :  payoffData?.remainingreserves,
            

        //     }));

        //     setFormData(payoffData?.savedfeesandcredits);
        // }else{
        //     let remainingPrincipalBal = 0;
        //     remainingPrincipalBal = (loanInfo?.loanamount && loanSummary?.principalObj?.amountDue) ?
        //                                 loanInfo?.loanamount - loanSummary?.principalObj?.amountDue : 0; 
            
        //     remainingPrincipalBal = parseFloat(remainingPrincipalBal.toFixed(2));

        //     let dailyperdiem = (remainingPrincipalBal) ? (remainingPrincipalBal * loanInfo?.interestrate) / 360 : 0;
        //     dailyperdiem = parseFloat(dailyperdiem).toFixed(2);
            
        //     let originalMaturityDate =  loanInfo?.cleartoclose?.declaredmaturitydate;//"2024-08-21T00:00:00.000Z"
        //     // Create Date objects for the dates
        //     const maturityDate = new Date(originalMaturityDate);
        //     const currentDate = new Date(); // Current date

        //     // Calculate the difference in milliseconds
        //     const differenceInTime = maturityDate - currentDate; // difference in milliseconds
            
        //     // Convert milliseconds to days and remove negative si
        //     let perDiemDays = Math.abs(Math.ceil(differenceInTime / (1000 * 3600 * 24))); // divide by milliseconds in a day
        //     perDiemDays = (perDiemDays > 0) ? perDiemDays : 0;

        //     let overWriteDays = payoffData?.overwritedays;
        //     let perdiemdaystotal = null;

        //     if(!overWriteDays){
        //         perdiemdaystotal = dailyperdiem * perDiemDays;
        //         perdiemdaystotal = perdiemdaystotal?.toFixed(2);
        //     }else{
        //         // Apply the fixed reduction per day
        //         perdiemdaystotal = dailyperdiem * payoffData?.overwritedays;
        //         perdiemdaystotal = perdiemdaystotal?.toFixed(2);
        //     }

        //     perdiemdaystotal = Number(perdiemdaystotal);

        //     let bankActNum = loanInfo?.wireinstructions?.bankaccountnumber;

        //      // Format the current date to 'YYYY-MM-DD' format for the date input
        //     const formattedCurrentDate = currentDate.toISOString().split('T')[0]; // 'YYYY-MM-DD'
        //     console.log('loan summary',loanSummary);

        //     let outstandingInterest = loanSummary?.lateFeeObj?.amountDue; //late fees

        //     // Calculate the total saved fees amount
        //     const allTotalSavedFees = formData?.reduce((acc,current) => {
        //         const amount = parseFloat(current.amount); // Convert the amount to a number
        //         return current.credit ? acc - amount : acc + amount; // Add if credit is true, subtract if false
        //     },0);

        //     console.log("Total Amount:", allTotalSavedFees);

        //     let outstandingPayment = loanSummary?.interestObj?.amountDue + loanSummary?.lateFeeObj?.amountDue + loanSummary?.extensionFeeObj?.amountDue 
        //         +  loanSummary?.nsfFeeObj?.amountDue + loanSummary?.servicingFeeObj?.amountDue + loanSummary?.othersFeeObj?.amountDue;
        //     // Format the outstandingPayment to 2 decimal places
        //     outstandingPayment = parseFloat(outstandingPayment).toFixed(2);

        //     let allFees = loanSummary?.lateFeeObj?.amountDue + loanSummary?.extensionFeeObj?.amountDue 
        //         +  loanSummary?.nsfFeeObj?.amountDue + loanSummary?.servicingFeeObj?.amountDue + loanSummary?.othersFeeObj?.amountDue;
        
        //     let remainingDrawBal = 0;
        //     remainingDrawBal = parseFloat(remainingDrawBal.toFixed(2));
        
        //     let totalPayOffAmt = remainingPrincipalBal + outstandingInterest + allFees + perdiemdaystotal + allTotalSavedFees;
        //     totalPayOffAmt = parseFloat(totalPayOffAmt);

        //     if(payoffData?.netfunddrawbalance === true && payoffData?.remainingdrawbalance > 0){
        //         let greaterVal = (totalPayOffAmt > remainingDrawBal) ? true : false;
        //         if(greaterVal){
        //             totalPayOffAmt = totalPayOffAmt - payoffData?.remainingdrawbalance;
        //             totalPayOffAmt = totalPayOffAmt?.toFixed(2);
        //         }else{
        //             totalPayOffAmt = payoffData?.remainingdrawbalance - totalPayOffAmt;
        //             totalPayOffAmt = totalPayOffAmt?.toFixed(2);
        //         }
                
        //     }


        //     //let outstandingDate = setDateToFirst(formattedCurrentDate);
        //     let dueAsOf = loanSummary?.dueAsOf; 
        //     let formatOutstandingDate = dueAsOf ? formatDateForInput(dueAsOf) : setDateToFirst(formattedCurrentDate);
        //     let outstandingDate = formatOutstandingDate;
        //     //console.log('outstanding Date',outstandingDate);

        //     let remainingReserves = parseFloat((loanInfo?.balanceinterestreserveamount || 0).toFixed(2));
            
        //     console.log('form data',formData);

        //     setPayOffData((prevData) => ({
        //         ...prevData,
        //         'dailyperdiem': dailyperdiem,
        //         'dailyperdiemdays' : parseFloat(perDiemDays),
        //         'payoffdate' : formattedCurrentDate,
        //         'perdiemdaystotal' : parseFloat(perdiemdaystotal),
        //         'bankaccountnumber' : bankActNum,
        //         'remainingprincipalbalance' : remainingPrincipalBal,
        //         'totalpayoffamount' : totalPayOffAmt,
        //         'outstandingpayment' : outstandingPayment,
        //         'remainingdrawbalance' : remainingDrawBal,
        //         'totalsavedfeesamount' : allTotalSavedFees,
        //         'outstandingpaymentasofdate' : outstandingDate,
        //         'remainingreserves' :  remainingReserves,
             
        //     }))
        // }

        
        let remainingPrincipalBal = 0;
        // remainingPrincipalBal = (loanInfo?.loanamount && loanSummary?.principalObj?.amountDue) ?
        //                             loanInfo?.loanamount - loanSummary?.principalObj?.amountDue : 0; 
        remainingPrincipalBal = (loanSummary?.principalObj?.amountDue) ? loanSummary?.principalObj?.amountDue : 0; 
        
        remainingPrincipalBal = parseFloat(remainingPrincipalBal.toFixed(2));

        let dailyperdiem = (remainingPrincipalBal) ? (remainingPrincipalBal * loanInfo?.interestrate) / 360 / 100 : 0;
        dailyperdiem = parseFloat(dailyperdiem).toFixed(2);
        
        let originalMaturityDate =  loanInfo?.cleartoclose?.declaredmaturitydate;//"2024-08-21T00:00:00.000Z"
        // Create Date objects for the dates
        const maturityDate = new Date(originalMaturityDate);
        const currentDate = new Date(); // Current date

        // Calculate the difference in milliseconds
        const differenceInTime = maturityDate - currentDate; // difference in milliseconds
        
        // Convert milliseconds to days and remove negative si
        let perDiemDays = Math.abs(Math.ceil(differenceInTime / (1000 * 3600 * 24))); // divide by milliseconds in a day
        perDiemDays = (perDiemDays > 0) ? perDiemDays : 0;

        let overWriteDays = payoffData?.overwritedays;
        let perdiemdaystotal = null;

        if(!overWriteDays){
            perdiemdaystotal = dailyperdiem * perDiemDays;
            perdiemdaystotal = perdiemdaystotal?.toFixed(2);
        }else{
            // Apply the fixed reduction per day
            perdiemdaystotal = dailyperdiem * payoffData?.overwritedays;
            perdiemdaystotal = perdiemdaystotal?.toFixed(2);
        }

        perdiemdaystotal = Number(perdiemdaystotal);

        let bankActNum = loanInfo?.wireinstructions?.bankaccountnumber;

         // Format the current date to 'YYYY-MM-DD' format for the date input
        const formattedCurrentDate = currentDate.toISOString().split('T')[0]; // 'YYYY-MM-DD'
        console.log('loan summary',loanSummary);

        let outstandingInterest = loanSummary?.interestObj?.amountDue;

        // Calculate the total saved fees amount
        const allTotalSavedFees = formData?.reduce((acc, current) => {
            const amount = parseFloat(current.amount); // Convert the amount to a number
            return current.credit ? acc - amount : acc + amount; // Add if credit is true, subtract if false
        }, 0)
        

        console.log("Total Amount:", allTotalSavedFees);

        // let outstandingPayment = loanSummary?.interestObj?.amountDue  + loanSummary?.extensionFeeObj?.amountDue 
        //     +  loanSummary?.nsfFeeObj?.amountDue + loanSummary?.servicingFeeObj?.amountDue + loanSummary?.othersFeeObj?.amountDue;
        // // Format the outstandingPayment to 2 decimal places
        // outstandingPayment = parseFloat(outstandingPayment).toFixed(2);

        let allFees = loanSummary?.extensionFeeObj?.amountDue 
            +  loanSummary?.nsfFeeObj?.amountDue + loanSummary?.servicingFeeObj?.amountDue + loanSummary?.othersFeeObj?.amountDue;
    
        let outstandingPayment = loanSummary?.interestObj?.amountDue  + allFees;
        // Format the outstandingPayment to 2 decimal places
        outstandingPayment = parseFloat(outstandingPayment).toFixed(2);
        
        let remainingDrawBal = (loanSummary?.totalDrawBalance) ? loanSummary?.totalDrawBalance : 0;
        remainingDrawBal = parseFloat(remainingDrawBal.toFixed(2));
    
        let totalPayOffAmt = remainingPrincipalBal + outstandingInterest + allFees + perdiemdaystotal + allTotalSavedFees;
        totalPayOffAmt = parseFloat(totalPayOffAmt);

        if(payoffData?.netfunddrawbalance === true && payoffData?.remainingdrawbalance > 0){
            let greaterVal = (totalPayOffAmt > remainingDrawBal) ? true : false;
            if(greaterVal){
                totalPayOffAmt = totalPayOffAmt - payoffData?.remainingdrawbalance;
                totalPayOffAmt = totalPayOffAmt?.toFixed(2);
            }else{
                totalPayOffAmt = payoffData?.remainingdrawbalance - totalPayOffAmt;
                totalPayOffAmt = totalPayOffAmt?.toFixed(2);
            }
            
        }


        //let outstandingDate = setDateToFirst(formattedCurrentDate);
        let dueAsOf = loanSummary?.dueAsOf; 
        let formatOutstandingDate = (dueAsOf) ? formatDateForInput(dueAsOf) : setDateToFirst(formattedCurrentDate);
        let outstandingDate = formatOutstandingDate;
        //console.log('outstanding Date',outstandingDate);

        let remainingReserves = parseFloat((loanInfo?.balanceinterestreserveamount || 0).toFixed(2));
        
        console.log('form data',formData);

        setPayOffData((prevData) => ({
            ...prevData,
            'dailyperdiem': dailyperdiem,
            'dailyperdiemdays' : parseFloat(perDiemDays),
            'payoffdate' : formattedCurrentDate,
            'perdiemdaystotal' : parseFloat(perdiemdaystotal),
            'bankaccountnumber' : bankActNum,
            'remainingprincipalbalance' : remainingPrincipalBal,
            'totalpayoffamount' : totalPayOffAmt,
            'outstandingpayment' : outstandingPayment,
            'remainingdrawbalance' : remainingDrawBal,
            'totalsavedfeesamount' : allTotalSavedFees,
            'outstandingpaymentasofdate' : outstandingDate,
            'remainingreserves' :  remainingReserves,
         
        }))

        // if(payOffId){
        //     setFormData(payoffData?.savedfeesandcredits);
        // }
    

        console.log("calc data", payoffData);

       
        
    }

    useEffect(() => {
        if(addConfirm || deleteConfirm){
            savePayoffDataApi('addFee');
        }
       
    },[payoffData?.totalsavedfeesamount])

    const handleFocus = (e) => {
        const { name} = e.target;
        
        setErrors({ ...errors, [name]: '' })
    }

    //call calculate fields
    useEffect(() => {
        calculateInputs();
    },[payOffId , loanInfo,loanSummary , payoffData?.overwritedays ,formData , payoffData?.overwritedays, payoffData?.netfunddrawbalance])
        
    

    const closePopup = () => {
        setPopupOpen(false);
     
    };

    const handleDeleteSavedfee = (data,index) => {
       
        // dispatch(showLoader());
        // const updatedFees = [...formData];
        // updatedFees.splice(index, 1);
        // setFormData(updatedFees);
        // setPopupOpen(true);
        // setMessage('Deleted Succesfully');
        // dispatch(hideLoader());
        // setShowConfirmation(false);
        //setShowConfirmation(false);
        // const updatedFees = [...formData];
        // updatedFees.splice(index, 1);
        //setFormData(updatedFees);
         // Filter out the item with the specified uuid
         const updatedData = savedFeesData.filter(item => item.uuid !== data.uuid);
        
         // Update state with the filtered data
         setSavedFeesData(updatedData);
         setFormData(updatedData);
         setDeleteConfirm(true);
         setShowConfirmation(false);
    }

    const handleToolTip = () => {
        setShowFormula(!showFormula);
    }

    const getPayOffDetails = () => {
        dispatch(showLoader());
        getPayoffData(loanid)
        .then(function(response) {
            if (response) {
                console.log(' getPayOffDetails api response', response);
                if(response?.result?.[0]?._id){
                   //setPayOffData(response.result[0]);
                    setPayOffData((prevData) => ({
                        ...prevData, // Spread the existing state
                        'payoffexpirydate': response.result[0]?.payoffexpirydate, // Add payoffexpirydate
                        'additionalpayoffletterverbage': response.result[0]?.additionalpayoffletterverbage // Add additionalpayoffletterverbage
                    }));
                
                   setPayOffId(response.result[0]?.payoffid);
                   setFormData(response.result[0]?.savedfeesandcredits);
                   setSavedFeesData(response.result[0]?.savedfeesandcredits);
                }
                
                dispatch(hideLoader());
            }
        })
        .catch((err) => {
            console.error("getPayOffDetails api response", err); 
            dispatch(hideLoader());
        });
    }

    // const generatePDFWithValues = (htmlContent) => {
    //     let replacedContent = htmlContent;
    //     const tempElement = document.createElement('div');
    //     tempElement.innerHTML = htmlContent; // Set HTML content
      
    //     // Append to body to ensure it's part of the DOM
    //     document.body.appendChild(tempElement);
      
    //     // Use html2canvas to convert the element to a canvas
    //     html2canvas(tempElement, { useCORS: true })
    //       .then((canvas) => {
    //         const imgData = canvas.toDataURL('image/png');
    //         const doc = new jsPDF({
    //           orientation: 'portrait',
    //           unit: 'px',
    //           format: 'a4',
    //         });
      
    //         // const imgWidth = 210; // A4 size page width in mm
    //         // const pageHeight = 295; // A4 size page height in mm
    //         // const imgHeight = (canvas.height * imgWidth) / canvas.width;
    //         // let heightLeft = imgHeight;
    //         // let position = 0;
      
    //         // pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //         // heightLeft -= pageHeight;
      
    //         // // Handle pages for content that goes beyond one page
    //         // while (heightLeft >= 0) {
    //         //   position = heightLeft - imgHeight;
    //         //   pdf.addPage();
    //         //   pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //         //   heightLeft -= pageHeight;
    //         // }
      
    //         // // Save the PDF
    //         // pdf.save('generated-document.pdf');
      
    //         // // Cleanup: Remove the temporary element from the DOM
    //         // document.body.removeChild(tempElement);

    //         doc.html(replacedContent, {
    //             x: 20, // Horizontal position (in pixels)
    //             y: 0, // Vertical position (in pixels)
    //             html2canvas: {
    //                 scale: 0.565 // Adjust scale to fit content within the page
    //             },
    
    //             callback: () => {
    //                 // Save PDF file after rendering is complete
                    
                   
    //                 let loanId = loanid;
    //                 let fileName = `Payoff-${loanId}.pdf`;
    //                 doc.save(fileName);
    //                  // Hide the element to prevent it from displaying on the webpage
    //                 //tempElement.style.display = 'none'; // Hide the element

    //                 dispatch(hideLoader());

    //             }
    //         });
                
    
    //       })
    //       .catch((err) => {
    //         console.error("Error generating PDF", err);
    //         // Cleanup: Remove the temporary element from the DOM even on error
    //         document.body.removeChild(tempElement);
    //       });

    //        // Hide the element to prevent it from displaying on the webpage
    //         tempElement.style.display = 'none'; // Hide the element

    // };

    // const generatePDFWithValues = (htmlContent) => {
    //     if (typeof document !== 'undefined') {
    //         let replacedContent = htmlContent;
    //         const tempElement = document.createElement('div');
    //         tempElement.innerHTML = htmlContent; // Set HTML content
    
    //         // Append to body to ensure it's part of the DOM
    //         document.body.appendChild(tempElement);
    
    //         // Use html2canvas to convert the element to a canvas
    //         html2canvas(tempElement, { useCORS: true })
    //             .then((canvas) => {
    //                 const imgData = canvas.toDataURL('image/png');
    //                 const doc = new jsPDF({
    //                     orientation: 'portrait',
    //                     unit: 'px',
    //                     format: 'a4',
    //                 });
    
    //                 doc.html(replacedContent, {
    //                     x: 20, // Horizontal position (in pixels)
    //                     y: 0, // Vertical position (in pixels)
    //                     html2canvas: {
    //                         scale: 0.565, // Adjust scale to fit content within the page
    //                     },
    //                     callback: () => {
    //                         // Instead of saving, we'll create a blob and display it in a popup
    //                         const pdfOutput = doc.output('blob');
    //                         const pdfUrl = URL.createObjectURL(pdfOutput);
    
    //                         // Create an iframe to display the PDF
    //                         const pdfWindow = window.open('', '_blank');
    //                         if (pdfWindow) {
    //                             pdfWindow.document.write(
    //                                 '<html><head><title>PDF Preview</title></head><body>'
    //                             );
    //                             pdfWindow.document.write(
    //                                 '<iframe width="100%" height="100%" src="' +
    //                                     pdfUrl +
    //                                     '" frameborder="0"></iframe>'
    //                             );
    //                             pdfWindow.document.write('</body></html>');
    //                             pdfWindow.document.close();
    //                         }
    
    //                         // Cleanup: Remove the temporary element from the DOM
    //                         document.body.removeChild(tempElement);
    //                         dispatch(hideLoader());
    //                     },
    //                 });
    //             })
    //             .catch((err) => {
    //                 console.error('Error generating PDF', err);
    //                 // Cleanup: Remove the temporary element from the DOM even on error
    //                 document.body.removeChild(tempElement);
    //             });
    
    //         // Hide the element to prevent it from displaying on the webpage
    //         tempElement.style.display = 'none'; // Hide the element
    //     } else {
    //         console.error('Document is not available');
    //         // dispatch(showLoader());
    //         // setPopupOpen(true);
    //         // setMessage("Unable to load the document preview. Please try again.");
    //         // dispatch(hideLoader());
    //     }
    // };


    

    const generatePDFWithValues = async (htmlContent) => {
        try {
            dispatch(showLoader());
            const replacedContent = htmlContent;
            let contentSections = replacedContent.split('<!-- pagebreak-->');
    
            // Function to generate PDFs for each content section
            const generatePDFs = async () => {
                const pdfDocs = [];
                for (let i = 0; i < contentSections.length; i++) {
                    let doc = new jsPDF({
                        orientation: 'portrait',
                        unit: 'px',
                        format: 'a4',
                        autoPrint: true,
                    });
    
                    await new Promise((resolve) => {
                        doc.html(contentSections[i], {
                            margin: [20, 30, 20, 30],
                            html2canvas: {
                                scale: 0.545,
                            },
                            callback: () => {
                                console.log(`Generated PDF ${i + 1}/${contentSections.length}`);
                                pdfDocs.push(doc.output('arraybuffer'));
                                resolve();
                            },
                        });
                    });
                }
                return pdfDocs;
            };
    
            // Function to merge PDFs
            const mergePDFs = async (pdfDocs) => {
                const mergedPdf = await PDFDocument.create();
                for (const pdfBytes of pdfDocs) {
                    const pdf = await PDFDocument.load(pdfBytes);
                    const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
                    copiedPages.forEach((page) => mergedPdf.addPage(page));
                }
                return await mergedPdf.save();
            };
    
            // Generate and merge PDFs
            const pdfDocs = await generatePDFs();
            const mergedPdfBytes = await mergePDFs(pdfDocs);
    
            // Create a blob from the merged PDF
            const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(blob); // Generate a URL for the PDF blob
    
            // Open the PDF in a new window
            const pdfWindow = window.open(pdfUrl, '_blank');
            if (pdfWindow) {
                pdfWindow.focus(); // Focus on the new window to ensure the PDF is displayed
            } else {
                console.error('Failed to open new window');
            }
    
            dispatch(hideLoader());
            console.log('All PDFs generated, merged, and opened in a new window.');
            
        } catch (error) {
            console.error('Error generating or downloading file:', error);
            dispatch(hideLoader());
        }
    };
    
    

    const savePayoffDataApi = (param) => {

        //calculateInputs();
        //let updatedSavedFeesData = [];

        //const updatedSavedFeesData = savedFeesData.map(({ id, ...rest }) => rest); // Remove the 'id' field
        // if(!payOffId){
        //     updatedSavedFeesData = savedFeesData.map(({ id, ...rest }) => ({
        //         ...rest,
        //         uuid: uuidv4() ,// Replace 'id' with 'uuid'
        //         amount : Math.abs(parseFloat(rest.amount)).toFixed(2)
        //     }))
        // }else {
        //     // If payOffId exists, you can choose to handle savedFeesData differently or leave it as is
        //     updatedSavedFeesData = formData; // or any logic you need
        // }

        let updatedSavedFeesData = savedFeesData.map(({ id, ...rest }) => ({
            ...rest,
            uuid: uuidv4(), // Replace 'id' with 'uuid'
            //amount: (rest?.credit && rest.amount) ? -(parseFloat(rest.amount)) : parseFloat(rest.amount) || 0 // Convert to float
            amount: parseFloat(rest.amount) || 0 
        }));

        // Convert specific fields to float
        const dailyPerDiemFloat = parseFloat(payoffData.dailyperdiem);
        const overwriteDaysFloat = parseFloat(payoffData.overwritedays);

        // Remove string formatting from 'outstandingpayment' and ensure it's a number
        //const outstandingPaymentFloat = parseFloat(payoffData.outstandingpayment).toFixed(2);
        const outstandingPaymentFloat = parseFloat(payoffData.outstandingpayment.replace(/,/g, ''));
         // Helper function to format the date to 'YYYY-MM-DD' format
        const formatDate = (dateString) => {
            if (!dateString) return null; // Return null if dateString is falsy
            const date = new Date(dateString);
            return date.toISOString(); // Keep the full ISO format with time and timezone
        };
        
        // let totalSavedFee = (parseFloat(payoffData?.totalsavedfeesamount) || 0);

        // // Check if credit is true, add the amount, else subtract the amount
        // if (deleteData?.credit) {
        //     totalSavedFee -= (parseFloat(deleteData?.amount) || 0);
        // } else {
        //     totalSavedFee += (parseFloat(savedFees?.amount) || 0);
        // }



        // Update the payoff data object
        let updatedPayoffData = {
            ...payoffData,
            loanid, // Add loanid to payoffData
            savedfeesandcredits: updatedSavedFeesData, // Embed savedFeesData under savedfeesandcredits
            dailyperdiem: !isNaN(dailyPerDiemFloat) ? dailyPerDiemFloat : 0, // Convert to float, fallback to 0 if invalid
            overwritedays: !isNaN(overwriteDaysFloat) ? overwriteDaysFloat : 0, // Convert to float, fallback to 0 if invalid
            payoffdate : formatDate(payoffData?.payoffdate),
            payoffexpirydate : formatDate(payoffData?.payoffexpirydate),
            outstandingpayment: outstandingPaymentFloat,
            outstandingpaymentasofdate: formatDate(payoffData?.outstandingpaymentasofdate),
            //totalsavedfeesamount : Math.abs(parseFloat(payoffData?.totalsavedfeesamount).toFixed(2))
            //sendemail : (param === "sendemail") ? true : false
        };

        // Set 'netfunddrawbalance' and 'sendtoborroweremail' to false if not present
        if (updatedPayoffData.netfunddrawbalance === undefined) {
            updatedPayoffData.netfunddrawbalance = false;
        }
        if (updatedPayoffData.sendtoborroweremail === undefined) {
            updatedPayoffData.sendtoborroweremail = false;
        }

        // If 'param' is "preview", remove 'sendtoborroweremail' and 'toemail'
        // if (param === "preview"){
        //     const { sendtoborroweremail, toemail, ...restPayoffData } = updatedPayoffData;
        //     updatedPayoffData = restPayoffData; // Update the object without these keys
        // }

        // Remove 'toemail' if param is "addFee"
        // if (param === "addFee") {
        //     const { toemail, ...restPayoffData } = updatedPayoffData; // Remove 'toemail'
        //     updatedPayoffData = restPayoffData;
        // }

        if(payOffId){
            
            const { _id,appname,cretaed,createdBy,modifiedBy,updated, ...restPayoffData } = updatedPayoffData;
            updatedPayoffData = {
                ...restPayoffData,// Use restPayoffData after removing the unwanted keys
                payoffid: payOffId // Set payoffid field
            };
        
        }

        // const formattedData = Object.entries(updatedPayoffData)
        // .map(([key, value]) => `${key}: ${value}`)
        // .join('\n');
        // const formattedData = Object.entries(updatedPayoffData)
        // .map(([key, value]) => `<p><strong>${key}:</strong> ${value}</p>`)  // Wrap each entry in <p> tags
        // .join('');  // Combine into a single string of HTML


        // console.log('Formatted Payoff Data:', formattedData); // For debugging if needed
        // setContent(formattedData); // Anything you want to show in the editor

        //if(content){
        console.log("saved fee amt",payoffData?.totalsavedfeesamount);
            console.log('finalpayoff data',updatedPayoffData);

            dispatch(showLoader());
            savePayoffData(loanid,updatedPayoffData,param)
            .then(function(response) {
                if (response) {
                    console.log('savePayoffDataApi api response', response);

                    // Check if response.result is HTML
                    const isHTML = /<\/?[a-z][\s\S]*>/i.test(response?.result); // Simple regex to check for HTML tags

                    if (param === "preview" && isHTML) {
                        // setPayOffData((prevData) => ({
                        //     ...prevData,
                        //     'template': response?.result, // Save the HTML template if it's detected
                        // }));
                        generatePDFWithValues(response?.result,loanid);
                       
                        
                    } else if (param === "sendemail" && response?.result === "Email sent successfully") {
                        setPopupOpen(true);
                        setMessage(response?.result);
                        setPayOffData(prevData => ({
                            ...prevData,
                            toemail: [] ,// This will empty the 'toemail' array
                            sendtoborroweremail : false
                        }));
                        
                        // if (payOffId) {
                        //     setMessage("Updated Successfully");
                        // } else {
                        //     setMessage("Saved Successfully");
                        // }
                    }else if (param === "addFee" && isHTML) {
                        setPopupOpen(true);
                        if(deleteConfirm === true){
                            setMessage('Deleted Successfully');
                            setShowConfirmation(false);
                            setDeleteConfirm(false);
                        }else{
                            setMessage('Added Succesfully');
                            // Reset savedFees state to initial values
                            setSavedFees({
                                description: '',
                                amount: '',
                                credit: false
                            });
                            setAddConfirm(false);
                        }
                       
                        
                       
                        // if (payOffId) {
                        //     setMessage("Updated Successfully");
                        // } else {
                        //     setMessage("Saved Successfully");
                        // }
                    }
                    dispatch(hideLoader());
                    
                    getPayOffDetails();
                  
                    
                }
            })
            .catch((err) => {
                console.error("savePayoffDataApi api response", err); 
                dispatch(hideLoader());
            });
        //}

       
    }

    const isValidEmail = (email) => {
        // Implement your email validation logic here
        // Example: Check if the email follows a standard format
        // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // const emailRegex = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
        // const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/;
        let emailtest = emailRegex.test(email);
        return emailtest;
    };

    const handlePreview = () => {
        // if(payoffData?.toemail && isValidEmail(payoffData?.toemail)){
        //     savePayoffDataApi('preview');
        // }else{
        //     setEmailError(true);
        // }

        const invalidEmails = payoffData?.toemail?.filter(email => !isValidEmail(email));
        
        if (payoffData?.toemail && invalidEmails?.length !== 0) {
            // If there are invalid emails, set error
            setEmailError(true);
            //setPopupOpen(true);
            //setMessage(`Invalid email(s) found: ${invalidEmails.join(", ")}`);
        }else{
            savePayoffDataApi('preview');
        }
        
    }

    // const handlePayoffEmail = () => {
    //     if(payoffData?.toemail && isValidEmail(payoffData?.toemail)){
    //         savePayoffDataApi('sendemail');
    //     }else{
    //         setEmailError(true);
    //     }
       
    // }

    // const handlePayoffEmail = () => {
    //     if(payoffData?.toemail && !isValidEmail(payoffData?.toemail)){
    //         setEmailError(true);
    //     }else if((payoffData?.toemail && isValidEmail(payoffData?.toemail)) || (payoffData?.sendtoborroweremail)){
    //         savePayoffDataApi('sendemail');
    //     }else if(!payoffData?.toemail || !payoffData?.sendtoborroweremail){
    //         setEmailError(true);
    //         dispatch(showLoader());
    //         setPopupOpen(true);
    //         setMessage("Either 'Send to Email' or 'Send to Borrower Email' is required.");
    //         dispatch(hideLoader());
    //     }
       
    // };

    const handlePayoffEmail = () => {
        // Validate the 'toemail' array - check if all emails are valid
        const invalidEmails = payoffData?.toemail?.filter(email => !isValidEmail(email));
        
        if (payoffData?.toemail && invalidEmails?.length !== 0) {
            // If there are invalid emails, set error
            setEmailError(true);
            //setPopupOpen(true);
            //setMessage(`Invalid email(s) found: ${invalidEmails.join(", ")}`);
        } else if ((payoffData?.toemail?.length > 0 && invalidEmails?.length === 0) || payoffData?.sendtoborroweremail) {
            // If all emails are valid or 'sendtoborroweremail' is true, call the API
            savePayoffDataApi('sendemail');
        } else if ((!payoffData?.toemail || payoffData?.toemail?.length === 0) && !payoffData?.sendtoborroweremail) {
            // If both fields are empty, show error and required message
            setEmailError(true);
            dispatch(showLoader());
            setPopupOpen(true);
            setMessage("Either 'Send to Email' or 'Send to Borrower Email' is required.");
            dispatch(hideLoader());
        }
    };
    

    const closePopup2 = () => {
        
        setShowConfirmation(false);
    };

    const openDeleteConfirmation = (row, index) => {
        setDeleteData(row); // Save the data for deletion
        setDeleteIndex(index); // Save the index for deletion
        setShowConfirmation(true); // Show the confirmation popup
    };
    // useEffect(() => {
    //     // This effect will run when formData or other dependencies change
    //     const newTotalSavedFees = formData.reduce((acc, item) => {
    //         const amount = parseFloat(item.amount) || 0; // Safely parse amounts
    //         return item.credit ? acc - amount : acc + amount;
    //     }, 0);
    
    //     // Ensure that all variables are numbers, default to 0 if any are undefined
    //     const remainingPrincipalBalance = parseFloat(payoffData?.remainingPrincipalBalance) || 0;
    //     const outstandingInterest = parseFloat(payoffData?.outstandingInterest) || 0;
    //     const perdiemDaysTotal = parseFloat(payoffData?.perdiemDaysTotal) || 0;
    
    //     const newPayoffAmount = remainingPrincipalBalance + outstandingInterest + newTotalSavedFees + perdiemDaysTotal;
    
    //     setPayOffData(prevData => ({
    //         ...prevData,
    //         totalsavedfeesamount: newTotalSavedFees, // This will work as newTotalSavedFees is guaranteed to be a number
    //         totalpayoffamount: newPayoffAmount // This will now work as well
    //     }));
    // }, [formData, payoffData?.remainingPrincipalBalance, payoffData?.outstandingInterest, payoffData?.perdiemDaysTotal]);
    
    

    // useEffect(() => {
    //     // This effect will run when formData or other dependencies change
    //     const newTotalSavedFees = formData.reduce((acc, item) => {
    //         const amount = parseFloat(item.amount) || 0;
    //         return item.credit ? acc - amount : acc + amount;
    //     }, 0);
    
    //     const newPayoffAmount = remainingPrincipalBalance + outstandingInterest + newTotalSavedFees + perdiemDaysTotal; // Add all necessary values
        
    //     setPayOffData(prevData => ({
    //         ...prevData,
    //         totalsavedfeesamount: newTotalSavedFees.toFixed(2),
    //         totalpayoffamount: newPayoffAmount.toFixed(2)
    //     }));
    // }, [formData, remainingPrincipalBalance, outstandingInterest, perdiemDaysTotal]); // Add other dependencies as necessary
    
    // useEffect(() => {
    //     if(addConfirm || deleteConfirm){
    //         savePayoffDataApi('addFee');
    //     }
       
    // },[addConfirm,deleteConfirm])

    const handleEmailKeyDown = (e) => {
        // On comma, enter, or space, we want to add the email
        if (["Enter", ",", " "].includes(e.key)) {
            e.preventDefault();
            const email = emailInput.trim();
            if (email && isValidEmail(email)) {
                if (!payoffData.toemail.includes(email)) {
                    setPayOffData((prevData) => ({
                        ...prevData,
                        toemail: [...prevData.toemail, email], // Add new email to the array
                    }));
                }
                setEmailInput(""); // Clear input
            } else {
                setEmailError("Invalid Email!");
            }
        }
    };

    const handleEmailChange = (e) => {
        setEmailInput(e.target.value);
        setEmailError(null); // Reset error when typing
    };

    const removeEmail = (emailToRemove) => {
        setPayOffData((prevData) => ({
            ...prevData,
            toemail: prevData.toemail.filter((email) => email !== emailToRemove),
        }));
    };

    return (
        <div id="layout-wrapper" className="dashboard">
            {loading ? <Loader /> : null}
            {isPopupOpen && (
                <PopupMessage
                    // type={message === 'Deleted Succesfully' || PopupMessage === 'Unable to load the document preview. Please try again later.' ? 'warning' : 'success'}
                    type={
                        message === "Either 'Send to Email' or 'Send to Borrower Email' is required."
                        ? 'warning' : 'success'}
                    message={message}
                    onClose={closePopup}
                />
            )}
            {showConfirmation && (
                <SweetAlert
                    warning
                    showCancel
                    confirmButtonText="Yes, delete it!"
                    cancelButtonText="No, cancel!"
                    title="Are you sure?"
                    onConfirm={() => handleDeleteSavedfee(deleteData, deleteIndex)} // Pass the correct data and index
                    onCancel={closePopup2}
                    focusCancel={true}
                    customButtons={[
                        React.createElement('button', {
                            id: 'delete-credit-yes',
                            className: 'btn btn-primary',
                            onClick: () => handleDeleteSavedfee(deleteData, deleteIndex), // Ensure correct data is passed
                            style: { marginRight: '8px', fontSize: '16px' }
                        }, 'Yes'),
                        React.createElement('button', {
                            id: 'delete-credit-no',
                            className: 'btn btn-light',
                            onClick: closePopup2,
                            style: { fontSize: '16px' }
                        }, 'No')
                    ]}
                >
                    <p>Do you really want to delete this Saved Fee or Credit?</p>
                </SweetAlert>
            )}


                <PipelineHeader />
            <div className="dashboard_block">
            <PipelineStatus />

            <h2 className="form_title">Servicing <span className="space">&#10095;</span>  <span className="sub">Payoff</span> </h2>
            {!loanInfo?.servicingsetup?.isServiceSetupCompleted && !configdata?.common?.showServicingForms ? 
            <Blockingtextmsg  textmessage={blockingtext}/> : 
            <div className="card user_management detailed_app services_form pay_off">
                    <div className="card-body my_account">
                        <div className="form_section">
                            <div className="row">
                                <div className="col-md-3 principal_balance">
                                    <div className="form-group">
                                    <div className="detail_block">
                                        <label>Remaining Principal Balance</label>
                                        <p>${payoffData?.remainingprincipalbalance}</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-md-3 principal_balance">
                                    <div className="form-group">
                                    <div className="detail_block">
                                        <label>Remaining Reserves</label>
                                        <p>${payoffData?.remainingreserves}</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-md-4 principal_balance">
                                    <div className="form-group">
                                    <div className="detail_block d-flex align-items-center justify-content-between">
                                    <div className="reamaing_balance">
                                        <label>Remaining Draw Balance</label>
                                        <p>${payoffData?.remainingdrawbalance}</p>
                                        </div>
                                    <div className="netfund_pay">
                                       <label className="toggle mb-0 mt-0">
                                            <span className="toggle-label me-3">Net Fund Pay Balance</span>
                                            <input className="toggle-checkbox" name="netfunddrawbalance" type="checkbox" onChange={handleSavedFeeChange} checked={payoffData?.credit}/>
                                            <div className="toggle-switch"></div>
                                        </label>
                                    </div>
                               
                                    </div>
                                    </div>
                                </div>
                               
                                {/* <div className="col-md-3">
                                   
                                </div> */}
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Payoff Date</label>
                                        {/* <DatePicker
                                            name ='payoffdate'
                                            selected={payoffData?.payoffdate ? payoffData?.payoffdate : new Date(selectedDate)}
                                            onChange={handleDateChange}
                                            dateFormat="MM/dd/yyyy"
                                            placeholderText="Select Date"
                                            className="form-control"
                                        /> */}
                                        <input type="date" className="form-control" placeholder="mm-dd-yyy" name="payoffdate" onChange={handleDateChange}  
                                            value={
                                                payoffData?.payoffdate 
                                                  ? new Date(payoffData.payoffdate).toISOString().split('T')[0] 
                                                  : new Date().toISOString().split('T')[0]
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Payoff Expiration Date</label>
                                        {/* <DatePicker
                                            name ='payoffexpirationdate'
                                            selected={selectedDate ? new Date(selectedDate) : null}
                                            onChange={handleDateChange}
                                            dateFormat="MM/dd/yyyy"
                                            placeholderText="Select Date"
                                            className="form-control"
                                        /> */}
                                        <input type="date" className="form-control" placeholder="mm-dd-yyy" name="payoffexpirydate" 
                                            onChange={handleDateChange}
                                            value={
                                                payoffData?.payoffexpirydate 
                                                  ? new Date(payoffData.payoffexpirydate).toISOString().split('T')[0] 
                                                  : ""
                                            }
                                            min={new Date().toISOString().split('T')[0]} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Daily Per Diem</label>
                                        <CurrencyInput
                                            id="PayOffDailyPerDiem"
                                            name="dailyperdiem"
                                            //   defaultValue={1000}
                                            placeholder="$0.00"
                                            decimalsLimit={2}
                                            className="form-control"
                                            prefix="$"
                                            groupSeparator=","
                                            value={payoffData?.dailyperdiem}
                                            onValueChange={(value, name, values) => {
                                                //console.log(values)
                                                handleCurrencyPayOffInputs(name,value)
                                            }}
                                        />
                                    </div>
                                </div>
                                
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Daily Per Diem # of Days</label>
                                        <input type="number" placeholder="Enter the Daily Perm Diem Days" className="form-control" name="dailyperdiemdays" 
                                        value={payoffData?.dailyperdiemdays} onChange={handleNonCurrencyInputs}/>
                                    </div>
                                </div>
                               
                                
                                
                                
                                {/* <div className="col-md-3 principal_balance">
                                    <div className="form-group">
                                        <label>Pre Payment Penality</label>
                                        <p>$ 0.00</p>
                                    </div>
                                </div> */}
                               
                                

                            </div>

                            {/* <hr className="hr" /> */}

                            <div className="row">
                              
                              
                            <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Overwrite Days for Per Diem Calculation</label>
                                        
                                        <input type="number" placeholder="Enter the Days for Per Diem Calculation" className="form-control" name="overwritedays"
                                            onChange={handleNonCurrencyInputs} value={payoffData?.overwritedays}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Bank Account Number</label>
                                        <input type="text" placeholder="Enter" className="form-control" name="bankaccountnumber" value={payoffData?.bankaccountnumber} onChange={handleNonCurrencyInputs}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Outstanding Payment As Of Date</label>
                                        <input type="date" className="form-control" placeholder="mm-dd-yyy" name="outstandingpaymentasofdate" value={payoffData?.outstandingpaymentasofdate} onChange={handleDateChange}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Outstanding Payment</label>
                                        {/* <input type="text" placeholder="Enter" className="form-control" /> */}
                                        <CurrencyInput
                                            id="PayOffoutstandingPayment"
                                            name="outstandingpayment"
                                            //   defaultValue={1000}
                                            placeholder="$0.00"
                                            decimalsLimit={2}
                                            className="form-control"
                                            prefix="$"
                                            groupSeparator=","
                                            value={payoffData?.outstandingpayment}
                                            onValueChange={(value, name, values) => {
                                                //console.log(values)
                                                handleCurrencyPayOffInputs(name,value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Per Diem Day(s) Total</label>
                                        <CurrencyInput
                                            id="payOffPerDiemDaysTotal"
                                            name="perdiemdaystotal"
                                            //   defaultValue={1000}
                                            placeholder="$0.00"
                                            decimalsLimit={2}
                                            className="form-control"
                                            prefix="$"
                                            groupSeparator=","
                                            value={payoffData?.perdiemdaystotal}
                                            onValueChange={(value, name, values) => {
                                                //console.log(values)
                                                handleCurrencyPayOffInputs(name,value);
                                            }}
                                            readOnly
                                            style={{background:'rgb(233, 236, 239)'}}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 allsavedfee_amount">
                                    <div className="form-group">
                                        <label className="d-flex align-item-center">Total Saved Fees</label>
                                        <CurrencyInput
                                            id="savedFeesTotalAmountt"
                                            name="totalsavedfeesamount"
                                            //   defaultValue={1000}
                                            placeholder="$0.00"
                                            decimalsLimit={2}
                                            className="form-control"
                                            prefix="$"
                                            groupSeparator=","
                                            value={(payoffData?.totalsavedfeesamount) ? Math.abs(parseFloat((payoffData?.totalsavedfeesamount).toFixed(2))) : parseFloat(0)}
                                            onValueChange={(value, name, values) => {
                                                //console.log(values)
                                                handleCurrencyPayOffInputs(name,value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 payoff_amount">
                                    <div className="form-group">
                                        <label className="d-flex align-item-center">Total Payoff Amount
                                            <span className="payoff-question-mark" style={{ cursor: 'pointer', marginLeft: '5px' }}>?</span>
                                            <div className="payoff-formula-popup">
                                                <p>Total Payoff Amount -</p>
                                                <p>Remaining Principal Balance + Outstanding Interest + All
                                                Fees + Per Diem Days Total + Total Saved Fees and Credits</p>
                                            </div>
                                        </label>
                                        <CurrencyInput
                                            id="PayOffTotalAmountt"
                                            name="totalpayoffamount"
                                            //   defaultValue={1000}
                                            placeholder="$0.00"
                                            decimalsLimit={2}
                                            className="form-control"
                                            prefix="$"
                                            groupSeparator=","
                                            value={(payoffData?.totalpayoffamount) ? Math.abs(parseFloat(payoffData.totalpayoffamount)).toFixed(2) : null}
                                            onValueChange={(value, name, values) => {
                                                //console.log(values)
                                                handleCurrencyPayOffInputs(name,value);
                                            }}
                                            readOnly
                                            style={{background:'rgb(233, 236, 239)'}}
                                        />
                                        
                                    </div>
                                    
                                    
                                    
                                </div>
                               
                             
                                {/* <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Fee or Credit Description</label>
                                        <input type="text" placeholder="Enter the Fee or Credit Description" className="form-control" name="feeDescription"
                                            onChange={handleNonCurrencyInputs} value={savedFees?.feeDescription}/>
                                    </div>
                                </div> */}
                                {/* <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Amount</label>
                                        <CurrencyInput
                                            id="PayOffAmount"
                                            name="payoffamount"
                                            placeholder="$0.00"
                                            decimalsLimit={2}
                                            className="form-control"
                                            prefix="$"
                                            groupSeparator=","
                                            value={savedFees?.payoffamount}
                                            onValueChange={(value, name, values) => {
                                                handleCurrencyPayOffInputs(name,value);
                                            }}
                                        />
                                    </div>
                                </div> */}
                                {/* <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Per Diem Days Total</label>
                                        <input type="text" placeholder="Enter" className="form-control" />
                                    </div>
                                </div> */}
                            </div>
                            <hr className="hr" />

                            <h3 className="title">ALL SAVED Fees & credits</h3>
                          
                            <div className="row">
                            <div className="col-md-3">
                            <div className="form-group">
                                            <label>Amount</label>
                                            <CurrencyInput
                                                inputId='amount'
                                                name='amount'
                                                className={`form-control ${errors['amount'] && 'invalid-input'}`}
                                                placeholder='$0.00'
                                                // defaultValue={payoffData.amount || ''}
                                                decimalsLimit={2}
                                                prefix="$"
                                                value={savedFees?.amount}
                                                onFocus={handleFocus}
                                                onValueChange={(value, name, values) => {
                                                    
                                                    handleCurrencyPayOffInputs(name,value);
                                                }}
                                            />
                                            {/* <input type="text" className="form-control" placeholder="Enter" /> */}
                                        </div>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="form-group">
                                                <label>Fee or Credit Description</label>
                                                <input type="text" inputmode="decimal" name="description" onFocus={handleFocus} placeholder="Enter a brief description of the fee or credit" inputid="amount" onChange={handleSavedFeeChange} value={savedFees?.description} className={`form-control ${errors['description'] && 'invalid-input'}`} />
                                                {/* <textarea id="creditDescription" name="description" onChange={handleSavedFeeChange} value={savedFees?.description} onFocus={handleFocus} rows="4" cols="50" className={`form-control ${errors['description']  && 'invalid-input'}`} placeholder="Enter Description"> </textarea> */}
                                            </div>
                                        </div>
                                        <div className="col-md-1 interest_only">
                                        <div className="form-group">
                                        <label></label>
                                        <label className="toggle mb-0">
                                            <span className="toggle-label me-3">Credit</span>
                                            <input className="toggle-checkbox"  name='credit' onChange={handleSavedFeeChange} checked={savedFees.credit} type="checkbox" />
                                            <div className="toggle-switch"></div>
                                        </label>
                                    </div>
                                    </div>
                                     <div className="col-md-1 hide_lable">
                                  
                                        <div className="form-group">
                                        <label>a</label>
                                            <button onClick={(e)=>{handleAddSavedFees()}} className="fs_btn">Add</button>
                                        </div>
                                        </div>
                                        </div>
                               
                                <div className="right">
                                    <div className="table-responsive">
                                        <DataTable 
                                        // key={tableKey}
                                            columns={columns}
                                            data={formData}
                                            //pagination
                                            customStyles={customStyles}
                                            persistTableHead
                                            // paginationTotalRows={totalRows}
                                            // paginationServer
                                            // paginationPerPage={perPage}
                                            // paginationDefaultPage={page}
                                            // // onChangeRowsPerPage={handlePerRowsChange}
                                            // // onChangePage={handlePageChange}
                                            // // onSort={handleSort}
                                            // // sortServer={true}
                                            // paginationRowsPerPageOptions={[5,10,15]}
                                            // paginationComponentOptions={{
                                            //     rowsPerPageText: 'Per Page',
                                            // }}
                                        />

                                        {/* <table className="table data_table">

                                            <thead className="table-light">
                                                <tr>
                                                    <th width="23%">CrEATED bY</th>
                                                    <th width="20%">dATE aDDED</th>
                                                    <th width="23%">DESCRIPTION</th>
                                                    <th width="17%">aMOUNT (USD)</th>
                                                    <th width="5%">Delete</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Jason Hemm</td>
                                                    <td>4th March, 2024</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>$ 30.00</td>
                                                    <td>
                                                        <div className="btn-group">
                                                            <button type="button" className="btn btn-outline-secondary"><span className="material-symbols-outlined icon"> delete </span></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Jason Hemm</td>
                                                    <td>4th March, 2024</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>$ 30.00</td>
                                                    <td>
                                                        <div className="btn-group">
                                                            <button type="button" className="btn btn-outline-secondary"><span className="material-symbols-outlined icon"> delete </span></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Jason Hemm</td>
                                                    <td>4th March, 2024</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>$ 30.00</td>
                                                    <td>
                                                        <div className="btn-group">
                                                            <button type="button" className="btn btn-outline-secondary"><span className="material-symbols-outlined icon"> delete </span></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Jason Hemm</td>
                                                    <td>4th March, 2024</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>$ 30.00</td>
                                                    <td>
                                                        <div className="btn-group">
                                                            <button type="button" className="btn btn-outline-secondary"><span className="material-symbols-outlined icon"> delete </span></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Jason Hemm</td>
                                                    <td>4th March, 2024</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>$ 30.00</td>
                                                    <td>
                                                        <div className="btn-group">
                                                            <button type="button" className="btn btn-outline-secondary"><span className="material-symbols-outlined icon"> delete </span></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Jason Hemm</td>
                                                    <td>4th March, 2024</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>$ 30.00</td>
                                                    <td>
                                                        <div className="btn-group">
                                                            <button type="button" className="btn btn-outline-secondary"><span className="material-symbols-outlined icon"> delete </span></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Jason Hemm</td>
                                                    <td>4th March, 2024</td>
                                                    <td>Lorem Ipsum</td>
                                                    <td>$ 30.00</td>
                                                    <td>
                                                        <div className="btn-group">
                                                            <button type="button" className="btn btn-outline-secondary"><span className="material-symbols-outlined icon"> delete </span></button>
                                                        </div>
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table> */}

                                    </div>
                                </div>
                          
                            <h3 className="title mt-4">Additional Payoff Letter Verbiage</h3>
                            <div className="row ">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        {/* <ReactQuill
                                            id="userInfoAboutMe"
                                            style={{ height: "350px" }}
                                            theme="snow"
                                            value={quillValue}
                                            onChange={setQuillValue}
                                            modules={modules}
                                            // formats={["header"]}
                                            formats={["header", "bold", "italic", "underline", "strike", "link", "image", "blockquote", "code-block", "list", "indent", "align", "color",
                                                "background", "script"]}
                                        /> */}
                                       <textarea 
                                            id="userInfoAboutMe"
                                            //style={{ height: "350px" }} 
                                            name="additionalpayoffletterverbage" 
                                            placeholder='Enter additional details or special instructions for the payoff letter'
                                            // rows="5" 
                                            // cols="30"
                                            value={payoffData?.additionalpayoffletterverbage}
                                            onChange={handleNonCurrencyInputs}
                                            className='form-control'
                                        >  
                                        </textarea>
                                        {/* <img className="w-100" src="assets/images/texteditor.jpg" alt="" /> */}
                                    </div>
                                </div>
                                {/* <div className="col-md-6">

                                </div> */}
                                {/* <div className="col-md-4">

                                </div> */}
                               
                                {/* <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Send to Email</label>
                                        <input 
                                            type="text" 
                                            placeholder="Enter recipient's email address" 
                                            //className={`form-control ${emailError === true && 'invalid-input'}`} 
                                            className={`form-control `}
                                            name="toemail" 
                                            value={payoffData.toemail} 
                                            onChange={handleNonCurrencyInputs} 
                                            onFocus={() => setEmailError(null)} // Use an arrow function here
                                        />
                                        
                                        {payoffData?.toemail && emailError === true && !isValidEmail(payoffData.toemail)
                                            && (<p style={{color : 'red',fontSize: '1.2em'}}>Invalid Email!</p>)
                                        }

                                    </div>
                                </div> */}
                                 <div className="col-md-6">
                                    <div className="form-group mb-2">
                                        <label>Send to Email</label>
                                        <div className="email-input-container">
                                            
                                            {/* Input field for email */}
                                            <input
                                                type="text"
                                                placeholder="Enter recipient's email address"
                                                className="form-control"
                                                value={emailInput}
                                                onChange={handleEmailChange}
                                                onKeyDown={handleEmailKeyDown}
                                                onBlur={() => {
                                                    // On blur, validate and add email
                                                    if (isValidEmail(emailInput.trim())) {
                                                        setPayOffData((prevData) => ({
                                                            ...prevData,
                                                            toemail: [...prevData.toemail, emailInput.trim()],
                                                        }));
                                                        setEmailInput(""); // Clear input
                                                    } else if (emailInput.trim()) {
                                                        setEmailError("Invalid Email!");
                                                    }
                                                }}
                                            />
                                            {/* Render Email Tags */}
                                            <div className="selected_email">
                                            {payoffData.toemail.map((email, index) => (
                                             
                                                <div className="email" key={index}>
                                                    {email}
                                                    <span className="material-symbols-outlined"
                                                        onClick={() => removeEmail(email)}
                                                        style={{marginLeft: '8px', cursor: 'pointer'}}
                                                    >
                                                        close
                                                    </span>
                                                </div>
                                              
                                            ))}
                                             </div>
                                        </div>

                                        {/* Error message for invalid email */}
                                        {emailError && payoffData?.toemail?.length !== 0 && <p style={{color: 'red', fontSize: '1.2em'}}>Invalid email!</p>}
                                    </div>
                                    {/* Instructional message for multiple emails */}
                                    <p className="help_text">
                                       You can add multiple email addresses. Press "Enter" to add.
                                    </p>
                                </div>
                                <div className="col-md-2 interest_only">
                                    <div className="form-group">
                                        <label></label>
                                        <label className="toggle mb-0">
                                            <span className="toggle-label">Send To Borrower Email</span>
                                            <input className="toggle-checkbox" type="checkbox" name="sendtoborroweremail" onChange={handleSavedFeeChange} checked={payoffData?.sendtoborroweremail} />
                                            <div className="toggle-switch"></div>
                                        </label>
                                    </div>
                                </div>
                                {/* <div className="col-md-2 interest_only">
                                    <div className="form-group">
                                        <label></label>
                                        <label className="toggle mb-0">
                                            <span className="toggle-label">Send To Borrower Portal</span>
                                            <input className="toggle-checkbox" type="checkbox" />
                                            <div className="toggle-switch"></div>
                                        </label>
                                    </div>
                                </div> */}
                                <div className="col-md-4">
                                    <div className="form-group">

                                    </div>
                                </div>

                                

                            </div>
                            <div className="btn-group mt_10 mb-4">

                                <button className="fs_btn" onClick={handlePreview}>Preview of Payoff Letter<span className="material-symbols-outlined"><span className="material-symbols-outlined">visibility</span></span></button>
                                <button className="fs_btn fill_btn" onClick={handlePayoffEmail}>Send Payoff Letter<span className="material-symbols-outlined">send</span></button>
                            </div>
                           
                        </div>

                    </div>
                </div>
            }  
            </div>
            <PipelineFooter />
        </div>
    );
};

export default ServicesPayoff;