import { createContext, useContext, useState } from 'react';

const UserTypeContext = createContext();

export const UserTypeProvider = ({ children }) => {
  const [useType, setUseType] = useState('');

  return (
    <UserTypeContext.Provider value={{ useType, setUseType }}>
      {children}
    </UserTypeContext.Provider>
  );
};

export const useUserType = () => {
  return useContext(UserTypeContext);
};